import { Button, chakra, VStack, ButtonGroup, Container, Box } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BottomNavBar from '../../../BottomNavBar';
import ChildHeader from '../ChildHeader';
import Loading from '../../../Loading';
function ConferePedido() {
  const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
  const navigate = useNavigate();
  const { state } = useLocation();
  const arrName = state.medicoNome.split(' ');
  const [splitRules, setSplitRules] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { agenda } = state;
  console.log(state);

  async function getSplitRules(agendaId) {
    try {
      const url = process.env.REACT_APP_BASE_URL_API_SERVER + '/schedule/splitRules/' + agendaId + '/' + state.procedimentoId;
      const options = {
        headers: { 'Content-Type': 'application/json' },
        method: 'GET',
      };
      const response = await fetch(url, options);
      const splitRules = await response.json();
      console.log(splitRules);
      setSplitRules(splitRules);
      setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (agenda.split_pagamento) {
      getSplitRules(agenda.AVAILABILITY_LID);
    } else {
      setLoading(false);
    }
  }, []);
  return loading ? (
    <Loading />
  ) : (
    <>
      <ChildHeader />
      <VStack
        w={{
          base: '90%',
          md: '500px',
        }}
        m="auto"
        alignItems="left"
        spacing={1}
        marginBottom="10%"
      >
        <chakra.h1 fontWeight="bold" margin="5% 0" fontSize="xl">
          Informações do Pedido:
        </chakra.h1>

        <chakra.p fontWeight="bold">
          {state.agenda.ACTIVITY_NAME ? state.agenda.ACTIVITY_NAME.toUpperCase() + ' COM ' : ''}
          {arrName[0].toUpperCase()} {arrName[1].toUpperCase()}
        </chakra.p>
        <chakra.p color="gray.500">paciente:</chakra.p>
        <chakra.p fontWeight="bold">{state.paciente.nome.toUpperCase()}</chakra.p>
        <chakra.p color="gray.500">procedimento:</chakra.p>
        <chakra.p fontWeight="bold">{state.procedimento}</chakra.p>
        <chakra.p color="gray.500">Data:</chakra.p>
        <chakra.p fontWeight="bold">
          {state.agenda.AVA_DATE}
          {' as '}
          {state.agenda.AVA_START_TIME}
        </chakra.p>
        <chakra.p color="gray.500">Local:</chakra.p>
        <chakra.p fontWeight="bold">{state.clinica}</chakra.p>
        <chakra.p color="black.500" fontSize="xs">
          {' '}
          {state.logradouro}, {state.numero} - {state.bairro} - {state.municipio}
        </chakra.p>
        <chakra.p color="gray.500">valor:</chakra.p>
        <chakra.p fontWeight="bold">R$ {state.valor}</chakra.p>
        <Container maxW="2xl" leftContent>
          <Box padding="1" borderRadius="md" borderWidth="1px" margin="5% 0" bcolor="black" maxW="sm">
            <chakra.p color="black">Orientações:</chakra.p>
            <chakra.p color="red.500" margin="5% 0" fontSize="sm">
              {state.agenda.preparo}
            </chakra.p>
          </Box>
        </Container>

        <ButtonGroup pt="1rem" pb="15%" width="100%">
          <Button
            colorScheme="teal"
            width="100%"
            onClick={() => {
              if (!splitRules) {
                if (!state.valor || state.valor === 0 || state.valor === '0.00' || state.valor === '0') {
                  navigate('/atendimento/loading/', {
                    state: {
                      valor: 0,
                      paciente: state.paciente,
                      agenda: state.agenda,
                      medico: state.medico,
                      orientacoes: state.orientacoes,
                      confim: true,
                      transaction_id: 9999999,
                      procedimentoId: state.procedimentoId,
                      procedimento: state.procedimento,
                      product_name: state.agenda.ACTIVITY_NAME,
                      paid: true,
                    },
                  });
                } else {
                  navigate('/pagamentos', {
                    state: {
                      parcelsActive: true,
                      valor: state.valor,
                      expiration_pix_in_min: 5,
                      service: 'schedule',
                      navig_to: '/alerts/sucesso/agendamento/',
                      item: {
                        cpf: onlyNumbers(state.paciente.cpf),
                        nome: state.paciente.nome,
                        fone: state.paciente.celular ? state.paciente.celular : state.paciente.phone1,
                        email: state.paciente.email,
                        scheduleId: state.agenda.AVAILABILITY_LID,
                        procedimento_convenio_id: state.procedimentoId,
                        product_name: state.agenda.ACTIVITY_NAME,
                        confim: true,
                      },
                    },
                  });
                }
              } else {
                navigate('/pagamentos', {
                  state: {
                    parcelsActive: true,
                    valor: state.valor,
                    split: splitRules.split_rules,
                    expiration_pix_in_min: 5,
                    service: 'schedule',
                    navig_to: '/alerts/sucesso/agendamento/',
                    item: {
                      cpf: onlyNumbers(state.paciente.cpf),
                      nome: state.paciente.nome,
                      fone: state.paciente.celular ? state.paciente.celular : state.paciente.phone1,
                      email: state.paciente.email,
                      scheduleId: state.agenda.AVAILABILITY_LID,
                      procedimento_convenio_id: state.procedimentoId,
                      product_name: state.agenda.ACTIVITY_NAME,
                      confim: true,
                    },
                  },
                });
              }
            }}
          >
            continuar
          </Button>
        </ButtonGroup>
      </VStack>
      <BottomNavBar />
    </>
  );
}

export default ConferePedido;
