import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  Stack,
  DrawerOverlay,
  Image,
  Button,
  useDisclosure,
  VStack,
  Center,
  Header,
  Heading,
  ListItem,
  chakra,
  Flex,
  Text,
  HStack,
  List,
  ButtonGroup,
  Input,
  Radio,
} from '@chakra-ui/react';
import React from 'react';
import { MdOutlineLocationOn } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useState } from 'react';
import DrawerHorario from './DrawerHorario';
import { useContext } from 'react';
import { AccountContext } from 'components/AccountContext';
function DrawerProcedimento(props) {
  const disclosure = props.disclosure;
  const unidade = props.data;
  const { empresa, endereco, numero, municipio, estado, distancia } = unidade[0];
  const { user } = useContext(AccountContext);
  const drawerHorarios = useDisclosure();
  const [pedido, setPedido] = useState();
  const navigate = useNavigate();
  let total_deovita = 0;
  let total_particular = 0;
  const useProcedure = unidade.map((procedure) => {
    total_deovita += parseFloat(procedure.valor_deovita);
    total_particular += parseFloat(procedure.valor_particular);
    return (
      <chakra.tr w="100%" borderBottom="1px solid" borderColor="#585858">
        <Flex mt="1em">
          <Text fontSize="xs" w="70%" color="#585858" alignSelf="flex-end">
            {procedure.nome}
          </Text>
          {user.id_contrato === 0 || user.id_contrato === null || user.id_contrato === undefined ? (
            <Text fontSize="sm" color="#EB8B2B" alignSelf="flex-end">
              {parseFloat(procedure.valor_particular).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
            </Text>
          ) : (
            <></>
          )}
          <Text fontSize="sm" color="#5AB2AD" pl="5%" minW="25%" alignSelf="flex-end">
            {parseFloat(procedure.valor_deovita).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
          </Text>
        </Flex>
      </chakra.tr>
    );
  });
  return (
    <>
      <Drawer isOpen={disclosure.isOpen} onClose={disclosure.onClose} placement="bottom">
        <DrawerHorario
          disclosure={drawerHorarios}
          empresa={unidade}
          valor={user.id_contrato > 0 ? total_deovita : total_particular}
          economia={total_particular - total_deovita}
        />
        <DrawerOverlay />
        <DrawerContent borderTopRadius={20} width="100%" h="80%">
          <DrawerBody pb="10%">
            <Stack direction="column" spacing={4} paddingTop={30} align="center">
              <Radio w="3em" autoFocus hidden></Radio>
              <List w="95%" spacing={4}>
                <ListItem
                  fontWeight="bold"
                  bgColor="rgba(90, 178, 173, 0.3)"
                  borderRadius="20px"
                  shadow="md"
                  mt="0.8em"
                  width="100%"
                  backgroundColor="gray.100"
                  minH="3em"
                  pl="5em"
                  m="2%"
                >
                  <Flex w="100%" minH="40%" maxH="50%">
                    <Image
                      src={`${process.env.REACT_APP_URL_LOGOMARCA}/` + empresa.empresa_id + '/logomarca.png'}
                      fallbackSrc={`${process.env.REACT_APP_URL_LOGOMARCA}/logomarca.png`}
                      backgroundColor="white"
                      border="1px"
                      h="5em"
                      w="5em"
                      ml="-6em"
                      mr="5%"
                      mt="0.5em"
                      mb="0.5em"
                      borderRadius="1.5em"
                    />

                    <VStack>
                      <VStack align="right" h="100%" spacing={0} minW="50%" maxH="100%">
                        <Text fontSize="xs" mt="2" textColor="gray" textAlign="start" maxW="80" lineheight="24.38px" noOfLines={2}>
                          {empresa}
                        </Text>

                        <Flex>
                          <Box>
                            {' '}
                            <Text fontSize="9px" fontWeight={5} textAlign="left" textColor="gray.400" noOfLines={1}>
                              {endereco}, {numero},
                            </Text>
                            <Text fontSize="9px" fontWeight={5} textAlign="left" textColor="gray.400" isTruncated>
                              - {municipio} / {estado}
                            </Text>
                          </Box>
                        </Flex>
                      </VStack>
                    </VStack>
                    <Box pb="2%" pt="10%">
                      {' '}
                      <HStack w="100%">
                        <Flex>
                          <MdOutlineLocationOn class="googlemap" color="green" />{' '}
                          <chakra.span color="orange" fontWeight={5} fontSize="9px">
                            {distancia ? distancia.toFixed(2) + ' km' : ''}
                          </chakra.span>
                        </Flex>{' '}
                      </HStack>
                    </Box>
                  </Flex>
                </ListItem>
              </List>
              <Heading color="#585858" size="sm" w="100%">
                Procedimento(s):
              </Heading>
            </Stack>

            <chakra.table w="100%">
              <chakra.tr w="100%">
                <Flex mt="1em">
                  <Text fontSize="xs" w="80%" color="#585858" alignSelf="flex-end"></Text>
                  {user.id_contrato === 0 || user.id_contrato === null || user.id_contrato === undefined ? (
                    <Text fontSize="sm" color="#585858" alignSelf="flex-end">
                      Particular
                    </Text>
                  ) : (
                    <></>
                  )}
                  <Text fontSize="sm" pl="5%" color="#585858" alignSelf="flex-end" minW="25%">
                    DeoVita
                  </Text>
                </Flex>
              </chakra.tr>
              {useProcedure}
            </chakra.table>
            <VStack w="100%">
              <Text color="#585858" size="sm" w="100%" mt="10%" textAlign="center" mb="10%">
                Valor {user.id_contrato === 0 || user.id_contrato === null || user.id_contrato === undefined ? <>Particular</> : <></>} :{' '}
                <chakra.span color="#5AB2AD">
                  {user.id_contrato > 0
                    ? parseFloat(total_deovita).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                    : parseFloat(total_particular).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                </chakra.span>
              </Text>

              <Button
                width="100%"
                bgColor="#5AB2AD"
                color="white"
                onClick={() => {
                  drawerHorarios.onOpen();
                }}
              >
                Continuar
              </Button>
              <Button
                width="100%"
                variant="link"
                color="#5AB2AD"
                onClick={() => {
                  disclosure.onClose();
                }}
              >
                Voltar
              </Button>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default DrawerProcedimento;
