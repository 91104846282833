import { useContext, useEffect } from "react";
import requestTimeout from "../../../Utils/Functions/requestTimeout";
import { AccountContext } from "../../../AccountContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import Loading from "../../../LoadingWithHeader";

const ProcessarCompra = () => {
  const { user, setUser, setPage } = useContext(AccountContext);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [erro, setErro] = useState(false);
  const timeOutInSeconds = process.env.REACT_APP_REQUEST_TIMEOUT_IN_SECONDS;

  setPage("Processando compra");

  function handleError() {
    setErro(true);
    setLoading(false);
  }

  function gravar() {
    let installmentsData = [
      {
        paciente_contrato_id: user.id_contrato,
        idPa: state.idPa,
        valor: state.valor,
        taxa_adesao: false,
        plano_id: user.id_plano,
        id_trasacao_gatway: state.transaction_id,
        classe: "adesao adicional",
        forma_pgto: state.forma_pgto,
      },
    ];

    const requestOptions = {
      signal: requestTimeout(timeOutInSeconds).signal,
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(installmentsData),
    };

    fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/extras/buyProntoAtendimento`, requestOptions)
      .catch((err) => {
        handleError();
        return;
      })
      .then((res) => {
        if (!res || !res.ok || res.status >= 400) {
          handleError();
          return;
        }
        return res.json();
      })
      .then((data) => {
        if (!data) return;
        if (data.success) {
          setLoading(false);
          setUser((prevUser) => {
            return {
              ...prevUser,
              adicionais: data.data,
            };
          });
        } else {
          handleError();
        }
      });
  }

  useEffect(() => {
    gravar();
  }, []);

  return loading ? <Loading /> : erro ? navigate("/alerts/erro/default") : navigate("/alerts/sucesso/default");
};

export default ProcessarCompra;
