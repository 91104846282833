import { React, useContext, useEffect, useState } from 'react';
import { chakra, Button, Heading, VStack, Text, Center, Stack, HStack, Alert, AlertIcon, AlertTitle, AlertDescription } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useNavigate, useLocation } from 'react-router';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import socketIOClient from 'socket.io-client';
import { AccountContext } from 'components/AccountContext';

const PixGenerico = (selecionado) => {
  console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
  const valor_selecionado = selecionado.selecionado;
  const mdvPercent = selecionado.mdvPercent;
  const mdvGerente = selecionado.mdvGerente;
  const mdvDiretor = selecionado.mdvDiretor;
  const { state } = useLocation();
  const { user } = useContext(AccountContext);
  console.log(state);
  const { expiration_pix_in_min, navig_to, split, voucher } = state;
  const fromCadastro = navig_to === '/cadastro/analise' ? true : false;
  const valor = fromCadastro ? valor_selecionado : state.valor;
  const product_name = fromCadastro ? 'Contratação DeoVita' : state.item.product_name;
  const navigate = useNavigate();
  const [pix, setPix] = useState({ sucess: '', pix_code: '', id: 0, paid: false });
  const [copy, setCopy] = useState(false);
  const [error, setError] = useState(null);
  const ENDPOINT = process.env.REACT_APP_BASE_URL_API_SERVER;
  const [socket] = useState(socketIOClient(ENDPOINT));
  const fromCadastroData = {
    client_id: state.client_id,
    nome: state.name,
    fone: user.phone1 ? user.phone1 : state.phone,
    adicionais: state.adicionais,
    plano: state.id_plano,
  };
  const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
  useEffect(() => {
    socket.on('retorno_pix', (response) => {
      if (response === true) {
        //setPix({ ...pix, paid: true });
        console.log('pix pago');
      }
    });
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        id_cliente: user.id_cliente_deovita ? user.id_cliente_deovita : state.client_id,
        nome_cliente: user.name ? user.name : state.name,
        document: user.username ? user.username : state.cpf,
        email: user.email ? user.email : 'novoassinante@deovita.com.br',
        phone: user && user.loggedIn === true ? (user.phone1 ? onlyNumbers(user.phone1) : onlyNumbers(user.phone2)) : state.phone,
        amount: fromCadastro === true ? valor_selecionado * 100 : valor * 100,
        expiration_in_min: expiration_pix_in_min,
        paid: false,
        split_rules: voucher
          ? voucher.recipient_id_gerente
            ? [
                { percentage: mdvGerente, recipient_id: voucher.recipient_id_gerente },
                { percentage: mdvDiretor, recipient_id: voucher.recipient_id_diretor },
                { percentage: mdvPercent, recipient_id: voucher.recipient_id_pagarme },
                {
                  percentage: 100 - mdvPercent - mdvDiretor - mdvGerente,
                  recipient_id: `re_clgwth495asjr019tm6ezd3yy`,
                },
              ]
            : voucher.recipient_id_diretor
            ? [
                { percentage: mdvDiretor, recipient_id: voucher.recipient_id_diretor },
                { percentage: mdvPercent, recipient_id: voucher.recipient_id_pagarme },
                {
                  percentage: 100 - mdvPercent - mdvDiretor,
                  recipient_id: `re_clgwth495asjr019tm6ezd3yy`,
                },
              ]
            : [
                {
                  percentage: mdvPercent,
                  recipient_id: voucher.recipient_id_pagarme,
                },
                {
                  percentage: 100 - mdvPercent,
                  recipient_id: `re_clgwth495asjr019tm6ezd3yy`,
                },
              ]
          : split,

        item: fromCadastro === true ? fromCadastroData : state.item,
        voucher: voucher,
      }),
    };

    const erroMsg = '"Não foi possível gerar seu código pix no momento"';
    fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/payment/${state.service}/pix`, requestOptions)
      .catch((err) => {
        setError(erroMsg);
        return;
      })
      .then((res) => {
        if (!res || !res.ok || res.status >= 400) {
          setError(erroMsg);
          return;
        }
        return res.json();
      })
      .then((data) => {
        if (!data) return;
        if (data.sucess === false && data.message === 'Já existe um contrato para esse paciente') {
          navigate('/alerts/sucesso/cadastro_contrato/');
        }
        if (data.sucess) {
          setError(null);
          socket.emit('pix', { duration: 5 * 60, id: data.id });
          setPix({ ...data, paid: false });
        } else {
          setError(erroMsg);
        }
      });
  }, []);

  function renderPixPaid() {
    return (
      <>
        <Alert status="success" variant="top-accent" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center">
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Pagamento confirmado!
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            <p>clique em finalizar para continuar</p>
          </AlertDescription>
        </Alert>
      </>
    );
  }

  function renderPix() {
    return (
      <>
        <Stack w="95%" padding="3">
          <Text as="p">Pix copia e cola</Text>
          <Text as="p">{pix.pix_code ? pix.pix_code.substring(0, 40) + '.........' : 'carregando...'} </Text>
          <Center w="100%">
            <CopyToClipboard text={pix.pix_code} onCopy={() => setCopy(true)}>
              <Button name="copy" colorScheme="teal" w="100%" variant="outline" disabled={pix.pix_code ? false : true}>
                {copy ? 'Copiado!' : 'Copiar'}
              </Button>
            </CopyToClipboard>
          </Center>
          <Center w="100%">O pix gerado é válido por 30 minutos</Center>
          <Center w="100%">
            <Text as="p" align="center" fontSize="small">
              Utilize a chave antes do fim deste tempo
            </Text>
          </Center>
        </Stack>
        <HStack w="95%" padding="3">
          <Text>{product_name}</Text>
          {fromCadastro ? (
            <Text>
              {valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
              <chakra.span>{fromCadastro === true ? (valor > 80 ? '/anual' : '/mês') : ''}</chakra.span>
            </Text>
          ) : (
            <></>
          )}
        </HStack>
        <Text>{error}</Text>
      </>
    );
  }

  return (
    <>
      <Formik validateOnChange={false} validateOnBlur={false}>
        <VStack as={Form} w={{ base: '90%', md: '500px' }} m="auto" justify="top" h="100vh" spacing="1rem" align="start">
          <Heading>Chave pix</Heading>
          {pix.paid ? renderPixPaid() : renderPix()}
          <Button
            colorScheme="teal"
            w="100%"
            //loadingText="...Aguardando pagamento"
            //isLoading={!pix.paid}
            isDisabled={pix.pix_code !== '' ? false : true}
            onClick={() => {
              console.log(state);
              fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/whatsapp/processa-pagamento`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  nome: state.name ? state.name : state.item.nome,
                  numero: user.phone1 ? user.phone1 : state.phone ? state.phone : state.item.fone,
                }),
              });
              navigate(navig_to, {
                state: {
                  ...state,
                  valor: valor,
                  transaction_id: pix.id,
                  forma_pgto: 'pix',
                  pix_code: pix.pix_code,
                },
              });
            }}
          >
            Continuar
          </Button>
        </VStack>
      </Formik>
    </>
  );
};

export default PixGenerico;
