import React, { useState, useEffect } from 'react';
import ChildHeader from '../../Dashboard/Main/ChildHeader';
import { Text, Button, VStack, Heading, Stack, HStack, chakra, Center, useColorModeValue, Alert, AlertIcon } from '@chakra-ui/react';
import { MdPendingActions } from 'react-icons/md';
import BottomNavBar from 'components/BottomNavBar';
import { useLocation } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';

import { MdCreditCard } from 'react-icons/md';
import Loading from 'components/Loading';

function DetalhesEmAnalise() {
  const { state } = useLocation();
  const { atendimento } = state;
  const [codigoPix, setCodigoPix] = useState('carregando');
  const [copy, setCopy] = useState(false);

  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const data = state.atendimento.APP_DATE;
  const formData = new Date(data ? data.replace(' ', 'T') : '');
  const dataCad = state.atendimento.DATA_ATUALIZACAO;
  console.log(formData);
  const formDataCad = new Date(data ? dataCad.replace(' ', 'T') : '');
  const appTime = state.atendimento.START_TIME;
  const [formaPagamento, setFormaPagamento] = useState();

  function myToLowerCase(text) {
    if (typeof text === 'string') {
      return text.toLocaleLowerCase();
    } else {
      return text;
    }
  }

  console.log(state);

  const fetchAppointments = async (agenda_id) => {
    const url = `${process.env.REACT_APP_BASE_URL_API_SERVER}/appointments/pending-payment/${agenda_id}`;
    const response = await fetch(url, {
      headers: { 'Content-Type': 'application/json' },
      method: 'GET',
    });
    const postsData = await response.json();
    setFormaPagamento(postsData.forma_pagamento);
    setCodigoPix(postsData.metadata.pix_code);
  };

  useEffect(() => {
    fetchAppointments(atendimento.APP_LID);
  }, []);

  return formaPagamento ? (
    formaPagamento === 'pix' ? (
      <>
        <ChildHeader />
        <VStack
          align="center"
          justify={{
            base: 'center',
            md: 'space-around',
            xl: 'space-between',
          }}
          direction={{ base: 'column-reverse' }}
          wrap="no-wrap"
          spacing={4}
          px={8}
          py={4}
        >
          <Stack direction="row" align="center">
            <Heading fontSize="lg">Processando Pagamento</Heading>
            <MdPendingActions size="50" />
          </Stack>

          <Center>
            <Stack
              borderWidth="1px"
              borderRadius="25px"
              borderColor={'yellow.400'}
              minW="105%"
              maxW="800px"
              direction={{ base: 'column', md: 'row' }}
              bg={'white'}
              boxShadow={'lg'}
              padding={4}
            >
              <VStack alignItems="right" spacing={4}>
                <Heading fontSize="xs">
                  {state.atendimento.ACTIVITY_NAME
                    ? state.atendimento.ACTIVITY_NAME
                    : 'CONSULTA COM ' + state.atendimento.ESPECIALIDADE.toUpperCase()}
                </Heading>
                <Text ml="-5" fontSize="0.7em" casing="capitalize">
                  {state.atendimento.RESOURCE_NAME === 'Sem Solicitante' ? '' : 'Dr. ' + myToLowerCase(state.atendimento.RESOURCE_NAME)}
                </Text>
                <chakra.p fontSize="0.7em">
                  <chakra.span fontWeight="bold"> Modalidade: </chakra.span>
                  {state.atendimento.TELEMEDICINA === true ? 'Telemedicina' : 'Presencial'}
                </chakra.p>
                <chakra.p fontSize="0.7em">
                  <chakra.span fontWeight="bold"> Data da consulta: </chakra.span>
                  {state.atendimento.RESOURCE_NAME === 'Sem Solicitante'
                    ? //formData.toLocaleDateString('pt-br', options) +
                      ' no turno da ' + (appTime === '06:00:00' ? 'manhã' : 'tarde') + ' (Atendimento por ordem de chegada)'
                    : formData.toLocaleDateString('pt-br', options)}
                </chakra.p>
                <chakra.p fontSize="0.7em">
                  <chakra.span fontWeight="bold"> Horário: </chakra.span>
                  {state.atendimento.RESOURCE_NAME === 'Sem Solicitante'
                    ? //formData.toLocaleDateString('pt-br', options) +
                      ' no turno da ' + (appTime === '06:00:00' ? 'manhã' : 'tarde') + ' (Atendimento por ordem de chegada)'
                    : appTime
                    ? appTime.split(':')[0] + ':' + appTime.split(':')[1]
                    : ''}
                </chakra.p>

                <chakra.p fontSize="0.7em">
                  <chakra.span fontWeight="bold"> Valor: </chakra.span>
                  R$ {state.atendimento.PRICE}
                </chakra.p>
              </VStack>
            </Stack>
          </Center>

          <Alert status="info" minW="80%" maxW="310px" fontSize="xs" borderRadius={20}>
            <AlertIcon />
            <Text fontSize="10">Caso ainda não tenha realizado o pagamento pague usando o codigo pix abaixo.</Text>
          </Alert>

          <HStack w="100%" textAlign="center">
            <Text w="100%" fontWeight="bold">
              Código Pix Copia e Cola
            </Text>
          </HStack>
          <HStack w="100%" textAlign="center">
            <Text w="100%" fontSize="12">
              {codigoPix.substring(0, 80) + '...'}
            </Text>
          </HStack>

          <CopyToClipboard text={codigoPix} onCopy={() => setCopy(true)}>
            <Button name="copy" colorScheme="teal" w="90%" variant="outline">
              {copy ? 'Copiado!' : 'Copiar'}
            </Button>
          </CopyToClipboard>

          <chakra.span textAlign="center" fontSize="sm" fontWeight="bold">
            O código acima tem validade de 30 minutos.
          </chakra.span>
          <Center>
            {' '}
            <chakra.span textAlign="center" fontSize="xs" fontWeight="bold" color="red">
              Caso o pagamento não seja realizado no prazo estipulado a sua aquisição será cancelada.
            </chakra.span>
          </Center>
          <VStack>
            <Stack direction="column" mb="12">
              <Text textAlign="center" fontSize="0.9em">
                Problemas com seu agendamento?
              </Text>
              <Button
                colorScheme="teal"
                borderRadius={8}
                height="40px"
                onClick={() => (window.location.href = 'https://wa.me/558881189898')}
                width="350px"
                variant="ghost"
              >
                Falar com Suporte
              </Button>
            </Stack>
          </VStack>
        </VStack>

        <BottomNavBar />
      </>
    ) : (
      <>
        <ChildHeader />
        <VStack
          align="center"
          justify={{
            base: 'center',
            md: 'space-around',
            xl: 'space-between',
          }}
          direction={{ base: 'column-reverse' }}
          wrap="no-wrap"
          spacing={5}
          px={4}
          py={6}
        >
          <Stack direction="row" align="center">
            <Heading fontSize="lg">Processando Pagamento</Heading>
            <MdCreditCard size="50" />
          </Stack>
          <Center>
            {' '}
            <Text alignItems="center">O pagamento de: </Text>
          </Center>

          <Center>
            <Stack
              borderWidth="1px"
              borderRadius="25px"
              // borderColor={'teal'}
              minW="100%"
              maxW="500px"
              direction={{ base: 'column', md: 'row' }}
              bg={'white'}
              boxShadow={'lg'}
              padding={4}
            >
              <VStack alignItems="right" spacing={4}>
                <Heading fontSize="xs">
                  {state.atendimento.ACTIVITY_NAME
                    ? state.atendimento.ACTIVITY_NAME
                    : 'CONSULTA COM ' + state.atendimento.ESPECIALIDADE.toUpperCase()}
                </Heading>
                <Text ml="-5" fontSize="0.7em" casing="capitalize">
                  {state.atendimento.RESOURCE_NAME === 'Sem Solicitante' ? '' : 'Dr. ' + myToLowerCase(state.atendimento.RESOURCE_NAME)}
                </Text>
                <chakra.p fontSize="0.7em">
                  <chakra.span fontWeight="bold"> Modalidade: </chakra.span>
                  {state.atendimento.TELEMEDICINA === true ? 'Telemedicina' : 'Presencial'}
                </chakra.p>
                <chakra.p fontSize="0.7em">
                  <chakra.span fontWeight="bold"> Data da consulta: </chakra.span>
                  {state.atendimento.RESOURCE_NAME === 'Sem Solicitante'
                    ? //formData.toLocaleDateString('pt-br', options) +
                      ' no turno da ' + (appTime === '06:00:00' ? 'manhã' : 'tarde') + ' (Atendimento por ordem de chegada)'
                    : formData.toLocaleDateString('pt-br', options)}
                </chakra.p>
                <chakra.p fontSize="0.7em">
                  <chakra.span fontWeight="bold"> Horário: </chakra.span>
                  {state.atendimento.RESOURCE_NAME === 'Sem Solicitante'
                    ? //formData.toLocaleDateString('pt-br', options) +
                      ' no turno da ' + (appTime === '06:00:00' ? 'manhã' : 'tarde') + ' (Atendimento por ordem de chegada)'
                    : appTime
                    ? appTime.split(':')[0] + ':' + appTime.split(':')[1]
                    : ''}
                </chakra.p>
              </VStack>
            </Stack>
          </Center>

          <Center>
            {' '}
            <Text alignItems="center">Está em processamento </Text>
          </Center>

          <chakra.span textAlign="center" fontSize="xs" fontWeight="bold" color="yellow.500"></chakra.span>
          <Stack>
            <Alert status="info" fontSize="xs" borderRadius={20}>
              <AlertIcon />
              <Text noOfLines={1} fontSize="10">
                Após a confirmação do pagamento você será notificado!
              </Text>
            </Alert>
          </Stack>
          <chakra.span textAlign="center" fontSize="xs" fontWeight="bold" color="red">
            Caso o pagamento não seja realizado no prazo estipulado a sua aquisição será cancelada.
          </chakra.span>
        </VStack>
        <VStack mt="3" mb="15%">
          <Stack direction="column" spacing={2}>
            <Text textAlign="center" fontSize="0.9em">
              Problemas com seu agendamento?
            </Text>
            <Button
              colorScheme="teal"
              borderRadius={8}
              height="40px"
              onClick={() => (window.location.href = 'https://wa.me/558881189898')}
              width="350px"
              variant="ghost"
            >
              Falar com Suporte
            </Button>
          </Stack>
        </VStack>
        <BottomNavBar />
      </>
    )
  ) : (
    <Loading />
  );
}

export default DetalhesEmAnalise;
