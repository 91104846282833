import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  Stack,
  DrawerOverlay,
  Image,
  Button,
  useDisclosure,
  VStack,
  Center,
  Header,
  Heading,
  ListItem,
  chakra,
  Flex,
  Text,
  HStack,
  List,
  ButtonGroup,
  Input,
  Radio,
  TableContainer,
  Table,
  Tr,
  LinkBox,
  Td,
  Badge,
} from '@chakra-ui/react';
import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import _ from 'lodash';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import SelectField from 'components/SelectField';
import { AccountContext } from 'components/AccountContext';
import { Formik } from 'formik';
import Loading from 'components/Loading';

function DrawerHorario(props) {
  const [pag, setPag] = useState(0);
  const [pac, setPac] = useState(0);
  const [horario, setHorario] = useState();
  const [escolha, setEscolha] = useState(false);
  const [dependentes, setDependentes] = useState([]);
  const { user, setUser } = useContext(AccountContext);
  const { state } = useLocation();
  const { cart } = user;
  const disclosure = props.disclosure;
  const itemList = props.empresa;
  const valor = props.valor;
  const economia = props.economia;
  const defaultDateTime = null;
  const isByOrder = true;
  const navigate = useNavigate();
  const arrayNomeDependentes = dependentes ? dependentes.map((dependente) => dependente.nome) : [];
  console.log(state);
  function generateTimestampsForNext30Days(horario_seg_sex_inicio, horario_seg_sex_fim, horario_sab_inicio, horario_sab_fim) {
    const startDate = new Date(Date.now() + 86400000);
    const endDate = new Date(startDate.getTime() + 30 * 24 * 60 * 60 * 1000);
    const days = _.range(0, 31)
      .map((index) => {
        const day = new Date(startDate.getTime() + index * 24 * 60 * 60 * 1000);
        if (day < startDate || day > endDate) {
          return null;
        }
        return day;
      })
      .filter((day) => day !== null);

    const timestamps = _.map(days, (day) => {
      const isCurrentDay = _.isEqual(day.toDateString(), new Date().toDateString());
      const dayTimestamps = _.range(0, 24 * 2)
        .map((index) => {
          const hour = _.floor(index / 2);
          const minute = (index % 2) * 30;
          const timestamp = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
          if (isCurrentDay) {
            const now = new Date();
            const currentHour = now.getHours();
            const currentMinute = now.getMinutes();
            if (hour < currentHour || (hour === currentHour && minute < currentMinute)) {
              return null;
            }
            if (hour === currentHour && minute < currentMinute) {
              return null;
            }
            if (hour === currentHour && minute === currentMinute && timestamp < horario_seg_sex_inicio) {
              return null;
            }
          } else if (day.getDay() === 6) {
            if (timestamp < horario_sab_inicio || timestamp >= horario_sab_fim) {
              return null;
            }
          } else {
            if (timestamp < horario_seg_sex_inicio || timestamp >= horario_seg_sex_fim) {
              return null;
            }
          }
          return timestamp;
        })
        .filter((timestamp) => timestamp !== null);
      if (_.isEmpty(dayTimestamps)) {
        return null;
      }
      return { date: day, timestamps: dayTimestamps };
    }).filter((timestamp) => timestamp !== null);
    return timestamps;
  }
  useEffect(() => {
    const fetchDependentes = async () => {
      const url2 = process.env.REACT_APP_BASE_URL_API_SERVER + '/client/dependents2';
      const titularId = {
        id_contrato: user.id_contrato,
      };
      const response = await fetch(url2, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(titularId),
      });
      const resp = await response.json();

      if (resp.data[0] !== null) {
        setDependentes(resp.data);
      } else {
        setDependentes([{ nome: user.name, paciente_id: user.id_cliente_deovita, ...user }]);
      }
    };
    fetchDependentes();
  }, []);

  const generatedDatas = generateTimestampsForNext30Days('07:30', '18:00', '08:00', '18:00');
  const useDatas = generatedDatas.map((generated, index) => {
    const uniqueDate = generated.date;
    const options = { month: 'short', day: 'numeric' };
    const event = new Date(uniqueDate);
    const dataIcon = event.toLocaleDateString('pt-br', options).replace(' de ', ' ').replace('.', '').split(' ');

    return (
      <>
        <Td
          id={`td${index}`}
          borderRadius="15px"
          p="0.5em"
          fontSize="xl"
          textAlign="center"
          class="margin-td"
          onClick={() => {
            document.getElementById('td' + index).scrollIntoView({ behavior: 'smooth' });
            document.querySelectorAll(`[class="chakra-stack css-1px2q30"]`).forEach((item) => {
              item.style.border = 'none';
            });
            document.querySelectorAll(`[class="chakra-badge css-raqg5x"]`).forEach((item) => {
              item.style.border = 'none';
            });
            document.getElementById('date' + index).style.border = '1px solid orange';
            setPag(index);
            setEscolha(false);
          }}
        >
          <HStack
            id={`date${index}`}
            backgroundColor="rgba(90, 178, 173, 0.25)"
            borderRadius="15px"
            style={pag === index ? { border: '1px solid orange' } : {}}
          >
            <Text width="60%">
              <Flex ml="1em">
                <Box backgroundColor="rgba(90, 178, 173, 0.3)" borderRadius="5px" margin="1em" color="rgba(62, 100, 255, 1)" padding="0 1em">
                  <Text fontWeight="bold" fontSize="xl">
                    {dataIcon[0]}
                  </Text>
                  <Text fontWeight="bold" fontSize="sm" mt="-1">
                    {dataIcon[1].toUpperCase()}
                  </Text>
                </Box>
                <Text margin="11% 0" fontSize="xs" align="left">
                  {event.toLocaleString('pt-br', { weekday: 'long' })}
                </Text>
              </Flex>
            </Text>
          </HStack>
        </Td>
      </>
    );
  });
  const useFilteredAgendas = generatedDatas[pag]['timestamps'].map((filteredAgenda, index) => {
    console.log(filteredAgenda);
    if (!escolha) {
      var dateTime = filteredAgenda;
      if (dateTime === defaultDateTime) {
        setEscolha(true);
      }
    }

    return (
      <>
        <Td>
          <Badge
            id={'agenda' + index}
            borderRadius="0.5em"
            margin="0 0.5em"
            padding="0.5em 1.4em"
            size="lg"
            fontSize="md"
            style={`${filteredAgenda} ${filteredAgenda}` === defaultDateTime ? { border: '1px solid orange' } : {}}
            backgroundColor="rgba(90, 178, 173, 0.3)"
            color="rgba(62, 100, 255, 1)"
            onClick={() => {
              document.getElementById('agenda' + index).scrollIntoView({ behavior: 'smooth' });
              document.querySelectorAll(`[class="chakra-badge css-raqg5x"]`).forEach((item) => {
                item.style.border = 'none';
              });
              document.getElementById('agenda' + index).scrollIntoView({ inline: 'center', behavior: 'smooth' });
              document.getElementById('agenda' + index).style.border = '1px solid orange';

              setEscolha(true);
              setHorario(filteredAgenda);
            }}
          >
            {filteredAgenda}
          </Badge>
        </Td>
      </>
    );
  });

  return (
    <Drawer isOpen={disclosure.isOpen} onClose={disclosure.onClose} placement="bottom">
      <DrawerOverlay />
      <DrawerContent borderTopRadius={20} width="100%" h="80%">
        <DrawerBody pb="10%">
          <Stack direction="column" spacing={4} paddingTop={30} align="center">
            {dependentes.length > 0 ? (
              <>
                <Radio w="3em" autoFocus hidden></Radio>
                <Text w="95%" size="sm" color="#585858" fontWeight="bold">
                  Selecione o paciente:
                </Text>
                <LinkBox id="" as="card" p="3" borderWidth="2px" borderColor="green" borderRadius="15px" fontSize="xs" width="100%">
                  <Formik initialValues={{ dependente: 0 }}>
                    <Heading size="sm" my="2">
                      <SelectField
                        name="dependente"
                        colorScheme="teal"
                        border="green"
                        color="black"
                        borderRadius="15px"
                        borderWidth="2px"
                        variant="ghost"
                        fontWeight="bold"
                        onChange={setPac}
                        options={arrayNomeDependentes}
                      />
                    </Heading>
                  </Formik>
                </LinkBox>
                <Text w="95%" size="sm" color="#585858" fontWeight="bold">
                  Selecione o dia e o horário
                </Text>

                <TableContainer minWidth="100%">
                  <Table variant="simple" size="lg" class="table-agendamento">
                    <Tr>{useDatas}</Tr>
                  </Table>
                </TableContainer>
                {isByOrder === true ? (
                  <>
                    <HStack>
                      <Badge
                        id="t1"
                        borderRadius="0.5em"
                        margin="0 0.5em"
                        padding="0.5em 1.4em"
                        size="lg"
                        fontSize="md"
                        backgroundColor="rgba(90, 178, 173, 0.3)"
                        color="rgba(62, 100, 255, 1)"
                        onClick={() => {
                          document.getElementById('t1').style.border = '2px solid orange';
                          document.getElementById('t2').style.border = 'none';
                          setHorario('07:30');
                          setEscolha(true);
                        }}
                      >
                        Manhã
                      </Badge>
                      <Badge
                        id="t2"
                        borderRadius="0.5em"
                        margin="0 0.5em"
                        padding="0.5em 1.4em"
                        size="lg"
                        fontSize="md"
                        backgroundColor="rgba(90, 178, 173, 0.3)"
                        color="rgba(62, 100, 255, 1)"
                        onClick={() => {
                          document.getElementById('t2').style.border = '2px solid orange';
                          document.getElementById('t1').style.border = 'none';
                          setHorario('12:30');
                          setEscolha(true);
                        }}
                      >
                        Tarde
                      </Badge>
                    </HStack>
                  </>
                ) : (
                  <TableContainer minWidth="100%">
                    <Table variant="simple">
                      <Tr>{useFilteredAgendas}</Tr>
                    </Table>
                  </TableContainer>
                )}
                <Button
                  colorScheme="teal"
                  w="100%"
                  boterRadius="15px"
                  disabled={escolha ? false : true}
                  onClick={() => {
                    navigate('/exames/finalizar/', {
                      state: {
                        item: itemList.map(
                          ({
                            descricao,
                            medico,
                            bairro,
                            estado,
                            cep,
                            distancia,
                            endereco,
                            horario_sab_fim,
                            horario_sab_inicio,
                            horario_seg_sex_fim,
                            horario_seg_sex_inicio,
                            latitude,
                            longitude,
                            numero,
                            telefone_01,
                            telefone_02,
                            ...rest
                          }) => rest
                        ),
                        empresa: {
                          empresa_id: itemList[0].empresa_id,
                          empresa_nome: itemList[0].empresa,
                          endereco: `${itemList[0].endereco}, ${itemList[0].numero} - ${itemList[0].municipio} / ${itemList[0].estado}`,
                        },
                        paciente: dependentes[pac],
                        valor: valor,
                        data: generatedDatas[pag]['date'],
                        horario: horario,
                        convenio: user.id_contrato > 0 ? '252' : '251',
                        economia: economia,
                        orcamento_id: state.orcamento_id,
                        isByOrder: isByOrder,
                        solicitante: state.solicitante,
                      },
                    });
                  }}
                >
                  Continuar
                </Button>
                <Button colorScheme="teal" w="100%" boterRadius="15px" variant="ghost" onClick={disclosure.onClose}>
                  Voltar
                </Button>
              </>
            ) : (
              <>
                <Loading />
              </>
            )}
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export default DrawerHorario;
