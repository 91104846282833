import { Image } from "@chakra-ui/react";
import bordacartao from './bordacartaopng-removebg-preview.png'

const ImgCartao = (props) => {
    return (
        
        
        
        <div>
            
            <svg width="314" height="189" viewBox="0 0 314 189" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_b_527_641)">
                    <rect x="310" y="4" width="181.431" height="306" rx="22" transform="rotate(90 310 4)" fill="url(#paint0_radial_527_641)" />
                    <rect
                        x="311.75"
                        y="2.25"
                        width="184.931"
                        height="309.5"
                        rx="23.75"
                        transform="rotate(90 311.75 2.25)"
                        stroke="url(#paint1_linear_527_641)"
                        stroke-width="3.5"
                    />
                </g>

                <text fill="black" x="40" y="130">
                    {props.name ? props.name : "NOME"}
                </text>
                <text fill="black" x="40" y="160">
                    {props.cardnumber ? props.cardnumber : "XXXX XXXX XXXX XXXX"}
                </text>
                <g filter="url(#filter1_d_527_641)">
                    <path
                        d="M256.16 57.2589C259.48 61.2991 259.48 66.5515 256.16 70.5918"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M259.64 54.5247C264.32 60.2187 264.32 67.6228 259.64 73.3168"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M252.51 59.0254C254.95 61.9852 254.95 65.8469 252.51 68.8067"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M248.85 61.4778C250.07 62.9624 250.07 64.8886 248.85 66.3731"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </g>
                <g opacity="0.8">
                    <g opacity="0.9" filter="url(#filter2_d_527_641)">
                        <rect
                            x="37.25"
                            y="64.25"
                            width="31.5"
                            height="23.881"
                            rx="3.75"
                            fill="url(#paint2_linear_527_641)"
                            stroke="#9F7E3B"
                            stroke-width="0.5"
                        />
                        <path
                            d="M37.5 71.5H46C47.1046 71.5 48 72.3954 48 73.5V77.375M48 87.9V83.25M48 83.25V77.375M48 83.25H37.5M48 77.375H37.5"
                            stroke="black"
                            stroke-opacity="0.76"
                            stroke-width="0.5"
                        />
                        <path
                            d="M68.5 71.5H58.5M58.5 71.5V77.375M58.5 71.5V64.5M58.5 87.9V83.25M58.5 83.25V77.375M58.5 83.25H68.5M58.5 77.375H68.5"
                            stroke="black"
                            stroke-opacity="0.76"
                            stroke-width="0.5"
                        />
                    </g>
                </g>
                <defs>
                    <filter
                        id="filter0_b_527_641"
                        x="-41.5"
                        y="-41.5"
                        width="397"
                        height="272.431"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                    >
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feGaussianBlur in="BackgroundImage" stdDeviation="21" />
                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_527_641" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_527_641" result="shape" />
                    </filter>
                    <filter
                        id="filter1_d_527_641"
                        x="240"
                        y="52.6455"
                        width="32"
                        height="30.5505"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                    >
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="2" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_527_641" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_527_641" result="shape" />
                    </filter>
                    <filter
                        id="filter2_d_527_641"
                        x="33"
                        y="60"
                        width="40"
                        height="42"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                    >
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dy="6" />
                        <feGaussianBlur stdDeviation="2" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_527_641" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_527_641" result="shape" />
                    </filter>
                    <radialGradient
                        id="paint0_radial_527_641"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(310 4) rotate(59.3358) scale(355.743 216.876)"
                    >
                        <stop stop-color="white" stop-opacity="0.4" />
                        <stop offset="1" stop-color="white" stop-opacity="0" />
                    </radialGradient>
                    
                    <linearGradient id="paint2_linear_527_641" x1="37" y1="64" x2="69" y2="88.5" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#E6AC3C" />
                        <stop offset="1" stop-color="#FFDA93" />
                    </linearGradient>
                </defs>
            </svg>
            <div> <Image  mt='-180' src={bordacartao}></Image></div>
        </div>
        
    );
};

export default ImgCartao;