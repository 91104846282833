import { ArrowForwardIcon } from '@chakra-ui/icons';
import {
  chakra,
  Box,
  Img,
  LinkBox,
  Text,
  VStack,
  Checkbox,
  Image,
  Radio,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  HStack,
  Drawer,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';
import { AccountContext } from 'components/AccountContext';
import BottomNavBar from 'components/BottomNavBar';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PetsImg from '../../../../../images/assets/telemedicina/pets.png';
import BackButton from '../../../../../images/assets/telemedicina/backbutton.svg';
import { Form, Formik } from 'formik';
import SelectField from 'components/SelectField';
import contrato from '../../../../../termos/telemedicina_vet.pdf';
import Pdf from '../../../../PdfViewer';

function Main() {
  const { user } = useContext(AccountContext);
  const navigate = useNavigate();
  const disclosure = useDisclosure();
  const [accept, setAccepted] = useState();
  const [dependentes, setDependentes] = useState(false);
  const [client, setClient] = useState();
  const [isDisabled, setDisabled] = useState(false);
  const [error, setError] = useState('');
  const arrayNomeDependentes = dependentes ? dependentes.map((dependente) => dependente.nome) : [];
  const fetchDependentes = async () => {
    const url2 = process.env.REACT_APP_BASE_URL_API_SERVER + '/client/pets';
    const titularId = {
      id_contrato: user.id_contrato,
    };
    const response = await fetch(url2, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(titularId),
    });
    const resp = await response.json();
    const dependentes = resp.data;
    setDependentes(dependentes);
    setClient(dependentes[0].paciente_id);
  };

  useEffect(() => {
    fetchDependentes();
  }, []);

  const useDependentes = dependentes
    ? dependentes.map((dependente, index) => {
        console.log(dependente);
        console.log(index);
        if (index > 0) {
          return (
            <>
              <Radio value={`${index}`} isDisabled={isDisabled}>
                {dependente.nome ? dependente.nome.split(' ')[0] + ' ' + (dependente.nome.split(' ')[1] ? dependente.nome.split(' ')[1] : '') : ''}
              </Radio>
            </>
          );
        }
      })
    : [];

  return (
    <>
      <Drawer isCentered isFullHeight size="md" isOpen={disclosure.isOpen} onClose={disclosure.onClose} placement="bottom">
        <DrawerContent isCentered nbgColor="rgba(90, 178, 173, 0.7)" pt="10%">
          <Pdf file={contrato} />
          <DrawerCloseButton color="red" borderColor="red" pt="15%" />
        </DrawerContent>
      </Drawer>
      <VStack
        align="center"
        justify={{
          base: 'center',
          md: 'space-around',
          xl: 'space-between',
        }}
        direction={{ base: 'column-reverse' }}
        wrap="no-wrap"
        minH="100vh"
        spacing={0}
        background="linear-gradient(180deg, #EB8B2A 0%, #EFA73F 28.65%, #5AB2AD 64.06%, #4E9E8F 100%);"
      >
        <Image top="2.5em" left="1.3em" zIndex="9999" position="absolute" src={BackButton} onClick={() => navigate(-1)} />
        <LinkBox
          mt="1"
          id=""
          as="card"
          width="328px"
          borderRadius="18px"
          fontSize="xs"
          size="10"
          backgroundColor="white"
          maxInlineSize="80"
          textAlign="center"
        >
          <VStack m="10%">
            <Img src={PetsImg} w="30%" mt="2em" />
            <Box textAlign="left">
              <Text mt="1em" color="red" fontSize="md" mb="0.5em">
                {error}
              </Text>
              <chakra.strong>
                <Text mt="1em" color="#585858" fontSize="md" mb="0.5em">
                  Para continuar você deve aceitar os termos do serviço.
                </Text>
              </chakra.strong>

              <Formik initialValues={{ dependente: 0 }} validateOnChange={false} validateOnBlur={false}>
                <VStack as={Form} w={{ base: '100%', md: '500px' }} m="auto" padding="5%" justify="top" overflow="hidden" align="start">
                  <SelectField
                    name="dependente"
                    placeholder="Pet    "
                    label="Selecione o Pet:"
                    w="230px"
                    variant="outline"
                    textAlign="left"
                    fontWeight="normal"
                    onChange={(e) => {
                      console.log(e);
                    }}
                    options={arrayNomeDependentes}
                  />
                </VStack>
              </Formik>
              <HStack w="100%" pl="1.5em">
                <Checkbox
                  name="termos"
                  onChange={(e) => {
                    if (!accept) {
                      document.getElementById('callBtn').style.color = '#529C94';
                      setAccepted(true);
                    } else {
                      document.getElementById('callBtn').style.color = '#D3D3D3';
                      setAccepted(false);
                    }
                  }}
                ></Checkbox>
                <Text
                  fontSize="0.8em"
                  color="#529C94"
                  onClick={() => {
                    disclosure.onOpen();
                  }}
                >
                  Eu li e aceito os {` `}
                  <chakra.a colorScheme="teal">
                    <u>Termos e Condições</u>
                  </chakra.a>
                </Text>
              </HStack>
              <Text
                textAlign="center"
                id="callBtn"
                color="#D3D3D3"
                fontWeight="bold"
                fontSize="1.5em"
                mt="1.5em"
                onClick={() => {
                  if (!client) {
                    setError('você precisa cadastrar um pet para começar a utilizar o serviço');
                  }
                  if (accept && client) {
                    navigate('/telepet/fila');
                  }
                }}
              >
                Falar com o veterinario
                <chakra.span>
                  <ArrowForwardIcon fontSize="0.9em" />
                </chakra.span>
              </Text>
            </Box>
          </VStack>
        </LinkBox>
      </VStack>
      <BottomNavBar />
    </>
  );
}

export default Main;
