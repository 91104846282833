import { CheckIcon } from "@chakra-ui/icons";
import { Heading, Image, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Text, VStack, Button, Box, Center, HStack } from "@chakra-ui/react";
import img4 from '../../images/ad4.png'

export default function PopUpAdicional(props) {

  let { adicional_id, nome, beneficios, extras, valor, beneficiarios, valor_numerico } = props.data

  return (
    <>
      <Modal isOpen={props.isOpen} onClose={props.onClose} size='xs' >
        <ModalOverlay />
        <ModalContent borderRadius='15px'>
          <ModalHeader mt='3'>
            <Center><Image src={img4} /></Center>
            <Center><Heading size='md'><Text casing='capitalize'>{typeof nome === 'string' ? nome : ''}</Text></Heading></Center>
          </ModalHeader>
          <ModalBody mb='3'>
            <VStack spacing={3} align='start'>
              <Box>
                <Heading size='sm'>Benefícios</Heading>
                {beneficios.map((beneficio) => {
                  return (
                    <HStack pl='2'>
                      <CheckIcon color='green.500' /><Text casing='capitalize'>{typeof beneficio.nome === 'string' ? beneficio.nome : ''}</Text>
                    </HStack>
                  )
                })}
              </Box>
              <Box>
                <Heading size='sm'>Beneficiados</Heading>
                {beneficiarios.map((beneficiario) => {
                  return (
                    <HStack pl='2'>
                      <CheckIcon color='green.500' /><Text casing='capitalize'>{typeof beneficiario.nome === 'string' ? beneficiario.nome : ''}</Text>
                    </HStack>
                  )
                })}
              </Box>
              <Box>
                <Heading size='sm'>Valor</Heading>
                <Text pl='2'>{valor ? valor.replace(".", ",") : ''}</Text>
              </Box>
              
              <Button
                colorScheme='teal'
                isFullWidth
                borderColor='blue.500'
                onClick={() => {
                  props.addCarrinho((state) => {
                    state.push({ id: adicional_id, nome: nome, valor: valor, valor_numerico: valor_numerico })
                    return state
                  })
                  props.onClose()
                }}
              >
                Adicionar
              </Button>
              <Button
                colorScheme='teal'
                variant='outline'
                isFullWidth
                onClick={props.onClose}
              >
                Continuar sem contratar
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

