import { Avatar, chakra, Box, Flex, Img, Text, HStack, Wrap, Image, WrapItem } from '@chakra-ui/react';
import React, { useState, useEffect, useContext } from 'react';
import Searchbox from '../Dashboard/Main/Searchbox';
import LogoDashboard from '../../images/logo_dashboard.svg';
import '../AreaLivre/PerfilEmpresa/css/main.css';
import { AccountContext } from '../AccountContext';
import ProfilePicturePH from '../../images/assets/placeholder/profile.svg';
import { MdLogin } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

function UserHeaderLivre() {
  const { page, setPage } = useContext(AccountContext);
  const navigate = useNavigate();
  let message = 'Seja Bem Vindo!';
  return (
    <>
      <chakra.header
        w="full"
        px={{ base: 2, sm: 4 }}
        py={4}
        shadow="md"
        backgroundColor="rgba(90, 178, 173, 0.7)"
        minHeight="180px"
        borderBottomRadius="30px"
      >
        <Flex alignItems="center" justifyContent="space-between" mx="auto">
          <Flex>
            <chakra.a display="flex" alignItems="center">
              <Wrap position="absolute" left="-8" top="1" borderRadius="50%" border="20px solid rgba(255,255,255,0.3)" p="20px">
                <Avatar name=" " src={ProfilePicturePH} size="lg" />
              </Wrap>
            </chakra.a>
            <chakra.h2 textShadow="2px 3px 5px #808080" fontSize="xl" fontWeight="bold" mt="2em" ml="3.5em" color="white">
              Olá!
              <br />
              {message}
            </chakra.h2>
          </Flex>

          <HStack display="flex" alignItems="center" spacing={1}>
            <Box display={{ base: 'inline-flex' }}>
              <Image position="absolute" right="0.02rem" top="2.89em" src={LogoDashboard} alt="teste" />
            </Box>
          </HStack>
        </Flex>

        <Text
          position="absolute"
          fontSize="xl"
          fontWeight="bold"
          pt="1.1em"
          textColor="white"
          textShadow="2px 3px 5px #808080"
          textAlign="center"
          width="90%"
          zIndex="1"
        ></Text>
        <Searchbox />
      </chakra.header>
    </>
  );
}

export default UserHeaderLivre;
