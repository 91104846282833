import React, { useState, useContext, useEffect } from 'react';
import ChildHeader from '../../Dashboard/Main/ChildHeader';
import TextField from 'components/TextFieldAuth';

import {
  useToast,
  HStack,
  chakra,
  VStack,
  Avatar,
  Flex,
  Heading,
  Box,
  Button,
  List,
  ListItem,
  Divider,
  Text,
  WrapItem,
  Image,
  Stack,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogFooter,
  AlertDialogContent,
  ModalCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  LinkBox,
  TableContainer,
  Table,
  Select,
  Badge,
  Center,
  Tr,
  Spacer,
  Td,
  Input,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { StarIcon, ChevronLeftIcon, ChevronRightIcon, CheckIcon, WarningTwoIcon, CloseIcon } from '@chakra-ui/icons';
import { MdOutlineWifi, MdOutlineWifiOff } from 'react-icons/md';
import SelectField from 'components/SelectField';
import { AccountContext } from '../../AccountContext';
import { useNavigate, useLocation } from 'react-router-dom';
import BottomNavBar from '../../BottomNavBar';
import { Formik } from 'formik';
import { isDisabled } from '@testing-library/user-event/dist/utils';

function CancelamentoConsulta() {
  const navigate = useNavigate();
  const { user, setPage } = useContext(AccountContext);
  const [loading, setLoading] = useState(true);
  const { state } = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { atendimento, statusAtual } = state;
  const [cancelamento, setCancelamento] = useState([]);
  const [desativaBotao, setDesativaBotao] = useState(true);
  const [selected, setSelected] = useState('');
  const cancelRef = React.useRef();
  const [motivoCancelamento, setMotivoCancelamento] = useState(false);
  const data = new Date(atendimento.APP_DATE.replace(' ', 'T'));
  const dataextenso = data.toLocaleDateString('pt-br', { weekday: 'long' });
  const monthNameLong = data.toLocaleString('pt-br', { month: 'long' });
  let titulo = '';
  if (atendimento.GENDER === 'M') {
    titulo = 'Dr. ';
  } else if (atendimento.GENDER === 'F') {
    titulo = 'Dra. ';
  }

  setPage('Minhas Consultas');

  async function ObterDados() {
    let url = process.env.REACT_APP_BASE_URL_API_SERVER + '/schedule/get_motivos_cancelamento';
    const resposta = await fetch(url, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    const postsDados = await resposta.json();
    setCancelamento(postsDados);
  }

  const SelectCancelamento = () => {
    const cancelamentoMap = cancelamento.map((cancelamento) => cancelamento.descricao);
    const arrayCancelamento = cancelamentoMap;
    arrayCancelamento.shift();

    const isValid = () => {
      const res = selected;

      if (res == null) return false;
      return true;
    };

    return (
      <Formik
        initialValues={{ cancelamento: 0 }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={Yup.object().shape({
          SelectCancelamento: Yup.string().required('Campo Obrigatório!'),
        })}
      >
        <SelectField
          name="SelectCancelamento"
          placeholder="Cancelamento"
          w="100%"
          defaultValue={arrayCancelamento[1]}
          colorScheme="teal"
          variant="outline"
          textAlign="left"
          borderColor="gray.300"
          borderRadius="10px"
          fontWeight="bold"
          value={cancelamento}
          onChange={(e) => {
            setSelected([e]);
          }}
          options={arrayCancelamento}
        />
      </Formik>
    );
  };

  useEffect(() => {
    ObterDados();
  }, []);

  const isValid = () => {
    if (selected.length === 0) {
      return true;
    } else if (selected.length >= 1) {
      return false;
    }
  };

  async function ObterDados() {
    let url = process.env.REACT_APP_BASE_URL_API_SERVER + '/schedule/get_motivos_cancelamento';
    const resposta = await fetch(url, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    const postsDados = await resposta.json();
    setCancelamento(postsDados);
  }

  function cancelarConsulta(motivoCancelamento) {
    const url = process.env.REACT_APP_BASE_URL_API_SERVER + '/appointments/cancel/';

    const payload = {
      agenda_exames_id: atendimento.APP_LID,
      motivo: selected,
      paciente_id: atendimento.USER_LID,
      medico: atendimento.RESOURCE_LID,
    };

    const sendWhatsappCancelamento = async () => {
      const url = process.env.REACT_APP_BASE_URL_API_SERVER + '/whatsapp/cancelamento';
      const payload = {
        numero: user.phone1,
        agenda_exames_id: atendimento.APP_LID,
        motivo: motivoCancelamento,
        procedimento: atendimento.ACTIVITY_NAME ? atendimento.ACTIVITY_NAME : 'CONSULTA COM ' + atendimento.ESPECIALIDADE,
        nome: atendimento.USER_FIRST_NAME,
        medico: atendimento.RESOURCE_NAME,
      };
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      };
      fetch(url, options);
    };

    const fetchPosts = async () => {
      try {
        const response = await fetch(url, {
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify(payload),
        });
        const postsData = await response.json();
        console.log(postsData);
        navigate('/alerts/sucesso/excluir-agendamento');
        sendWhatsappCancelamento();
      } catch (error) {
        console.log(error);
        navigate('/alerts/erro/excluir-agendamento');
      }
    };

    fetchPosts();
  }

  return (
    <>
      <ChildHeader />
      <VStack
        w={{ base: '90%' }}
        m="auto"
        marginTop="12"
        py="5"
        px="5"
        borderWidth={3}
        borderColor="#00000"
        borderRadius="10px"
        justify="center"
        align="start"
        spacing={5}
      >
        <Heading size="md" mt="4">
          Deseja cancelar essa consulta?
        </Heading>
        <Text textAlign="left" fontSize="sm">
          {atendimento.ACTIVITY_NAME ? atendimento.ACTIVITY_NAME : 'CONSULTA COM ' + atendimento.ESPECIALIDADE}- {atendimento.NAME} na {dataextenso},{' '}
          {data.getDate()} de {monthNameLong} de {data.getFullYear()} as{' '}
          {atendimento.START_TIME ? atendimento.START_TIME.split(':')[0] + ':' + atendimento.START_TIME.split(':')[1] : ''} com {titulo}{' '}
          {atendimento.RESOURCE_NAME}
        </Text>
        <chakra.h1 marginTop="2%" textAlign="left" ml="6" color="rgba(0, 0, 0, 0.5)" fontSize="sm">
          Paciente
        </chakra.h1>
        <chakra.p textAlign="left" ml="6">
          {atendimento.USER_FIRST_NAME} {atendimento.USER_SECOND_NAME}
        </chakra.p>
        <Heading size="xs" mt="7" ml="5">
          Qual o motivo do cancelamento?
        </Heading>
        {SelectCancelamento()}
        <HStack display="flex" flex-direction="row" width="100%" height="100%">
          <Spacer />
          <Button
            whiteSpace="initial"
            colorScheme="teal"
            size="sm"
            variant="outline"
            isDisabled={isValid()}
            width="328px"
            height="50px"
            borderRadius="15px"
            padding="3"
            onClick={onOpen}
          >
            <Text>Cancelar agendamento</Text>
          </Button>
          <Button
            whiteSpace="initial"
            colorScheme="teal"
            size="sm"
            variant="solid"
            width="328px"
            height="50px"
            borderRadius="15px"
            padding="3"
            onClick={() => navigate(-1)}
          >
            Continuar com o agendamento
          </Button>
          <Spacer />
        </HStack>
      </VStack>
      <AlertDialog isOpen={isOpen} motionPreset="slideInBottom" leastDestructiveRef={cancelRef} onClose={onClose} size="xs" isCentered isLazy={true}>
        <AlertDialogOverlay>
          <AlertDialogContent borderRadius={15}>
            <AlertDialogFooter>
              <VStack w="100%" spacing={5} margin={1}>
                <Center>
                  <Text isTruncated fontWeight="bold" fontSize="md">
                    Quer mesmo desmarcar a consulta?
                  </Text>
                </Center>
                <HStack display="flex" flex-direction="row" width="100%" height="100%">
                  <Spacer />
                  <Button
                    whiteSpace="initial"
                    colorScheme="teal"
                    size="sm"
                    width="328px"
                    height="50px"
                    borderRadius="15px"
                    padding="3"
                    //isDisabled={!motivoCancelamento}
                    onClick={() => {
                      cancelarConsulta(motivoCancelamento);
                      onClose();
                    }}
                  >
                    <Text>Sim</Text>
                  </Button>
                  <Button
                    colorScheme="teal"
                    whiteSpace="initial"
                    variant="outline"
                    size="sm"
                    width="328px"
                    height="50px"
                    borderRadius="15px"
                    padding="3"
                    onClick={onClose}
                    isFullWidth
                  >
                    Não
                  </Button>
                  <Spacer />
                </HStack>
              </VStack>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

export default CancelamentoConsulta;
