import { StarIcon } from '@chakra-ui/icons';
import { HStack, chakra, VStack, Avatar, Flex, Image, Text, WrapItem, Box, Heading, ButtonGroup, Button } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AccountContext } from '../../../AccountContext';
import NoUserHeader from '../../NoUserHeader';
import { useNavigate } from 'react-router-dom';
import detectAndroid from '../../../Utils/Functions/detectAndroid';

function DetalhesEmpresaLivre() {
  const { state } = useLocation();
  const { unidade } = state;
  const { setPage } = useContext(AccountContext);

  setPage('Informações');

  const isAndroid = detectAndroid();
  var mapUriPrefix = 'https://maps.apple.com/?q=';
  if (isAndroid) mapUriPrefix = 'https://maps.google.com/?q=';

  const navigate = useNavigate();
  const [medicos, setmedicos] = useState([]);
  const pageDefault = 1;
  const limitDefault = 999;

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  }

  const pic = getRandomInt(0, 999);

  async function fetchData(url) {
    try {
      let response = await fetch(url, {
        headers: { 'Content-Type': 'application/json' },
        method: 'GET',
      });
      let data = await response.json();
      return data;
    } catch (error) {
      return [];
    }
  }

  async function fetchSchedules(page, limit) {
    let url = `${process.env.REACT_APP_BASE_URL_API_SERVER}/schedule?page=${page}&limit=${limit}`;
    const payload = {
      data: {
        empresa_id: 1,
      },
    };
    let data = await fetchData(url, payload);
    let medicos = Array.isArray(data) ? data.filter((medico) => medico.dados.empresa_id == unidade.LOCATION_LID) : [];

    setmedicos(medicos);
  }

  useEffect(() => {
    fetchSchedules(pageDefault, limitDefault);
    setmedicos(medicos);
  }, []);

  const Whatsapp = () => {
    return (
      <>
        <VStack w={{ base: '90%', md: '500px' }} m="auto" marginTop="5%" spacing="2rem" justify="top" mb="5%">
          <Box w="90%" borderRadius="5px" padding="5px">
            <chakra.p textAlign="justify">Agendamento para esta empresa disponivel via Whatsapp através do número (88)98118-9898.</chakra.p>
          </Box>
          <ButtonGroup>
            <Button colorScheme="teal" onClick={() => (window.location.href = 'https://wa.me/558881189898')}>
              Agende seu Exame
            </Button>
          </ButtonGroup>
        </VStack>
      </>
    );
  };

  const useMedicos = medicos.map((medico, index) => {
    const arrName = medico.dados.nome.split(' ');
    const event = new Date(medico.horarios[0].AVA_DATE.split('/').reverse().join('-') + 'T08:00:00');

    const options = { weekday: 'long', month: 'long', day: 'numeric' };
    let titulo = '';
    if (medico.dados.sexo === 'M') {
      titulo = 'Dr. ';
    } else if (medico.dados.sexo === 'F') {
      titulo = 'Dra. ';
    }
    return (
      <>
        <Flex minW="320px" maxW="320px" mx="auto" bg="" overflow="hidden">
          <Box w={1 / 3} bgSize="cover" align="center">
            <WrapItem ml="5px" mt="0.8em">
              <Avatar
                id={medico.dados.medico_id}
                size="xl"
                name={medico.dados.nome}
                src={process.env.REACT_APP_URL_PROFILEPICS_OPERADOR + '/' + medico.dados.medico + '/foto_profissional.webp?' + pic}
                onClick={() => {
                  navigate('/atendimento/agendamento', {
                    state: {
                      medico,
                      startNum: 0,
                      st: 0,
                      defaultDateTime: null,
                    },
                  });
                }}
              />{' '}
            </WrapItem>
            <chakra.a
              color="rgba(14, 170, 139, 1)"
              fontWeight="bold"
              fontSize="sm"
              onClick={() => {
                navigate('/curriculolivre', {
                  state: {
                    medico,
                    pic,
                  },
                });
              }}
            >
              ver curriculo
            </chakra.a>
          </Box>
          <Box w={2 / 3} p={{ base: 4, md: 4 }}>
            <chakra.h2
              fontSize="xl"
              fontWeight="bold"
              color=""
              onClick={() => {
                navigate('/agendamentolivre', {
                  state: {
                    medico,
                    startNum: 0,
                    st: 0,
                    defaultDateTime: null,
                  },
                });
              }}
            >
              {titulo}
              {''}
              {arrName[0]} {arrName[1]}
            </chakra.h2>
            <chakra.p
              mt={-1}
              fontSize="sm"
              color=""
              onClick={() => {
                navigate('/agendamentolivre', {
                  state: {
                    medico,
                    startNum: 0,
                    st: 0,
                    defaultDateTime: null,
                  },
                });
              }}
            >
              {medico.dados.especialidade}
            </chakra.p>
            <HStack>
              <chakra.p
                fontSize="sm"
                onClick={() => {
                  navigate('/agendamentolivre', {
                    state: {
                      medico,
                      startNum: 0,
                      st: 0,
                      defaultDateTime: null,
                    },
                  });
                }}
              >
                {medico.dados.conselho && medico.dados.conselho} {medico.dados.rqe && medico.dados.rqe}
              </chakra.p>
            </HStack>
            <HStack spacing={1} display="flex" alignItems="center" fontSize="sm" pt={0.5}>
              <StarIcon color="gray.500" />
              <StarIcon color="gray.500" />
              <StarIcon color="gray.500" />
              <StarIcon color="gray.500" />
              <StarIcon color="gray.500" />
              <chakra.span fontSize="xs">0 opiniões</chakra.span>
            </HStack>
          </Box>
        </Flex>
        <Flex minW="320px" maxW="320px" mx="auto" bg="" overflow="hidden" alignItems="center" justifyContent="space-between"></Flex>
      </>
    );
  });

  return (
    <>
      <NoUserHeader />
      <VStack />
      <HStack w="100%" paddingTop="5%" justify="center">
        <Image
          minwidth="150px"
          minHeight="50px"
          maxWidth="220px"
          mt="5"
          name={unidade.NAME}
          src={`${process.env.REACT_APP_URL_LOGOMARCA}/` + unidade.LOCATION_LID + '/logomarca.png'}
        />

        <HStack paddingTop="5%" justify="center">
          <VStack align="center">
            <HStack spacing={1} display="flex" alignItems="center" fontSize="sm" pt={0.5}></HStack>
          </VStack>
        </HStack>
      </HStack>

      <chakra.h1 marginTop="8%" textAlign="left" ml="7" fontWeight="bold" fontSize="xl">
        História
      </chakra.h1>

      <chakra.h1 h="5" textAlign="left" mt="5" ml="10" fontWeight="bold" fontSize="md">
        Sobre a Clínica
      </chakra.h1>
      <chakra.p textAlign="justify" padding="0 8%" mt="5" ml="2" fontSize="sm" color="rgba(0, 0, 0, 0.5)">
        {unidade.DESCRICAO}
      </chakra.p>
      <Flex mt="5">
        <chakra.h1 marginTop="2%" textAlign="left" ml="6" fontWeight="bold" fontSize="xl">
          Endereço
        </chakra.h1>
      </Flex>
      <chakra.h1 marginTop="5%" textAlign="left" ml="10" fontWeight="bold" fontSize="sm">
        {unidade.NAME}
      </chakra.h1>

      <chakra.p textAlign="left" padding="0 6%" mt="2" ml="15" fontSize="sm" color="rgba(0, 0, 0, 0.5)">
        {unidade.ADDRESS}
        {', '}
        {unidade.NUMBER}
        {' - '}
        {unidade.PROVINCE}
        {', '}
        {unidade.CITY}
        {' - '}
        {unidade.ZIP_CODE}
      </chakra.p>
      <WrapItem mt="1" ml="50">
        <chakra.a
          href={mapUriPrefix + unidade.ADDRESS + ', ' + unidade.NUMBER + ', ' + unidade.PROVINCE + ', ' + unidade.CITY + ', Ceará'}
          fontSize="sm"
        >
          <chakra.span textaAlign="left" mt="5" fontSize="sm" color="rgba(14, 170, 139, 1)">
            {' '}
            Ver mapa
          </chakra.span>
        </chakra.a>
      </WrapItem>
      <chakra.p fontWeight="bold" fontSize="sm" padding="0 10%" textAlign="center">
        <br />

        <chakra.h1 marginTop="4%" textAlign="left" ml="-4" fontWeight="bold" fontSize="xl">
          Funcionamento
        </chakra.h1>

        <chakra.h1 marginTop="5%" textAlign="left" ml="-2" fontWeight="bold" fontSize="md">
          Segunda a Sexta
        </chakra.h1>

        <chakra.p color="rgba(0, 0, 0, 0.5)" textAlign="left">
          {' '}
          {unidade.HORARIO_SEG_SEX_ABERTURA} as {unidade.HORARIO_SEG_SEX_ENCERRAMENTO}
        </chakra.p>

        <chakra.h1 marginTop="5%" textAlign="left" ml="-2" fontWeight="bold" fontSize="md">
          Sábado
        </chakra.h1>

        <chakra.p color="rgba(0, 0, 0, 0.5)" textAlign="left" mb="15%">
          {' '}
          {unidade.HORARIO_SAB_ABERTURA} as {unidade.HORARIO_SAB_ENCERRAMENTO}
        </chakra.p>
      </chakra.p>

      <chakra.h1 marginTop="1%" textAlign="left" ml="6" fontWeight="bold" fontSize="xl">
        Profissionais
      </chakra.h1>
      <VStack spacing={2} mb="5%">
        {Whatsapp()}, {useMedicos}
      </VStack>
    </>
  );
}

export default DetalhesEmpresaLivre;
