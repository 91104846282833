import { React, useState, useContext } from 'react';
import { useLocation } from 'react-router';
import {
  chakra,
  Button,
  ButtonGroup,
  VStack,
  useToast,
  RadioGroup,
  Radio,
  Flex,
  Text,
  Center,
  HStack,
  Image,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Heading,
  useColorModeValue,
} from '@chakra-ui/react';
import CartaoGenerico from './CartaoGenerico';
import PixGenerico from './PixGenerico';
import { AccountContext } from '../AccountContext';
import ChildHeader from '../Dashboard/Main/ChildHeader';
import NoUserHeader from '../AreaLivre/NoUserHeader';
import BottomNavBar from '../BottomNavBar';
import CreditCardImg from '../../images/card.svg';
import PixImg from '../../images/pix.svg';
import BoletoImg from '../../images/boleto.svg';
import Meses2white from '../../images/2-meses-white.svg';
import Meses2 from '../../images/2-meses.svg';
import BoletoGenerico from './BoletoGenerico';

const Menu = () => {
  /*
      passar as via state por useLocation:
      navig_to: página para onde deve ser redirecionada após ser concluido com sucesso o pagamento (string)
      valor: valor a ser cobrado (float)
      parcesActive: define se o campo de parcelamento deve ficar ativo para o usuário selecionar em quantas vezes deseja dividir o pagamento (bool)
      expitation_pix_in_min: define o tempo de vencimento do pix em minutos (int)
      item: a descricao dos itens que estão sendo cobrados para exibição na tela de pix (string)
  */

  let { state } = useLocation();
  const { user, setPage } = useContext(AccountContext);
  const { navig_to } = state;
  const fromCadastro = navig_to === '/cadastro/analise' ? true : false;
  const fromDependente = navig_to === '/perfil/dependentes/processar-exclusao' ? true : false;
  const isAndroid = user.device === 'android' ? true : false;
  const [page, setPages] = useState('card');
  const [valor, setValor] = useState(null);
  const [mdvPercent, setMdvPercent] = useState(0);
  const [mdvDiretor, setMdvDiretor] = useState(0);
  const [mdvGerente, setMdvGerente] = useState(0);
  console.log(state);

  const toast = useToast({
    title: 'Aviso',
    description: 'Forma de pagamento indisponível',
    status: 'info',
    duration: 2000,
    isClosable: true,
    position: 'top',
    colorScheme: 'green',
    containerStyle: {
      paddingTop: '50px',
    },
  });

  setPage('Pagamentos');

  var buttonProps = {
    variante: 'outline',
    colorScheme: 'teal',
    h: '90px',
    w: '90px',
    borderWidth: '2px',
  };

  const RegrasCartao = (valor) => {
    const colors = useColorModeValue(['#5AB2AD', '#EB8B2A', 'blue.50'], ['#5AB2AD', '#EB8B2A', 'blue.900']);
    const [tabIndex, setTabIndex] = useState(0);
    const bg = colors[tabIndex];
    return (
      <>
        <Tabs
          w="90%"
          borderRadius="1.2em"
          isFitted
          variant="enclosed"
          onChange={(index) => setTabIndex(index)}
          bg={bg}
          color="white"
          fontWeight="bold"
        >
          <TabList mb="1em" color="white">
            <Tab fontWeight="bold" bg={'#5AB2AD'} borderTopLeftRadius="1.2em">
              ANUAL
            </Tab>
            <Tab fontWeight="bold" bg={'#EB8B2A'} borderTopRightRadius="1.2em">
              MENSAL
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Center>APENAS</Center>
              <Center>
                <Heading color="white" fontSize="3em">
                  {(state.valor_plano * 10).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                </Heading>
              </Center>
              <Center>Em até 3x sem juros</Center>
            </TabPanel>
            <TabPanel>
              <Center>APENAS</Center>
              <Center>
                <Heading color="white" fontSize="3em">
                  {state.valor_plano.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                </Heading>
              </Center>
              <Center>Mensais</Center>
            </TabPanel>
          </TabPanels>
        </Tabs>
        <Center>
          <Heading w="100%">
            <img src={tabIndex === 0 ? Meses2white : Meses2} alt="logo deovita" />
          </Heading>
        </Center>
        <Button
          colorScheme="teal"
          w="85%"
          borderRadius="1.5em"
          onClick={() => {
            if (tabIndex === 0) {
              setValor(state.valor_plano * 10);
              if (state.id_plano === 29) {
                setMdvPercent(7);
                setMdvGerente(1);
                setMdvDiretor(1);
              }
              if (state.id_plano === 55) {
                setMdvPercent(7);
                setMdvGerente(1);
                setMdvDiretor(1);
              }
            } else {
              setValor(state.valor_plano);
              if (state.id_plano === 29) {
                setMdvPercent(67);
                setMdvGerente(27);
                setMdvDiretor(6);
              }
              if (state.id_plano === 55) {
                setMdvPercent(67);
                setMdvGerente(27);
                setMdvDiretor(6);
              }
            }
          }}
        >
          Continuar
        </Button>
      </>
    );
  };

  const RegrasPix = (valor) => {
    const colors = useColorModeValue(['#5AB2AD', '#EB8B2A', 'blue.50'], ['#5AB2AD', '#EB8B2A', 'blue.900']);
    const [tabIndex, setTabIndex] = useState(0);
    const bg = colors[tabIndex];
    return (
      <>
        <Tabs
          w="90%"
          borderRadius="1.2em"
          isFitted
          variant="enclosed"
          onChange={(index) => setTabIndex(index)}
          bg={bg}
          color="white"
          fontWeight="bold"
        >
          <TabList mb="1em" color="white">
            <Tab fontWeight="bold" bg={'#5AB2AD'} borderTopLeftRadius="1.2em">
              ANUAL
            </Tab>
            {/*<Tab fontWeight="bold" bg={'#EB8B2A'} borderTopRightRadius="1.2em">
              MENSAL
            </Tab>*/}
          </TabList>
          <TabPanels>
            <TabPanel>
              <Center>APENAS</Center>
              <Center>
                <Heading color="white" fontSize="3em">
                  {(state.valor_plano * 10).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                </Heading>
              </Center>
              <Center>Por ano</Center>
            </TabPanel>
            <TabPanel>
              <Center>APENAS</Center>
              <Center>
                <Heading color="white" fontSize="3em">
                  {state.valor_plano.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                </Heading>
              </Center>
              <Center>Mensais</Center>
            </TabPanel>
          </TabPanels>
        </Tabs>
        <Center>
          <Heading w="100%">
            <img src={tabIndex === 0 ? Meses2white : Meses2} alt="logo deovita" />
          </Heading>
        </Center>
        <Button
          colorScheme="teal"
          w="85%"
          borderRadius="1.5em"
          onClick={() => {
            if (tabIndex === 0) {
              setValor(state.valor_plano * 10);
              if (state.id_plano === 29) {
                setMdvPercent(7);
                setMdvGerente(1);
                setMdvDiretor(1);
              }
              if (state.id_plano === 55) {
                setMdvPercent(7);
                setMdvGerente(1);
                setMdvDiretor(1);
              }
            } else {
              setValor(state.valor_plano);
              if (state.id_plano === 29) {
                setMdvPercent(67);
                setMdvGerente(27);
                setMdvDiretor(6);
              }
              if (state.id_plano === 55) {
                setMdvPercent(67);
                setMdvGerente(27);
                setMdvDiretor(6);
              }
            }
          }}
        >
          Continuar
        </Button>
      </>
    );
  };
  const RegrasBoleto = (valor) => {
    const colors = useColorModeValue(['#5AB2AD', '#EB8B2A', 'blue.50'], ['#5AB2AD', '#EB8B2A', 'blue.900']);
    const [tabIndex, setTabIndex] = useState(0);
    const bg = colors[tabIndex];
    return (
      <>
        <Tabs
          w="90%"
          borderRadius="1.2em"
          isFitted
          variant="enclosed"
          onChange={(index) => setTabIndex(index)}
          bg={bg}
          color="white"
          fontWeight="bold"
        >
          <TabList mb="1em" color="white">
            <Tab fontWeight="bold" bg={'#5AB2AD'} borderTopLeftRadius="1.2em">
              ANUAL
            </Tab>
            <Tab fontWeight="bold" bg={'#EB8B2A'} borderTopRightRadius="1.2em">
              MENSAL
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Center>APENAS</Center>
              <Center>
                <Heading color="white" fontSize="3em">
                  {(state.valor_plano * 10).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                </Heading>
              </Center>
              <Center>Por ano</Center>
            </TabPanel>
            <TabPanel>
              <Center>APENAS</Center>
              <Center>
                <Heading color="white" fontSize="3em">
                  {(state.valor_plano + 10).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                </Heading>
              </Center>
              <Center>Mensais</Center>
            </TabPanel>
          </TabPanels>
        </Tabs>
        <Center>
          <Heading w="100%">
            <img src={tabIndex === 0 ? Meses2white : Meses2} alt="logo deovita" />
          </Heading>
        </Center>
        <Button
          colorScheme="teal"
          w="85%"
          borderRadius="1.5em"
          onClick={() => {
            if (tabIndex === 0) {
              setValor(state.valor_plano * 10);
              if (state.id_plano === 29) {
                setMdvPercent(7.33);
                setMdvGerente(0.8);
                setMdvDiretor(0.17);
              }
              if (state.id_plano === 55) {
                setMdvPercent(7.21);
                setMdvGerente(0.85);
                setMdvDiretor(0.27);
              }
            } else {
              setValor(state.valor_plano + 10);
              if (state.id_plano === 29) {
                setMdvPercent(50.12);
                setMdvGerente(20.05);
                setMdvDiretor(4.76);
              }
              if (state.id_plano === 55) {
                setMdvPercent(33.16);
                setMdvGerente(13.41);
                setMdvDiretor(3.16);
              }
            }
          }}
        >
          Continuar
        </Button>
      </>
    );
  };

  function renderPage() {
    switch (page) {
      case 'card':
        if (fromCadastro === true) {
          if (valor) {
            return <CartaoGenerico selecionado={valor} mdvPercent={mdvPercent} mdvGerente={mdvGerente} mdvDiretor={mdvDiretor} />;
          } else {
            return <RegrasCartao />;
          }
        } else {
          return <CartaoGenerico />;
        }

      case 'pix':
        if (fromCadastro === true) {
          if (valor) {
            return <PixGenerico selecionado={valor} mdvPercent={mdvPercent} mdvGerente={mdvGerente} mdvDiretor={mdvDiretor} />;
          } else {
            return <RegrasPix />;
          }
        } else {
          return <PixGenerico />;
        }
      case 'boleto':
        if (fromCadastro === true) {
          if (valor) {
            return <BoletoGenerico selecionado={valor} mdvPercent={mdvPercent} mdvGerente={mdvGerente} mdvDiretor={mdvDiretor} />;
          } else {
            return <RegrasBoleto />;
          }
        } else {
          return <BoletoGenerico />;
        }
      default:
        return <CartaoGenerico />;
    }
  }

  return (
    <>
      {user.loggedIn ? <ChildHeader /> : <NoUserHeader />}
      <VStack w={{ base: '95%', md: '500px' }} m="auto" justify="top" spacing="1rem" align="center" marginBottom="40">
        <chakra.div mt="5%" fontWeight="bold" color="#585858">
          Selecione a forma de pagamento.
        </chakra.div>
        <HStack spacing={5}>
          <RadioGroup
            onChange={(e) => {
              setPages(e);
              setValor(null);
            }}
            value={page}
            colorScheme="teal"
          >
            <HStack w="100%" spacing={10}>
              <VStack>
                <Image src={CreditCardImg} w="2em" h="2em" />
                <Radio value="card"></Radio>
                <Text>Cartão</Text>
              </VStack>
              <VStack start="center">
                <Image src={PixImg} w="2em" h="2em" />
                <Radio value="pix"></Radio>
                <Text>Pix</Text>
              </VStack>
            </HStack>
          </RadioGroup>
        </HStack>
        {renderPage()}
      </VStack>
      {user.loggedIn && fromCadastro === false ? <BottomNavBar /> : ''}
    </>
  );
};

export default Menu;
