import {
  VStack,
  Button,
  Text,
  RadioGroup,
  Radio,
  chakra,
  Wrap,
  Flex,
  Heading,
  Box,
  HStack,
  Input,
  ButtonGroup,
  useDisclosure,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import React from 'react';
import { CheckIcon } from '@chakra-ui/icons';
import NoUserHeader from '../AreaLivre/NoUserHeader';
import ChildHeader from '../Dashboard/Main/ChildHeader';
import { AccountContext } from '../AccountContext';
import Loading from '../LoadingWithHeader';
import PlanoImg from '../../images/deovita_plano.svg';
import TextField from 'components/TextField';
import DrawerIndicacao from './DrawerIndicacao';

const Plano = () => {
  const [item, setItem] = useState(false);
  const [value, setValue] = useState(0);
  const { state } = useLocation();
  const { user, setPage } = useContext(AccountContext);
  const [adicionais, setAdicionais] = useState(false);
  const [error, setError] = useState(false);

  const [errorText, setErrorText] = useState('');
  const [disabled, setDisabled] = useState(false);
  const disclosure = useDisclosure();
  const navigate = useNavigate();
  setPage('Plano');

  const handleChange = (val) => {
    let planId = item[parseInt(val)].id;
    carregarAdicionais(planId);
    setValue(parseInt(val));
  };

  function handleSubmit(index) {
    let parms = {
      ...state,
      id_plano: item[index].id,
      nome_plano: item[index].nome,
      dependentes_plano: item[index].qtd_dependentes,
      parcelsActive: true,
      valor_plano: parseFloat(item[index].valor_numerico),
      valor_plano_formatado: item[index].valor,
      navig_to: '/cadastro/analise',
      expiration_pix_in_min: 5,
      item: 'Contratação DeoVita',
      service: 'contract',
      voucher: user.voucher ? user.voucher : null,
    };

    let carrinho = [{ id: 0, nome: parms.nome_plano, valor: parms.valor_plano_formatado, valor_numerico: parms.valor_plano }];
    navigate('/pagamentos', { state: { ...parms, carrinho: carrinho } });
  }

  async function carregarItems() {
    try {
      const resposta = await fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/plans`, {
        method: 'GET',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
      });
      resposta
        .json()
        .then((res) => {
          let plans =
            res &&
            res.map((item) => ({
              id: item.forma_pagamento_id,
              nome: item.nome,
              valor: item.valor,
              qtd_dependentes: item.parcelas - 1,
              beneficios: item.beneficios_plano,
              valor_numerico: item.valor_numerico,
            }));
          setItem(plans);
        })
        .catch(() => setError(true));
    } catch (err) {
      setError(true);
    }
  }

  async function carregarAdicionais(planId) {
    let url = process.env.REACT_APP_BASE_URL_API_SERVER + '/extras/byPlanId/' + planId;
    try {
      let response = await fetch(url, { method: 'GET' });
      let res = await response.json();
      setAdicionais(res);
    } catch (err) {
      setAdicionais([]);
    }
  }

  useEffect(() => {
    carregarAdicionais(0);
    carregarItems();
  }, []);

  useEffect(() => {
    if (!user.voucher) {
      disclosure.onOpen();
    } else {
      disclosure.onClose();
    }
  }, [user]);

  return item ? (
    <>
      {user.loggedIn ? <ChildHeader /> : <NoUserHeader />}

      <VStack w={{ base: '80%', md: '300px' }} m="auto" justify="top" marginBottom="5%" marginTop="5%" spacing="1rem" align="center">
        <DrawerIndicacao disclosure={disclosure} />
        <Heading>
          <img src={PlanoImg} alt="logo deovita" />
        </Heading>
        {item &&
          item.map((item, index) => (
            <>
              <Box
                w="100%"
                minHeight="200px"
                backgroundColor={item.nome === 'FAMILIA' ? '#5AB2AD' : '#EB8B2A'}
                borderRadius="1.5em"
                onClick={() => handleSubmit(index)}
              >
                <VStack mt="5%">
                  <Heading color="white" fontSize="1.5em">
                    {item.nome}
                  </Heading>
                  <Heading color="white" fontSize="3em">
                    {item.valor}
                  </Heading>
                  <chakra.h4 color="white" fontWeight="bold">
                    {item.nome === 'FAMILIA' ? '+ ' + '4' + ' dependentes + 1 pet' : '+ 1 pet'}
                  </chakra.h4>
                  <chakra.h6>
                    <Text fontSize="sm" width="100%" pl="10%" pr="10%" pb="5%" color="white" textAlign="center">
                      {item.beneficios &&
                        item.beneficios.map((beneficio, index) => (
                          <>
                            {beneficio.descricao} {index < item.beneficios.length - 1 ? ' | ' : ''}{' '}
                          </>
                        ))}
                    </Text>
                  </chakra.h6>
                </VStack>
              </Box>
            </>
          ))}
        {user.voucher ? (
          <>
            <VStack spacing={-1}>
              <Text color="teal">voucher {user.voucher.vendedor_id} aplicado </Text>{' '}
              <Text color="#0cd5f0" onClick={() => disclosure.onOpen()}>
                alterar
              </Text>
            </VStack>
          </>
        ) : (
          <></>
        )}
      </VStack>
    </>
  ) : error ? (
    navigate('/alerts/erro/cadastro_plano')
  ) : (
    <Loading />
  );
};

export default Plano;
