import React, { useEffect } from 'react';
import { VStack, TabList, TabPanel, TabPanels, Tabs, Box } from '@chakra-ui/react';
import BottomNavBar from '../../../../BottomNavBar';
import ChildHeader from '../../ChildHeader';
import { AccountContext } from '../../../../AccountContext';
import { useContext } from 'react';
import Resumo from './Resumo';
import ListaNiveis from './ListaNiveis';
import SearchBar from './SearchBar';
import { useLocation } from 'react-router-dom';
function Main() {
  const { user, setPage } = useContext(AccountContext);
  const { state } = useLocation();
  const { vendedores } = state;
  const [total, setTotal] = React.useState();

  useEffect(() => {
    let teste = vendedores;
    var ar = [];
    ar.push(teste);
    let totalGeral = 0;
    const MapaVendedores = Object.keys(ar).map((key) => {
      const useMapaVendedores = Object.keys(ar[key]).map((vendedor) => {
        const vendedorData = ar[key][vendedor];
        totalGeral += parseFloat(ar[key][vendedor].qtd);
        setTotal(totalGeral);
      });
    });
  }, []);

  setPage('Vendedores');

  return (
    <>
      <ChildHeader />
      <VStack
        align="center"
        width="100%"
        justify={{
          base: 'center',
          md: 'space-around',
          xl: 'space-between',
        }}
        direction={{ base: 'column-reverse' }}
        wrap="no-wrap"
        minH="10vh"
        px={3}
        mb="20"
      >
        <Resumo totalGeral={total} />
        <VStack width="100%">
          <Tabs size="md" w="100%">
            <TabList></TabList>

            <TabPanels>
              <TabPanel fontSize="sm">
                <SearchBar />
                <Box marginTop="5%">
                  <ListaNiveis lista={vendedores}></ListaNiveis>
                </Box>
              </TabPanel>{' '}
            </TabPanels>
          </Tabs>
          <BottomNavBar />
        </VStack>
      </VStack>
      <BottomNavBar />
    </>
  );
}

export default Main;
