import { VStack, Image } from '@chakra-ui/react';
import React from 'react';
import Carousel from 'react-elastic-carousel';
import Banner1 from '../../../images/banner1.png';

function BannerCarrosel() {
  return (
    <>
      <Carousel itemsToShow={1.1} showArrows={false} FocusOnSelect={true} pagination={true}>
        <Image src={Banner1} borderRadius={20} h="160"></Image>
        <Image src={Banner1} h="160" borderRadius={20}></Image>
      </Carousel>
    </>
  );
}

export default BannerCarrosel;
