import React, { useState, useEffect } from "react";
import { createIcon } from "@chakra-ui/react";
import "../css/floatbutton.css";

function Floatbutton() {
  const CallCenter = createIcon({
    displayName: "CallCenterSvgrepoCom",
    viewBox: "0 0 480.542 480.542",
    d: "M89.104 219.999h26.626c17.666 51.873 66.863 89.289 124.637 89.289 41.424 0 78.433-19.241 102.577-49.248h34.228c6.352 0 11.5-5.148 11.5-11.5v-28.541h5.184c12.474 0 22.621-10.148 22.621-22.622V139.54c0-11.022-7.926-20.224-18.376-22.217-7.547-34.184-26.132-63.235-54.028-84.307C315.476 11.417 279.615 0 240.366 0 160.701 0 97.864 47.184 82.511 117.9c-9.268 2.83-16.029 11.459-16.029 21.64v57.837c0 12.474 10.148 22.622 22.622 22.622zm.378-23v-57.081h24.789a131.198 131.198 0 00-5.525 37.748c0 6.567.49 13.021 1.423 19.333H89.482zm150.884 89.289c-59.894 0-108.621-48.728-108.621-108.622s48.727-108.62 108.621-108.62c59.895 0 108.622 48.727 108.622 108.62 0 21.898-6.52 42.297-17.709 59.374h-97.586c-6.351 0-11.5 5.149-11.5 11.5 0 6.352 5.149 11.5 11.5 11.5h77.398c-19.018 16.351-43.736 26.248-70.725 26.248zm125.305-49.248h-7.857a130.924 130.924 0 007.189-17.041h.667v17.041zm27.805-40.041h-22.91a132.128 132.128 0 001.423-19.333c0-13.115-1.938-25.785-5.525-37.748h27.013v57.081zM240.366 23c66.29 0 118.756 37.303 133.954 93.918h-17.217c-21.988-42.08-66.06-70.872-116.737-70.872-50.676 0-94.748 28.792-116.736 70.872h-17.22C121.593 60.338 174.166 23 240.366 23zM358.477 314.352H122.065c-36.506 0-66.206 29.699-66.206 66.205v88.484c0 6.352 5.149 11.5 11.5 11.5s11.5-5.148 11.5-11.5v-88.484c0-23.823 19.382-43.205 43.206-43.205h236.412c23.824 0 43.207 19.382 43.207 43.206v88.484c0 6.352 5.148 11.5 11.5 11.5s11.5-5.148 11.5-11.5v-88.484c0-36.506-29.7-66.206-66.207-66.206z",
  });

  const Headphones = createIcon({
    displayName: "HeadphonesWithMicSvgrepoCom",
    viewBox: "0 0 207.093 207.093",
    d: "M114.133 205.962H92.914c-4.112 0-7.458-3.345-7.458-7.457v-5.586c0-4.112 3.345-7.458 7.458-7.458h21.219c4.112 0 7.457 3.345 7.457 7.458v.793h28.303c16.213 0 29.403-13.19 29.403-29.403v-12.757c-5.878-.958-10.378-6.072-10.378-12.216v-41.31c0-6.039 4.348-11.082 10.077-12.164v-5.257c0-41.616-33.856-75.472-75.472-75.472-41.616 0-75.473 33.856-75.473 75.472v5.249c5.753 1.063 10.124 6.117 10.124 12.172v41.31c0 6.825-5.553 12.378-12.378 12.378-6.675 0-12.133-5.311-12.37-11.929C5.227 135.958 0 127.817 0 118.679c0-9.138 5.227-17.279 13.427-21.104.216-6.031 4.767-10.976 10.624-11.807v-5.166c0-43.821 35.651-79.472 79.472-79.472 43.821 0 79.473 35.651 79.473 79.472v5.159c5.88.811 10.454 5.766 10.671 11.813 8.2 3.825 13.427 11.967 13.427 21.104s-5.227 17.278-13.427 21.104c-.213 5.943-4.636 10.833-10.37 11.768v12.757c0 18.418-14.984 33.403-33.403 33.403H121.59v.793c0 4.113-3.345 7.459-7.457 7.459zm-21.219-16.501a3.462 3.462 0 00-3.458 3.458v5.586a3.461 3.461 0 003.458 3.457h21.219a3.461 3.461 0 003.457-3.457v-5.586a3.462 3.462 0 00-3.457-3.458H92.914zm88.382-99.815c-4.619 0-8.378 3.758-8.378 8.378v41.31c0 4.62 3.759 8.378 8.378 8.378s8.378-3.758 8.378-8.378v-41.31c0-4.619-3.759-8.378-8.378-8.378zm-155.499 0c-4.62 0-8.378 3.758-8.378 8.378v41.31c0 4.62 3.758 8.378 8.378 8.378s8.378-3.758 8.378-8.378v-41.31c0-4.619-3.758-8.378-8.378-8.378zm-12.378 12.452C7.613 105.538 4 111.764 4 118.679c0 6.915 3.613 13.141 9.419 16.581v-33.162zm180.255 0v33.163c5.806-3.44 9.419-9.666 9.419-16.581s-3.614-13.142-9.419-16.582z",
  });

  return (
    <>
      <a href="#" class="float">
        <i class="fa fa-plus my-float">
          {" "}
          <Headphones
            boxSize={10}
            mt="2"
            onClick={() =>
              (window.location.href = "https://wa.me/558881189898")
            }
            color="white"
          />
        </i>
      </a>
    </>
  );
}

export default Floatbutton;
