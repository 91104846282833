import {
  Flex,
  HStack,
  Box,
  chakra,
  Avatar,
  WrapItem,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Table,
  Text,
  Tr,
  Td,
  TableContainer,
  Badge,
  VStack,
} from '@chakra-ui/react';
import { StarIcon } from '@chakra-ui/icons';
import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchContext } from '../../../Dashboard/Main/SearchContext';
import Loading from '../../../Loading';
import '../css/lista-medicos.css';
import DotsLoading from '../../../Utils/Components/DotsLoading';

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
}

function ListaMedicos() {
  const pic = getRandomInt(0, 999);
  const navigate = useNavigate();
  var mapUriPrefix = 'https://maps.apple.com/?q=';
  const { medicos, loadingMedicos, fetchData, searchContext, lastSearch } = useContext(SearchContext);
  const [medicos2, setMedicos2] = useState(medicos);
  const [fetchMore, setFetchMore] = useState(false);
  const server = process.env.REACT_APP_BASE_URL_API_SERVER;

  useEffect(() => {
    setMedicos2(medicos);
  }, [medicos]);

  useEffect(() => {
    var busyStack = false;
    var page = 1;
    const handleScroll = async () => {
      if (window.innerHeight + window.scrollY > document.body.offsetHeight && busyStack === false) {
        setFetchMore(true);
        busyStack = true;
        let url = server + `/schedule?page=${page + 1}&limit=${10}`;
        if (searchContext === 'search') url = server + `/schedule/search?text=${lastSearch}&page=${page + 1}&limit=${10}`;
        let data = await fetchData(url);
        let lastPage = data.length === 0;
        if (!lastPage) busyStack = false;
        setMedicos2((prev) => [...prev, ...data]);
        page++;
        setFetchMore(false);
      }
    };

    window.removeEventListener('scroll', handleScroll);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [searchContext, lastSearch]);

  const useMedicos = medicos2.map((medico, index) => {
    const arrName = medico.dados.nome.split(' ');
    const preposicoes = ['das', 'dos', 'des', 'da', 'do', 'de'];
    const nomeMedico = Array.isArray(arrName)
      ? arrName.filter((name, index) => index <= 1 && preposicoes.includes(name.toLowerCase()) === false).join(' ')
      : '';
    const event = new Date(medico.horarios[0].AVA_DATE.split('/').reverse().join('-') + 'T08:00:00');
    const avaliacao = Math.round(medico.dados.avaliacao_media);
    const options = { weekday: 'long', month: 'long', day: 'numeric' };
    let titulo = '';
    let promocao = '';
    if (medico.dados.sexo === 'M') {
      titulo = 'Dr. ';
    } else if (medico.dados.sexo === 'F') {
      titulo = 'Dra. ';
    }
    if (medico.horarios[0].valor_promocao === true) {
      promocao = 'Consulta Doada';
    }

    return (
      <>
        <Flex minW="320px" maxW="320px" mx="auto" bg="" overflow="hidden">
          <Box w={1 / 3} bgSize="cover" align="center">
            <WrapItem ml="5px" mt="0.8em">
              <Avatar
                id={medico.dados.medico_id}
                size="xl"
                name={medico.dados.nome}
                src={process.env.REACT_APP_URL_PROFILEPICS_OPERADOR + '/' + medico.dados.medico + '/foto_profissional.webp?' + pic}
              />{' '}
            </WrapItem>
            <chakra.a
              color="rgba(14, 170, 139, 1)"
              fontWeight="bold"
              fontSize="sm"
              onClick={() => {
                navigate('/curriculolivre', {
                  state: {
                    medico,
                    pic,
                  },
                });
              }}
            >
              ver curriculo
            </chakra.a>
          </Box>
          <Box w={2 / 3} p={{ base: 4, md: 4 }}>
            <Text casing="capitalize" fontSize="xl" fontWeight="bold" color="">
              {titulo}
              {''}
              {nomeMedico.toLocaleLowerCase()}
            </Text>
            <chakra.p mt={-1} fontSize="sm" color="">
              {medico.dados.especialidade}
            </chakra.p>
            <HStack>
              <chakra.p fontSize="sm">
                {medico.dados.conselho && medico.dados.conselho} {medico.dados.conselho && medico.dados.rqe && ' '}{' '}
                {medico.dados.rqe && medico.dados.rqe}
              </chakra.p>
            </HStack>
            <HStack spacing={1} display="flex" alignItems="center" fontSize="sm" pt={0.5}>
              {avaliacao >= 1 ? <StarIcon color="orange" /> : <StarIcon color="gray.500" />}
              {avaliacao >= 2 ? <StarIcon color="orange" /> : <StarIcon color="gray.500" />}
              {avaliacao >= 3 ? <StarIcon color="orange" /> : <StarIcon color="gray.500" />}
              {avaliacao >= 4 ? <StarIcon color="orange" /> : <StarIcon color="gray.500" />}
              {avaliacao >= 5 ? <StarIcon color="orange" /> : <StarIcon color="gray.500" />}
              <chakra.span fontSize="xs">{medico.dados.avaliacoes} opiniões</chakra.span>
            </HStack>
            <chakra.p color="red.500">{promocao}</chakra.p>
          </Box>
        </Flex>
        <Flex minW="320px" maxW="320px" mx="auto" bg="" overflow="hidden" alignItems="center" justifyContent="space-between">
          <Tabs maxW="320px">
            <TabList>
              <Tab>Clinica</Tab>
              <Tab isDisabled></Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <chakra.a
                  href={
                    mapUriPrefix +
                    medico.dados.logradouro +
                    ', ' +
                    medico.dados.numero +
                    ', ' +
                    medico.dados.bairro +
                    ', ' +
                    medico.dados.municipio +
                    ', Ceará'
                  }
                  fontSize="sm"
                >
                  <chakra.p align="justify-self">
                    {medico.dados.logradouro}, {medico.dados.numero} - {medico.dados.bairro} - {medico.dados.municipio}
                    <chakra.span color="rgba(14, 170, 139, 1)"> ver no mapa</chakra.span>
                  </chakra.p>
                  <chakra.p mt="0.1em">{medico.dados.empresa}</chakra.p>
                </chakra.a>
                <chakra.p>
                  <chakra.span color="gray.500">Próxima Data:</chakra.span>
                  <chakra.span color="black.500"> {event.toLocaleDateString('pt-br', options)}</chakra.span>
                </chakra.p>
                <TableContainer minWidth="100%">
                  <Table variant="simple">
                    <Tr>
                      {medico.horarios.map((horario, index) => {
                        return index > 1 ? (
                          ''
                        ) : (
                          <Td>
                            <Badge
                              borderRadius="0.5em"
                              margin="0 0.5em"
                              padding="0.5em 1.4em"
                              size="lg"
                              fontSize="md"
                              colorScheme="green"
                              onClick={() => {
                                navigate('/agendamentolivre', {
                                  state: {
                                    medico,
                                    startNum: 0,
                                    st: 0,
                                    defaultDateTime: horario.AVA_DATE + ' ' + horario.AVA_START_TIME,
                                  },
                                });
                              }}
                            >
                              {horario.AVA_START_TIME}
                            </Badge>
                          </Td>
                        );
                      })}
                      <Td>
                        <Badge
                          borderRadius="0.5em"
                          margin="0 0.5em"
                          padding="0.5em 1.4em"
                          size="lg"
                          fontSize="md"
                          colorScheme="green"
                          onClick={() => {
                            navigate('/agendamentolivre', {
                              state: {
                                medico,
                                startNum: 0,
                                st: 0,
                                defaultDateTime: null,
                              },
                            });
                          }}
                        >
                          Mais
                        </Badge>
                      </Td>
                    </Tr>
                  </Table>
                </TableContainer>
              </TabPanel>
              <TabPanel></TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
      </>
    );
  });
  return loadingMedicos ? (
    <>
      <VStack w={{ base: '20%', md: '100px' }} m="auto" justify="top" align="start">
        <Loading />
      </VStack>
    </>
  ) : (
    <>
      <div style={{ marginBottom: '13%' }}>
        {medicos2 && useMedicos} {fetchMore && <DotsLoading />}
      </div>
    </>
  );
}

export default React.memo(ListaMedicos);
