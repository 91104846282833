import { StarIcon } from '@chakra-ui/icons';
import { Img, VStack, chakra, Tabs, Box, TabList, Tab, TabPanels, TabPanel, HStack, Image } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Back from '../../../images/back.svg';
import placeholder from '../Medico/profile-placeholder.png';
import { AccountContext } from '../../AccountContext';
import BottomNavBar from '../../BottomNavBar';

function CurriculoDetalhes() {
  const navigate = useNavigate();
  const { user } = useContext(AccountContext);
  const { state } = useLocation();
  const { atendimento } = state;
  const img = process.env.REACT_APP_URL_PROFILEPICS_OPERADOR + '/' + atendimento.RESOURCE_LID + '.jpg';
  const [exp, setExp] = useState([]);
  const defaultimg = { placeholder };
  const url = `${process.env.REACT_APP_BASE_URL_API_SERVER}/schedule/cv/${atendimento.RESOURCE_LID}`;
  async function fetchData(url) {
    try {
      let response = await fetch(url, {
        headers: { 'Content-Type': 'application/json' },
        method: 'GET',
      });
      let data = await response.json();
      setExp(data);
      console.log(exp);
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
  useEffect(() => {
    fetchData(url);
  }, []);
  console.log(atendimento.RESOURCE_LID);
  const useEsp = exp.map((item, index) => {
    return <chakra.p>{item.especializacao_academica}</chakra.p>;
  });
  const useExp = exp.map((item, index) => {
    return <chakra.p>{item.experiencia_tempo}</chakra.p>;
  });
  return (
    <>
      <Img
        src={Back}
        boxSize="2em"
        onClick={() => navigate(-1)}
        color="blackAlpha.500"
        marginTop="2.5em"
        position="absolute"
        left="5"
        zIndex="100"
        borderRadius="15px"
      />
      <Image src={img} zIndex="-1" w="full" position="relative" fallbackSrc={process.env.REACT_APP_URL_PROFILEPICS_OPERADOR + '/perfil.png'} />
      <chakra.header
        w="full"
        px={{ base: 2, sm: 4 }}
        py={4}
        marginTop="-8"
        shadow="md"
        backgroundColor="white"
        minHeight="100px"
        borderTopRadius="30px"
        zIndex="100"
      >
        <chakra.h1 fontWeight="bold" align="center" fontSize="xl">
          {atendimento?.RESOURCE_NAME}
        </chakra.h1>
        <chakra.h2 marginTop="1em" paddingBottom="0.5em" align="center">
          {' '}
          {'0km'} | 5 <StarIcon color="gray.500" /> |{' '}
        </chakra.h2>
      </chakra.header>
      <Tabs variant="soft-rounded" w="full" px={{ base: 2, sm: 4 }} py={8}>
        <TabList borderBottom="1px solid gray" paddingBottom="1em">
          <Tab _selected={{ color: 'white', bg: 'black' }}>Experiencia</Tab>
          <Tab _selected={{ color: 'white', bg: 'black' }}>Formação</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <HStack>
              <VStack>
                <chakra.p color="gray.500">Especialidade</chakra.p>
                {exp && useEsp}
              </VStack>
              <VStack>
                <chakra.p color="gray.500">Experiencia</chakra.p>
                {exp && useExp}
              </VStack>
            </HStack>
            <chakra.p marginTop="5%" fontWeight="bold">
              Sobre mim
            </chakra.p>
            <chakra.p></chakra.p>
          </TabPanel>
          <TabPanel>
            <HStack>
              <VStack>
                <chakra.p color="gray.500">Especialidade</chakra.p>
                <chakra.p>dados medicos</chakra.p>
              </VStack>
              <VStack>
                <chakra.p color="gray.500">Experiencia</chakra.p>
                <chakra.p>0 Anos</chakra.p>
              </VStack>
            </HStack>
            <chakra.p marginTop="5%" fontWeight="bold">
              Graduação
            </chakra.p>
            <chakra.p>dados medicos</chakra.p>
          </TabPanel>
        </TabPanels>
      </Tabs>

      <BottomNavBar />
    </>
  );
}

export default CurriculoDetalhes;
