import { StarIcon } from '@chakra-ui/icons';
import { Img, Text, VStack, chakra, Tabs, Box, TabList, Tab, TabPanels, TabPanel, HStack, Badge, WrapItem, Center, Image } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Back from '../../../images/back.svg';
import BottomNavBar from '../../BottomNavBar';
import format from 'date-fns/format';
import { ptBR } from 'date-fns/locale';

function CurriculoMedico() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { medico, pic, startNum, defaultDateTime } = state;
  const uniqueDates = [];
  medico.horarios.map((agenda, index) => {
    if (!uniqueDates.includes(agenda.AVA_DATE)) {
      uniqueDates.push(agenda.AVA_DATE);
    }
  });
  const img = process.env.REACT_APP_URL_PROFILEPICS_OPERADOR + '/' + medico.dados.medico + '/foto_profissional.webp?' + pic;
  const options = { month: 'short', day: 'numeric' };
  const [exp, setExp] = useState([]);
  const [cv, setCv] = useState([]);
  const proximasDatas = [];
  let mesPorExtenso = [];

  const url = `${process.env.REACT_APP_BASE_URL_API_SERVER}/schedule/cv/${medico.dados.medico}`;
  async function fetchData(url) {
    try {
      let response = await fetch(url, {
        headers: { 'Content-Type': 'application/json' },
        method: 'GET',
      });
      let data = await response.json();
      setExp(data.experiencia);
      setCv(data.formacao);
      return data;
    } catch (error) {
      console.log(error);

      return [];
    }
  }

  useEffect(() => {
    fetchData(url);
  }, []);

  const useEsp = exp.map((item, index) => {
    return <chakra.p>{item.experiencia}</chakra.p>;
  });

  const useExp = exp.map((item, index) => {
    return <chakra.p>{item.tempo_experiencia}</chakra.p>;
  });

  const Formacoes = cv.map((item) => (
    <>
      <chakra.p marginTop="5%" fontWeight="bold">
        {item.nivel_formacao}
      </chakra.p>
      <chakra.p>{item.formacao_academica + ' - ' + item.data_formacao}</chakra.p>
    </>
  ));

  const horariosMapeados = medico.horarios.map((horario, index) => {
    let AVA_DATE = medico.horarios[index].AVA_DATE;
    let event = format(
      new Date(AVA_DATE.split('/').reverse().join('-') + 'T08:00:00'),
      "'na' EEEEEEE',' dd 'de' MMMMMMM 'de' yyyy",
      { locale: ptBR },
      { options }
    );

    let dataIcon = event.replace(' de ', ' ').replace('.', '').split(' ', 2);

    proximasDatas.push(dataIcon);
  });

  const Horarios = (props) => (
    <Center>
      <Badge
        direction="column"
        minWidth="380px"
        height="95px"
        left="0px"
        borderRadius="1em"
        margin="1em"
        ml="2"
        padding="0.5em"
        fontSize="sm"
        colorScheme={props.rank === 0 ? 'rgba(90, 178, 173, 0.25);' : 'orange'}
        onClick={() => {
          if (!props.horario.procedimentos) {
            if (props.index === 1 && medico.horarios[0].AVA_DATE !== medico.horarios[1].AVA_DATE) {
              navigate('/atendimento/agendamento', {
                state: {
                  medico,
                  startNum: 1,
                  st: 1,
                  defaultDateTime: props.horario.AVA_DATE + ' ' + props.horario.AVA_START_TIME,
                  procedimentoId: props.horario.ACTIVITY_LID,
                  procedimento: props.horario.ACTIVITY_NAME,
                  price: props.horario.PRICE,
                },
              });
            } else {
              navigate('/atendimento/agendamento', {
                state: {
                  medico,
                  startNum: 0,
                  st: 0,
                  defaultDateTime: props.horario.AVA_DATE + ' ' + props.horario.AVA_START_TIME,
                  procedimentoId: props.horario.ACTIVITY_LID,
                  procedimento: props.horario.ACTIVITY_NAME,
                  price: props.horario.PRICE,
                },
              });
            }
          } else {
            if (props.horario.procedimentos.length === 1) {
              if (props.index === 1 && medico.horarios[0].AVA_DATE !== medico.horarios[1].AVA_DATE) {
                navigate('/atendimento/agendamento', {
                  state: {
                    medico,
                    startNum: 1,
                    st: 1,
                    defaultDateTime: props.horario.AVA_DATE + ' ' + props.horario.AVA_START_TIME,
                    procedimentoId: props.horario.procedimentos[0].id,
                    procedimento: props.horario.procedimentos[0].nome,
                    price: props.horario.procedimentos[0].valor,
                    procedimentoParticular:
                      props.horario &&
                      props.horario.length > 0 &&
                      props.horario.procedimentos &&
                      props.horario.procedimentos.length > 0 &&
                      props.horario.procedimentos[0].procedimento_particular &&
                      props.horario.procedimentos[0].procedimento_particular.length > 0
                        ? props.horario[0].procedimentos[0].procedimento_particular[0].particular_id
                        : null,
                    valorParticular:
                      props.horario &&
                      props.horario.procedimentos &&
                      props.horario.procedimentos.length > 0 &&
                      props.horario.procedimentos[0].procedimento_particular &&
                      props.horario.procedimentos[0].procedimento_particular.length > 0
                        ? props.horario.procedimentos[0].procedimento_particular[0].particular_valor
                        : null,
                  },
                });
              } else {
                navigate('/atendimento/agendamento', {
                  state: {
                    medico,
                    startNum: 0,
                    st: 0,
                    defaultDateTime: props.horario.AVA_DATE + ' ' + props.horario.AVA_START_TIME,
                    procedimentoId: props.horario.procedimentos[0].id,
                    procedimento: props.horario.procedimentos[0].nome,
                    price: props.horario.procedimentos[0].valor,
                    procedimentoParticular:
                      props.horario &&
                      props.horario.length > 0 &&
                      props.horario.procedimentos &&
                      props.horario.procedimentos.length > 0 &&
                      props.horario.procedimentos[0].procedimento_particular &&
                      props.horario.procedimentos[0].procedimento_particular.length > 0
                        ? props.horario[0].procedimentos[0].procedimento_particular[0].particular_id
                        : null,
                    valorParticular:
                      props.horario &&
                      props.horario.procedimentos &&
                      props.horario.procedimentos.length > 0 &&
                      props.horario.procedimentos[0].procedimento_particular &&
                      props.horario.procedimentos[0].procedimento_particular.length > 0
                        ? props.horario.procedimentos[0].procedimento_particular[0].particular_valor
                        : null,
                  },
                });
              }
            } else {
              navigate('/atendimento/escolha-procedimento', {
                state: {
                  medico,
                  startNum: 0,
                  st: 0,
                  defaultDateTime: props.horario.AVA_DATE + ' ' + props.horario.AVA_START_TIME,
                },
              });
            }
          }
        }}
      >
        <Box
          backgroundColor={props.rank === 0 ? 'rgba(90, 178, 173, 0.3)' : 'rgba(239, 167, 63, 0.35);'}
          borderRadius="5px"
          margin="8px"
          color={props.rank === 0 ? 'rgba(62, 100, 255, 1)' : 'rgba(62, 100, 255, 1)'}
          padding="1px"
          width="60px"
        >
          <Center>
            <Text fontWeight="bold" fontSize="xl" color={props.rank === 0 ? '' : '#FE904B'}>
              {props.horario.AVA_DATE.slice(0, 2)}
            </Text>
          </Center>
          <Center>
            <Text fontWeight="bold" fontSize="xl" color={props.rank === 0 ? '' : '#FE904B'}>
              {format(new Date(props.horario.AVA_DATE.split('/').reverse().join('-') + 'T08:00:00'), 'MMMMMM', { locale: ptBR }).substring(0, 3)}
            </Text>
          </Center>
        </Box>

        <chakra.h1 ml="20" color="black" fontSize="sm" mt="-14">
          {props.horario.ACTIVITY_NAME ? props.horario.ACTIVITY_NAME : ''}
        </chakra.h1>
        <Text ml="20" color="black">
          {props.horario.AVA_DATE
            ? format(new Date(props.horario.AVA_DATE.split('/').reverse().join('-') + 'T08:00:00'), ' EEEEEEE', { locale: ptBR })
            : ''}{' '}
          {props.horario.AVA_START_TIME} - {props.horario.AVA_END_TIME}
        </Text>
      </Badge>
    </Center>
  );

  return (
    <>
      <Img
        src={Back}
        boxSize="2em"
        onClick={() => navigate(-1)}
        color="white"
        marginTop="2.5em"
        position="absolute"
        left="5"
        zIndex="100"
        borderRadius="15px"
        backgroundColor="black"
      />
      <Image src={img} zIndex="-1" w="full" position="relative" fallbackSrc={process.env.REACT_APP_URL_PROFILEPICS_OPERADOR + '/perfil.svg'} />
      <chakra.header
        w="full"
        px={{ base: 2, sm: 4 }}
        py={4}
        marginTop="-8"
        shadow="md"
        backgroundColor="white"
        minHeight="100px"
        borderTopRadius="30px"
        zIndex="100"
      >
        <chakra.h1 fontWeight="bold" align="center" fontSize="xl">
          {medico.dados.nome}
        </chakra.h1>
        <chakra.h2 marginTop="1em" paddingBottom="0.5em" align="center">
          {' '}
          {medico.dados.avaliacao_media} <StarIcon color="orange" /> | {medico.dados.conselho}{' '}
        </chakra.h2>
      </chakra.header>
      <Tabs variant="soft-rounded" w="full" px={{ base: 2, sm: 4 }} py={8}>
        <TabList borderBottom="1px solid gray" paddingBottom="1em">
          <Tab _selected={{ color: 'white', bg: 'black' }}>Experiência</Tab>
          <Tab _selected={{ color: 'white', bg: 'black' }}>Formação</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <HStack>
              <VStack>
                <chakra.p color="gray.500">Especialidade</chakra.p>
                {exp && useEsp}
              </VStack>
              <VStack>
                <chakra.p color="gray.500">Experiência</chakra.p>
                {exp && useExp}
              </VStack>
            </HStack>
            <chakra.p marginTop="5%" fontWeight="bold">
              Sobre mim
            </chakra.p>
            <chakra.p>{medico.dados.descricaoPessoal}</chakra.p>
          </TabPanel>
          <TabPanel>
            <HStack>
              <VStack>
                <chakra.p color="gray.500"></chakra.p>
                <chakra.p></chakra.p>
              </VStack>
              <VStack>
                <chakra.p color="gray.500"></chakra.p>
                <chakra.p></chakra.p>
              </VStack>
            </HStack>
            {cv && Formacoes}
          </TabPanel>
          <chakra.h1 fontWeight="bold" fontSize="lg" mt="10" ml="2">
            Próximos Horários Disponíveis
          </chakra.h1>

          {medico.horarios &&
            medico.horarios.map((horario, index) => {
              return index < 2 ? <Horarios horario={horario} rank={index} /> : '';
            })}
        </TabPanels>
      </Tabs>
      <WrapItem mt="35" />
      <BottomNavBar />
    </>
  );
}

export default CurriculoMedico;
