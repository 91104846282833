import { React, useContext, useEffect, useState } from 'react';
import {
  chakra,
  Button,
  Heading,
  VStack,
  Text,
  Center,
  Stack,
  HStack,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useNavigate, useLocation } from 'react-router';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import socketIOClient from 'socket.io-client';
import { MdDownloadForOffline } from 'react-icons/md';
import { AccountContext } from 'components/AccountContext';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;
const REACT_APP_DEOVITA_RECIPIENT_ID =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_DEOVITA_RECIPIENT_ID_PROD
    : process.env.REACT_APP_DEOVITA_RECIPIENT_ID_TEST;

const BoletoGenerico = (selecionado) => {
  const disclosureBoleto = useDisclosure();
  const valor_selecionado = selecionado.selecionado;
  const mdvPercent = selecionado.mdvPercent;
  const mdvGerente = selecionado.mdvGerente;
  const mdvDiretor = selecionado.mdvDiretor;
  const { state } = useLocation();
  const { user } = useContext(AccountContext);
  console.log(state);
  const { expiration_pix_in_min, navig_to, split, voucher } = state;
  const fromCadastro = navig_to === '/cadastro/analise' ? true : false;
  const valor = fromCadastro ? valor_selecionado : state.valor;
  const product_name = fromCadastro ? 'Contratação DeoVita' : state.item.product_name;
  const navigate = useNavigate();
  const [boleto, setBoleto] = useState({ sucess: '', boleto_url: '', boleto_boleto_barcode: '', id: 0, paid: false });
  const [copy, setCopy] = useState(false);
  const [error, setError] = useState(null);
  const [socket] = useState(socketIOClient(ENDPOINT));
  const fromCadastroData = {
    client_id: state.client_id,
    nome: state.name,
    fone: user.phone1 ? user.phone1 : state.phone,
    adicionais: state.adicionais,
    plano: state.id_plano,
  };

  useEffect(() => {
    socket.on('retorno_pix', (response) => {
      if (response === true) {
        //setPix({ ...pix, paid: true });
        console.log('pix pago');
      }
    });
    const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        id_cliente: user.id_cliente_deovita ? user.id_cliente_deovita : state.client_id,
        nome_cliente: user.name ? user.name : state.name,
        document: user.username ? user.username : state.cpf,
        email: user.email ? user.email : 'novoassinante@deovita.com.br',
        phone: user && user.loggedIn === true ? (user.phone1 ? onlyNumbers(user.phone1) : onlyNumbers(user.phone2)) : state.phone,
        amount: fromCadastro === true ? valor_selecionado * 100 : valor * 100,
        paid: false,
        split_rules: voucher
          ? voucher.recipient_id_gerente
            ? [
                { amount: Math.round(valor_selecionado * mdvGerente), recipient_id: voucher.recipient_id_gerente },
                { amount: Math.round(valor_selecionado * mdvDiretor), recipient_id: voucher.recipient_id_diretor },
                {
                  amount: Math.round(valor_selecionado * mdvPercent),
                  recipient_id: voucher.recipient_id_pagarme,
                },
                {
                  amount: Math.round(
                    valor_selecionado * 100 -
                      Math.round(valor_selecionado * mdvPercent) -
                      Math.round(valor_selecionado * mdvDiretor) -
                      Math.round(valor_selecionado * mdvGerente)
                  ),
                  recipient_id: `${REACT_APP_DEOVITA_RECIPIENT_ID}`,
                },
              ]
            : voucher.recipient_id_diretor
            ? [
                { amount: Math.round(valor_selecionado * mdvDiretor), recipient_id: voucher.recipient_id_diretor },
                {
                  amount: Math.round(valor_selecionado * mdvPercent),
                  recipient_id: voucher.recipient_id_pagarme,
                },
                {
                  amount: Math.round(
                    valor_selecionado * 100 - Math.round(valor_selecionado * mdvPercent) - Math.round(valor_selecionado * mdvDiretor)
                  ),
                  recipient_id: `${REACT_APP_DEOVITA_RECIPIENT_ID}`,
                },
              ]
            : [
                {
                  amount: Math.round(valor_selecionado * mdvPercent),
                  recipient_id: voucher.recipient_id_pagarme,
                },
                {
                  amount: Math.round(valor_selecionado * 100 - Math.round(valor_selecionado * mdvPercent)),
                  recipient_id: `${REACT_APP_DEOVITA_RECIPIENT_ID}`,
                },
              ]
          : split,

        voucher: voucher,
        item: fromCadastro === true ? fromCadastroData : state.item,
      }),
    };

    const erroMsg = '"Não foi possível gerar seu código pix no momento"';
    fetch(`${ENDPOINT}/payment/${state.service}/boleto`, requestOptions)
      .catch((err) => {
        setError(erroMsg);
        return;
      })
      .then((res) => {
        if (!res || !res.ok || res.status >= 400) {
          setError(erroMsg);
          return;
        }
        return res.json();
      })
      .then((data) => {
        if (!data) return;
        if (data.sucess === false && data.message === 'Já existe um contrato para esse paciente') {
          navigate('/alerts/sucesso/cadastro_contrato/');
        }
        if (data.sucess) {
          setError(null);
          setBoleto({ ...data, paid: false });
          console.log(boleto);
        } else {
          setError(erroMsg);
        }
      });
  }, []);

  function renderBoletoPaid() {
    return (
      <>
        <Alert status="success" variant="top-accent" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center">
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Pagamento confirmado!
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            <p>clique em finalizar para continuar</p>
          </AlertDescription>
        </Alert>
      </>
    );
  }

  function renderBoleto() {
    return (
      <>
        <Stack w="95%" padding="3">
          <Text as="p">Código de Barra</Text>
          <Text as="p">{boleto.boleto_barcode ? boleto.boleto_barcode : 'carregando...'} </Text>
          <Center w="100%">
            <CopyToClipboard text={boleto.boleto_barcode} onCopy={() => setCopy(true)}>
              <Button name="copy" colorScheme="teal" w="100%" variant="outline" disabled={boleto.boleto_barcode ? false : true}>
                {copy ? 'Copiado!' : 'Copiar'}
              </Button>
            </CopyToClipboard>
          </Center>

          {boleto.boleto_url && (
            <>
              <Center w="100%">Baixar Boleto</Center>
              <Center onClick={() => disclosureBoleto.onOpen()}>
                <MdDownloadForOffline color="teal" fontSize="5em" />
              </Center>
            </>
          )}
          <Center w="100%">
            <Text as="p" align="center" fontSize="small">
              prazo para o processo de compensação bancária pode demorar até 3 dias úteis
            </Text>
          </Center>
        </Stack>
        <HStack w="95%" padding="3">
          <Text>{product_name}</Text>
          <Text>
            {valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
            <chakra.span>{fromCadastro === true ? (valor > 80 ? '/anual' : '/mês') : ''}</chakra.span>
          </Text>
        </HStack>
        <Text>{error}</Text>
      </>
    );
  }

  const ModalBoleto = (props) => {
    const disclosure = props.disclosure;
    const url = props.url;
    console.log(disclosure);
    return (
      <Modal size="full" isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ModalCloseButton
              marginTop="4em"
              onClick={() => {
                disclosure.onClose();
              }}
            />
          </ModalHeader>

          <ModalBody>
            <chakra.iframe height="100vh" width="90%" scrolling="auto" frameborder="1" src={url} />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="green" variant="outline" mr={3} onClick={disclosure.onClose}>
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };
  return (
    <>
      <ModalBoleto disclosure={disclosureBoleto} url={boleto.boleto_url ? boleto.boleto_url : ''} />
      <Formik validateOnChange={false} validateOnBlur={false}>
        <VStack as={Form} w={{ base: '90%', md: '500px' }} m="auto" justify="top" h="100vh" spacing="1rem" align="start">
          <Heading>Boleto Bancário</Heading>
          {boleto.paid ? renderBoletoPaid() : renderBoleto()}
          <Button
            colorScheme="teal"
            w="100%"
            //loadingText="...Aguardando pagamento"
            //isLoading={!pix.paid}
            isDisabled={boleto.boleto_barcode !== '' ? false : true}
            onClick={() => {
              navigate(navig_to, {
                state: {
                  ...state,
                  valor: valor,
                  transaction_id: boleto.id,
                  forma_pgto: 'boleto',
                  boleto_url: boleto.boleto_url,
                },
              });
            }}
          >
            Continuar
          </Button>
        </VStack>
      </Formik>
    </>
  );
};

export default BoletoGenerico;
