import React, { useContext } from 'react';
import { Formik } from 'formik';
import { Box, Heading, VStack, Button, ButtonGroup, chakra } from '@chakra-ui/react';
import { AccountContext } from '../../AccountContext';

const AvisoLivre = () => {
  const { setPage } = useContext(AccountContext);
  setPage('Pronto atendimento');
  return (
    <>
      <Formik validateOnChange={false} validateOnBlur={false}>
        <VStack w={{ base: '90%', md: '500px' }} m="auto" marginTop="5%" spacing="2rem" justify="top">
          <Box w="90%" borderWidth="1px" borderRadius="5px" padding="5px">
            <Heading textAlign="center">ATENÇÃO</Heading>
            <chakra.p p="5px" textAlign="justify">
              Você ainda não possui acesso ao serviço de pronto atendimento ou está com pendência financeiras. Contate o atendimento pelo WhatsApp
              (88)98118-9898 para mais detalhes.
            </chakra.p>
          </Box>
          <ButtonGroup>
            <Button colorScheme="teal" onClick={() => (window.location.href = 'https://wa.me/558881189898')}>
              Ir para WhatsApp
            </Button>
          </ButtonGroup>
        </VStack>
      </Formik>
    </>
  );
};
export default AvisoLivre;
