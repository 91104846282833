import { Avatar, chakra, Box, Flex, HStack, Wrap, Image, Text, Input, Img } from '@chakra-ui/react';

import React, { useContext, useState } from 'react';
import LogoDashboard from '../../../images/logo_dashboard.svg';
import '../css/main.css';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../../AccountContext';
import CameraIcon from '../../../images/assets/icons/p6.png';

function MainChildHeader() {
  const navigate = useNavigate();
  const { user, page, pic, setPic } = useContext(AccountContext);
  let firstName = user.name.split(' ');
  //const [file, setFile] = useState(null);

  const handleSubmit = (file) => {
    console.log(file);

    var formdata = new FormData();
    formdata.append('file', file, file.name);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
    };

    fetch(`https://backoffice.deovita.com.br:4000/uploads/profilepics/${user.username}`, requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log('error', error));

    let datePic = Date.now();
    setPic(datePic);

    navigate('/alerts/sucesso/atualiza_foto');
  };
  return (
    <>
      <chakra.header
        w="full"
        px={{ base: 2, sm: 4 }}
        py={4}
        shadow="md"
        backgroundColor="rgba(90, 178, 173, 0.7)"
        minHeight="180px"
        borderBottomRadius="30px"
      >
        <Flex alignItems="center" justifyContent="space-between" mx="auto">
          <Flex>
            <chakra.a display="flex" alignItems="center">
              <Wrap as="label" position="absolute" left="-8" top="1" borderRadius="50%" border="20px solid rgba(255,255,255,0.3)" p="20px">
                <Avatar name={firstName[0]} src={`${process.env.REACT_APP_URL_PROFILEPICS_PACIENTES}/${user.username}.jpeg?${pic}`} size="lg" />
                <Input
                  type="file"
                  accept="image/*;capture=camera"
                  onChange={(e) => handleSubmit(e.target.files[0])}
                  display="none"
                  //onClick={/*(e) => setFile(e.target.files[0])*/}
                />
                <Img src={CameraIcon} position="absolute" width="29%" left="49" top="55" />
              </Wrap>
            </chakra.a>
            <chakra.h2 textShadow="2px 3px 5px #808080" fontSize="xl" fontWeight="bold" mt="2em" ml="3.5em" color="white">
              Olá!
              <br />
              {firstName[0]}
            </chakra.h2>
          </Flex>
          <HStack display="flex" alignItems="center" spacing={1}>
            <Box display={{ base: 'inline-flex' }}>
              <Image position="absolute" right="0.02rem" top="2.89em" src={LogoDashboard} alt="teste" />
            </Box>
          </HStack>
        </Flex>
        <Text
          position="absolute"
          fontSize="xl"
          fontWeight="bold"
          pt="1.1em"
          textColor="white"
          textShadow="2px 3px 5px #808080"
          textAlign="center"
          width="90%"
          zIndex="1"
        >
          {page}
        </Text>
      </chakra.header>
    </>
  );
}

export default MainChildHeader;
