import { Img, VStack } from '@chakra-ui/react';
import React from 'react';
import { useContext } from 'react';
import Spin from '../images/spin.svg';
import { AccountContext } from './AccountContext';
import './loading.css';
import MainHeader from './Dashboard/Main/MainHeader';
import NoUserHeader from './AreaLivre/NoUserHeader';

function Loading() {
  const { user } = useContext(AccountContext);

  return (
    <>
      <NoUserHeader />
      <VStack
        w={{
          base: '20%',
          md: '100px',
        }}
        m="50% auto"
        justify="center"
      >
        <Img size="sm" margin="auto" src={Spin} className="rotate" />
      </VStack>
    </>
  );
}

export default Loading;
