import { VStack, Button, Heading, Text, List, ListItem, ListIcon, ButtonGroup } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { Form, Formik } from 'formik';
import { React, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import NoUserHeader from '../NoUserHeader';
import ProgressBarLivre from './ProgressBarLivre';

const FimLivre = () => {
  const navigate = useNavigate();

  async function geturl() {
    setLoading(true);
    const resposta = await fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/rapidoc/url/${cliente_id}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
    });

    resposta.json().then((res) => {
      if (res.success) {
        navigate('/pagamentos', {
          state: {
            parcelsActive: true,
            valor: '10', //
            expiration_pix_in_min: 5,
            navig_to: '/pronto-atendimento/pagamento-avulso',
            item: 'Pronto Atendimento',
            link: res.data,
            confim: true,
            url: res.data,
          },
        });
      } else {
        setLoading(false);
        setError('Não foi possível realizar seu atendimento no momento, tente novamente mais tarde ou entre em contato com o suporte');
      }
    });
  }

  const { state } = useLocation();
  const cliente_id = state.cliente_id;

  const [comorb, sintomas] = state.vals;
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showText, setShowText] = useState('');
  const [value, setValue] = useState(5);
  return (
    <>
      <NoUserHeader />
      <ProgressBarLivre value={value} />
      <Formik validateOnChange={false} validateOnBlur={false}>
        <VStack as={Form} w={{ base: '100%', md: '500px' }} m="auto" padding="5%" mb="25%" justify="top" overflow="hidden" align="start">
          {showText && (
            <Text fontSize="xs" color="red">
              Isso pode demorar alguns segundos por favor aguarde. Você será redirecionado ao atendimento com o médico em breve.
            </Text>
          )}
          <Heading fontSize="1.2rem">Confirme suas informações</Heading>
          <Text as="p" color="red.500">
            {error}
          </Text>
          <Text fontSize="1rem" fontWeight="bold">
            Comorbidades
          </Text>
          <List>
            {comorb.map((item) => (
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="green.500" />
                {item.nome}
              </ListItem>
            ))}
          </List>
          <Text fontSize="1rem" fontWeight="bold">
            Sintomas
          </Text>
          <List>
            {sintomas.map((item) => (
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="green.500" />
                {item.nome}
              </ListItem>
            ))}
          </List>

          <ButtonGroup width="100%" paddingBottom="5%">
            <Button
              colorScheme="teal"
              w="100%"
              onClick={() => {
                geturl();
                setValue(7);
                setShowText(!showText);
              }}
              isLoading={loading}
              loadingText="Carregando"
            >
              Continuar
            </Button>
          </ButtonGroup>
        </VStack>
      </Formik>
    </>
  );
};

export default FimLivre;
