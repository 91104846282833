import React, { useState, useContext, useEffect } from 'react';
import NoUserHeader from '../NoUserHeader';
import {
  useToast,
  HStack,
  chakra,
  VStack,
  Avatar,
  Flex,
  Heading,
  Box,
  Button,
  Text,
  WrapItem,
  Image,
  Stack,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogFooter,
  AlertDialogContent,
  ModalCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  LinkBox,
  TableContainer,
  Table,
  Select,
  Badge,
  Center,
  Tr,
  Spacer,
  Td,
  Input,
} from '@chakra-ui/react';
import { StarIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { AccountContext } from '../../AccountContext';
import { useNavigate, useLocation } from 'react-router-dom';
import BottomNavBar from '../../BottomNavBar';
import Loading from '../../Loading';
import PopupAvaliacao from 'components/Perfil/Historico/PopupAvalicao';
import BottomNavBarLivre from '../BottomNavBarLivre';

function DetalhesLivre() {
  const navigate = useNavigate();
  const { setPage } = useContext(AccountContext);
  const [loading, setLoading] = useState(true);
  const { state } = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { atendimento, statusAtual } = state;
  const [cancelamento, setCancelamento] = useState([]);
  const [agendas, setAgendas] = useState([]);
  const data = new Date(atendimento.APP_DATE.replace(' ', 'T'));
  const img = process.env.REACT_APP_URL_PROFILEPICS_OPERADOR + '/' + atendimento.RESOURCE_LID + '/foto_profissional.webp';
  const dataextenso = data.toLocaleDateString('pt-br', { weekday: 'long' });
  const monthNameLong = data.toLocaleString('pt-br', { month: 'long' });
  const options = { month: 'short', day: 'numeric' };
  const subTitle = { casing: 'capitalize', color: 'gray' };
  const body = { casing: 'capitalize', fontWeight: 'bold' };
  const logo = `${process.env.REACT_APP_URL_LOGOMARCA}/` + atendimento.LOCATION_LID + '/logomarca.png';
  let titulo = '';
  if (atendimento.GENDER === 'M') {
    titulo = 'Dr. ';
  } else if (atendimento.GENDER === 'F') {
    titulo = 'Dra. ';
  }

  setPage('Minhas Consultas');

  const LoadingIcon = () => {
    return (
      <>
        <NoUserHeader />
        <Box w={{ base: '20%', md: '100px' }} m="auto" justify="top" align="start">
          <Loading />
        </Box>
      </>
    );
  };

  function cancelarConsulta(motivoCancelamento) {
    const url = process.env.REACT_APP_BASE_URL_API_SERVER + '/appointments/cancel/';
    const payload = {
      agenda_exames_id: atendimento.APP_LID,
      motivo: motivoCancelamento,
      paciente_id: atendimento.USER_LID,
      medico: atendimento.RESOURCE_LID,
    };

    const fetchPosts = async () => {
      try {
        const response = await fetch(url, {
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify(payload),
        });
        const postsData = await response.json();
        navigate('/alerts/sucesso/excluir-agendamento');
      } catch (error) {
        navigate('/alerts/erro/excluir-agendamento');
      }
    };

    fetchPosts();
  }

  function AlertDialogExample(props) {
    const cancelRef = React.useRef();

    return (
      <>
        <AlertDialog
          isOpen={isOpen}
          motionPreset="slideInBottom"
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          size="xs"
          isCentered
          isLazy={true}
        >
          <AlertDialogOverlay>
            <AlertDialogContent borderRadius={15}>
              <AlertDialogFooter>
                <VStack w="100%" spacing={3} margin={2}>
                  <Center></Center>
                  <Text fontWeight="bold" fontSize="x-large">
                    Deseja cancelar este agendamento?
                  </Text>
                  <Button
                    ref={cancelRef}
                    isFullWidth
                    onClick={() => {
                      cancelarConsulta();
                      onClose();
                    }}
                  >
                    Cancelar Agendamento
                  </Button>
                  <Button colorScheme="teal" onClick={onClose} isFullWidth>
                    Continuar Agendamento
                  </Button>
                </VStack>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    );
  }

  async function fetchAgendas() {
    const url = process.env.REACT_APP_BASE_URL_API_SERVER + '/schedule/getAvailability/' + atendimento.RESOURCE_LID;
    const response = await fetch(url, {
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      method: 'GET',
    });
    const postsData = await response.json();
    setAgendas(postsData);
    setLoading(false);
  }

  async function ObterDados() {
    let url = process.env.REACT_APP_BASE_URL_API_SERVER + '/schedule/get_motivos_cancelamento';
    const resposta = await fetch(url, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    const postsDados = await resposta.json();
    setCancelamento(postsDados);
  }

  useEffect(() => {
    ObterDados();
    fetchAgendas();
  }, []);

  function isNotLonger24Hours() {
    let date = atendimento.APP_DATE.split('/').reverse().join('-') + 'T' + atendimento.START_TIME;
    const then = new Date(date);
    const now = new Date();

    const msBetweenDates = then.getTime() - now.getTime();
    const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);

    let response = true;
    if (hoursBetweenDates < 24) {
      response = false;
    }

    return response;
  }

  function CancelarConsulta() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef();
    const [motivoCancelamento, setMotivoCancelamento] = useState(false);

    return (
      <>
        <Button
          colorScheme="red"
          size="xs"
          height="12"
          isFullWidth
          variant="outline"
          borderRadius={'10'}
          onClick={onOpen}
          isDisabled={!isNotLonger24Hours()}
        >
          Cancelar Consulta
        </Button>
        <Modal
          closeOnOverlayClick={false}
          isOpen={isOpen}
          onClose={onClose}
          leastDestructiveRef={cancelRef}
          size="sm"
          width="360px"
          height="354px"
          isCentered
          motionPreset="slideInBottom"
        >
          <ModalOverlay />
          <ModalContent borderRadius="23px" size="sm">
            <ModalHeader mt="2">Deseja cancelar essa consulta?</ModalHeader>
            <ModalCloseButton size="sm" />
            <ModalBody textAlign="left" fontSize="sm">
              {atendimento.ACTIVITY_NAME ? atendimento.ACTIVITY_NAME : 'CONSULTA COM ' + atendimento.ESPECIALIDADE}- {atendimento.NAME} na{' '}
              {dataextenso}, {data.getDate()} de {monthNameLong} de {data.getFullYear()} as{' '}
              {atendimento.START_TIME ? atendimento.START_TIME.split(':')[0] + ':' + atendimento.START_TIME.split(':')[1] : ''} com {titulo}{' '}
              {atendimento.RESOURCE_NAME}
            </ModalBody>
            <chakra.h1 marginTop="2%" textAlign="left" ml="6" color="rgba(0, 0, 0, 0.5)" fontSize="sm">
              Paciente
            </chakra.h1>
            <chakra.p textAlign="left" ml="6">
              {atendimento.USER_FIRST_NAME} {atendimento.USER_SECOND_NAME}
            </chakra.p>
            <Heading size="xs" mt="7" ml="5">
              Qual o motivo do cancelamento?
            </Heading>
            <Select
              width="340px"
              height="34px"
              placeholder="Selecione uma opção"
              variant="outline"
              size="sm"
              padding="3"
              alignContent="right"
              borderRadius="2px"
              paddingInlineStart={7}
              paddingInlineEnd={1}
              value={motivoCancelamento}
              onChange={(e) => {
                setMotivoCancelamento(e.target.value);
              }}
            >
              {cancelamento &&
                cancelamento.map((cancelamento) => (
                  <option
                    value={cancelamento.ambulatorio_cancelamento_id}
                    variant="outline"
                    size="sm"
                    padding="3"
                    alignContent="center"
                    borderRadius="8px"
                    paddingInlineStart={5}
                  >
                    {cancelamento.descricao}
                  </option>
                ))}
            </Select>
            <ModalFooter>
              <HStack display="flex" flex-direction="row" width="100%" height="100%">
                <Spacer />
                <Button
                  whiteSpace="initial"
                  colorScheme="teal"
                  size="sm"
                  variant="outline"
                  width="328px"
                  height="50px"
                  borderRadius="15px"
                  padding="3"
                  isDisabled={!motivoCancelamento}
                  onClick={() => {
                    cancelarConsulta(motivoCancelamento);
                    onClose();
                  }}
                >
                  <Text>Cancelar agendamento</Text>
                </Button>
                <Button
                  whiteSpace="initial"
                  colorScheme="teal"
                  size="sm"
                  variant="solid"
                  width="328px"
                  height="50px"
                  borderRadius="15px"
                  padding="3"
                  onClick={onClose}
                >
                  Continuar com o agendamento
                </Button>
                <Spacer />
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  }

  function RemarcarConsulta() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [pag, setPag] = useState(0);
    const [escolha, setEscolha] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const toast = useToast();

    let uniqueDates = [];
    agendas.map((agenda) => (!uniqueDates.includes(agenda.AVA_DATE) ? uniqueDates.push(agenda.AVA_DATE) : ''));

    let dataAtendimento = new Date(atendimento.APP_DATE.replace(' ', 'T'));
    let dataextenso = dataAtendimento.toLocaleDateString('pt-br', {
      weekday: 'long',
    });
    let monthNameLong = dataAtendimento.toLocaleString('pt-br', {
      month: 'long',
    });
    let data = '';
    let dataIcon = ['', ''];
    if (typeof uniqueDates[pag] === 'string') {
      data = new Date(uniqueDates[pag].split('/').reverse().join('-') + 'T08:00:00');
      dataIcon = data.toLocaleDateString('pt-br', options).replace(' de ', ' ').replace('.', '').split(' ');
    }

    function remarcarConsulta() {
      setLoading(true);
      const url = process.env.REACT_APP_BASE_URL_API_SERVER + '/schedule/reschedule';
      const payload = {
        agenda_exames_id_atual: parseInt(atendimento.APP_LID),
        agenda_exames_id_novo: escolha,
      };

      const fetchPosts = async () => {
        try {
          const postsData = await fetch(url, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify(payload),
          });
          const response = await postsData.json();
          if (response.success) {
            //setCancelamento(response.data);
            navigate('/alerts/sucesso/default');
          } else {
            navigate('/alerts/erro/default');
          }
        } catch (error) {
          navigate('/alerts/erro/default');
        }
      };
      fetchPosts();
    }
    const filteredAgendas = agendas.filter((i) => uniqueDates[pag].includes(i.AVA_DATE));
    const useFilteredAgendas = filteredAgendas.map((agendas, index) => {
      return (
        <>
          <Td>
            <Badge
              id={agendas.AVA_START_TIME + index}
              borderRadius="1em"
              margin="0 0.5em"
              padding="1em 1.4em"
              size="md"
              borderEndRadius="15px"
              borderLeftRadius="15px"
              fontSize="sm"
              backgroundColor="rgba(90, 178, 173, 0.3)"
              color="black"
              onClick={() => {
                document.querySelectorAll(`[class="chakra-badge css-wm29s0"]`).forEach((item) => {
                  item.style.border = 'none';
                });
                document.getElementById(agendas.AVA_START_TIME + index).style.border = '1px solid orange';
                setEscolha(agendas.AVAILABILITY_LID);
                setDisabled(true);
              }}
            >
              {agendas.AVA_START_TIME}
            </Badge>
          </Td>
        </>
      );
    });
    return agendas.length > 0 ? (
      <>
        <Button colorScheme="teal" isFullWidth height="12" size="xs" borderRadius={'10'} variant="outline" onClick={onOpen}>
          Remarcar Consulta
        </Button>
        <Modal
          isCentered
          onClose={onClose}
          isOpen={isOpen}
          //width='150px'
          //height='471px'
          top="-176px"
          left="1616px"
          size="sm"
        >
          <ModalOverlay />
          <ModalContent borderRadius="23px">
            <ModalHeader textAlign="justify" mt="5">
              Deseja remarcar essa consulta?
            </ModalHeader>
            <ModalCloseButton size="sm" />
            <ModalBody textAlign="left" fontSize="sm">
              {atendimento.ACTIVITY_NAME ? atendimento.ACTIVITY_NAME : 'CONSULTA COM ' + atendimento.ESPECIALIDADE} - {atendimento.NAME} na{' '}
              {dataextenso}, {dataAtendimento.getDate()} de {monthNameLong} de {dataAtendimento.getFullYear()} as{' '}
              {atendimento.START_TIME ? atendimento.START_TIME.split(':')[0] + ':' + atendimento.START_TIME.split(':')[1] : ''} com {titulo}{' '}
              {atendimento.RESOURCE_NAME}
            </ModalBody>
            <chakra.h1 marginTop="4%" textAlign="left" ml="10" color="rgba(0, 0, 0, 0.5)" fontSize="sm">
              Paciente
            </chakra.h1>
            <chakra.p textAlign="left" ml="10">
              {atendimento.USER_FIRST_NAME} {atendimento.USER_SECOND_NAME}
            </chakra.p>
            <ModalHeader fontWeight="bold" fontSize="21" mt="2">
              Selecione dia e horário
            </ModalHeader>
            <LinkBox
              mt="1"
              id=""
              as="card"
              width="328px"
              height="85px"
              ml="7"
              borderRadius="18px"
              fontSize="xs"
              size="10"
              backgroundColor="rgba(90, 178, 173, 0.25)"
              maxInlineSize="80"
              textAlign="center"
            >
              <HStack>
                <ChevronLeftIcon
                  fontSize="5em"
                  w={12}
                  h={8}
                  mt="-3"
                  onClick={() => {
                    if (pag > 0) {
                      setPag(pag - 1);
                      document.querySelectorAll(`[class="chakra-badge css-raqg5x"]`).forEach((item) => {
                        item.style.border = 'none';
                      });
                      setEscolha(null);
                      setDisabled(false);
                    }
                  }}
                />
                <Flex>
                  <Box
                    backgroundColor="rgba(90, 178, 173, 0.3)"
                    borderRadius="5px"
                    margin="20px"
                    color="rgba(62, 100, 255, 1)"
                    padding="0 10px"
                    ml="-3"
                    mt="2"
                  >
                    <Text fontWeight="bold" fontSize="xl" mt="1">
                      {dataIcon[0]}
                    </Text>
                    <Text fontWeight="bold" fontSize="sm">
                      {dataIcon[1].toUpperCase()}
                    </Text>
                  </Box>
                  <Text margin="15% 0" ml="2" mt="1" fontSize="lg" align="left">
                    <chakra.h1 textAlign="center" color="black" fontSize="md" mt="2" fontWeight="bold">
                      Consulta Médica
                    </chakra.h1>
                    {data.toLocaleString('pt-br', { weekday: 'long' })}
                  </Text>
                </Flex>
                <ChevronRightIcon
                  fontSize="5em"
                  w={12}
                  h={8}
                  mt="-3"
                  onClick={() => {
                    if (pag < uniqueDates.length - 1) {
                      setPag(pag + 1);
                      document.querySelectorAll(`[class="chakra-badge css-raqg5x"]`).forEach((item) => {
                        item.style.border = 'none';
                      });
                      setEscolha(null);
                      setDisabled(false);
                    }
                  }}
                />
              </HStack>
            </LinkBox>
            <TableContainer minWidth="100%" size="sm" mt="15">
              <Table variant="simple" borderRadius="15px" width="99px" height="44px">
                <Tr>{useFilteredAgendas}</Tr>
              </Table>
            </TableContainer>
            <Button
              colorScheme="teal"
              isFullWidth
              height="12"
              size="xs"
              borderRadius={'10'}
              ml="38"
              mt="5"
              width="300px"
              isLoading={loading}
              onClick={remarcarConsulta}
            >
              Remarcar Consulta
            </Button>
            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal>
      </>
    ) : (
      <>
        <Button
          colorScheme="teal"
          isFullWidth
          height="12"
          size="xs"
          borderRadius={'10'}
          variant="outline"
          onClick={() =>
            toast({
              title: 'Indisponível',
              description: 'Profissional não possuí horários disponíveis para reagendamento ',
              status: 'warning',
              duration: 4000,
              isClosable: true,
              position: 'bottom',
              colorScheme: 'green',
              containerStyle: {
                paddingTop: '50px',
              },
            })
          }
        >
          Remarcar Consulta
        </Button>
      </>
    );
  }

  return loading ? (
    <LoadingIcon />
  ) : (
    <>
      <AlertDialogExample />
      <NoUserHeader />
      <VStack
        w={{ base: '90%' }}
        m="auto"
        marginTop="5"
        py="5"
        px="5"
        borderWidth={3}
        borderColor="#00000"
        borderRadius="10px"
        justify="center"
        align="start"
        spacing={5}
      >
        <Text fontWeight="bold">{atendimento.ACTIVITY_NAME ? atendimento.ACTIVITY_NAME : 'CONSULTA COM ' + atendimento.ESPECIALIDADE}</Text>
        <HStack w="100%" justify="center">
          <Image minwidth="150px" minHeight="50px" maxWidth="150px" padding="2" mt="5" name={atendimento.NAME} src={logo} />{' '}
        </HStack>
        <HStack paddingTop="5%" justify="center">
          <div></div>
          <VStack align="start">
            <Text fontSize="lg" textAlign="center" maxW="80">
              {' '}
              {atendimento.NAME}
            </Text>
            <HStack spacing={1} display="flex" alignItems="center" fontSize="sm" pt={0.5}></HStack>
            <HStack spacing={1} fontSize="LG"></HStack>
          </VStack>
        </HStack>
        <chakra.p>
          <Text {...subTitle}>Data:</Text>
          <Text {...body} casing="lowercase">
            {dataextenso}, {data.getDate()} de {monthNameLong} de {data.getFullYear()} as{' '}
            {atendimento.START_TIME ? atendimento.START_TIME.split(':')[0] + ':' + atendimento.START_TIME.split(':')[1] : ''}.
          </Text>
        </chakra.p>
        <chakra.p>
          <Text {...subTitle}>Status:</Text>
          <Text {...body}>{statusAtual}</Text>
        </chakra.p>
        <chakra.p>
          <Text {...subTitle}>Endereço:</Text>
          <Text {...body}>
            {atendimento.NAME.toLowerCase()} {atendimento.CITY.toLowerCase()}
          </Text>
          <Text {...body} fontSize="xs">
            {atendimento.ADRESS.toLowerCase()}
          </Text>
        </chakra.p>
        <chakra.p>
          <Text {...subTitle}>Telefone:</Text>
          <Text {...body}>{atendimento.PHONE} </Text>
        </chakra.p>
        <VStack align="start" spacing={5}>
          <chakra.h2 color="grey">Profisional</chakra.h2>
          <HStack>
            <VStack align="start">
              <Avatar id={atendimento.ACTIVITY_LID} size="lg" bg="teal.400" name={atendimento.RESOURCE_NAME} src={img} />
            </VStack>
            <VStack align="start" spacing={-1}>
              <chakra.h2 fontSize="md" fontWeight="bold" color="">
                {titulo} {atendimento.RESOURCE_NAME}
              </chakra.h2>
              <Text casing="capitalize" fontSize="md" color="">
                {atendimento.ESPECIALIDADE}
              </Text>
              <HStack>
                <chakra.p fontSize="xs">{atendimento.CONSELHO}</chakra.p>
                <chakra.p fontSize="xs"> {atendimento.RQE}</chakra.p>
              </HStack>
              <HStack spacing={1} fontSize="sm"></HStack>
            </VStack>
          </HStack>
          <VStack variant="soft-rounded" w="full" alignItems="justify">
            <chakra.p>
              <Text {...subTitle}>Paciente:</Text>
              <Text {...body}>
                {atendimento.USER_FIRST_NAME} {atendimento.USER_SECOND_NAME}
              </Text>
            </chakra.p>
            <chakra.p>
              <Text {...subTitle}>Valor Deovita: </Text>
              <Text {...body}>R$ {atendimento.PRICE}</Text>
            </chakra.p>
          </VStack>
          <VStack w="full" minWidth="280px" alignItems="justify" spacing={4}>
            <Heading size="md">Orientações</Heading>
            <Box padding="5" borderRadius="10" borderWidth="0.063rem" borderColor="yellow.500" color="red.500" fontSize="sm" fontWeight="bold">
              <chakra.p>{atendimento.PREPARO}</chakra.p>
            </Box>
            {statusAtual === 'Concluído' ? (
              //verificar se já foi feita a avaliação e desativar o botão
              <>
                <PopupAvaliacao data={atendimento} />
              </>
            ) : (
              <>
                <HStack isFullWidth align="start">
                  <CancelarConsulta />
                  <RemarcarConsulta />
                </HStack>
                {!isNotLonger24Hours() ? (
                  <Text fontSize="xs" color="red">
                    Não é permitido cancelar ou remarcar consultas com menos de 24 horas para o atendimento
                  </Text>
                ) : (
                  ''
                )}
              </>
            )}
            <Button colorScheme="teal" isFullWidth height="12" size="xs" borderRadius={'10'} onClick={() => navigate('/consultaslivre')}>
              ↑ Detalhes
            </Button>
          </VStack>
        </VStack>
      </VStack>
      <WrapItem mt="20">
        <BottomNavBarLivre />
      </WrapItem>
    </>
  );
}

export default DetalhesLivre;
