import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdOutlineShoppingCart, MdOutlinePersonOutline, MdOutlineAttachMoney, MdOutlineHouse, MdOutlineCalendarToday } from 'react-icons/md';
import { Image, useDisclosure } from '@chakra-ui/react';
import Notify from '../images/assets/icons/notify.svg';
import { AccountContext } from './AccountContext';
import DrawerServico from './Dashboard/Main/DrawerServico';

const BottomNavBar = (props) => {
  const navigate = useNavigate();
  const [activeTabs, setActiveTabs] = useState(props.name);
  const { user, setUser } = useContext(AccountContext);
  const disclosure = useDisclosure();

  useEffect(() => {
    switch (activeTabs) {
      case 'dashboard':
        navigate('/dashboard');
        break;
      case 'perfil':
        navigate('/perfil/main');
        break;
      case 'consultas':
        navigate('/consultas');
        break;
      case 'empreendedor':
        navigate('/empreendedor');
        break;
      case 'carrinho':
        navigate('/carrinho');
        break;
      /*default:
                navigate('/dashboard')
                break;
            */
    }
  }, [activeTabs]);
  return user.loggedIn === true ? (
    <>
      <DrawerServico disclosure={disclosure} />
      <div className="bottom-nav">
        <div className="bn-tab">
          {activeTabs === 'perfil' ? (
            <MdOutlinePersonOutline size="35" color="rgba(90, 178, 173, 0.7)" onClick={() => setActiveTabs('perfil')} />
          ) : (
            <MdOutlinePersonOutline size="35" color="rgba(174, 174, 178, 1)" onClick={() => setActiveTabs('perfil')} />
          )}
        </div>
        <div className="bn-tab">
          {activeTabs === 'carrinho' ? (
            <MdOutlineShoppingCart size="30" color="rgba(90, 178, 173, 0.7)" onClick={() => setActiveTabs('carrinho')} />
          ) : (
            <MdOutlineShoppingCart size="30" color="rgba(174, 174, 178, 1)" onClick={() => setActiveTabs('carrinho')} />
          )}
          {user.cart || (user.solicitacoes && user.solicitacoes.length && user.solicitacoes.length > 0) ? (
            <Image position="absolute" left="35%" top="10%" src={Notify} boxSize={4}></Image>
          ) : (
            <></>
          )}
        </div>
        <div className="bn-tab">
          {activeTabs === 'dashboard' ? (
            <MdOutlineHouse size="35" color="rgba(90, 178, 173, 0.7)" onClick={() => setActiveTabs('dashboard')} />
          ) : (
            <MdOutlineHouse size="35" color="rgba(174, 174, 178, 1)" onClick={() => setActiveTabs('dashboard')} />
          )}
        </div>
        <div className="bn-tab">
          {activeTabs === 'consultas' ? (
            <MdOutlineCalendarToday size="30" color="rgba(90, 178, 173, 0.7)" onClick={() => setActiveTabs('consultas')} />
          ) : (
            <MdOutlineCalendarToday size="30" color="rgba(174, 174, 178, 1)" onClick={() => setActiveTabs('consultas')} />
          )}
          {user.avaliacao_pendente === true ? <Image position="absolute" left="67%" top="10%" src={Notify} boxSize={4}></Image> : <></>}
        </div>
        <div className="bn-tab">
          {activeTabs === 'empreendedor' ? (
            <MdOutlineAttachMoney
              size="35"
              color="rgba(90, 178, 173, 0.7)"
              onClick={() => {
                if (user.id_contrato > 0) {
                  if (user.empreendedor_popup === 'ativo') {
                    setActiveTabs('empreendedor');
                  }
                } else {
                  disclosure.onOpen();
                }
              }}
            />
          ) : (
            <MdOutlineAttachMoney
              size="35"
              color="rgba(174, 174, 178, 1)"
              onClick={() => {
                if (user.id_contrato > 0) {
                  setActiveTabs('empreendedor');
                } else {
                  disclosure.onOpen();
                }
              }}
            />
          )}
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

export default BottomNavBar;
