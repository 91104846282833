import React, { useState, useEffect } from 'react';
import Carousel from 'react-elastic-carousel';
import { chakra, Img } from '@chakra-ui/react';
import '../css/lista-empresas.css';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../../Dashboard/Main/SearchContext';

function ListaEmpresasLivre() {
  const navigate = useNavigate();
  const [unidades, setUnidades] = useState([]);
  const url = `${process.env.REACT_APP_BASE_URL_API_TUOTEMPO}/getLocations/`;
  const payload = {
    authorization: {
      user: 'gGKWlHHtIV',
      password: 'H0vDHauMdt',
    },
    data: {
      LOCATION_LID: '',
    },
  };
  useEffect(() => {
    const fetchPosts = async () => {
      const response = await fetch(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(payload),
      });
      const postsData = await response.json();
      setUnidades(postsData.return);
    };
    fetchPosts();
  }, []);

  const useUnidades = unidades.map((unidade, index) => {
    return (
      <>
        <div>
          <Img
            id={unidade.LOCATION_LID}
            src={`${process.env.REACT_APP_URL_LOGOMARCA}/` + unidade.LOCATION_LID + '/logomarca.png'}
            alt="logo"
            maxWidth="180px"
            maxHeight="70px"
            onClick={() => {
              navigate('/empresa/detalheslivre', { state: { unidade } });
            }}
          />
        </div>
      </>
    );
  });

  return (
    <>
      <UserContext>
        <chakra.h1 fontSize="1.2em" fontWeight="bold">
          Rede de Atendimento
        </chakra.h1>
        <Carousel itemsToShow={1.7} showArrows={false} FocusOnSelect={true} pagination={false}>
          {unidades && useUnidades}
        </Carousel>
      </UserContext>
    </>
  );
}

export default ListaEmpresasLivre;
