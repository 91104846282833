import { Alert, AlertDescription, AlertIcon, AlertTitle, Button, VStack, HStack, Box, Heading, Text } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { React, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AccountContext } from '../AccountContext';
import NoUserHeader from '../AreaLivre/NoUserHeader';
import MainChildHeader from '../Dashboard/Main/MainChildHeader';
import _ from 'lodash';
export default function Sucesso() {
  const { rota } = useParams();
  const { user, setUser, page, setPage } = useContext(AccountContext);

  if (page === 'Titulo da Pagina' || page === '' || !page) {
    setPage('');
  }

  var msg = 'Operação realizada com sucesso';
  var navigateTo = '/dashboard';
  console.log(rota);
  switch (rota) {
    case 'excluir-agendamento':
      msg = 'Agendamento excluído com sucesso';
      navigateTo = '/consultas';
      break;
    case 'update_adress':
      msg = 'Endereço atualizado com sucesso';
      break;
    case 'atualiza_dados':
      msg = 'dados atualizados com sucesso';
      break;
    case 'agendamento':
      msg = 'Estamos processando seu pagamento. Você receberá uma notificação assim que o pagamento for confirmado.';
      navigateTo = '/consultas';

      break;
    case 'agendamento-exame':
      msg = 'Estamos processando seu pagamento. Você receberá uma notificação assim que o pagamento for confirmado.';

      break;
    case 'agendamentoAvulso':
      msg = 'Estamos processando seu pagamento. Você receberá uma notificação assim que o pagamento for confirmado.';
      navigateTo = '/arealivre/sucesso';
      break;
    case 'recuperacao':
      msg = 'recuperacao de senha realizada com sucesso';
      break;
    case 'atualizar_dependente':
      msg = 'dependente atualizado com sucesso';
      navigateTo = '/perfil/dependentes';
      break;
    case 'excluir_dependente':
      msg = 'dependente excluído com sucesso';
      navigateTo = '/perfil/dependentes';
      break;
    case 'atualiza_foto':
      msg = 'Foto atualizada com sucesso';
      navigateTo = '/perfil/main';
      break;
    case 'cadastrar_dependente':
      msg = 'dependente atualizado com sucesso';
      navigateTo = '/perfil/dependentes';
      break;
    case 'ativa_empreendedor':
      msg = 'Adesão realizada com sucesso.';
      navigateTo = '/empreendedor';
      break;
    case 'dados_bancarios_empreendedor':
      msg = 'Dados bancarios cadastrados com sucesso.';
      navigateTo = '/empreendedor';
      break;
    case 'cadastro_estabelecimento':
      msg = 'Estabelecimento cadastrado com sucesso';
      navigateTo = '/empreendedor';
      break;
    case 'transferencia':
      msg =
        'Sua transferência será encaminhada ao banco até o final do dia, caso tenha sido feito até às 15h no horário de Brasília. Após isso, será encaminhado somente no próximo dia útil.';
      navigateTo = '/empreendedor';
      break;
    case 'sucesso_solicitacoes':
      msg = 'Solicitação recusada com sucesso';
      navigateTo = '/carrinho';
      break;
    case 'telemedicina':
      msg =
        'Estamos processando seu pagamento. Você receberá uma mensagem via whatsapp com o link para o atendimento assim que o pagamento for confirmado.';
      navigateTo = '/dashboard';
      break;
    case 'antecipacao':
      msg =
        'Antecipações para o mesmo dia útil precisam ser solicitadas antes das 10:59 da manhã. Esta antecipação será analisada e poderá ser aprovada ou rejeitada. Após a aprovação, o valor a ser antecipado é adicionado ao saldo a partir das 12 horas do dia selecionado.';
      navigateTo = '/empreendedor';
      break;
    case 'cadastro_contrato':
      msg = 'Já existe um contrato para este paciente. Faça já seu login no aplicativo utilizando seu cpf e a senha informada no cadastro.';
      navigateTo = '/login';
      break;
    default:
  }

  function Feature({ title, desc, ...rest }) {
    return (
      <Box p={15} w="100%" shadow="xl" borderWidth="1px" borderRadius="15" {...rest}>
        <CheckCircleIcon ml="40%" mt="5" color="green" boxSize={20} />
        <Heading fontSize="xl" mt="5" textAlign="center">
          {title}{' '}
        </Heading>

        <Text mt={5} textAlign="center">
          {' '}
          <p>{msg}</p>
          <Button
            mt="10"
            borderRadius="15"
            height="48px"
            width="200px"
            border="1px"
            alignItems="center"
            onClick={() => {
              setUser((prevState) => ({ ...prevState, pedido_pendente: null }));

              if (user.cart && user.cart_temp && rota === 'agendamento-exame') {
                let newCartItems = _.filter(
                  user.cart.itens,
                  (item) => !_.includes(_.map(user.cart_temp.itens, 'procedimento_tuss_id'), item.procedimento_tuss_id)
                );
                if (newCartItems.length === 0) {
                  setUser((prevState) => ({ ...prevState, cart: null }));
                } else {
                  setUser((prevState) => ({ ...prevState, cart: { ...prevState.cart, itens: newCartItems } }));
                }
                navigate('/carrinho');
              } else {
                navigate(navigateTo ? navigateTo : '/dashboard');
              }
            }}
          >
            OK
          </Button>
        </Text>
      </Box>
    );
  }

  const navigate = useNavigate();
  return (
    <>
      {user.loggedIn ? <MainChildHeader /> : <NoUserHeader />}
      <VStack w="90%" m="auto" justify="top" spacing="1rem" marginTop="20">
        <HStack>
          <Feature
            title="Sucesso"
            desc="You deserve good things. With a whooping 10-15% interest rate per annum, grow your savings on your own terms with our completely automated process"
          />
        </HStack>
      </VStack>
    </>
  );
}
