import React, { useContext } from 'react';
import { AccountContext } from '../../AccountContext';
import { HStack, VStack, Avatar, chakra, Box, Flex, Wrap, Image, Text, Img } from '@chakra-ui/react';
import ListaDependentes from './ListaDependentes';
import BottomNavBar from '../../BottomNavBar';
import Back from '../../../images/back.svg';
import LogoDashboard from '../../../images/logo_dashboard.svg';
import { useNavigate } from 'react-router-dom';
import ListarPets from './ListarPets';
function Main() {
  const { setPage, user } = useContext(AccountContext);
  setPage('Dependentes');
  let firstName = user.name.split(' ');
  const formatCpf = (str) => str.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');

  function ChildHeader() {
    const navigate = useNavigate();
    const { user, page, pic } = useContext(AccountContext);
    let firstName = user.name.split(' ');
    return (
      <>
        <chakra.header
          w="full"
          px={{ base: 2, sm: 4 }}
          py={4}
          shadow="md"
          backgroundColor="rgba(90, 178, 173, 0.7)"
          minHeight="180px"
          borderBottomRadius="30px"
        >
          <Flex alignItems="center" justifyContent="space-between" mx="auto">
            <Flex>
              <Wrap position="absolute" left="-8" top="1" borderRadius="50%" border="20px solid rgba(255,255,255,0.3)" p="20px">
                <Avatar name={firstName[0]} src={`${process.env.REACT_APP_URL_PROFILEPICS_PACIENTES}/${user.username}.jpeg?${pic}`} size="lg" />
              </Wrap>

              <chakra.h2 textShadow="2px 3px 5px #808080" fontSize="xl" fontWeight="bold" mt="2em" ml="3.5em" color="white">
                Olá!
                <br />
                {firstName[0]}
              </chakra.h2>
            </Flex>
            <HStack display="flex" alignItems="center" spacing={1}>
              <Box display={{ base: 'inline-flex' }}>
                <Image position="absolute" right="0.02rem" top="2.89em" src={LogoDashboard} alt="teste" />
              </Box>
            </HStack>
          </Flex>
          <Img src={Back} boxSize="2em" onClick={() => navigate('/perfil/main')} color="white" marginTop="1.3em" position="absolute" zIndex="100" />
          <Text
            position="absolute"
            fontSize="xl"
            fontWeight="bold"
            pt="1.1em"
            textColor="white"
            textShadow="2px 3px 5px #808080"
            textAlign="center"
            width="90%"
            zIndex="1"
          >
            {page}
          </Text>
        </chakra.header>
      </>
    );
  }

  return (
    <>
      <ChildHeader />
      <VStack
        w={{ base: '90%', md: '500px' }}
        m="auto"
        justify="top"
        marginTop="5%"
        spacing="1rem"
        align="start"
        overflow="hidden"
        marginBottom="15%%"
      >
        <chakra.h1 fontWeight="bold" fontSize="lg">
          Gerenciamento Dependentes
        </chakra.h1>
        <HStack width="100%" spacing={6} borderBottom="0.5px solid #D6D9D8">
          <VStack alignItems="left">
            <chakra.p fontSize="sm" color="gray.500">
              Titular
            </chakra.p>
            <chakra.p fontSize="md" fontWeight="bold">
              {firstName[0]} {firstName[1]}
            </chakra.p>
          </VStack>
          <VStack alignItems="left">
            <chakra.p fontSize="sm" color="gray.500">
              CPF
            </chakra.p>
            <chakra.p fontSize="md" fontWeight="bold">
              {formatCpf(user.cpf)}
            </chakra.p>
          </VStack>
        </HStack>
        <chakra.h1 fontWeight="bold">Dependentes no Plano</chakra.h1>
        <ListaDependentes />
        <chakra.h1 fontWeight="bold">Pets no Plano</chakra.h1>
        <ListarPets />
      </VStack>
      <BottomNavBar />
    </>
  );
}

export default Main;
