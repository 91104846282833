import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Loading from "../../../Loading";
import requestTimeout from "../../../Utils/Functions/requestTimeout";

function ProcessarExclusao() {
  const { state } = useLocation();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const timeOutInSeconds = process.env.REACT_APP_REQUEST_TIMEOUT_IN_SECONDS;

  function handleError() {
    setLoading(false);
  }

  function removerDependente(vals) {
    const data = { id: vals.id };
    fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/client/del_dependent`, {
      signal: requestTimeout(timeOutInSeconds).signal,
      method: "POST",
      credentials: "include",
      headers: { Accept: "application/json", "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .catch((err) => {
        handleError();
        return;
      })
      .then((res) => {
        if (!res || !res.ok || res.status >= 400) {
          handleError();
          return;
        }
        return res.json();
      })
      .then((data) => {
        if (data && data.success) {
          gravarPagamento(vals);
        } else {
          handleError();
        }
      });
  }

  function gravarPagamento(vals) {
    let installmentsData = [
      {
        paciente_contrato_id: vals.paciente_contrato_id,
        valor: vals.valor,
        taxa_adesao: true,
        id_trasacao_gatway: vals.transaction_id,
        classe: "REMOCAO DEPENDENTE",
        forma_pgto: vals.forma_pgto,
      },
    ];

    const requestOptions = {
      signal: requestTimeout(timeOutInSeconds).signal,
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(installmentsData),
    };

    fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/installments`, requestOptions)
      .catch((err) => {
        handleError();
        return;
      })
      .then((res) => {
        if (!res || !res.ok || res.status >= 400) {
          handleError();
          return;
        }
        return res.json();
      })
      .then((data) => {
        if (!data) return;
        let returnRequest = data[0];
        if (returnRequest.success) {
          navigate("/alerts/sucesso/excluir_dependente");
        } else {
          handleError();
        }
      });
  }

  removerDependente(state);

  return loading ? <Loading /> : navigate("/alerts/erro/excluir_dependente");
}

export default ProcessarExclusao;
