import { ChevronLeftIcon, ChevronRightIcon, StarIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Button,
  chakra,
  ButtonGroup,
  Flex,
  Alert,
  Center,
  Image,
  Stack,
  HStack,
  VStack,
  WrapItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  LinkBox,
  Text,
  Td,
  Badge,
  useRadio,
  useRadioGroup,
  Table,
  Tr,
  TableContainer,
  useDisclosure,
  Heading,
} from '@chakra-ui/react';
import { CheckCircleIcon, CheckIcon } from '@chakra-ui/icons';
import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Loading from '../../Loading';
import { AccountContext } from '../../AccountContext';
import NoUserHeader from '../NoUserHeader';

import popup from '../Imagens/popuparealivre.png';

function AgendamentoLivre() {
  const navigate = useNavigate();
  const { user, setPage, socket } = useContext(AccountContext);
  const [dependentes, setDependentes] = useState([]);
  const [price, setPrice] = useState('');

  //dados do medico
  const { state } = useLocation();
  const { medico, startNum, st, defaultDateTime } = state;
  const arrName = medico.dados.nome.split(' ');
  const [pag, setPag] = useState(startNum);
  const [pac, setPac] = useState(st);
  const [escolha, setEscolha] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [agendas, setAgendas] = useState([]);
  const [procedimento, setProcedimento] = useState('');
  const [active, setActive] = useState(1);

  function RadioCard(props) {
    const { getInputProps, getCheckboxProps } = useRadio(props);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const input = getInputProps();
    const checkbox = getCheckboxProps();

    return (
      <Box as="label">
        <VStack spacing={4} verticalAlign>
          <input {...input} />

          <Box
            onClick={onOpen}
            {...checkbox}
            cursor="pointer"
            borderWidth="2px"
            height="90px"
            width="360px"
            borderRadius="lg"
            boxShadow="sm"
            src={CheckCircleIcon}
            _checked={{
              borderColor: '#11D4AE',
            }}
            px={5}
            py={3}
          >
            {props.children}
          </Box>
          <Modal isOpen={isOpen} isCentered onClose={onClose} size="xs">
            <ModalOverlay />
            <ModalContent borderRadius="20px">
              <ModalHeader>
                <Center>
                  {' '}
                  <Image mb={5} sizes={10} boxSize="80px" src={popup} />
                </Center>
                <Center>
                  {' '}
                  <Heading size="md">Benefícios Deovita</Heading>
                </Center>
              </ModalHeader>
              <ModalBody>
                <VStack spacing={2} align="stretch" fontSize="sm">
                  <Heading mb={5} size="sm">
                    Aproveite os benefícios de ser um cliente Deovita!
                  </Heading>
                  <chakra.p>Por apenas R$ 24,90 mensais!</chakra.p>
                  <Box>
                    <CheckIcon color="green.500" /> Inúmeros exames e procedimentos com descontos de até 80%*
                  </Box>
                  <Box>
                    <CheckIcon color="green.500" /> Profilaxia odontológica a partir de R$ 10,00.
                  </Box>
                  <Box>
                    <CheckIcon color="green.500" /> Consultas a partir de R$ 29,90.
                  </Box>
                </VStack>
              </ModalBody>

              <ModalFooter>
                <VStack w="100%">
                  <Button
                    colorScheme="teal"
                    borderColor="blue.500"
                    w="100%"
                    onClick={() => {
                      navigate('/cadastro');
                    }}
                  >
                    Contratar
                  </Button>
                  <Button colorScheme="teal" w="100%" variant="outline" onClick={onClose}>
                    Continuar sem contratar
                  </Button>
                </VStack>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </VStack>
      </Box>
    );
  }

  function RadioSelect() {
    const options = ['Assinante', 'Particular'];

    const { getRootProps, getRadioProps } = useRadioGroup({
      defaultValue: options[1],

      onChange: console.log,
    });

    const group = getRootProps();

    return (
      <VStack {...group}>
        {options.map((value, i) => {
          const radio = getRadioProps({ value });
          const valor = escolha ? (value === 'Assinante' ? escolha.valor_deovita : escolha.valor) : 0;
          return valor ? (
            <RadioCard isDisabled key={value[i]} {...radio}>
              <Flex minWidth="max-content" alignItems="center" gap="10">
                <Text fontSize="sm">{procedimento ? procedimento + ' ' : ''} </Text>
                <Text fontSize="md" fontWeight="bold">
                  {valor ? ' R$ ' + valor : ''}
                </Text>
              </Flex>

              <Badge
                ml="1"
                textColor="ButtonText"
                textAlign="center"
                fontFamily="sans-serif"
                fontSize="12"
                marginTop="4"
                color="orange"
                bgColor={active == i ? value.bgColor : '#FF9A00'}
                width="90px"
                height="22px"
                borderRadius="8px"
              >
                {value}
              </Badge>
              <Text></Text>
            </RadioCard>
          ) : null;
        })}
      </VStack>
    );
  }

  let titulo = '';
  if (medico.dados.sexo === 'M') {
    titulo = 'Dr. ';
  } else if (medico.dados.sexo === 'F') {
    titulo = 'Dra. ';
  }

  setPage('Agendamento');

  useEffect(() => {
    const fetchPosts = async () => {
      const url = process.env.REACT_APP_BASE_URL_API_SERVER + '/schedule/getAvailability/' + medico.dados.medico;
      const response = await fetch(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
      });
      const postsData = await response.json();
      setAgendas(postsData);
    };
    const fetchDependentes = async () => {
      const url2 = process.env.REACT_APP_BASE_URL_API_SERVER + '/client/dependents2';
      const titularId = {
        id_contrato: user.id_contrato,
      };
      const response = await fetch(url2, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(titularId),
      });
      const resp = await response.json();

      setDependentes(resp.data);
    };
    fetchDependentes();
    fetchPosts();
  }, []);

  let uniqueDates = [];

  const useAgenda = agendas.map((agenda, index) => {
    if (!uniqueDates.includes(agenda.AVA_DATE)) {
      uniqueDates.push(agenda.AVA_DATE);
    }
  });

  const useUniqueDates = uniqueDates.map((uniqueDate, index) => {
    const options = { month: 'short', day: 'numeric' };
    const event = new Date(uniqueDate.split('/').reverse().join('-') + 'T08:00:00');
    const dataIcon = event.toLocaleDateString('pt-br', options).replace(' de ', ' ').replace('.', '').split(' ');

    return (
      <>
        <Flex>
          <Box backgroundColor="rgba(90, 178, 173, 0.3)" borderRadius="5px" margin="5px" color="rgba(62, 100, 255, 1)" padding="0 13px">
            <Text fontWeight="bold" fontSize="xl">
              {dataIcon[0]}
            </Text>
            <Text fontWeight="bold" fontSize="sm" mt="-1">
              {dataIcon[1].toUpperCase()}
            </Text>
          </Box>
          <Text margin="5% 0" fontSize="xs" align="left">
            <chakra.p fontWeight="bold">{medico.dados.tipo ? medico.dados.tipo.toUpperCase() : ''}</chakra.p>
            {event.toLocaleString('pt-br', { weekday: 'long' })}
          </Text>
        </Flex>
      </>
    );
  });
  const filteredAgendas = agendas.filter((i) => uniqueDates[pag].includes(i.AVA_DATE));

  const useFilteredAgendas = filteredAgendas.map((filteredAgenda, index) => {
    if (!escolha) {
      var dateTime = `${filteredAgenda.AVA_DATE} ${filteredAgenda.AVA_START_TIME}`;
      if (dateTime === defaultDateTime) {
        setEscolha({
          medicoNome: medico.dados.nome,
          tipo: medico.dados.tipo,
          especialidade: medico.dados.especialidade,
          valor: filteredAgenda.valor_particular ? filteredAgenda.valor_particular : '',
          valor_deovita: filteredAgenda.PRICE ? filteredAgenda.PRICE : '',
          agenda: filteredAgenda,
          paciente: dependentes[pac],
          clinica: medico.dados.empresa,
          logradouro: medico.dados.logradouro,
          numero: medico.dados.numero,
          bairro: medico.dados.bairro,
          municipio: medico.dados.municipio,
          medico: medico,
        });
        socket.emit('lockSchedule', filteredAgenda.AVAILABILITY_LID);
        setDisabled(true);
        setPrice(filteredAgenda.PRICE ? filteredAgenda.PRICE : 0);
        setProcedimento(filteredAgenda.ACTIVITY_NAME ? filteredAgenda.ACTIVITY_NAME : '');
      }
    }

    return (
      <>
        <Td>
          <Text></Text>
          <Badge
            id={'agenda' + index}
            borderRadius="0.5em"
            margin="0 0.5em"
            padding="0.5em 1.4em"
            size="lg"
            fontSize="md"
            style={`${filteredAgenda.AVA_DATE} ${filteredAgenda.AVA_START_TIME}` === defaultDateTime ? { border: '1px solid orange' } : {}}
            backgroundColor="rgba(90, 178, 173, 0.3)"
            color="rgba(62, 100, 255, 1)"
            onClick={() => {
              document.querySelectorAll(`[class="chakra-badge css-raqg5x"]`).forEach((item) => {
                item.style.border = 'none';
              });
              document.getElementById('agenda' + index).style.border = '1px solid orange';
              setEscolha({
                medicoNome: medico.dados.nome,
                tipo: medico.dados.tipo,
                especialidade: medico.dados.especialidade,
                valor: filteredAgenda.valor_particular ? filteredAgenda.valor_particular : 0,
                valor_deovita: filteredAgenda.PRICE ? filteredAgenda.PRICE : '',
                agenda: filteredAgenda,
                paciente: dependentes[pac],
                clinica: medico.dados.empresa,
                logradouro: medico.dados.logradouro,
                numero: medico.dados.numero,
                bairro: medico.dados.bairro,
                municipio: medico.dados.municipio,
                medico: medico,
              });
              socket.emit('unlockSchedule', '');
              socket.emit('lockSchedule', filteredAgenda.AVAILABILITY_LID);
              setPrice(filteredAgenda.PRICE ? filteredAgenda.PRICE : 0);
              setProcedimento(filteredAgenda.ACTIVITY_NAME ? filteredAgenda.ACTIVITY_NAME : '');
              dependentes[pac].cpf && setDisabled(true);
            }}
          >
            {filteredAgenda.AVA_START_TIME}
          </Badge>
        </Td>
      </>
    );
  });

  return dependentes.length > 0 && agendas.length > 0 ? (
    <>
      <NoUserHeader />

      <VStack
        w={{
          base: '90%',
          md: '500px',
        }}
        m="auto"
        justify="center"
      >
        <Flex minW="320px" maxW="320px" mx="auto" bg="" overflow="hidden">
          <Box w={1 / 3} bgSize="cover" align="center">
            <WrapItem ml="5px" mt="0.8em">
              <Avatar
                id={medico.dados.medico_id}
                size="xl"
                name={medico.dados.nome}
                src={process.env.REACT_APP_URL_PROFILEPICS_OPERADOR + '/' + medico.dados.medico + '/foto_profissional.webp'}
              />{' '}
            </WrapItem>
            <chakra.a
              color="rgba(14, 170, 139, 1)"
              fontWeight="bold"
              fontSize="sm"
              onClick={() => {
                navigate('/curriculolivre', {
                  state: {
                    medico,
                  },
                });
              }}
            >
              ver curriculo
            </chakra.a>
          </Box>
          <Box w={2 / 3} p={{ base: 4, md: 4 }} marginBottom="5%">
            <chakra.h2 fontSize="xl" fontWeight="bold" color="">
              {titulo}
              {''}
              {arrName[0]} {arrName[1]}
            </chakra.h2>
            <chakra.p mt={-1} fontSize="md" color="">
              {medico.dados.especialidade}
            </chakra.p>
            <HStack>
              <chakra.p fontSize="sm">{medico.dados.conselho}</chakra.p>
            </HStack>
            <HStack spacing={1} display="flex" alignItems="center" fontSize="sm" pt={0.5}>
              <StarIcon color="gray.500" />
              <StarIcon color="gray.500" />
              <StarIcon color="gray.500" />
              <StarIcon color="gray.500" />
              <StarIcon color="gray.500" />
              <chakra.span fontSize="xs">0 opiniões</chakra.span>
            </HStack>
          </Box>
        </Flex>

        <Heading id={medico.dados.empresa_id} paddingInlineEnd="180" size="sm" mb="10" selected="true">
          {medico.dados.empresa}
        </Heading>

        <Text fontSize="sm" fontWeight="bold" padding="0 2em">
          <chakra.p>
            {' '}
            {medico.dados.logradouro}, {medico.dados.numero} , {medico.dados.bairro} {medico.dados.municipio} {'-'} {medico.dados.cep}
          </chakra.p>
        </Text>
        <WrapItem mt="10"></WrapItem>
        <chakra.h1 fontWeight="bold" paddingInlineEnd="170" textAlign="start">
          Selecione uma Opção
        </chakra.h1>

        <RadioSelect />
        <WrapItem mt="15"></WrapItem>

        <chakra.h1 fontSize="lg" fontWeight="bold">
          Selecione dia e horário
        </chakra.h1>
        <LinkBox id="" as="card" borderRadius="15px" fontSize="xs" backgroundColor="rgba(90, 178, 173, 0.25)" width="100%" textAlign="center">
          <HStack>
            <ChevronLeftIcon
              fontSize="5em"
              onClick={() => {
                if (pag > 0) {
                  setPag(pag - 1);
                  if (agendas[pag].PRICE != null) {
                    setPrice(agendas[pag].PRICE);
                  }
                  document.querySelectorAll(`[class="chakra-badge css-raqg5x"]`).forEach((item) => {
                    item.style.border = 'none';
                  });
                  setEscolha(null);
                  setDisabled(false);
                }
              }}
            />
            <Text width="60%">{agendas && useUniqueDates[pag]}</Text>
            <ChevronRightIcon
              fontSize="5em"
              onClick={() => {
                if (pag < uniqueDates.length - 1) {
                  setPag(pag + 1);
                  if (agendas[pag].PRICE != null) {
                    setPrice(agendas[pag].PRICE);
                  }
                  document.querySelectorAll(`[class="chakra-badge css-raqg5x"]`).forEach((item) => {
                    item.style.border = 'none';
                  });
                  setEscolha(null);
                  setDisabled(false);
                }
              }}
            />
          </HStack>
        </LinkBox>
        <TableContainer minWidth="100%">
          <Table variant="simple">
            <Tr>{useFilteredAgendas}</Tr>
          </Table>
        </TableContainer>
        <ButtonGroup pt="1rem" pb="25%" width="100%">
          <Button
            width="100%"
            colorScheme="teal"
            onClick={() => {
              let states = {
                ...escolha,
                agenda: escolha.agenda,
                medico: medico,
              };
              navigate('/cpflivre', {
                state: states,
              });
            }}
            disabled={!disabled || !escolha.valor}
          >
            Continuar
          </Button>
        </ButtonGroup>
      </VStack>
    </>
  ) : (
    <>
      <NoUserHeader />
      <VStack
        w={{
          base: '20%',
          md: '100px',
        }}
        m="50% auto"
        justify="center"
      >
        <Loading />
      </VStack>
    </>
  );
}

export default AgendamentoLivre;
