import {
  VStack,
  chakra,
  Text,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Image,
  useDisclosure,
  Heading,
  Box,
  Button,
  Modal,
  Center,
  ModalOverlay,
  ButtonGroup,
} from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import { CheckCircleIcon, CheckIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../Dashboard/Main/SearchContext';
import CardProntoAtendimento from '../../Dashboard/Main/CardProntoAtendimento';
import ListaEmpresasLivre from '../PerfilEmpresa/Empresa/ListaEmpresasLivre';
import ListaMedicosLivre from '../PerfilEmpresa/Medico/ListaMedicosLivre';
import UserHeaderLivre from '../UserHeaderLivre';
import popup from '../Imagens/popuparealivre.png';
import Floatbutton from '../../Dashboard/Main/FloatButton';
import FloatDoorbutton from './FloatDoorButton';
import ListaGrupos from '../../Dashboard/Main/ListaGrupos';
import BannerCarrosel from './BannerCarrosel';
import BadgesCarrosel from './BadgesCarrosel';
import BottomNavBarLivre from '../BottomNavBarLivre';

function MainLivre({ ...rest }) {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  return (
    <>
      <UserContext>
        <UserHeaderLivre />
        <Modal isOpen={isOpen} isCentered onClose={onClose} size="xs">
          <ModalOverlay />
          <ModalContent borderRadius="20px">
            <ModalHeader>
              <Center>
                {' '}
                <Image mb={5} sizes={10} boxSize="80px" src={popup} />
              </Center>
              <Center>
                {' '}
                <Heading size="md">Benefícios Deovita</Heading>
              </Center>
            </ModalHeader>
            <ModalBody>
              <VStack spacing={2} align="stretch" fontSize="sm">
                <Heading mb={5} size="sm">
                  Aproveite os benefícios de ser um cliente Deovita!
                </Heading>
                <chakra.p>Por apenas R$ 24,90 mensais!</chakra.p>
                <Box>
                  <CheckIcon color="green.500" /> Inúmeros exames e procedimentos com descontos de até 80%*
                </Box>
                <Box>
                  <CheckIcon color="green.500" /> Profilaxia odontológica a partir de R$ 10,00.
                </Box>
                <Box>
                  <CheckIcon color="green.500" /> Consultas a partir de R$ 29,90.
                </Box>
              </VStack>
            </ModalBody>

            <ModalFooter>
              <VStack w="100%">
                <Button
                  colorScheme="teal"
                  borderColor="blue.500"
                  w="100%"
                  onClick={() => {
                    navigate('/cadastro');
                  }}
                >
                  Contratar
                </Button>
                <Button colorScheme="teal" w="100%" variant="outline" onClick={onClose}>
                  Continuar sem contratar
                </Button>
              </VStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <VStack
          align="center"
          justify={{
            base: 'center',
            md: 'space-around',
            xl: 'space-between',
          }}
          direction={{ base: 'column-reverse' }}
          wrap="no-wrap"
          minH="10vh"
          px={8}
          {...rest}
        >
          <CardProntoAtendimento />
          <ListaGrupos />
          <Floatbutton />

          <BannerCarrosel />
          <BadgesCarrosel />
          <ListaEmpresasLivre />

          <chakra.h1 fontSize="1.2em" fontWeight="bold">
            Profissionais para a sua saúde
          </chakra.h1>
          <ListaMedicosLivre isLoading={(value) => setLoading(value)} />
        </VStack>
        <BottomNavBarLivre />
      </UserContext>
    </>
  );
}

export default MainLivre;
