import { Search2Icon } from "@chakra-ui/icons";
import {
	Button,
	Input,
	InputGroup,
	InputRightElement,
	HStack,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { SearchContext } from "./SearchContext";

function Searchbox() {
	const { fetchSearch, lastSearch } = useContext(SearchContext);
	const [textSearch, setTextSearch] = useState("");
	const [loading, setLoading] = useState(false);

	const search = async () => {
		setLoading(true);
		if (textSearch !== lastSearch) fetchSearch(textSearch, 1, 10) // text, page, limit
		setLoading(false);
	};

	return (
		<HStack>
			<InputGroup>
				<Input
					placeholder="buscar"
					focusBorderColor="teal"
					backgroundColor="white"
					shadow="lg"
					borderRadius="50px"
					value={textSearch}
					onChange={(event) => setTextSearch(event.target.value)}
					onKeyPress={(event) => {
						if (event.key === "Enter") {
							search();
						}
					}}
				/>

				<InputRightElement width="15%">
					<Button
						backgroundColor="#EB8B2A"
						borderRadius="50px"
						width="100%"
						children={<Search2Icon color="white" />}
						onClick={search}
						isLoading={loading}
					></Button>{" "}
				</InputRightElement>
			</InputGroup>
		</HStack>
	);
}

export default Searchbox;
