import { VStack, chakra } from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import NoUserHeader from "../NoUserHeader";

function AtendimentoLivre() {
  const navigate = useNavigate();
  const { state } = useLocation();

  return (
    <>
      <NoUserHeader />
      <VStack align="center" minHeight="100vh">
        <chakra.iframe
          width="95%"
          paddingTop="0.5em"
          height="600px"
          src={state.rapidoc}
          allow="camera *;microphone *"
        ></chakra.iframe>
      </VStack>
    </>
  );
}

export default AtendimentoLivre;
