import { Box, chakra, Image, Img, Text, useToast, VStack, HStack, Center, useDisclosure } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/main.css';
import { useContext } from 'react';
import { AccountContext } from '../../AccountContext';
import vinte from '../../../images/24.svg';
import especialistas from '../../../images/doctor.svg';
import pet from '../../../images/pet.svg';
import '../css/bannerpa.css';
import DrawerServico from './DrawerServico';
import DrawerConsulta from './Telemedicina/FluxoLivre/DrawerConsulta';
function CardProntoAtendimento() {
  const { user } = useContext(AccountContext);
  const toast = useToast();
  const navigate = useNavigate();
  const [medico, setMedico] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tipo, setTipo] = useState('');
  const disclosure = useDisclosure();
  const fetchRdy = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BASE_URL_API_SERVER + `/pet/medico_online`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      });
      const postsData = await response.json();
      setMedico(postsData.medico_online);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  fetchRdy();
  return (
    <VStack
      align="flex-start"
      justify={{
        base: 'center',
        md: 'space-around',
        xl: 'space-between',
      }}
      direction={{ base: 'column-reverse' }}
      wrap="no-wrap"
      minH="20vh"
      pt="5%"
      spacing={0.2}
    >
      <DrawerConsulta disclosure={disclosure} tipo={tipo} />
      {(!user.id_contrato || user.id_contrato === 0) && user.titular !== false ? (
        <Center w="100%">
          <Text ml="3" fontWeight="bold" color="#585858" fontSize="sm">
            Pronto Atendimento
          </Text>
        </Center>
      ) : (
        <>
          <Center w="100%">
            <Text ml="3" fontWeight="bold" color="#585858" fontSize="md">
              Telemedicina sem custo adicional
            </Text>
          </Center>
        </>
      )}

      <HStack>
        <Box>
          {' '}
          <button class="btn-square btn-telemedicina">
            <Center>
              <Image
                src={vinte}
                onClick={() => {
                  if (user.contrato_pendente_pagamento === true) {
                    toast({
                      title: 'Assinatura pendente',
                      description: 'O pagamento da sua assinatura está em analise. Aguarde a confirmação do pagamento para acessar esse serviço.',
                      status: 'warning',
                      duration: 4000,
                      isClosable: true,
                      position: 'bottom',
                      colorScheme: 'green',
                      containerStyle: {
                        paddingBottom: '5em',
                      },
                    });
                    return;
                  } else {
                    if ((user.loggedIn === true && user.id_contrato > 0) || (user.loggedIn === true && user.titular === false)) {
                      navigate('/medico-agora/');
                    } else {
                      setTipo('Clínico Geral');
                      disclosure.onOpen();
                    }
                  }
                }}
              ></Image>
            </Center>
          </button>
          <Center>
            <Text>Médico 24h</Text>
          </Center>
        </Box>
        {user.loggedIn === true && user.id_contrato > 0 ? (
          <Box>
            <button class="btn-square btn-telemedicina">
              {' '}
              <Center>
                <Image
                  src={especialistas}
                  onClick={() => {
                    if ((user.loggedIn === true && user.id_contrato > 0) || (user.loggedIn === true && user.titular === false)) {
                      navigate('/medico-agora/especialista');
                    } else {
                      disclosure.onOpen();
                    }
                  }}
                ></Image>
              </Center>
            </button>
            <Center>
              <Text>Especialistas</Text>
            </Center>
          </Box>
        ) : (
          <></>
        )}
        <Box>
          <button class="btn-square btn-telemedicina">
            {' '}
            <Center>
              <Image
                src={pet}
                onClick={() => {
                  if (user.contrato_pendente_pagamento === true) {
                    toast({
                      title: 'Assinatura pendente',
                      description: 'O pagamento da sua assinatura está em analise. Aguarde a confirmação do pagamento para acessar esse serviço.',
                      status: 'warning',
                      duration: 4000,
                      isClosable: true,
                      position: 'bottom',
                      colorScheme: 'green',
                      containerStyle: {
                        paddingBottom: '5em',
                      },
                    });
                    return;
                  } else {
                    if (user.loggedIn === true && user.id_contrato > 0) {
                      if (medico === true) {
                        navigate('/telepet/termos');
                      } else {
                        toast({
                          title: 'Indisponível',
                          description: 'Temporariamente o TELEPET está funcionando apenas em horário comercial. de 8h às 18h.',
                          status: 'warning',
                          duration: 4000,
                          isClosable: true,
                          position: 'bottom',
                          colorScheme: 'green',
                          containerStyle: {
                            paddingBottom: '5em',
                          },
                        });
                      }
                    } else {
                      setTipo('Veterinário');
                      disclosure.onOpen();
                    }
                  }
                }}
              ></Image>
              {/* <Box
                  //right="0.6em"
                  bottom="9.5em"
                  //transform="rotate(-25deg)"
                  position="absolute"
                  backgroundColor={medico ? '#529C94' : 'red'}
                  borderRadius="20px"
                  color="white"
                  textAlign="center"
                  w="19%"
                >
                  {medico ? 'online' : 'offline'}
                </Box> */}
            </Center>
          </button>
          <Center>
            <Text>Telepet</Text>
          </Center>
        </Box>
      </HStack>
    </VStack>
  );
}

export default CardProntoAtendimento;
