import { useNavigate } from 'react-router';
import socketIOClient from 'socket.io-client';

const { createContext, useState, useEffect } = require('react');

export const AccountContext = createContext();

const UserContext = ({ children }) => {
  const ENDPOINT = process.env.REACT_APP_BASE_URL_API_SERVER;
  const [socket, setSocket] = useState(socketIOClient(ENDPOINT));
  let seller;
  let device = 'ios';
  let url = window.location.href;
  if (url.replace('https://cadastro.adolfolutz.com.br/', '') > 0) {
    seller = url.replace('https://cadastro.adolfolutz.com.br/', '');
  }
  console.log(url);
  console.log(seller);

  let schedulingFlow;
  // if schedulingFlow
  if (url.includes('/atendimento/pedido') || (url.includes('/pagamentos') && window.history.state.usr.navig_to === '/atendimento/loading/')) {
    schedulingFlow = true;
    !socket.connected && socket.connect();
  } else {
    schedulingFlow = false;
    socket.connected && socket.emit('unlockSchedule', true);
  }

  const [pic, setPic] = useState('1');
  const [page, setPage] = useState('Titulo da Pagina');
  const [user, setUser] = useState({
    loggedIn: null,
    username: null,
    device: null,
    name: null,
    seller: null,
    id_contrato: null,
    id_plano: null,
    phone1: null,
    phone2: null,
    phone3: null,
    cpf: null,
    rg: null,
    data_aniv: null,
    sexo: null,
    cep: null,
    logradouro: null,
    numero: null,
    complemento: null,
    bairro: null,
    cidade: null,
    uf: null,
    code_ibge: null,
    email: null,
    cod_enel: null,
    situacao: null,
    inicio_contrato: null,
    conciliado_rapidoc: null,
    id_rapidoc: null,
    id_cliente_deovita: null,
    qtde_max_dependentes: 0,
    qtde_max_dependentes_total: 0,
    taxa_remocao_dependente: null,
    cards: [],
    adicionais: [],
    adicionais_pendentes: [],
    popup_adicionais: true,
    avaliacao_pendente: false,
    empreendedor_popup: false,
    url: null,
    qrcode: null,
    coords: null,
    cart: null,
    titular: null,
    contrato_pendente_pagamento: null,
  });
  const navigate = useNavigate();
  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/auth/login`, {
      credentials: 'include',
    })
      .catch((err) => {
        setUser((user) => {
          return { loggedIn: false, device: device, seller: seller };
        });
        return;
      })
      .then((r) => {
        if (!r || !r.ok || r.status >= 400) {
          setUser((user) => {
            return { loggedIn: false, device: device, seller: seller };
          });
          return;
        }
        return r.json();
      })
      .then((data) => {
        if (!data) {
          setUser((user) => {
            return { loggedIn: false, device: device, seller: seller };
          });
          return;
        }
        console.log('reset');
        setUser({ ...data, device: device, seller: seller });
        if (data.id_contrato === 0 && data.titular === true) {
          console.log(data);
        } else {
          navigate(`${seller}`);
          //navigate('/');
        }
      });
  }, []);
  return <AccountContext.Provider value={{ user, setUser, page, setPage, pic, setPic, socket }}>{children}</AccountContext.Provider>;
};

export default UserContext;
