import { Tab, TabList, TabPanel, TabPanels, Tabs, VStack, chakra, Button, Text, Flex, Heading, WrapItem, Box } from '@chakra-ui/react';
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../../AccountContext';
import BottomNavBar from '../../BottomNavBar';
import ChildHeader from '../../Dashboard/Main/ChildHeader';
import PopupPendente from './PopupPendente';
import SelectField from 'components/SelectField';
import { Form, Formik } from 'formik';

function Consultas() {
  const { user } = useContext(AccountContext);
  const navigate = useNavigate();
  const { setPage } = useContext(AccountContext);
  const [atendimentos, setAtendimentos] = useState([]);
  const [dependentes, setDependentes] = useState([]);
  const [pendenteAva, setPendenteAva] = useState('');
  const [pendenteModal] = useState(false);

  setPage('Agendamentos');

  function myToLowerCase(text) {
    if (typeof text === 'string') {
      return text.toLocaleLowerCase();
    } else {
      return text;
    }
  }

  const fetchDependentes = async () => {
    const url = process.env.REACT_APP_BASE_URL_API_SERVER + '/client/dependents2';
    const titularId = {
      id_contrato: user.id_contrato,
    };
    const response = await fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(titularId),
    });
    const resp = await response.json();
    setDependentes(resp.data);
  };

  const fetchAppointments = async (userID) => {
    const url = `${process.env.REACT_APP_BASE_URL_API_SERVER}/appointments/${userID}`;
    const response = await fetch(url, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    const postsData = await response.json();
    setAtendimentos(postsData);
  };

  useEffect(() => {
    fetchAppointments(user.username);
    fetchDependentes();
  }, []);

  const noShow = 'No-show';
  const confirmedByPatient = 'Confirmed by patient';
  const approved = 'Approved';
  const checkout = 'Check-out';
  const pending_payment = 'pending payment';

  const SelectClient = () => {
    const arrayNomeDendente = dependentes.map((dependente) => dependente.nome);
    return (
      <SelectField
        name="clients"
        label="Cliente:"
        placeholder="Cliente"
        w="100%"
        variant="outline"
        textAlign="left"
        borderColor="gray.300"
        borderRadius="10px"
        fontWeight="bold"
        onChange={(e) => {
          fetchAppointments(dependentes[e].cpf);
        }}
        options={arrayNomeDendente}
      />
    );
  };

  const Atendimento = (props) => {
    let atendimentosFiltrados = [];
    if (props.guia === 'agendamento') {
      atendimentosFiltrados = atendimentos.filter(
        (atendimento) =>
          atendimento.STATUS === noShow ||
          atendimento.STATUS === checkout ||
          atendimento.STATUS === confirmedByPatient ||
          atendimento.STATUS === pending_payment
      );
    } else if (props.guia === 'historico') {
      atendimentosFiltrados = atendimentos.filter((atendimento) => atendimento.STATUS === approved);
    }

    return atendimentosFiltrados.map((atendimento) => {
      let statusAtual = '';
      let statusColor = 'black';
      if (atendimento.STATUS === noShow) {
        statusColor = 'yellow.500';
        statusAtual = 'Agendado';
      }
      if (atendimento.STATUS === approved) {
        statusColor = 'green.500';
        statusAtual = 'Concluído';
        if (atendimento.STATUS_AVALIACAO === false) {
          setPendenteAva(atendimento);
        }
      }
      if (atendimento.STATUS === checkout) {
        statusColor = 'green.500';
        statusAtual = 'Agendado';
      }
      if (atendimento.STATUS === pending_payment) {
        statusColor = 'red.500';
        statusAtual = 'Pagamento em Analise';
      }
      if (atendimento.STATUS === confirmedByPatient) {
        statusColor = 'green.500';
        statusAtual = 'Confirmado';
      }
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      const data = atendimento.APP_DATE;
      const formData = new Date(data ? data.replace(' ', 'T') : '');
      const dataCad = atendimento.DATA_ATUALIZACAO;
      console.log(formData);
      const formDataCad = new Date(dataCad ? dataCad.replace(' ', 'T') : '');
      const appTime = atendimento.START_TIME;
      return (
        <Flex
          minW="210px"
          maxW="340px"
          mx="auto"
          alignItems="center"
          overflow="hidden"
          border="1px solid"
          borderColor={statusColor}
          borderRadius="10px"
          padding="1em"
          marginTop={5}
          px={5}
          pt={5}
        >
          <VStack as={Form} alignItems="right" spacing={2.5}>
            <Heading fontSize="sm">
              {atendimento.ACTIVITY_NAME ? atendimento.ACTIVITY_NAME : 'CONSULTA COM ' + atendimento.ESPECIALIDADE.toUpperCase()}
            </Heading>
            <Text ml="-5" fontSize="0.7em" casing="capitalize">
              {atendimento.RESOURCE_NAME === 'Sem Solicitante' ||
              atendimento.RESOURCE_NAME === null ||
              atendimento.RESOURCE_NAME === '' ||
              atendimento.RESOURCE_NAME === undefined ||
              atendimento.RESOURCE_NAME === 'SEM SOLICITANTE '
                ? ''
                : 'Dr. ' + myToLowerCase(atendimento.RESOURCE_NAME)}
            </Text>
            <chakra.p fontSize="0.7em">
              <chakra.span fontWeight="bold"> Modalidade: </chakra.span>
              {atendimento.TELEMEDICINA === true ? 'Telemedicina' : 'Presencial'}
            </chakra.p>
            <chakra.p fontSize="0.7em">
              <chakra.span fontWeight="bold"> Data do agendamento: </chakra.span>
              {formDataCad.toLocaleDateString('pt-br', options)}
            </chakra.p>

            <chakra.p ml="10" fontSize="0.7em">
              <Text casing="capitalize">
                <chakra.span fontWeight="bold"> Local: </chakra.span>
                {atendimento.NAME.toUpperCase()} - {myToLowerCase(atendimento.ADRESS)}, {myToLowerCase(atendimento.NUMBER)} -{' '}
                {myToLowerCase(atendimento.CITY)}
              </Text>
            </chakra.p>

            <chakra.p fontSize="0.7em">
              <chakra.span fontWeight="bold"> Data da consulta: </chakra.span>
              {
                //atendimento.turno
                atendimento.ordem_chegada === true ? (
                  <chakra fontSize="0.7em">
                    {formData.toLocaleDateString('pt-br', options) +
                      ' no turno da ' +
                      (appTime === '06:00:00' ? 'manhã' : 'tarde') +
                      ' - atendimento por ordem de chegada'}
                  </chakra>
                ) : (
                  formData.toLocaleDateString('pt-br', options) + ' às ' + (appTime ? appTime.split(':')[0] + ':' + appTime.split(':')[1] : '')
                )
              }
            </chakra.p>

            <chakra.p fontSize="0.7em">
              <Text casing="capitalize">
                <chakra.span fontWeight="bold">PACIENTE: </chakra.span>
                {myToLowerCase(atendimento.USER_FIRST_NAME)}
                <chakra.span fontWeight="bold"> STATUS: </chakra.span>
                {statusAtual}
              </Text>
            </chakra.p>
            {statusAtual !== 'Pagamento em Analise' ? (
              <>
                <chakra.p fontSize="0.7em">
                  <chakra.span fontWeight="bold">E-Ticket: </chakra.span>
                  PMA{atendimento.APP_LID}
                </chakra.p>
              </>
            ) : (
              <></>
            )}
            {atendimento.tuotempo === 'INTEGRADO' || atendimento.tuotempo === null || atendimento.tuotempo === 'null' ? (
              <chakra.p fontSize="0.7em">
                <chakra.span fontWeight="bold">
                  Agendamento realizado {atendimento.tuotempo === 'INTEGRADO' ? 'pelo app' : 'manualmente'}{' '}
                </chakra.span>
              </chakra.p>
            ) : null}

            <Button
              mt="4"
              borderRadius="10px"
              background="#5AB2AD;"
              color="white"
              size="sm"
              maxwidth="30em"
              height="10"
              onClick={() => {
                if (statusAtual === 'Pagamento em Analise') {
                  navigate('/consultas/detalhes-analise', { state: { atendimento: atendimento, statusAtual } });
                } else {
                  navigate('/consultas/detalhes', { state: { atendimento: atendimento, statusAtual } });
                }
              }}
            >
              ↓ Detalhes
            </Button>
          </VStack>
        </Flex>
      );
    });
  };

  return (
    <Formik initialValues={{ clients: 0 }} validateOnChange={false} validateOnBlur={false}>
      <Box as={Form}>
        <ChildHeader />
        <Tabs px={{ base: 2, sm: 4 }} py={5}>
          {user.titular === true && user.id_contrato > 0 && <SelectClient pb="5" />}
          <TabList>
            <Tab>Agendamento</Tab>
            <Tab>Historico</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Atendimento guia="agendamento" />
            </TabPanel>
            <TabPanel>
              <Atendimento guia="historico" />
            </TabPanel>
          </TabPanels>
        </Tabs>
        <WrapItem mt="20">
          <BottomNavBar name="consultas" />
        </WrapItem>
        {user.avaliacao_pendente === true && pendenteAva ? <PopupPendente data={pendenteAva} showPendente={pendenteModal} /> : ''}
      </Box>
    </Formik>
  );
}

export default Consultas;
