import { Avatar, chakra, Box, Flex, HStack, Wrap, Image, Text, Input, Img, useDisclosure, Center, Icon } from '@chakra-ui/react';
import { PhoneIcon, AddIcon, WarningIcon } from '@chakra-ui/icons';

import React, { useContext, useEffect, useState, useRef } from 'react';
import LogoDashboard from '../../../images/logo_dashboard.svg';
import '../css/main.css';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../../AccountContext';
import Back from '../../../images/back.svg';
import Carrinho from '../../../images/carrinho.svg';
import DrawerCarrinho from './Carrinho/DrawerCarrinho';
import { motion, useAnimation } from 'framer-motion';
import CircleCart from 'components/AreaLivre/CircleCart';

function ChildHeader() {
  const navigate = useNavigate();
  const [bgColor, setBgColor] = useState(false);
  const [show, setShow] = useState(false);
  const { user, page, pic } = useContext(AccountContext);
  const [anima, setAnima] = useState('');
  const disclosureCarrinho = useDisclosure();
  let firstName = user.name.split(' ');
  let url = window.location.href;

  return (
    <>
      <DrawerCarrinho disclosure={disclosureCarrinho} />
      <chakra.header
        w="full"
        px={{ base: 2, sm: 4 }}
        py={4}
        shadow="md"
        backgroundColor="rgba(90, 178, 173, 0.7)"
        minHeight="180px"
        borderBottomRadius="30px"
      >
        <Flex alignItems="center" justifyContent="space-between" mx="auto">
          <Flex>
            <Wrap position="absolute" left="-8" top="1" borderRadius="50%" border="20px solid rgba(255,255,255,0.3)" p="20px">
              <Avatar name={firstName[0]} src={`${process.env.REACT_APP_URL_PROFILEPICS_PACIENTES}/${user.username}.jpeg?${pic}`} size="lg" />
            </Wrap>

            <chakra.h2 textShadow="2px 3px 5px #808080" fontSize="xl" fontWeight="bold" mt="2em" ml="3.5em" color="white">
              Olá!
              <br />
              {firstName[0]}
            </chakra.h2>
          </Flex>
          <HStack display="flex" alignItems="center" spacing={1}>
            <Box display={{ base: 'inline-flex' }}>
              <Image position="absolute" right="0.02rem" top="2.89em" src={LogoDashboard} alt="logo" />
            </Box>
          </HStack>
        </Flex>
        <Img src={Back} boxSize="2em" onClick={() => navigate(-1)} color="white" marginTop="1.3em" position="absolute" zIndex="100" />

        <Text
          position="absolute"
          fontSize="xl"
          fontWeight="bold"
          pt="1.1em"
          textColor="white"
          textShadow="2px 3px 5px #808080"
          textAlign="center"
          width="90%"
          zIndex="1"
        >
          {page}
        </Text>
        {url.includes('/exames/agendamento') || url.includes('/exames/empresa') ? (
          <>
            <Img
              src={Carrinho}
              boxSize="1.5em"
              onClick={() => disclosureCarrinho.onOpen()}
              color="orange"
              marginTop="1.6em"
              marginLeft="84%"
              position="absolute"
              zIndex="101"
            />

            {user.cart && user.cart.itens ? <CircleCart quantidade={user.cart.itens.length} /> : null}
          </>
        ) : null}
      </chakra.header>
    </>
  );
}

export default ChildHeader;
