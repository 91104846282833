import { VStack, chakra, Text, useDisclosure, Tabs, Tab, TabList, TabPanels, TabPanel } from '@chakra-ui/react';
import React, { useState, useEffect, useContext } from 'react';
import UserContext from './SearchContext';
import ListaEmpresas from './ListaEmpresas';
import ProntoAtendimento from './CardProntoAtendimento';
import ListaMedicos from './ListaMedicos';
import MainHeader from './MainHeader';
import '../css/main.css';
import BottomNavBar from '../../BottomNavBar';
import Loading from '../../Loading';
import PopupAvisoAdicionais from './WelcomeScreen/PopupAvisoAdicionais';
import FloatButton from './FloatButton';
import ListaGrupos from './ListaGrupos';
import BadgesCarrosel from 'components/AreaLivre/DashboardLivre/BadgesCarrosel';
import { AccountContext } from 'components/AccountContext';
import AvisoDependente from './DashboardAlerts/AvisoDependente';
import ListaCategorias from './ListaCategorias';
import UserHeaderLivre from 'components/AreaLivre/UserHeaderLivre';
import BadgeNaoAssinante from './BadgeNaoAssinante';
import DrawerPedidoPendente from './DrawerPedidoPendente';
import ModalLogin from './ModalLogin';

function Main({ ...rest }) {
  const [loading, setLoading] = useState(true);
  const { user, setUser } = useContext(AccountContext);
  const getCoords = async () => {
    try {
      if (user.coords == null) {
        navigator.geolocation.getCurrentPosition((position) => {
          setUser((prevState) => ({
            ...prevState,
            coords: {
              latitudeAtual: position.coords.latitude,
              longitudeAtual: position.coords.longitude,
            },
          }));
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCoords();
  });

  return (
    <>
      <AvisoDependente />
      {/*<PopupAvisoAdicionais />*/}
      <UserContext>
        {user.loggedIn === true ? <MainHeader /> : <UserHeaderLivre />}
        <VStack
          align="center"
          justify={{
            base: 'center',
            md: 'space-around',
            xl: 'space-between',
          }}
          direction={{ base: 'column-reverse' }}
          wrap="no-wrap"
          minH="10vh"
          px={8}
          {...rest}
        >
          {user.loggedIn === true && user.pedido_pendente ? <DrawerPedidoPendente /> : <></>}
          {user.loggedIn === false ? <ModalLogin /> : <></>}
          <ProntoAtendimento />

          {/*(user.id_contrato && user.id_contrato > 0) || user.titular === false || user.loggedIn === false ? <></> : <BadgeNaoAssinante />*/}

          <FloatButton />
        </VStack>
        <Tabs w="100%" pb={user.loggedIn === true ? '15%' : ''} pt="5%">
          <TabList w="100%">
            <Tab w="50%">PROFISSIONAIS</Tab>
            <Tab w="50%" onClick->
              EXAMES/OUTROS
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              {loading && <Loading />}
              <ListaMedicos isLoading={(value) => setLoading(value)} />
            </TabPanel>
            <TabPanel bgColor="#F0F0F0">
              <ListaCategorias />
            </TabPanel>
          </TabPanels>
        </Tabs>
        <BottomNavBar name="dashboard" />
      </UserContext>
    </>
  );
}

export default Main;
