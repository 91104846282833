import { VStack, Heading, Text, Button, HStack, ButtonGroup, Wrap, Image, Center, Box } from '@chakra-ui/react';
import NoUserHeader from '../AreaLivre/NoUserHeader';
import { useContext } from 'react';
import { AccountContext } from '../AccountContext';
import deovitalogo from '../../images/deovitalogogrande2.png';
import qrcode from '../../images/qrcode.png';

const QrCode = () => {
  const { setPage } = useContext(AccountContext);
  setPage('Sucesso');

  return (
    <>
      <NoUserHeader />

      <VStack w={{ base: '90%', md: '500px' }} m="auto" justify="top">
        <Center>
          <Image mt="-10" src={deovitalogo}></Image>
        </Center>
        <Center>
          <Image boxSize="150px" mt="-20" src={qrcode}></Image>
        </Center>

        <Box>
          <Center>
            <Heading mt="8" fontSize="3xl" marginBottom="30" textColor="black">
              Baixe o aplicativo
            </Heading>
          </Center>
          <Text textAlign="center">e faça seus agendamentos de onde estiver!</Text>
          <Center>
            <HStack mt="10">
              <Button colorScheme="black" variant="outline" leftIcon="" onClick={() => (window.location.href = 'https://onelink.to/deovit')}>
                Baixar App
              </Button>
            </HStack>
          </Center>
        </Box>
      </VStack>
    </>
  );
};

export default QrCode;
