import { Avatar, chakra, Box, Flex, HStack, Wrap, Image, Text, Img, Center, useDisclosure } from '@chakra-ui/react';

import React, { useContext, useState } from 'react';
import LogoDashboard from '../../images/logo_dashboard.svg';
import './PerfilEmpresa/css/main.css';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../AccountContext';
import Back from '../../images/back.svg';
import ProfilePicturePH from '../../images/assets/placeholder/profile.svg';
import Carrinho from '../../images/carrinho.svg';
import DrawerCarrinho from '../Dashboard/Main/Carrinho/DrawerCarrinho';
import CircleCart from './CircleCart';

function NoUserHeader() {
  const navigate = useNavigate();
  const { user, page, setPage } = useContext(AccountContext);
  let url = window.location.href;
  const disclosureCarrinho = useDisclosure();
  let message = 'Seja Bem Vindo!';
  return (
    <>
      <DrawerCarrinho disclosure={disclosureCarrinho} />
      <chakra.header
        w="full"
        px={{ base: 2, sm: 4 }}
        py={4}
        shadow="md"
        backgroundColor="rgba(90, 178, 173, 0.7)"
        minHeight="180px"
        borderBottomRadius="30px"
      >
        <Flex alignItems="center" justifyContent="space-between" mx="auto">
          <Flex>
            <chakra.a display="flex" alignItems="center">
              <Wrap position="absolute" left="-8" top="1" borderRadius="50%" border="20px solid rgba(255,255,255,0.3)" p="20px">
                <Avatar name=" " src={ProfilePicturePH} size="lg" />
              </Wrap>
            </chakra.a>
            <chakra.h2 textShadow="2px 3px 5px #808080" fontSize="xl" fontWeight="bold" mt="2em" ml="3.5em" color="white">
              Olá!
              <br />
              {message}
            </chakra.h2>
          </Flex>
          <HStack display="flex" alignItems="center" spacing={1}>
            <Box display={{ base: 'inline-flex' }}>
              <Image position="absolute" right="0.02rem" top="2.89em" src={LogoDashboard} alt="teste" />
            </Box>
          </HStack>
        </Flex>

        {page === 'Contratação DeoVita' ? (
          <Img src={Back} boxSize="2em" onClick={() => navigate('/login')} color="white" marginTop="1.3em" position="absolute" zIndex="100" />
        ) : (
          <Img src={Back} boxSize="2em" onClick={() => navigate(-1)} color="white" marginTop="1.3em" position="absolute" zIndex="100" />
        )}
        <Text
          position="absolute"
          fontSize="xl"
          fontWeight="bold"
          pt="1.1em"
          textColor="white"
          textShadow="2px 3px 5px #808080"
          textAlign="center"
          width="90%"
          zIndex="1"
        >
          {page}
        </Text>
        {url.includes('/exames/agendamento') || url.includes('/exames/empresa') ? (
          <>
            <Img
              src={Carrinho}
              boxSize="1.5em"
              onClick={() => disclosureCarrinho.onOpen()}
              color="orange"
              marginTop="1.6em"
              marginLeft="84%"
              position="absolute"
              zIndex="101"
            />

            {user.cart && user.cart.itens ? <CircleCart quantidade={user.cart.itens.length} /> : null}
          </>
        ) : null}
      </chakra.header>
    </>
  );
}

export default NoUserHeader;
