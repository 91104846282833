import { VStack, Heading, Select, Button, Text } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TextField from '../TextField';
import SelectField from 'components/SelectField';
import ChangeTitle from '../ChangeTitle';
import { differenceInYears, parse } from 'date-fns';
import NoUserHeader from '../AreaLivre/NoUserHeader';
import { AccountContext } from 'components/AccountContext';

const DadosAdc = () => {
  ChangeTitle('Cadastro');
  const { setPage, user } = useContext(AccountContext);
  console.log(user);
  const today = new Date().toLocaleDateString();
  setPage('Cadastro');
  const navigate = useNavigate();
  const [sexo, setSexo] = useState('M');

  const { state } = useLocation();
  console.log(state);

  return (
    <>
      <NoUserHeader />
      <Formik
        initialValues={{ nascimento: '', sexo: '' }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={Yup.object({
          nascimento: Yup.date()
            .max(new Date(), 'Futuro não é permitido')
            .min(new Date(new Date().setFullYear(new Date().getFullYear() - 100)), 'Data inválida')
            .transform((value, originalValue) => {
              return parse(originalValue, 'dd/MM/yyyy', new Date());
            })
            .required('Data de nascimento é obrigatória!')
            .test('age18', 'O títular deve ser maior de 18 anos', (value) => {
              let today = new Date();
              let age = differenceInYears(today, value);
              return age >= 18;
            })
            .typeError('Data inválida, digite uma data no formato DD/MM/AAAA'),
          sexo: Yup.number().required('Campo obrigatório'),
        })}
        onSubmit={(values, actions) => {
          const vals = { ...values };
          console.log(vals);
          console.log({
            state: {
              ...state,
              nascimento: vals.nascimento,
              sexo: vals.sexo === 0 ? 'M' : 'F',
            },
          });
          navigate('/cadastro/senha', {
            state: {
              ...state,
              nascimento: vals.nascimento,
              sexo: vals.sexo === 0 ? 'M' : 'F',
            },
          });
        }}
      >
        <VStack as={Form} w={{ base: '90%', md: '500px' }} m="auto" justify="top" marginTop="5%" marginBottom="5%" spacing="1rem" align="start">
          <Heading fontSize="1em">Precisaremos também de mais alguns dados pessoais</Heading>
          <TextField name="nascimento" type="tel" placeholder="Data de nascimento" mask="99/99/9999" maskChar={null} />
          <SelectField
            name="sexo"
            placeholder="Sexo"
            w="100%"
            variant="outline"
            textAlign="left"
            fontWeight="normal"
            onChange={(e) => setSexo(e)}
            options={['Masculino', 'Feminino']}
          />
          <Text as="p" fontSize="small">
            A informação do sexo biológico é necessária para destinar profissionais e procedimentos médicos corretos para o beneficiário.
          </Text>
          <Button colorScheme="teal" w="100%" type="submit">
            Avançar
          </Button>
        </VStack>
      </Formik>
    </>
  );
};

export default DadosAdc;
