import ChildHeader from '../../Dashboard/Main/ChildHeader';
import { AccountContext } from '../../AccountContext';
import { React, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  VStack,
  chakra,
  ButtonGroup,
  Box,
  Text,
  HStack,
  Badge,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Form, Formik } from 'formik';

import BottomNavBar from '../../BottomNavBar';

const DadosBancarios = () => {
  const { setPage, user, setUser } = useContext(AccountContext);
  const { cards } = user;
  const [aviso, setAviso] = useState(null);
  const navigate = useNavigate();
  setPage('Dados Bancários');

  const PopupDelete = (props) => {
    const { card, isDisabled } = props;
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loading, setLoading] = useState(false);

    function excluir() {
      setLoading(true);
      fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/client/rm_credit_card/${card.paciente_cartao_credito_pagarme_id}`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
      })
        .catch((error) => {
          setLoading(false);
          setAviso('Não foi possível excluir o cartão no momento.');
          return;
        })
        .then((res) => {
          if (!res || !res.ok || res.status >= 400) {
            setLoading(false);
            setAviso('Não foi possível excluir o cartão no momento.');
            return;
          }
          return res.json();
        })
        .then((data) => {
          setLoading(false);
          if (!data) return;
          if (data.success) {
            let newCards = user.cards.filter(
              (creditCard) => creditCard.paciente_cartao_credito_pagarme_id !== card.paciente_cartao_credito_pagarme_id
            );
            setUser((prevState) => ({ ...prevState, cards: newCards }));
            setAviso('');
            onClose();
          } else {
            setAviso('Não foi possível excluir o cartão no momento.');
            setLoading(false);
          }
        });
    }
    //const cancelRef = React.useRef();
    return (
      <>
        <Button isDisabled={isDisabled} colorScheme="red" onClick={onOpen}>
          Excluir
        </Button>

        <AlertDialog isOpen={isOpen} onClose={onClose} size="xs">
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Excluir cartão
              </AlertDialogHeader>

              <AlertDialogBody>
                {card.default
                  ? 'Você não pode excluir o seu cartão preferencial. Caso deseje excluir este cartão, selecione outro como preferencial e tente novamente'
                  : 'Tem certeza? Você não poderá desfazer esta ação depois.'}
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button
                  onClick={() => {
                    setAviso('');
                    onClose();
                  }}
                >
                  Cancelar
                </Button>
                <Button colorScheme="red" isDisabled={card.default} isLoading={loading} loadingText="Processando" ml={3} onClick={excluir}>
                  Excluir
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    );
  };

  const CreditCardList = (props) => {
    let { cards } = props;
    const [creditCards, setCreditCards] = useState(cards);
    const [loading, setLoading] = useState(false);

    return creditCards.map((card, index) => {
      function selecionarPreferencial(card, index) {
        fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/client/stamp-default-card/${card.paciente_cartao_credito_pagarme_id}`, {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
        })
          .catch((error) => {
            setLoading(false);
            setAviso('Não foi possível atualizar os dados no momento.');
            return;
          })
          .then((res) => {
            if (!res || !res.ok || res.status >= 400) {
              setLoading(false);
              setAviso('Não foi possível atualizar os dados no momento.');
              return;
            }
            return res.json();
          })
          .then((data) => {
            setLoading(false);
            if (!data) return;
            if (data.success) {
              setCreditCards((cardsList) => {
                cardsList = cardsList.map((card) => {
                  card.default = false;
                  return card;
                });
                cardsList[index].default = true;
                return cardsList;
              });
              setUser((user) => {
                return {
                  ...user,
                  cards: creditCards,
                };
              });
              setAviso('');
            } else {
              setLoading(false);
              setAviso('Não foi possível atualizar os dados no momento.');
            }
          });
      }
      return (
        <Box p="10px" w="100%" h="80px" border="1px" borderColor="gray.100" borderRadius="8px" boxShadow="md">
          <HStack justifyContent="space-between">
            <VStack align="start">
              <HStack align="start" onClick={() => navigate('/perfil/editar-cartao', { state: { op: 'upd', card: card } })}>
                <Text color="#4E9E8F" fontSize="md" fontWeight="bold">
                  {card.brand} final {card.last_digits}
                </Text>
                {card.default && (
                  <Badge fontSize="8" colorScheme="yellow" bgColor="yellow.200" p="1" borderRadius="4px">
                    Preferencial
                  </Badge>
                )}
              </HStack>
              <ButtonGroup size="xs">
                {<PopupDelete card={card} isDisabled={creditCards.length <= 1} />}
                {!card.default && (
                  <Button
                    isDisabled={loading}
                    isLoading={loading}
                    loadingText="processando"
                    colorScheme="yellow"
                    onClick={() => selecionarPreferencial(card, index)}
                  >
                    Marcar como preferencial
                  </Button>
                )}
              </ButtonGroup>
            </VStack>
            <ChevronRightIcon color="teal" onClick={() => navigate('/perfil/editar-cartao', { state: { op: 'upd', card: card } })} />
          </HStack>
        </Box>
      );
    });
  };

  return (
    <>
      <ChildHeader />
      <Formik validateOnChange={false} validateOnBlur={false}>
        <VStack as={Form} w={{ base: '85%', md: '500px' }} m="auto" paddingBottom="5%" spacing="1rem" align="start" marginTop="5%">
          <chakra.p fontWeight="bold" fontSize="lg">
            Cartões
          </chakra.p>
          <chakra.p color="red">{aviso}</chakra.p>
          <CreditCardList cards={cards} />
          <ButtonGroup w="100%">
            <Button colorScheme="teal" w="100%" onClick={() => navigate('/perfil/editar-cartao', { state: { op: 'new', card: {} } })}>
              Adicionar cartão
            </Button>
          </ButtonGroup>
        </VStack>
      </Formik>
      <BottomNavBar />
    </>
  );
};

export default DadosBancarios;
