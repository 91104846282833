import { DeleteIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Flex,
  LinkBox,
  Text,
  Divider,
  useRadioGroup,
  Button,
  VStack,
  Modal,
  useRadio,
  Image,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogFooter,
  AlertDialogContent,
  Center,
  chakra,
  Heading,
  HStack,
  Badge,
  RadioGroup,
  Radio,
  Stack,
} from '@chakra-ui/react';
import { CheckCircleIcon, CheckIcon } from '@chakra-ui/icons';
import React from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccountContext } from '../../AccountContext';
import NoUserHeader from '../../AreaLivre/NoUserHeader';
import popup from '../Imagens/popuparealivre.png';
import Plano from 'components/Cadastro/Plano';

export default function ResumoPagamento() {
  const { user, setPage } = useContext(AccountContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [escolha, setEscolha] = useState(null);
  const { cart, empresa } = state;
  const [pag, setPag] = useState(0);
  const [turno, setTurno] = useState('');
  const [plano, setPlano] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState('');
  const [active, setActive] = useState(1);
  var valorParcial = 0;
  var valorParticular = 0 / 5;
  var valorItemParticular = 0;

  setPage('Resumo da compra');
  const getDaysArray = function (s, e) {
    for (var a = [], d = new Date(s); d <= new Date(e); d.setDate(d.getDate() + 1)) {
      a.push(new Date(d));
    }
    return a;
  };

  const dateInterval = getDaysArray(new Date(), new Date(new Date().setDate(new Date().getDate() + 30)));

  const useDatas = dateInterval.map((date, index) => {
    const options = { month: 'short', day: 'numeric' };
    const dataIcon = date.toLocaleDateString('pt-br', options).replace(' de ', ' ').replace('.', '').split(' ');
    return (
      <>
        <Flex>
          <Box backgroundColor="rgba(90, 178, 173, 0.3)" borderRadius="5px" margin="5px" color="rgba(62, 100, 255, 1)" padding="0 13px">
            <Text fontWeight="bold" fontSize="xl">
              {dataIcon[0]}
            </Text>
            <Text fontWeight="bold" fontSize="sm" mt="-1">
              {dataIcon[1].toUpperCase()}
            </Text>
          </Box>
          <Text margin="5% 0" fontSize="xs" align="left">
            {date.toLocaleString('pt-br', { weekday: 'long' })}
          </Text>
        </Flex>
      </>
    );
  });

  function AlertDialogExample(props) {
    const cancelRef = React.useRef();

    return (
      <>
        <AlertDialog
          isOpen={isOpen}
          k
          motionPreset="slideInBottom"
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          size="xs"
          isCentered
          isLazy={true}
        >
          <AlertDialogOverlay>
            <AlertDialogContent borderRadius={15}>
              <AlertDialogFooter>
                <VStack w="100%" spacing={3} margin={2}>
                  <Center>
                    <DeleteIcon h={50} w={50} color="teal" />
                  </Center>
                  <Text fontWeight="bold" align="center" fontSize="x-large">
                    Deseja remover {props.name} da lista
                  </Text>
                  <Button
                    ref={cancelRef}
                    isFullWidth
                    fontSize="xl"
                    onClick={() => {
                      cart.splice(props.index, 1);
                      onClose();
                    }}
                  >
                    Remover
                  </Button>
                  <Button colorScheme="teal" onClick={onClose} isFullWidth fontSize="xl">
                    Cancelar
                  </Button>
                </VStack>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    );
  }

  function CheckoutAssinante() {
    return (
      <VStack
        w={{ base: '90%' }}
        id="Assinante"
        m="auto"
        py="5"
        px="5"
        width={360}
        borderRadius="10px"
        justify="center"
        border="2px solid gray"
        align="flex-start"
        spacing={5}
        onClick={() => {
          setPlano('Assinante');
          document.getElementById('Assinante').style.border = '2px solid lime';
          document.getElementById('Particular').style.border = '2px solid gray';

          setDisabled(false);
        }}
      >
        <VStack spacing={3} align="start">
          <Heading fontSize="xl">Serviços</Heading>
          <Text color="red.500">{error}</Text>

          <Box width="full" spacing={2}>
            {cart &&
              cart.map((item, index) => {
                valorParcial += parseFloat(item.valor);

                return (
                  <>
                    <AlertDialogExample name={item.nome} index={index} />
                    <HStack>
                      <Text w="60%">{item.nome}</Text>
                      <Text w="30%">R$ {item.valor}</Text>

                      <DeleteIcon
                        color="teal"
                        onClick={() => {
                          onOpen();
                        }}
                      />
                    </HStack>
                  </>
                );
              })}
            <Divider bgColor="black" mt="4" color="black" />
            <HStack mt="5%">
              <Text w="60%" fontSize="lg" fontWeight="bold">
                Total
              </Text>

              <Text w="30%" fontSize="lg" fontWeight="bold">
                R$ {valorParcial ? valorParcial.toString().replace('.', ',') : ''}
              </Text>
            </HStack>
            <Badge
              id="Assinante"
              ml="1"
              textColor="ButtonText"
              textAlign="center"
              fontFamily="sans-serif"
              fontSize="12"
              marginTop="4"
              color="orange"
              bgColor={'#FF9A00'}
              width="90px"
              height="22px"
              borderRadius="8px"
            >
              {'assinante'}
            </Badge>
          </Box>
        </VStack>
      </VStack>
    );
  }

  function CheckoutParticular() {
    return (
      <VStack
        id="Particular"
        w={{ base: '90%' }}
        py={5}
        px={5}
        justify="center"
        align="flex-start"
        borderRadius="10px"
        border="2px solid gray"
        width={360}
        spacing={5}
        onClick={() => {
          setPlano('Particular');
          document.getElementById('Particular').style.border = '2px solid lime';
          document.getElementById('Assinante').style.border = '2px solid gray';

          setDisabled(false);
        }}
      >
        <VStack spacing={3} align="start">
          <Heading fontSize="xl">Serviços</Heading>
          <Text color="red.500">{error}</Text>

          <Box width="full" spacing={2}>
            {cart &&
              cart.map((item, index) => {
                valorParticular += parseFloat(item.valor) * 2;
                valorItemParticular = parseFloat(item.valor) * 2;

                return (
                  <>
                    <AlertDialogExample name={item.nome} index={index} />
                    <HStack>
                      <Text w="60%">{item.nome}</Text>
                      <Text w="30%">R$ {valorItemParticular},00</Text>

                      <DeleteIcon
                        color="teal"
                        onClick={() => {
                          onOpen();
                        }}
                      />
                    </HStack>
                  </>
                );
              })}
            <Divider bgColor="black" mt="4" />
            <HStack mt="5%">
              <Text w="60%" fontSize="lg" fontWeight="bold">
                Total
              </Text>

              <Text w="30%" fontSize="lg" fontWeight="bold">
                R$ {valorParticular ? valorParticular.toString().replace('.', ',') : ''}
              </Text>
            </HStack>
            <Badge
              id="Particular"
              ml="1"
              textColor="ButtonText"
              textAlign="center"
              fontFamily="sans-serif"
              fontSize="12"
              marginTop="4"
              bgColor="rgba(0, 0, 0, 0.15)"
              width="90px"
              height="22px"
              borderRadius="8px"
            >
              {'particular'}
            </Badge>
          </Box>
        </VStack>
      </VStack>
    );
  }

  return (
    <>
      <NoUserHeader />
      <VStack w={{ base: '90%', md: '300px' }} m="auto" justify="top" mt="5%" mb="25%" spacing="2rem" align="start">
        <VStack w="full" spacing={3} align="start">
          <Heading fontSize="xl" mt="2">
            Agende seu Procedimento
          </Heading>

          <chakra.h1 fontWeight="bold">{empresa.empresa}</chakra.h1>
          <chakra.h2 fontSize="sm" fontWeight="normal">
            {empresa.endereco}, {empresa.numero} - {empresa.bairro}
          </chakra.h2>
          <chakra.h2 fontSize="sm" fontWeight="normal">
            {empresa.municipio} / {empresa.estado}
          </chakra.h2>
        </VStack>
        <Heading fontSize="xl">Selecione uma Opção</Heading>
        <Stack align="column">
          <CheckoutAssinante />
          <CheckoutParticular />
        </Stack>
        <VStack w="full" spacing={3} align="start">
          <chakra.h1 fontWeight="bold">Turno:</chakra.h1>
          <HStack pl="15%">
            <Badge
              id="t1"
              margin="0 0.5em"
              padding="0.5em 1.4em"
              size="lg"
              fontSize="md"
              colorScheme="green"
              onClick={() => {
                document.getElementById('t1').style.border = '2px solid orange';
                document.getElementById('t2').style.border = '2px solid gray';
                setTurno('manhã');
                setDisabled(false);
              }}
            >
              Manhã
            </Badge>
            <Badge
              id="t2"
              margin="0 0.5em"
              padding="0.5em 1.4em"
              size="lg"
              fontSize="md"
              colorScheme="green"
              onClick={() => {
                document.getElementById('t2').style.border = '2px solid orange';
                document.getElementById('t1').style.border = '2px solid gray';
                setTurno('tarde');
                setDisabled(false);
              }}
            >
              Tarde
            </Badge>
          </HStack>
          <chakra.h1 fontWeight="bold">Selecione Dia:</chakra.h1>
          <LinkBox id="" as="card" borderRadius="15px" fontSize="xs" backgroundColor="rgba(90, 178, 173, 0.25)" width="100%" textAlign="center">
            <HStack>
              <ChevronLeftIcon
                fontSize="5em"
                onClick={() => {
                  if (pag > 0) {
                    setPag(pag - 1);
                  }
                }}
              />
              <Text width="60%">{dateInterval && useDatas[pag]}</Text>
              <ChevronRightIcon
                fontSize="5em"
                onClick={() => {
                  if (pag < dateInterval.length - 1) {
                    setPag(pag + 1);
                  }
                }}
              />
            </HStack>
          </LinkBox>
        </VStack>
        <Button
          isFullWidth
          colorScheme="teal"
          disabled={disabled}
          onClick={() => {
            if (plano === 'Particular') {
              valorParcial = valorParcial * 2;
            }
            if (Object.keys(cart).length > 0) {
              if (!valorParcial || valorParcial === 0) {
                navigate('/area-livre/exames/cpf', {
                  state: {
                    valor: 0,
                    item: cart,
                    paciente: '',
                    empresa: empresa,
                    turno: turno,
                    data: dateInterval[pag],
                    examesLivre: true,
                  },
                });
              } else {
                navigate('/area-livre/exames/cpf', {
                  state: {
                    valor: valorParcial,
                    turno: turno,
                    empresa: empresa,
                    data: dateInterval[pag],
                    navig_to: '/exames/loading/',
                    item: cart,
                    paciente: '',
                    examesLivre: true,
                    plano: plano,
                  },
                });
              }
            } else {
              setError('É necessario ter adicionado ao menos um procedimento para prosseguir com o agendamento.');
            }
          }}
        >
          Continuar
        </Button>
      </VStack>
    </>
  );
}
