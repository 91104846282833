import React, { useContext } from 'react';
import {
  VStack,
  chakra,
  Text,
  useDisclosure,
  Center,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Heading,
  Flex,
  Image,
  Box,
  Spacer,
  GridItem,
  Grid,
  List,
  ListItem,
  Avatar,
  ListIcon,
  OrderedList,
  UnorderedList,
  Divider,
  ButtonGroup,
  Radio,
} from '@chakra-ui/react';
import happy from '../../../../../images/happy-face.svg';
import sad from '../../../../../images/sad-face.svg';

import { useLocation, useNavigate } from 'react-router-dom';
import { AccountContext } from 'components/AccountContext';

function DrawerConsulta(props) {
  const { disclosure, tipo } = props;
  const { user, setUser } = useContext(AccountContext);
  const state = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <Drawer isOpen={disclosure.isOpen} placement="bottom" onClose={disclosure.onClose}>
        <DrawerOverlay />
        <DrawerContent borderTopRadius="20px" h="85%">
          <DrawerBody>
            <Radio w="3em" autoFocus hidden></Radio>
            <Grid gap={1} alignContent="center" w="100%">
              <Center>
                {' '}
                <Text mt="2" w="60%" mb="1em" fontWeight="bold" fontSize="sm" textAlign="center">
                  Sejá atendido(a) por um {tipo}, agora!
                </Text>
              </Center>
              <DrawerCloseButton />
              <GridItem
                w="100%"
                h="80%"
                mt="2em"
                paddingBottom="3em"
                borderColor="#00000"
                boxShadow={'lg'}
                borderRadius="10px"
                justify="center"
                align="left"
                px={5}
                spacing="2em"
              >
                <Avatar marginTop="-1.2em" paddingBottom="-20" src={happy}></Avatar>
                <VStack mt="0.8em" spacing={4}>
                  <Text fontSize="sm" w="90%" textColor="gray.500">
                    Só com a economia deste atendimento você já paga
                  </Text>
                  <Heading fontSize="md" textColor="#529C94" lineHeight="31px">
                    1 ANO E 3 MESES DE ASSINATURA
                  </Heading>
                  <Text fontSize="sm" textColor="gray.500">
                    E ainda garante inúmeros outros benefícios:
                  </Text>{' '}
                  <Center>
                    {' '}
                    <UnorderedList fontSize="sm">
                      <ListItem textAlign="left" textColor="teal.400" fontSize="sm">
                        Especialistas sem custo adicional
                      </ListItem>
                      <ListItem textAlign="left" textColor="teal.400" fontSize="sm">
                        Telepet sem custo adicional
                      </ListItem>
                      <ListItem ttextAlign="left" textColor="teal.400" fontSize="sm">
                        Consultas presenciais a partir de R$25,00
                      </ListItem>
                      <ListItem textAlign="left" textColor="teal.400" fontSize="sm">
                        Exames com até 80% de desconto
                      </ListItem>
                    </UnorderedList>
                  </Center>
                  <Divider borderColor="black" />
                  <Flex minWidth="max-content" alignItems="center" gap="12">
                    <Box p="2">
                      <Text size="sm" fontWeight="bold" textColor="gray.500">
                        Atendimento
                      </Text>
                      <Text size="sm" fontWeight="bold" textColor="gray.500">
                        Para assinante
                      </Text>
                    </Box>
                    <Spacer />
                    <Text fontWeight="bold" fontSize="lg" color="#529C94">
                      R$ 0,00
                    </Text>
                  </Flex>
                  <ButtonGroup w="100%">
                    {' '}
                    <Center w="100%">
                      {' '}
                      <Button
                        colorScheme="teal"
                        fontSize="sm"
                        borderRadius="20px"
                        w="80%"
                        size="md"
                        onClick={() => {
                          if (user.loggedIn === false) {
                            setUser((prevState) => {
                              return {
                                ...prevState,
                                service_type: 'assinar',
                              };
                            });
                            navigate('/cadastro');
                          } else {
                            setUser((prevState) => {
                              return {
                                ...prevState,
                                service: 'telemedicine',
                                service_type: 'assinar',
                              };
                            });
                            navigate('/cadastro/plano', {
                              state: {
                                client_id: user.id_cliente_deovita,
                                name: user.name,
                                cpf: user.cpf,
                              },
                            });
                          }
                        }}
                      >
                        Quero assinar
                      </Button>
                    </Center>
                  </ButtonGroup>
                </VStack>
              </GridItem>

              <GridItem
                w="100%"
                h="70%"
                paddingBottom="3em"
                borderColor="#00000"
                boxShadow={'lg'}
                borderRadius="10px"
                justify="center"
                align="left"
                px={4}
                spacing="2em"
              >
                <VStack align="right" mb="3em">
                  <Avatar marginTop="-1.2em" src={sad}></Avatar>
                  <Flex minWidth="max-content" alignItems="center" gap="8">
                    <Box p="2">
                      <Text size="sm" fontWeight="bold" textColor="gray.500">
                        Atendimento
                      </Text>
                      <Text size="sm" fontWeight="bold" textColor="gray.500">
                        Particular
                      </Text>
                    </Box>
                    <Spacer />
                    <Text fontWeight="bold" fontSize="lg" color="orange">
                      R$ 150,00
                    </Text>
                  </Flex>
                  <ButtonGroup w="100%">
                    {' '}
                    <Center w="100%">
                      {' '}
                      <Button
                        colorScheme="orange"
                        fontSize="sm"
                        marginBottom="-55"
                        borderRadius="20px"
                        w="80%"
                        size="md"
                        zIndex="9999"
                        onClick={() => {
                          if (user.loggedIn === false) {
                            navigate('/cadastro', {
                              state: {
                                service: 'telemedicine',
                                valor: '150.00',
                              },
                            });
                          } else {
                            navigate('/pagamentos', {
                              state: {
                                name: user.name,
                                service: 'telemedicine',
                                valor: '150.00',
                                navig_to: '/alerts/sucesso/telemedicina',
                                parcelsActive: true,
                                item: {
                                  client_id: user.id_cliente_deovita,
                                  product_name: '',
                                  nome: user.name,
                                  fone: user.phone1 ? user.phone1 : user.phone2 ? user.phone2 : '',
                                  pet: state.pet,
                                },
                                expiration_pix_in_min: 5,
                              },
                            });
                          }
                        }}
                      >
                        Ir para pagamento
                      </Button>
                    </Center>
                  </ButtonGroup>
                </VStack>
              </GridItem>
            </Grid>
            {user.loggedIn === false && (
              <ButtonGroup w="100%" pb="15%">
                {' '}
                <Center w="100%">
                  {' '}
                  <Button
                    colorScheme="teal"
                    fontSize="sm"
                    marginBottom="-50"
                    borderRadius="20px"
                    w="80%"
                    size="md"
                    zIndex="9999"
                    onClick={() => {
                      navigate('/login');
                    }}
                  >
                    Já sou assinante
                  </Button>
                </Center>
              </ButtonGroup>
            )}
          </DrawerBody>{' '}
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default DrawerConsulta;
