import {
  VStack,
  Heading,
  Text,
  Container,
  Stack,
  useColorModeValue,
  Center,
  createIcon,
  Image,
  Thead,
  Tr,
  Th,
  Table,
  Td,
  TableCaption,
  Tfoot,
  StackDivider,
  Tbody,
  TableContainer,
  Icon,
  Badge,
  Flex,
  Spacer,
  ButtonGroup,
  Box,
  Grid,
  HStack,
  List,
  ListItem,
  ListIcon,
  Button,
  Divider,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import React, { useContext, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ChangeTitle from '../ChangeTitle';
import NoUserHeader from '../AreaLivre/NoUserHeader';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { AccountContext } from '../AccountContext';
import { FaCheckCircle } from 'react-icons/fa';
import { MdPublishedWithChanges, MdLogin } from 'react-icons/md';
import background from '../../images/sucesso-background-3.svg';

const FimCadastroCliente = ({ children }) => {
  ChangeTitle('Cadastro');
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log(state);
  const { setPage, setUser, user } = useContext(AccountContext);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [valor, setValor] = useState('000,00');
  const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
  function handleError() {
    setError(true);
    setLoading(false);
  }
  setPage('Sucesso');
  console.log(user);
  function Card() {
    return (
      <Center>
        <Box padding="2%" bgColor="white" borderWidth="1px" borderRadius="1em" width="110%">
          <Box>
            <Text fontSize="sm" textColor="teal.500" fontWeight="semibold" fontFamily="body" textAlign="center">
              Médicos e especialistas
            </Text>
            <Text textColor="teal.500" fontWeight="semibold" marginTop="-0.4em" fontFamily="body" fontSize="sm" textAlign="center">
              sem custo para assinantes:
            </Text>{' '}
            <HStack>
              <Box p={1}>
                <TableContainer w="100%">
                  <Table variant="none" size="5">
                    <Tbody>
                      <Tr>
                        <Td>
                          {' '}
                          <Text fontSize="0.5em">Clinico Geral</Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Text fontSize="0.5em">Cardiologia</Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          {' '}
                          <Text fontSize="0.5em">Dermatologia</Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          {' '}
                          <Text fontSize="0.5em">Endocrinologia</Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          {' '}
                          <Text fontSize="0.5em">Geriatria</Text>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
              <Box>
                <TableContainer w="100%">
                  <Table variant="none" size="xs">
                    <Thead>
                      <Tr> </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td>
                          {' '}
                          <Text fontSize="0.5em">Otorrinolaringologia</Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Text fontSize="0.5em">Neurologia</Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          {' '}
                          <Text fontSize="0.5em">Pediatria</Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          {' '}
                          <Text fontSize="0.5em">Psiquiatria</Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          {' '}
                          <Text fontSize="0.5em">Traumatologia</Text>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
              <Box>
                <TableContainer w="100%">
                  <Table variant="none" size="xs">
                    <Thead>
                      <Tr> </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td>
                          {' '}
                          <Text fontSize="0.5em">Traumatologia</Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Text fontSize="0.5em">Otorrinolaringologia</Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          {' '}
                          <Text fontSize="0.5em">Urologia</Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          {' '}
                          <Text fontSize="0.5em">Nutrição</Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          {' '}
                          <Text fontSize="0.5em">Veterinário</Text>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
            </HStack>
          </Box>
        </Box>
      </Center>
    );
  }

  const userLogin = () => {
    const vals = {
      username: onlyNumbers(state.cpf),
      password: state.senha,
    };
    fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/auth/login`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(vals),
    })
      .catch((err) => {
        handleError();
        return;
      })
      .then((res) => {
        if (!res || !res.ok || res.status >= 400) {
          handleError();
          return;
        }
        return res.json();
      })
      .then((data) => {
        if (!data) return;
        setUser((prevState) => ({ ...prevState, ...data }));
        if (data.status) {
          handleError();
        } else if (data.loggedIn) {
          const payload = {
            cpf: data.cpf,
            numero: data.phone1,
            nome: data.name,
          };
          if (!state.service) {
            navigate('/dashboard');
          } else {
            if (state.service === 'telemedicine') {
              navigate('/pagamentos', {
                state: {
                  ...state,
                  navig_to: 'alerts/sucesso/telemedicina',
                  parcelsActive: true,
                  item: { product_name: '', client_id: data.id_cliente_deovita, nome: data.name, fone: data.phone1 },
                  expiration_pix_in_min: 5,
                },
              });
            }
          }
        }
      });
  };
  return (
    <>
      <NoUserHeader />

      <Formik w="100%">
        <Stack>
          <Center>
            <Stack direction="column">
              {' '}
              <Heading
                as="h1"
                mt="1"
                bgClip="text"
                bgGradient="linear-gradient(90deg, #529C94 0%, #5AB2AD 28.13%, #EFA73F 62.5%, #EB8B2A 100%);"
                fontSize="3em"
              >
                Parabéns!
              </Heading>
              <Center>
                <Text fontSize="0.8em" fontWeight="extrabold" color={'gray.600'}>
                  Cadastro finalizado com sucesso!
                </Text>
              </Center>
            </Stack>
          </Center>

          <VStack
            as={Form}
            backgroundImage={background}
            style={{
              backgroundSize: 'cover',

              paddingBottom: '7em',
            }}
            w="100%"
            mt="10%"
          >
            <Center>
              <VStack alignItems="center" paddingTop="12em">
                {' '}
                <Box>
                  <Grid mt="7em">
                    <Center>
                      {' '}
                      <Center>
                        {' '}
                        <VStack>
                          {' '}
                          {/*<Heading textColor="white" fontSize="1em" textAlign="center">
                            Comece a economizar agora!
                          </Heading>
                          <Text fontSize="8" textAlign="center" textColor="white" maxW="59%">
                            Seja um(a) assinante e pague apenas R$ {valor} em seus procedimentos agendados.
                          </Text>*/}
                        </VStack>
                      </Center>
                    </Center>{' '}
                  </Grid>
                </Box>
                <HStack spacing="24px">
                  <Center>
                    {' '}
                    {/*<Text textAlign="center" fontSize="sm" textColor="white" fontWeight="bold">
                      ECONOMIA DE R${valor}
                    </Text>*/}
                  </Center>
                </HStack>{' '}
                <Card />
                <Center>
                  {user && user.service_type && user.service_type === 'assinar' ? (
                    <>
                      {' '}
                      <Stack direction="column" align="center" mt="1em" w="100%">
                        <Button
                          size="md"
                          colorScheme={'teal'}
                          variant="solid"
                          borderRadius="20em"
                          onClick={() => {
                            navigate('/cadastro/plano', { state: { ...state } });
                          }}
                        >
                          Continuar
                        </Button>
                      </Stack>
                    </>
                  ) : (
                    <>
                      <Stack direction="column" align="center" mt="1em">
                        <Button
                          size="md"
                          colorScheme={'teal'}
                          variant="solid"
                          borderRadius="20em"
                          onClick={() => {
                            navigate('/cadastro/plano', { state: { ...state } });
                          }}
                        >
                          Continuar
                        </Button>
                      </Stack>
                    </>
                  )}
                </Center>
              </VStack>
            </Center>
          </VStack>
        </Stack>
      </Formik>
    </>
  );
};

export default FimCadastroCliente;
