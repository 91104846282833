import {
  Heading,
  VStack,
  Button,
  Checkbox,
  CheckboxGroup,
  Text,
  ButtonGroup,
  chakra,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import { React, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import BottomNavBar from '../../../BottomNavBar';
import ChildHeader from '../ChildHeader';
import ProgressBar from './ProgressBar';

const Termos = () => {
  const [termos, setTermos] = useState(false);
  const [maioridade, setMaioridade] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = useRef(null);
  const navigate = useNavigate();
  return (
    <>
      <Modal size="full" finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ModalCloseButton marginTop="4em" />
          </ModalHeader>

          <ModalBody>
            <chakra.iframe height="10000px" frameborder="1" src={process.env.REACT_APP_URL_CONTRATOADESAO} />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="green" variant="outline" mr={3} onClick={onClose}>
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <ChildHeader />
      <ProgressBar value={1} />
      <Formik validateOnChange={false} validateOnBlur={false}>
        <VStack w={{ base: '90%', md: '500px' }} m="auto" marginTop="3%" marginBottom="5%" justify="top" spacing="1rem" align="start">
          <Heading fontSize="1rem">Para continuar você deve confirmar o seguinte</Heading>
          <CheckboxGroup pt="1rem">
            <Checkbox
              name="termos"
              onChange={(e) => {
                if (!termos) {
                  setTermos(true);
                } else {
                  setTermos(false);
                }
              }}
            >
              Eu li e aceito os
              <chakra.a pl="10px" colorScheme="teal" onClick={onOpen}>
                <u>Termos e Condições</u>
              </chakra.a>
            </Checkbox>
            <Checkbox
              name="maioridade"
              onChange={(e) => {
                if (!maioridade) {
                  setMaioridade(true);
                } else {
                  setMaioridade(false);
                }
              }}
            >
              Tenho 18 anos ou mais
            </Checkbox>
          </CheckboxGroup>
          <Text as="p" fontSize="small">
            {
              '*Poderá haver cobrança proporcional ao gozo dos serviços colocados a disposição do consumidor de acordo com a data de vencimento do seu plano.'
            }
          </Text>
          <ButtonGroup width="100%">
            <Button colorScheme="teal" w="100%" onClick={() => navigate('/comorbidadespa')} isDisabled={!(termos && maioridade)}>
              Continuar
            </Button>
          </ButtonGroup>
          <chakra.p></chakra.p>
          <chakra.p></chakra.p>
          <chakra.p></chakra.p>
          <chakra.p></chakra.p>
        </VStack>
      </Formik>
      <BottomNavBar />
    </>
  );
};

export default Termos;
