import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  Stack,
  DrawerOverlay,
  Image,
  Button,
  useDisclosure,
  VStack,
  Center,
  Header,
  Heading,
  ListItem,
  chakra,
  Flex,
  Text,
  HStack,
  List,
  ButtonGroup,
  Input,
  Radio,
  Img,
} from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useState } from 'react';
import Carrinho from '../../../../images/carrinho.svg';
import { useContext } from 'react';
import { AccountContext } from 'components/AccountContext';
import { DeleteIcon } from '@chakra-ui/icons';

function DrawerCarrinho(props) {
  const navigate = useNavigate();
  const { user, setUser } = useContext(AccountContext);
  const disclosure = props.disclosure;
  const cart = user.cart;
  const { itens, valor } = cart || {};
  let url = window.location.href;
  const [currentIndex, setCurrentIndex] = useState();

  const useProcedimentos = cart?.itens?.map((item, index) => {
    return (
      <>
        <chakra.tr w="100%" borderBottom="1px solid" borderColor="#AEAEB2">
          <Flex mt="1em">
            <Text fontSize="xs" w="90%" color="#585858" alignSelf="flex-end">
              {item.nome}
            </Text>
            <DeleteIcon
              color="#EB8B2A"
              onClick={() => {
                const updatedItens = cart.itens.filter((cartItem) => cartItem !== item);
                if (updatedItens.length === 0) {
                  setUser((prevState) => ({ ...prevState, cart: null }));
                } else {
                  setUser((prevState) => ({ ...prevState, cart: { ...prevState.cart, itens: updatedItens } }));
                }
              }}
            />
          </Flex>
        </chakra.tr>
      </>
    );
  });
  return (
    <Drawer isOpen={disclosure.isOpen} onClose={disclosure.onClose} placement="bottom">
      <DrawerOverlay />
      <DrawerContent borderTopRadius={20} width="100%" h="80%">
        <DrawerBody pb="10%" pt="10%">
          {' '}
          <VStack mt="5%">
            <Radio w="3em" autoFocus hidden></Radio>
            <chakra.h1 fontWeight="bold" pb="10%">
              Ultimo carrinho de compras
            </chakra.h1>
            {cart ? (
              <>
                <Box
                  w="90%"
                  mx="auto"
                  alignItems="center"
                  overflow="hidden"
                  border="1px solid"
                  borderColor="gray.200"
                  borderRadius="10px"
                  padding="1em"
                  marginTop={5}
                  px={5}
                  pt={5}
                >
                  <chakra.h2 marginTop="5%" fontWeight="bold">
                    PROCEDIMENTOS
                  </chakra.h2>
                  <chakra.table w="100%">{useProcedimentos}</chakra.table>
                  <Button
                    mt="10%"
                    width="100%"
                    colorScheme="teal"
                    onClick={() => {
                      if (url.includes('/exames/empresa')) {
                        disclosure.onClose();
                      } else {
                        const destinationPage = '/exames/empresa';
                        navigate(destinationPage, {
                          state: { cart: user.cart.itens, empresa: user.cart.empresa },
                        });
                      }
                    }}
                  >
                    Fechar Pedido
                  </Button>
                  <Button
                    mt="10%"
                    width="100%"
                    colorScheme="gray"
                    onClick={() => {
                      setUser((prevState) => ({ ...prevState, cart: null }));
                    }}
                  >
                    Esvaziar Carrinho
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <Img boxSize="5em" src={Carrinho} />
                <chakra.h1 fontWeight="bold">Carrinho vazio!</chakra.h1>
                <chakra.h2 fontColor="#585858" w="55%" align="center">
                  Você não possue nenhum agendamento pendente.
                </chakra.h2>
              </>
            )}
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export default DrawerCarrinho;
