import { Button, chakra, VStack, ButtonGroup, Container, Box, Text } from '@chakra-ui/react';
import React from 'react';
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccountContext } from '../../../AccountContext';
import NoUserHeader from '../../../AreaLivre/NoUserHeader';
import BottomNavBar from '../../../BottomNavBar';
import ChildHeader from '../ChildHeader';
import _ from 'lodash';

function ConferePedido() {
  const navigate = useNavigate();
  const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
  const { user, setUser, setPage } = useContext(AccountContext);
  const { state } = useLocation();
  console.log(state);
  setPage('Pedido');
  const confirmEstimate = (id) => {
    console.log(id);
    if (id > 0) {
      const postData = fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/cart/estimate/${id}/confirm`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((res) => {
        if (res.status === 200) {
          let newCartItems = _.filter(user.solicitacoes, (item) => !_.includes([id], item.ambulatorio_orcamento_id));
          console.log(newCartItems);
          if (newCartItems.length === 0) {
            setUser((prevState) => ({ ...prevState, solicitacoes: {} }));
          } else {
            setUser((prevState) => ({ ...prevState, solicitacoes: { ...newCartItems } }));
          }
        }
      });
    }
  };
  const useItem = state.item.map((procedimento) => {
    return (
      <>
        <chakra.p>{procedimento.nome}</chakra.p>
      </>
    );
  });

  const usePreparo = state.item.map((procedimento) => {
    return procedimento && procedimento.preparo === true ? (
      <>
        <Text mt="1%" mb="1%" fontWeight="bold">
          {procedimento.nome}:
        </Text>
        <Text>{procedimento && procedimento.preparo_descricao ? procedimento.preparo_descricao : ''}</Text>
      </>
    ) : (
      <></>
    );
  });

  return (
    <>
      {user.loggedIn ? <ChildHeader /> : <NoUserHeader />}
      <VStack
        w={{
          base: '90%',
          md: '500px',
        }}
        m="auto"
        alignItems="left"
        spacing={1}
        marginBottom="10%"
      >
        <chakra.h1 fontWeight="bold" margin="5% 0" fontSize="xl">
          Informações do Pedido:
        </chakra.h1>
        <chakra.p fontWeight="bold"></chakra.p>
        <chakra.p color="gray.500">paciente:</chakra.p>
        <chakra.p fontWeight="bold">{state.paciente.nome.toUpperCase()}</chakra.p>
        <chakra.p color="gray.500">procedimentos:</chakra.p>
        <chakra.p fontWeight="bold">{useItem}</chakra.p>
        <chakra.p color="gray.500">Data:</chakra.p>
        <chakra.p fontWeight="bold">
          {state.data.toLocaleDateString('pt-br', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}{' '}
          {state.isByOrder ? <></> : state.horario ? ' às ' + state.horario : ''}
        </chakra.p>
        {state.isByOrder ? <chakra.p fontWeight="bold">(atendimento por ordem de chegada)</chakra.p> : <></>}
        <chakra.p color="gray.500">Local:</chakra.p>
        <chakra.p fontWeight="bold">{state.empresa.empresa_nome}</chakra.p>
        <chakra.p color="black.500" fontSize="xs">
          {' '}
          {state.empresa.endereco}
        </chakra.p>

        {user.id_contrato > 0 ? (
          <>
            <chakra.p color="gray.500">valor particular:</chakra.p>
            <chakra.p fontWeight="bold">
              <chakra.del>{(state.valor + state.economia).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</chakra.del>
            </chakra.p>
            <chakra.p color="gray.500">valor DeoVita:</chakra.p>
            <chakra.p fontWeight="bold" color="#529C94">
              {state.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
            </chakra.p>
            <chakra.p fontWeight="bold" color="#529C94">
              Economia de {state.economia.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} reais neste agendamento.
            </chakra.p>
          </>
        ) : (
          <>
            <chakra.p color="gray.500">valor:</chakra.p>
            <chakra.p fontWeight="bold">{state.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</chakra.p>
          </>
        )}
        <Container maxW="2xl" leftContent>
          <Box padding="1" borderRadius="md" borderWidth="1px" margin="5% 0" bcolor="black" maxW="sm">
            <chakra.p color="black">Orientações:</chakra.p>
            <chakra.p color="red.500" margin="5% 0" fontSize="sm">
              {usePreparo}
            </chakra.p>
          </Box>
        </Container>

        <ButtonGroup pt="1rem" pb="15%" width="100%">
          <Button
            colorScheme="teal"
            width="100%"
            onClick={() => {
              confirmEstimate(state.orcamento_id);
              console.log(useItem);
              if (!state.valor || state.valor === 0) {
                setUser((prevState) => ({ ...prevState, cart_temp: { itens: state.item } }));
                navigate('/exames/loading/', {
                  state: {
                    ...state,
                    valor: 0,
                    item: state.item,
                    paciente: state.paciente,
                    empresa: state.empresa,
                    horario: state.horario,
                    convenio: state.convenio,
                    data: state.data,
                    confim: true,
                    transaction_id: 9999999,
                    isByOrder: state.isByOrder,
                  },
                });
              } else {
                //setUser((prevState) => ({ ...prevState, cart: null }));
                setUser((prevState) => ({ ...prevState, cart_temp: { itens: state.item } }));
                navigate('/pagamentos', {
                  state: {
                    ...state,
                    parcelsActive: true,
                    valor: state.valor,
                    expiration_pix_in_min: 5,
                    service: 'schedule_procedure',
                    navig_to: '/alerts/sucesso/agendamento-exame/',
                    item: {
                      valor: state.valor,
                      cpf: onlyNumbers(state.paciente.cpf),
                      nome: state.paciente.nome,
                      fone: state.paciente.celular,
                      email: state.paciente.email,
                      horario: state.horario,
                      empresa: state.empresa,
                      convenio: state.convenio,
                      data: state.data,
                      cart: state.item,
                      confim: true,
                      isByOrder: state.isByOrder,
                    },
                  },
                });
              }
            }}
          >
            Ir para o pagamento
          </Button>
        </ButtonGroup>
      </VStack>
      <BottomNavBar />
    </>
  );
}

export default ConferePedido;
