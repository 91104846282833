import { Button, Heading, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, VStack } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from 'components/AccountContext';
import { useContext } from 'react';

function AvisoDependentes(props) {
  const navigate = useNavigate();
  console.log(props);
  const disclosure = props.disclosure;
  const { user } = useContext(AccountContext);
  return (
    <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose} isCentered>
      <ModalOverlay />
      <ModalContent w="80%" border-radius="16px">
        <ModalHeader></ModalHeader>
        <ModalBody>
          <Heading size="md" ml="3" mt="15">
            Você atingiu o limite de {user.qtde_max_dependentes ? user.qtde_max_dependentes : 0} dependentes incluso no seu plano. Para adicionar mais
            dependentes é necessário pagar uma taxa de R$ {user.valor_adicional ? user.valor_adicional : 0} a mais na mensalidade por dependente
            extra. Deseja prosseguir?
          </Heading>
        </ModalBody>

        <ModalFooter>
          <Stack direction="row" spacing={5} align="center">
            <Button
              colorScheme="teal"
              size="lg"
              onClick={() => {
                navigate('/perfil/dependentes/cadastro', {
                  state: { id_cliente_deovita: user.id_cliente_deovita, id_contrato: user.id_contrato, extra: true },
                });
              }}
            >
              Sim
            </Button>
            <Button
              variant="solid"
              size="lg"
              onClick={() => {
                disclosure.onClose();
              }}
            >
              Não
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default AvisoDependentes;
