import {
  Box,
  Image,
  Spacer,
  Text,
  ButtonModal,
  Stack,
  ModalOverlay,
  Modal,
  Button,
  useDisclosure,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Center,
} from '@chakra-ui/react';
import comissoesestebelecimento from '../../../../../images/assets/icons/iconesEmprendedor/comissoesicone.png';
import { useState } from 'react';
import { BsYoutube } from 'react-icons/bs';

export default function CardYoutube(props) {
  function BasicUsage() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
      <>
        <Box
          maxW="160"
          h="170"
          padding="10%"
          borderRadius="24"
          boxShadow="lg"
          textAlign="center"
          opacity="0.8"
          bgColor="#F12900"
          onClick={() => (window.location.href = 'https://www.youtube.com/@deovitasaude4641')}
        >
          <Box marginTop="0.2em" marginBottom="0.8em" onClick={onOpen}>
            <BsYoutube color="white" fontSize="2em" />
            <Text fontSize="xs" color="white" textAlign="start" fontWeight="bold" mt="3">
              Acompanhe nosso canal no YouTube
            </Text>
            <Text color="white" fontSize="xs" mt="7">
              @deovitasaude
            </Text>
          </Box>
        </Box>
        <Center></Center>
      </>
    );
  }
  return (
    <div className="App">
      <BasicUsage />
    </div>
  );
}
