import {
  VStack,
  Heading,
  Button,
  Text,
  HStack,
  Checkbox,
  Link,
  Wrap,
  chakra,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Drawer,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import React, { useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import TextField from '../TextField';
import ChangeTitle from '../ChangeTitle';
import { AccountContext } from '../AccountContext';
import PageBack from '../PageBack';
import Contrato from '../../termos/ContratoAdesao.pdf';
import NoUserHeader from '../AreaLivre/NoUserHeader';
import requestTimeout from '../Utils/Functions/requestTimeout';
import Pdf from '../PdfViewer';

const Conferencia = () => {
  ChangeTitle('Cadastro');
  const navigate = useNavigate();
  const { setUser, setPage } = useContext(AccountContext);
  const [termos, setTermos] = useState(false);
  const { state } = useLocation();
  const [error, setError] = useState(null);
  console.log(state);
  const timeOutInSeconds = process.env.REACT_APP_REQUEST_TIMEOUT_IN_SECONDS;
  setPage('Cadastro');
  const sexo_nome = () => {
    if (state.sexo === 'F') {
      return 'feminino';
    } else {
      return 'masculino';
    }
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = React.useRef(null);

  function renderPage() {
    if (error === 'Seu cadastro já foi finalizado, faça login para continuar') {
      return (
        <>
          <Button colorScheme="teal" w="100%" onClick={() => navigate('/login')}>
            Ir para login
          </Button>
        </>
      );
    } else {
      return (
        <>
          <TextField name="nome" label="Nome Completo" isDisabled={true} />
          <HStack>
            <TextField name="cpf" label="CPF" isDisabled={true} />
            <TextField name="sexo" label="Sexo" isDisabled={true} />
          </HStack>
          <HStack>
            <TextField name="nascimento" label="Nascimento" isDisabled={true} />
            <TextField name="fone" label="Telefone" isDisabled={true} />
          </HStack>
          <TextField name="email" label="E-mail" isDisabled={true} />
          <TextField name="endereco" label="Endereço" isDisabled={true} />
          <HStack>
            <Checkbox
              name="termos"
              onChange={(e) => {
                if (!termos) {
                  setTermos(true);
                } else {
                  setTermos(false);
                }
              }}
            ></Checkbox>

            <chakra.a pl="10px" colorScheme="teal" onClick={onOpen}>
              Li e aceito os <u>termos de uso</u> e de <u>adesão do aplicativo</u>.
            </chakra.a>
          </HStack>
          <Button colorScheme="teal" w="100%" type="submit" isDisabled={!termos}>
            Cadastrar
          </Button>
        </>
      );
    }
  }

  return (
    <>
      <Drawer isCentered isFullHeight size="md" isOpen={isOpen} onClose={onClose} placement="bottom">
        <DrawerContent isCentered nbgColor="rgba(90, 178, 173, 0.7)" pt="10%">
          <Pdf file={Contrato} />
          <DrawerCloseButton color="red" borderColor="red" pt="15%" />
        </DrawerContent>
      </Drawer>
      <NoUserHeader />
      <Formik
        initialValues={{
          cpf: state.cpf,
          nome: state.nome,
          email: state.email,
          fone: state.fone,
          nascimento: state.nascimento,
          sexo: sexo_nome(),
          endereco: `${state.endereco}, ${state.numero}, ${state.complemento}, ${state.bairro} - ${state.cidade}, ${state.estado} - CEP: ${state.cep}`,
        }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values, actions) => {
          let split_nascimento = state.nascimento.split('/');
          let clientData = [
            {
              nome: state.nome,
              cod_ibge: state.code_ibge,
              cep: state.cep,
              logradouro: state.endereco,
              numero: state.numero,
              complemento: state.complemento,
              bairro: state.bairro,
              sexo: state.sexo,
              nascimento: new Date(split_nascimento[2], split_nascimento[1] - 1, split_nascimento[0]),
              cpf: state.cpf,
              celular: state.fone,
              email: state.email,
              senha: state.senha,
            },
          ];
          fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/clients`, {
            signal: requestTimeout(timeOutInSeconds).signal,
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(clientData),
          })
            .catch((err) => {
              return;
            })
            .then((res) => {
              if (!res || !res.ok || res.status >= 400) {
                setError('Não foi possível concluir seu cadastro no momento');
                return;
              }
              return res.json();
            })
            .then((data) => {
              if (!data) return;
              let returnRequest = data[0];
              if (returnRequest.success) {
                let client = returnRequest.data;
                navigate('/cadastro/plano', {
                  state: {
                    valor: state.valor,
                    service: state.service,
                    client_id: client.paciente_id,
                    name: state.nome,
                    cpf: state.cpf,
                    senha: state.senha,
                    phone: state.fone,
                  },
                });
              } else {
                if (returnRequest.data.error.substring(0, 3) === 'cpf') {
                  setError('Seu cadastro já foi finalizado, faça login para continuar');
                } else {
                  setError('Não foi possível concluir seu cadastro no momento');
                }
              }
            });
        }}
      >
        <VStack as={Form} w={{ base: '90%', md: '500px' }} m="auto" paddingBottom="5%" justify="top" marginTop="5%" spacing="1rem" align="start">
          <Heading fontSize="1em">Vamos conferir se os dados foram preenchidos corretamente.</Heading>
          <Text as="p" color="red.500">
            {error}
          </Text>
          {renderPage()}
        </VStack>
      </Formik>
    </>
  );
};

export default Conferencia;
