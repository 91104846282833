import { ArrowForwardIcon } from '@chakra-ui/icons';
import {
  chakra,
  Box,
  Img,
  LinkBox,
  Text,
  VStack,
  Checkbox,
  Image,
  RadioGroup,
  Stack,
  Radio,
  Spinner,
  Heading,
  useDisclosure,
} from '@chakra-ui/react';
import { AccountContext } from 'components/AccountContext';
import BottomNavBar from 'components/BottomNavBar';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PetsImg from '../../../../../images/assets/telemedicina/pets.png';
import BackButton from '../../../../../images/assets/telemedicina/backbutton.svg';
import SelectField from 'components/SelectField';
import { Form, Formik } from 'formik';
import PopupDependenteSemCpf from '../../Atendimento/PopupDependenteSemCpf';

function Main() {
  const { user } = useContext(AccountContext);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showText, setShowText] = useState('');
  const [value, setValue] = useState(null);
  const [dependentes, setDependentes] = useState(false);
  const arrayNomeDependentes = dependentes ? dependentes.map((dependente) => dependente.nome) : [];
  const [client, setClient] = useState();
  const navigate = useNavigate();
  const [accept, setAccepted] = useState();
  const [isDisabled, setDisabled] = useState(false);
  const disclosure = useDisclosure();
  const { state } = useLocation();
  const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');

  const registerAppointment = async () => {
    const resposta = await fetch(
      `${process.env.REACT_APP_BASE_URL_API_SERVER}/rapidoc/appointment-insert/${state && state.especialista ? 'especialista' : 'clinico'}/${
        dependentes[value].cpf
      }`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    resposta.json().then((res) => {
      if (res.success) {
        console.log('sucesso');
      }
    });
  };

  async function geturl() {
    setLoading(true);

    const resposta = await fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/rapidoc/url/${dependentes[value].paciente_id}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
    });
    resposta.json().then((res) => {
      if (res.success) {
        registerAppointment(onlyNumbers(dependentes[value].cpf));
        window.location = res.data;
        //navigate('/pa/atendimento', {state:{rapidocUrl: res.data}})
      } else {
        setLoading(false);
        setDisabled(false);
        setError('Não foi possível realizar seu atendimento no momento, tente novamente mais tarde ou entre em contato com o suporte');
      }
    });
  }

  const fetchDependentes = async () => {
    const url2 = process.env.REACT_APP_BASE_URL_API_SERVER + '/client/dependents2';
    const titularId = {
      id_contrato: user.id_contrato,
    };
    const response = await fetch(url2, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(titularId),
    });
    const resp = await response.json();
    if (resp.data[0] !== null) {
      setDependentes(resp.data);
    } else {
      setDependentes([{ nome: user.name, paciente_id: user.id_cliente_deovita, ...user }]);
      setValue(0);
      console.log(dependentes);
    }
    console.log(resp.data);
  };

  useEffect(() => {
    fetchDependentes();
  }, []);

  return dependentes ? (
    <>
      <VStack
        align="center"
        justify={{
          base: 'center',
          md: 'space-around',
          xl: 'space-between',
        }}
        direction={{ base: 'column-reverse' }}
        wrap="no-wrap"
        minH="100vh"
        spacing={0}
        background="linear-gradient(180deg, #EB8B2A 0%, #EFA73F 28.65%, #5AB2AD 64.06%, #4E9E8F 100%);"
      >
        <PopupDependenteSemCpf disclosure={disclosure} datas={dependentes[value]} />
        <Image top="2.5em" left="1.3em" zIndex="9999" position="absolute" src={BackButton} onClick={() => navigate(-1)} />
        <Box
          mt="1"
          id=""
          as="card"
          width="328px"
          borderRadius="18px"
          fontSize="xs"
          size="10"
          backgroundColor="white"
          maxInlineSize="80"
          textAlign="center"
        >
          <VStack m="5%" textColor="#585858">
            <Text fontWeight="bold" fontSize="1.5em">
              Selecione o assinante que deseja atendimento.
            </Text>

            {showText && !error ? (
              <Text fontSize="xs" color="green">
                Isso pode demorar alguns segundos por favor aguarde. Você será redirecionado ao atendimento com o médico em breve.
              </Text>
            ) : (
              <Text color="red">{error}</Text>
            )}

            <Formik initialValues={{ dependente: user.titular === true ? null : 0 }} validateOnChange={false} validateOnBlur={false}>
              <VStack as={Form} w={{ base: '100%', md: '500px' }} m="auto" padding="5%" justify="top" overflow="hidden" align="start">
                <SelectField
                  name="dependente"
                  placeholder="Selecione"
                  label="Paciente:"
                  w="100%"
                  variant="outline"
                  textAlign="left"
                  fontWeight="normal"
                  onChange={(e) => {
                    if (dependentes[e].cpf) {
                      setError(null);
                      setShowText(false);
                      setValue(e);
                    } else {
                      setValue(e);
                      disclosure.onOpen();
                    }
                  }}
                  options={arrayNomeDependentes}
                />
              </VStack>
            </Formik>
            {!loading ? (
              <Text
                textAlign="center"
                id="callBtn"
                color={user.titular === true ? (dependentes && value ? '#529C94' : '#D3D3D3') : '#529C94'}
                fontWeight="bold"
                fontSize="1.5em"
                mt="0.5em"
                onClick={() => {
                  if (user.titular === true) {
                    if (dependentes && value) {
                      geturl();
                      setError(null);
                      setShowText(true);
                      setDisabled(true);
                    }
                  } else {
                    geturl();
                    setError(null);
                    setShowText(true);
                    setDisabled(true);
                  }
                }}
              >
                Seguir
                <chakra.span>
                  <ArrowForwardIcon fontSize="0.9em" />
                </chakra.span>
              </Text>
            ) : (
              <>
                <Text textAlign="center" fontSize="1.5em" mt="0.5em">
                  carregando <Spinner size="sm" />
                </Text>
              </>
            )}
          </VStack>
        </Box>
      </VStack>
      <BottomNavBar />
    </>
  ) : (
    <>
      <VStack
        align="center"
        justify={{
          base: 'center',
          md: 'space-around',
          xl: 'space-between',
        }}
        direction={{ base: 'column-reverse' }}
        wrap="no-wrap"
        minH="100vh"
        spacing={0}
        background="linear-gradient(180deg, #EB8B2A 0%, #EFA73F 28.65%, #5AB2AD 64.06%, #4E9E8F 100%);"
      >
        <Image top="2.5em" left="1.3em" zIndex="9999" position="absolute" src={BackButton} onClick={() => navigate(-1)} />
        <Box
          mt="1"
          id=""
          as="card"
          width="328px"
          minH="238px"
          borderRadius="18px"
          fontSize="xs"
          size="10"
          backgroundColor="white"
          maxInlineSize="80"
          textAlign="center"
        ></Box>
      </VStack>
      <BottomNavBar />
    </>
  );
}

export default Main;
