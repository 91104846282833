import { DeleteIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Flex,
  LinkBox,
  Select,
  Stack,
  Text,
  Button,
  VStack,
  Box,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogFooter,
  AlertDialogContent,
  Center,
  chakra,
  Heading,
  HStack,
  Badge,
  Divider,
  TableContainer,
  Table,
  Tr,
  Td,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccountContext } from '../../../AccountContext';
import BottomNavBar from '../../../BottomNavBar';
import ChildHeader from '../ChildHeader';
import SelectField from 'components/SelectField';
import NoUserHeader from 'components/AreaLivre/NoUserHeader';
import DrawerCompraPlano from '../DrawerCompraPlano';
import DrawerServico from '../DrawerServico';

export default function ResumoPagamento() {
  const dataAtual = new Date();
  const { user, setPage } = useContext(AccountContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { cart, empresa } = state;
  const [disableButton, setDisableButton] = useState(false);
  const [dependentes, setDependentes] = useState([]);
  const arrayNomeDendente = dependentes.map((dependente) => dependente.nome);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [pac, setPac] = useState(0);
  const [badge, setBadge] = useState(false);
  const [pag, setPag] = useState(0);
  const [turno, setTurno] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState('');
  const disclosure = useDisclosure();
  const scheduleDrawer = useDisclosure();
  let valorParcial = 0;

  setPage('Resumo da compra');
  const getDaysArray = function (s, e) {
    for (var a = [], d = new Date(s); d <= new Date(e); d.setDate(d.getDate() + 1)) {
      if (d.getDay() !== 0 && d.getDay() !== 7) {
        a.push(new Date(d));
      }
    }
    return a;
  };

  const dateInterval = getDaysArray(
    dataAtual.getHours() > '15' ? new Date().setDate(new Date().getDate() + 1) : new Date(),
    new Date(new Date().setDate(new Date().getDate() + 30))
  );

  useEffect(() => {
    const fetchDependentes = async () => {
      const url2 = process.env.REACT_APP_BASE_URL_API_SERVER + '/client/dependents2';
      const titularId = {
        id_contrato: user.id_contrato,
      };
      const response = await fetch(url2, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(titularId),
      });
      const resp = await response.json();
      if (resp.data[0] !== null) {
        setDependentes(resp.data);
      } else {
        setDependentes([{ nome: user.name, paciente_id: user.id_cliente_deovita, ...user }]);
        console.log(dependentes);
      }
      console.log(resp.data);
    };
    fetchDependentes();
  }, []);

  const useDatas = dateInterval.map((date, index) => {
    const options = { month: 'short', day: 'numeric' };
    const dataIcon = date.toLocaleDateString('pt-br', options).replace(' de ', ' ').replace('.', '').split(' ');

    return (
      <>
        <Td
          id={`td${index}`}
          borderRadius="15px"
          p="0.5em"
          fontSize="xl"
          textAlign="center"
          class="margin-td"
          onClick={() => {
            document.getElementById('td' + index).scrollIntoView({ behavior: 'smooth' });
            document.querySelectorAll(`[class="chakra-stack css-1px2q30"]`).forEach((item) => {
              item.style.border = 'none';
            });
            document.querySelectorAll(`[class="chakra-badge css-raqg5x"]`).forEach((item) => {
              item.style.border = 'none';
            });
            document.getElementById('date' + index).style.border = '1px solid orange';
            setPag(index);
          }}
        >
          <HStack
            id={`date${index}`}
            backgroundColor="rgba(90, 178, 173, 0.25)"
            borderRadius="15px"
            style={pag === index ? { border: '1px solid orange' } : {}}
          >
            <Text width="60%">
              <Flex>
                <Box backgroundColor="rgba(90, 178, 173, 0.3)" borderRadius="5px" margin="1em" color="rgba(62, 100, 255, 1)" padding="0 1em">
                  <Text fontWeight="bold" fontSize="xl">
                    {dataIcon[0]}
                  </Text>
                  <Text fontWeight="bold" fontSize="sm" mt="-1">
                    {dataIcon[1].toUpperCase()}
                  </Text>
                </Box>
                <Text margin="11% 0" pr="1em" fontSize="xs" align="left">
                  <chakra.p fontWeight="bold">{'EXAME'}</chakra.p>
                  {date.toLocaleString('pt-br', { weekday: 'long' })}
                </Text>
              </Flex>
            </Text>
          </HStack>
        </Td>
      </>
    );
  });

  const TableItens = () => {
    return (
      <>
        <VStack spacing={3} w="100%" align="start">
          <Heading fontSize="md" color="#585858">
            Procedimento(s):
          </Heading>
          <Text color="red.500">{error}</Text>

          <Box width="full" spacing={2}>
            {cart.map((item, index) => {
              if (item.valor) {
                valorParcial += parseFloat(item.valor);
              }

              console.log(valorParcial);
              return (
                <>
                  <HStack>
                    <Text w="60%" color="#000000" noOnline={3}>
                      {item.nome}
                    </Text>
                    <Text color="#529C94" w="30%">
                      R$ {item.valor ? item.valor : '0,00'}
                    </Text>
                    <DeleteIcon
                      color="#EB8B2A"
                      onClick={() => {
                        setCurrentIndex((prevState) => prevState + 1);
                        cart.splice(index, 1);
                      }}
                    />
                  </HStack>
                  <Divider />
                </>
              );
            })}

            <HStack mt="5%">
              <Text w="60%" fontSize="md" fontWeight="bold">
                Valor Parcial
              </Text>
              <Text w="30%" fontSize="md" color="#529C94" fontWeight="bold">
                R$ {valorParcial ? valorParcial.toString().replace('.', ',') : '0,00'}
              </Text>
            </HStack>
          </Box>
        </VStack>
      </>
    );
  };

  let botaoManha = '';
  let botaoTarde = '';

  if (dataAtual.toDateString() === dateInterval[pag].toDateString() && dataAtual.getHours() > '12') {
    console.log('as datas são iguais e já passou do meio dia');

    botaoManha = '';
  } else {
    botaoManha = (
      <Badge
        id="t1"
        margin="0 0.5em"
        padding="0.5em 1.4em"
        size="lg"
        fontSize="md"
        bgColor="#F0F0F0"
        borderRadius="15px"
        shadow="md"
        onClick={() => {
          document.getElementById('t1').style.border = '2px solid #529C94';
          document.getElementById('t2').style.border = 'none';
          setTurno('manhã');
          setDisabled(false);
        }}
      >
        Manhã
      </Badge>
    );
  }

  return (
    <>
      {user.loggedIn ? <ChildHeader /> : <NoUserHeader />}
      <DrawerCompraPlano disclosure={disclosure} />
      <DrawerServico disclosure={scheduleDrawer} />
      <Formik initialValues={{ dependente: 0 }} validateOnChange={false} validateOnBlur={false}>
        <VStack as={Form} w="90%" m="auto" justify="top" mt="5%" mb="25%" spacing="2rem" align="center">
          <VStack w="full" spacing={3} align="start">
            {user.titular === true && (
              <>
                <chakra.h1 fontWeight="bold" color="#585858">
                  Paciente:
                </chakra.h1>
                <LinkBox
                  id=""
                  as="card"
                  p="3"
                  borderWidth="2px"
                  borderColor="green"
                  borderRadius="15px"
                  bgColor="#F0F0F0"
                  shadow="lg"
                  fontSize="xs"
                  width="100%"
                >
                  <Heading size="sm" my="2">
                    <SelectField
                      name="dependente"
                      colorScheme="teal"
                      border="green"
                      color="#585858"
                      borderRadius="15px"
                      borderWidth="2px"
                      variant="ghost"
                      fontWeight="bold"
                      onChange={setPac}
                      options={arrayNomeDendente}
                    />
                  </Heading>
                </LinkBox>
              </>
            )}
            <chakra.h1 fontWeight="bold" color="#585858">
              {empresa.empresa}
            </chakra.h1>
            <chakra.h2 color="#585858" fontSize="sm" fontWeight="normal">
              {empresa.endereco}, {empresa.numero} - {empresa.bairro}
            </chakra.h2>
            <chakra.h2 color="#585858" fontSize="sm" fontWeight="normal">
              {empresa.municipio} / {empresa.estado}
            </chakra.h2>
            <Divider />
          </VStack>
          <TableItens />
          <VStack w="full" spacing={3} align="start">
            <chakra.h1 fontWeight="bold">Turno:</chakra.h1>{' '}
            <Stack paddingInlineStart={12} direction="row" spacing={4}>
              {botaoManha}
              <Badge
                id="t2"
                margin="0 0.5em"
                padding="0.5em 1.4em"
                size="lg"
                fontSize="md"
                bgColor="#F0F0F0"
                borderRadius="15px"
                shadow="md"
                onClick={() => {
                  document.getElementById('t2').style.border = '2px solid #529C94';
                  if (botaoManha !== '') {
                    document.getElementById('t1').style.border = 'none';
                  }
                  setTurno('tarde');
                  setDisabled(false);
                }}
              >
                Tarde
              </Badge>
            </Stack>
            <chakra.h1 fontWeight="bold">Dia:</chakra.h1>
            <TableContainer minWidth="100%">
              <Table variant="simple">
                <Tr>{useDatas}</Tr>
              </Table>
            </TableContainer>
          </VStack>
          <Button
            isFullWidth
            colorScheme="teal"
            isDisabled={disabled}
            onClick={() => {
              if (user.loggedIn === true) {
                if (Object.keys(cart).length > 0) {
                  if (!valorParcial || valorParcial === 0) {
                    navigate('/exames/finalizar/', {
                      state: {
                        valor: 0,
                        item: cart,
                        paciente: dependentes[pac],
                        empresa: empresa,
                        turno: turno,
                        service: 'schedule_procedure',
                        data: dateInterval[pag],
                      },
                    });
                  } else {
                    navigate('/exames/finalizar/', {
                      state: {
                        valor: valorParcial,
                        turno: turno,
                        empresa: empresa,
                        data: dateInterval[pag],
                        navig_to: '/exames/loading/',
                        item: cart,
                        service: 'schedule_procedure',
                        paciente: dependentes[pac],
                      },
                    });
                  }
                } else {
                  setError('É necessario ter adicionado ao menos um procedimento para prosseguir com o agendamento.');
                }
              } else {
                disclosure.onOpen();
              }
            }}
          >
            Continuar
          </Button>
        </VStack>
      </Formik>
    </>
  );
}
