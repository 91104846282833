import React, { useContext } from 'react';
import NoUserHeader from '../AreaLivre/NoUserHeader';
import { Text, Button, VStack, Heading, Stack, HStack, chakra, Center, useColorModeValue, Alert, AlertIcon, Box, Container } from '@chakra-ui/react';

import BottomNavBar from 'components/BottomNavBar';

import { AccountContext } from 'components/AccountContext';

import { Icon } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';

function PagamentoEmAnalise() {
  const { user, setPage, setUser } = useContext(AccountContext);
  const { state } = useLocation();
  const navigate = useNavigate();
  console.log(state);
  console.log(user);
  setPage('Contratação DeoVita');
  const arrName = state.name ? state.name.split(' ') : user.name.split(' ');

  const Vector_2 = (props) => (
    <Icon viewBox="0 0 658 599" {...props}>
      <path
        fill="currentColor"
        d="M581.453 1.021c-.234.068-.468.146-.698.23L149.009 75.64a14.93 14.93 0 00-11.896 8.162c-.172.385-.328.77-.469 1.166l-.234.235c-.094.385-.167.776-.229 1.166a14.927 14.927 0 00-.704 4.198v195.416C59.628 296.916.891 362.525.891 441.291c0 86.369 70.588 156.946 156.973 156.946 83.921 0 152.88-66.5 156.747-149.48h298.56-.005c8.244 0 14.926-6.682 14.926-14.921V329.363h14.927c8.245 0 14.927-6.683 14.932-14.927V209.963v.005c-.005-8.245-6.688-14.927-14.932-14.927h-14.927V90.568v.005c0-8.245-6.682-14.926-14.926-14.926h-14.927V15.949a14.925 14.925 0 00-16.797-14.922l.011-.006zm-13.062 32.646V75.64h-243.52l243.52-41.973zm-403.054 71.822h432.907v89.547H419.111c-26.287 0-44.787 21.86-44.787 44.776v44.776c0 22.912 18.5 44.776 44.787 44.776h179.133v89.547H313.217c-10.588-73.454-72.406-130.803-147.88-134.32V105.489zm253.774 119.396h209v74.626h-209c-8.865 0-14.927-8.901-14.927-14.922v-44.776c0-6.026 6.068-14.926 14.927-14.926v-.002zm22.39 14.927c-12.364 0-22.39 10.021-22.39 22.385 0 12.365 10.026 22.386 22.39 22.386 12.365 0 22.391-10.021 22.391-22.386 0-12.364-10.026-22.385-22.391-22.385zm-283.626 74.855c70.254 0 126.656 56.385 126.656 126.625 0 70.24-56.402 126.625-126.656 126.625-70.255 0-126.656-56.385-126.656-126.625 0-70.24 56.401-126.625 126.656-126.625zm0 22.15c-57.536 0-104.495 46.948-104.495 104.474 0 57.521 46.964 104.473 104.495 104.473 57.536 0 104.494-46.953 104.494-104.473 0-57.52-46.964-104.474-104.494-104.474zm0 29.85a74.406 74.406 0 0174.64 74.625 74.407 74.407 0 01-74.64 74.625 74.407 74.407 0 01-74.64-74.625 74.407 74.407 0 0174.64-74.625zm-1.631 14.693v.005c-7.692.844-13.464 7.422-13.296 15.156v44.776-.005c0 8.245 6.687 14.927 14.926 14.927h29.86c4 .062 7.854-1.485 10.703-4.292a14.919 14.919 0 000-21.266 14.918 14.918 0 00-10.703-4.292h-14.926V396.52a14.958 14.958 0 00-4.849-11.333 14.919 14.919 0 00-11.714-3.823l-.001-.004z"
      />
    </Icon>
  );

  return (
    <>
      <NoUserHeader />
      <VStack
        align="center"
        justify={{
          base: 'center',
          md: 'space-around',
          xl: 'space-between',
        }}
        direction={{ base: 'column-reverse' }}
        wrap="no-wrap"
        spacing={8}
        height="full"
        px={6}
        py={1}
      >
        <Container maxW={'3xl'}>
          <Stack as={Box} textAlign={'center'} spacing={{ base: 5, md: 10 }} py={{ base: 5, md: 30 }}>
            <Heading fontWeight={600} fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }} lineHeight={'110%'}>
              Pagamento <br />
              <Text as={'span'} textAlign="justify" color={'teal'}>
                Em Análise
              </Text>
              <Center>
                {' '}
                <Vector_2 boxSize={40} mt="3" />
              </Center>
            </Heading>

            <Heading>Olá, {arrName[0]}!</Heading>

            <Center>
              <Text color={'gray.700'} fontSize="sm">
                {' '}
                Seu pagamento está sendo processado. Aguarde a conclusão do pagamento para acessar o aplicativo! Você receberá uma notificação via
                whatsapp quando o pagamento for confirmado.
              </Text>
              <Text color={'gray.500'}> </Text>
              <VStack alignItems="right"> </VStack>
            </Center>
            <Stack direction={'column'} align={'center'} alignSelf={'center'} position={'relative'}>
              <Text textAlign="center" fontSize="0.9em" fontWeight="bold">
                Problemas com pagamento?
              </Text>
              <Button colorScheme={'teal'} px={10} variant="link" onClick={() => (window.location.href = 'https://wa.me/558881189898')}>
                Falar com Suporte
              </Button>
            </Stack>
          </Stack>
        </Container>
      </VStack>
    </>
  );
}

export default PagamentoEmAnalise;
