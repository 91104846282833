/* eslint-disable import/no-unresolved */
import * as Yup from 'yup';
import { useContext } from 'react';
import BottomNavBar from 'components/BottomNavBar';
import ChildHeader from 'components/Dashboard/Main/ChildHeader';
import validaCPF from 'components/Utils/Functions/validaCPF';
import Input from 'components/TextField';
import Select from 'components/SelectField';
import { Form, Formik } from 'formik';
import { Button, ButtonGroup, Heading, VStack } from '@chakra-ui/react';
import { AccountContext } from 'components/AccountContext';
import { useLocation, useNavigate } from 'react-router-dom';

function FormDadosResponsavel() {
  const { setPage } = useContext(AccountContext);
  const { state } = useLocation();
  const defaultArgs = { borderRadius: 8, w: '100%', mask: '', maskChar: null };
  const defaultArgsSelect = { textAlign: 'left', fontWeight: 'normal', variant: 'outline', onChange: () => {} };
  const initialValues = { nome: '', cpf: '', sexo: '', email: '', fone: '', celular: '', whatsapp: '' };
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    nome: Yup.string()
      .required('Campo obrigatório')
      .matches(/^[A-Za-z]+\s[A-Za-z ]+$/m, 'Escreva o nome e sobrenome pelo menos e sem acentos ou caracteres especiais'),
    cpf: Yup.string()
      .required('Campo obrigatório')
      .trim()
      .test('valida_cpf', 'Cpf inválido', (cpf) => validaCPF(cpf)),
    sexo: Yup.number().required('Campo obrigatório'),
    email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
    fone: Yup.string()
      .trim()
      .matches(/^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}-?[0-9]{4}$/, 'Telefone Inválido'),
    celular: Yup.string()
      .trim()
      .matches(/^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}-?[0-9]{4}$/, 'Telefone Inválido')
      .required('Campo obrigatório'),
    whatsapp: Yup.string()
      .trim()
      .matches(/^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}-?[0-9]{4}$/, 'Telefone Inválido')
      .required('campo obrigatório'),
  });

  const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');

  async function handleSubmit(values) {
    const sexoNome = values.sexo === 0 ? 'm' : 'f';
    const vals = { ...values, sexo: sexoNome, cpf: onlyNumbers(values.cpf), nextPage: '/empreendedor/cadastro-estabelecimento/fim' };
    navigate('/empreendedor/cadastro-estabelecimento/senha', { state: { ...state, ...vals } });
  }

  // eslint-disable-next-line no-nested-ternary
  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      <VStack as={Form} w={{ base: '90%', md: '500px' }} align="start" mx="auto" mt="5" mb="20" spacing={5}>
        <Heading size="sm">Infome os dados do responsável pela empresa</Heading>
        <Input {...defaultArgs} name="nome" placeholder="Nome completo" type="text" mask="" />
        <Input {...defaultArgs} name="cpf" placeholder="CPF" type="tel" mask="999.999.999-99" />
        <Select {...defaultArgs} {...defaultArgsSelect} name="sexo" placeholder="Selecione o sexo" options={['Masculino', 'Feminino']} />
        <Input {...defaultArgs} name="email" placeholder="E-mail" type="email" />
        <Input {...defaultArgs} name="fone" placeholder="Telefone" type="tel" mask="(99) 99999-9999" />
        <Input {...defaultArgs} name="celular" placeholder="Celular" type="tel" mask="(99) 99999-9999" />
        <Input {...defaultArgs} name="whatsapp" placeholder="Whatsapp" type="tel" mask="(99) 99999-9999" />
        <ButtonGroup w="100%">
          <Button {...defaultArgs} colorScheme="teal" onClick={() => navigate(-1)}>
            Voltar
          </Button>
          <Button {...defaultArgs} colorScheme="teal" type="submit">
            Continuar
          </Button>
        </ButtonGroup>
      </VStack>
    </Formik>
  );
}

function DadosResponsavel() {
  return (
    <>
      <ChildHeader />
      <FormDadosResponsavel />
      <BottomNavBar />
    </>
  );
}

export default DadosResponsavel;
