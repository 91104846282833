import BottomNavBar from '../../../BottomNavBar';
import React, { useState } from 'react';
import MainChildHeader from '../MainChildHeader';
import { AccountContext } from '../../../AccountContext';
import { useContext } from 'react';
import { Heading, chakra, VStack, Flex, Text, Box, HStack, useDisclosure, Button, Img, Stack } from '@chakra-ui/react';
import { DeleteIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import Carrinho from '../../../../images/carrinho.svg';
import _ from 'lodash';

function Main() {
  const navigate = useNavigate();
  const { user, setPage, setUser } = useContext(AccountContext);
  setPage('Carrinho de Compras');
  const { cart, solicitacoes } = user;

  const { itens, valor } = cart || {};

  const [currentIndex, setCurrentIndex] = useState();

  const refuseEstimate = (id) => {
    console.log(id);
    if (id > 0) {
      const postData = fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/cart/estimate/${id}/refuse`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((res) => {
        if (res.status === 200) {
          let newCartItems = _.filter(user.solicitacoes, (item) => !_.includes([id], item.ambulatorio_orcamento_id));
          console.log(newCartItems);
          if (newCartItems.length === 0) {
            setUser((prevState) => ({ ...prevState, solicitacoes: {} }));
          } else {
            setUser((prevState) => ({ ...prevState, solicitacoes: { ...newCartItems } }));
          }
          navigate('/alerts/sucesso/sucesso_solicitacoes');
        }
      });
    }
  };
  const useProcedimentos = cart?.itens?.map((item, index) => {
    return (
      <>
        <chakra.tr w="100%" borderBottom="1px solid" borderColor="#AEAEB2">
          <Flex mt="1em">
            <Text fontSize="xs" w="90%" color="#585858" alignSelf="flex-end">
              {item.nome}
            </Text>
            <DeleteIcon
              color="#EB8B2A"
              onClick={() => {
                const updatedItens = cart.itens.filter((cartItem) => cartItem !== item);
                if (updatedItens.length === 0) {
                  setUser((prevState) => ({ ...prevState, cart: null }));
                } else {
                  setUser((prevState) => ({ ...prevState, cart: { ...prevState.cart, itens: updatedItens } }));
                }
              }}
            />
          </Flex>
        </chakra.tr>
      </>
    );
  });
  console.log(solicitacoes.length);
  const useSolicitacoes = Object.keys(solicitacoes)?.map((index) => {
    const useProcedimentos = solicitacoes[index].itens?.map((item, index) => {
      return (
        <>
          <chakra.tr w="100%" borderBottom="1px solid" borderColor="#AEAEB2">
            <Flex mt="1em">
              <Text fontSize="xs" w="90%" color="#585858" alignSelf="flex-end">
                {item.nome}
              </Text>
            </Flex>
          </chakra.tr>
        </>
      );
    });

    return solicitacoes[index] && solicitacoes[index].itens ? (
      <>
        <Box
          w="95%"
          mx="auto"
          alignItems="center"
          overflow="hidden"
          border="1px solid"
          borderColor="gray.200"
          borderRadius="10px"
          padding="1em"
          marginTop={5}
          px={5}
          pt={5}
        >
          <Flex w="100%">
            <Stack fontSize="sm" w="100%">
              <Text fontSize="0.7em" color="#585858">
                solicitante:
              </Text>
              <Text fontSize="0.8em" fontWeight="bold">
                {solicitacoes[index].solicitante}
              </Text>
            </Stack>
            <Stack fontSize="sm">
              <Text fontSize="0.7em" color="#585858">
                Data da Solicitação:
              </Text>
              <Text fontSize="0.8em" fontWeight="bold">
                {new Date(solicitacoes[index].data_cadastro)?.toLocaleDateString('pt-br', {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </Text>
            </Stack>
          </Flex>
          <chakra.table w="100%">{useProcedimentos}</chakra.table>
          <Button
            mt="10%"
            width="100%"
            colorScheme="teal"
            onClick={() => {
              const destinationPage = '/exames/empresa';
              setUser((prevState) => ({ ...prevState, cart: { itens: solicitacoes[index].itens } }));
              navigate(destinationPage, {
                state: { solicitacao: true, cart: solicitacoes[index].itens, orcamento_id: solicitacoes[index].ambulatorio_orcamento_id },
              });
            }}
          >
            Fechar Pedido
          </Button>
          <Button
            mt="10%"
            width="100%"
            colorScheme="gray"
            onClick={() => {
              refuseEstimate(solicitacoes[index].ambulatorio_orcamento_id);
            }}
          >
            Recusar Solicitação
          </Button>
        </Box>
      </>
    ) : (
      <></>
    );
  });
  console.log(useSolicitacoes);
  return (
    <>
      <MainChildHeader />
      <VStack mt="5%">
        <chakra.h1 fontWeight="bold">Ultimo carrinho de compras</chakra.h1>
        {cart ? (
          <>
            <Box
              w="90%"
              mx="auto"
              alignItems="center"
              overflow="hidden"
              border="1px solid"
              borderColor="gray.200"
              borderRadius="10px"
              padding="1em"
              marginTop={5}
              px={5}
              pt={5}
            >
              <chakra.h2 marginTop="5%" fontWeight="bold">
                PROCEDIMENTOS
              </chakra.h2>
              <chakra.table w="100%">{useProcedimentos}</chakra.table>
              <Button
                mt="10%"
                width="100%"
                colorScheme="teal"
                onClick={() => {
                  const destinationPage = '/exames/empresa';
                  navigate(destinationPage, {
                    state: { cart: user.cart.itens },
                  });
                }}
              >
                Fechar Pedido
              </Button>
              <Button
                mt="10%"
                width="100%"
                colorScheme="gray"
                onClick={() => {
                  setUser((prevState) => ({ ...prevState, cart: null }));
                }}
              >
                Esvaziar Carrinho
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Img boxSize="5em" src={Carrinho} />
            <chakra.h1 fontWeight="bold">Carrinho vazio!</chakra.h1>
            <chakra.h2 fontColor="#585858" w="55%" align="center">
              Você não possue nenhum agendamento pendente.
            </chakra.h2>
          </>
        )}
      </VStack>
      <VStack pb="25%">
        {solicitacoes && (
          <>
            <chakra.h1 fontWeight="bold" paddingTop="10%">
              Solicitações
            </chakra.h1>
            <VStack>{useSolicitacoes}</VStack>
            {(Object.keys(solicitacoes).length === 0 || !Object.keys(solicitacoes).length) && <>Nenhuma solicitação disponivel no momento</>}
          </>
        )}
      </VStack>

      <BottomNavBar name="carrinho" />
    </>
  );
}

export default Main;
