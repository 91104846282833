import { HStack, chakra, Box, Badge } from '@chakra-ui/react';
import React from 'react';
import { MdOutlineOpenInBrowser } from 'react-icons/md';

function Operations(operations) {
  console.log(operations.data);
  const { type, date_created, amount, fee, movement_object } = operations.data;
  let tipo;
  if (type === 'payable') {
    tipo = 'Entrada';
  }
  if (type === 'transfer') {
    tipo = 'Saque';
  }
  if (type === 'antecipation') {
    tipo = 'Antecipado';
  }
  console.log(new Date(date_created).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' }));
  return (
    <>
      <HStack>
        <chakra.p fontSize="sm" color="gray.500">
          {new Date(date_created).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })}
        </chakra.p>
      </HStack>
      <HStack w="100%">
        <Box>
          <MdOutlineOpenInBrowser color="#0EAA8B" />
        </Box>
        <Box align="center" w="25%">
          {tipo === 'Entrada' ? movement_object.transaction_id : movement_object.id}
        </Box>
        <Box align="center" w="25%">
          {tipo === 'Entrada' ? movement_object.payment_method : movement_object.type}
        </Box>
        <Badge borderRadius="15px" minW="25%" textAlign="center" backgroundColor={tipo === 'Entrada' ? 'lime' : 'red.400'}>
          {(amount / 100).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
        </Badge>
        <Box textColor="red.500" align="center" w="25%">
          {(fee / 100).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
        </Box>
      </HStack>
    </>
  );
}

export default Operations;
