import { Box, chakra, Image, Img, Text, useToast, VStack } from '@chakra-ui/react';
import { AccountContext } from 'components/AccountContext';
import BottomNavBar from 'components/BottomNavBar';
import React, { useState } from 'react';
import { useContext } from 'react';
import MainImage from '../../../../images/assets/telemedicina/mainimage.png';
import BackButton from '../../../../images/assets/telemedicina/backbutton.svg';
import MedicoAgora from '../../../../images/assets/telemedicina/medico-agora.png';
import Especialistas from '../../../../images/assets/telemedicina/especialistas.png';
import Telepet from '../../../../images/assets/telemedicina/telepet.png';
import Loading from '../../../Loading';

import { useNavigate } from 'react-router-dom';
function Main() {
  const { user } = useContext(AccountContext);
  const toast = useToast();
  const navigate = useNavigate();
  const [medico, setMedico] = useState(false);
  const [loading, setLoading] = useState(true);
  const fetchRdy = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BASE_URL_API_SERVER + `/pet/medico_online`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      });
      const postsData = await response.json();
      setMedico(postsData.medico_online);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  fetchRdy();
  return (
    <VStack
      align="center"
      justify={{
        base: 'center',
        md: 'space-around',
        xl: 'space-between',
      }}
      direction={{ base: 'column-reverse' }}
      wrap="no-wrap"
      minH="100vh"
      spacing={0}
    >
      <Box
        minHeight="50vh"
        width="100%"
        borderRadius="0px 0px 100px 0px"
        backgroundColor=""
        backgroundImage={MainImage}
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
      >
        <Image mt="10%" ml="5%" zIndex="9999" src={BackButton} onClick={() => navigate(-1)} />
      </Box>
      <Box minHeight="50vh" width="90%" pt="3%" pb="25%">
        <Text>
          <chakra.strong>
            Olá, <chakra.span color="#529C94">{user.name ? user.name.split(' ')[0] : ''}</chakra.span>!
          </chakra.strong>
        </Text>
        <Text>
          Agora você tem <chakra.strong>atendimento</chakra.strong> online,
        </Text>
        <Text>com clínico e especialistas,</Text>
        <Text>
          <chakra.strong>sempre que precisar.</chakra.strong>
        </Text>
        {loading ? (
          <>
            <VStack w={{ base: '20%', md: '100px' }} m="auto" justify="top" align="start">
              <Loading />
            </VStack>
          </>
        ) : (
          <>
            <Box mt="5%">
              <Text color="#529C94">Qual atendimento deseja?</Text>
            </Box>
            <VStack>
              <Box>
                <Img src={MedicoAgora} onClick={() => navigate('/medico-agora/')} />
              </Box>
              <Box>
                <Img src={Especialistas} onClick={() => navigate('/medico-agora/especialista')} />
              </Box>
              {user.titular === true && (
                <Box>
                  <Img
                    src={Telepet}
                    onClick={() => {
                      if (medico === true) {
                        navigate('/telepet/termos');
                      } else {
                        toast({
                          title: 'Indisponível',
                          description: 'Fora do horario de funcionamento',
                          status: 'warning',
                          duration: 4000,
                          isClosable: true,
                          position: 'bottom',
                          colorScheme: 'green',
                          containerStyle: {
                            paddingTop: '50px',
                          },
                        });
                      }
                    }}
                  />
                  <Box
                    right="0.6em"
                    bottom="7em"
                    transform="rotate(-25deg)"
                    position="absolute"
                    backgroundColor={medico ? '#529C94' : 'red'}
                    borderRadius="25px"
                    color="white"
                    textAlign="center"
                    w="25%"
                  >
                    {medico ? 'online' : 'offline'}
                  </Box>
                </Box>
              )}
            </VStack>
          </>
        )}
      </Box>

      <BottomNavBar />
    </VStack>
  );
}

export default Main;
