import React, { useContext, useState, useEffect } from 'react';
import ChildHeader from '../ChildHeader';
import {
  Avatar,
  Text,
  Button,
  Divider,
  VStack,
  Heading,
  Center,
  Stack,
  ButtonGroup,
  HStack,
  chakra,
  List,
  ListItem,
  ListIcon,
} from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { AccountContext } from 'components/AccountContext';
import { MdEditCalendar } from 'react-icons/md';
import { date } from 'yup/lib/locale';

function Checkin() {
  let objeto = [
    {
      ACTIVITY_GROUP_LID: 'null',
      ACTIVITY_GROUP_NAME: ' null',
      ACTIVITY_LID: ' null',
      ACTIVITY_NAME: 'null',
      ADRESS: 'Aldeota - Santos Dumont ',
      APP_DATE: '2022-11-25T03:00:00.000Z',
      APP_LID: '115833',
      ATIVO: 'true',
      CITY: 'FORTALEZA',
      CONSELHO: 'CREFITO: 200666',
      DATA_ATUALIZACAO: '2022-09-01T12:02:19.000Z',
      DESCRICAO: '',
      EMAIL: 'null',
      START_TIME: '09:00:00',
      END_TIME: '10:00:00',
      ESPECIALIDADE: 'CLÍNICO GERAL',
      GENDER: 'F',
      HORARIO_SAB_ABERTURA: '06:00',
      HORARIO_SAB_ENCERRAMENTO: '12:00',
      HORARIO_SEG_SEX_ABERTURA: '06:00',
      HORARIO_SEG_SEX_ENCERRAMENTO: '17:00',
      IDENTIFICATION_ID: '03184288307',
      LOCALIZATION: 'EDIFICIO EDVIGES VIEIRA DE SOUSA',
      LOCATION_LID: 238,
      NAME: 'ADOLFO LUTZ - FORTALEZA',
      NUMBER: '2811',
      PHONE: '0800 280 2133',
      PREPARO: null,
      PRICE: '200.00',
      PROVINCE: 'santos dumont',
      RESOURCE_LID: 50512,
      RESOURCE_NAME: 'Adaliane das Neves Cunha',
      RQE: '',
      START_TIME: '09:20:00',
      STATUS: 'No-show',
      STATUS_AVALIACAO: false,
      TELEMEDICINA: null,
      USER_DATE_OF_BIRTH: '1996-06-11T03:00:00.000Z',
      USER_EMAIL: null,
      USER_FIRST_NAME: 'GABRIEL',
      USER_GENDER: 'F',
      USER_ID_NUMBER: '60540638390',
      USER_LID: 786765,
      USER_MOBILE_PHONE: '85981192976',
      USER_SECOND_NAME: 'MOREIRA',
      USER_ZIP_CODE: '60860-445',
      ZIP_CODE: '60150-161',
      agenda_procedimento_id: 5504,
      cep: '63500-000',
      checkedin: null,
      cnpj: '43979353000198',
      confirmado: false,
      confirmado_tuotempo: false,
      conselho: '200666',
      convenio: null,
      convenio_id: null,
      cpf: '60540638390',
      empresa_id: 238,
      faturado: true,
      latitude: '-3.7278389',
      longitude: '-38.4954941',
      numero: '2811',
      paciente: 'GABRIEL MOREIRA DO AMARAL SOUZA',
      realizada: false,
      rqe: '',
      sigla: 'CREFITO',
      telefonema: null,
      tuotempo: 'INTEGRADO',
    },
  ];

  console.log(objeto);

  // data do atendimento

  let data = new Date(objeto[0].APP_DATE);

  let diaAtendimento = data.getDate();
  let mesAtendimento = data.toLocaleString('pt-BR', { month: 'long' });
  let anoAtendimento = data.getFullYear();
  let minutosAtendimento = data.getMinutes();

  return (
    <>
      <ChildHeader />
      <VStack
        align="center"
        justify={{
          base: 'center',
          md: 'space-around',
          xl: 'space-between',
        }}
        direction={{ base: 'column-reverse' }}
        wrap="no-wrap"
        spacing={8}
        px={5}
        py={3}
      >
        <Stack direction="row" spacing={4} align="center">
          <Heading fontSize="lg">Confirmação de Consulta</Heading>
          <MdEditCalendar size="40" />
        </Stack>
        <Text>Olá, {objeto[0].USER_FIRST_NAME}!</Text>
        <Text size="xs">Consulta com o(a) Profisional:</Text>
        <chakra.span fontWeight="bold" size="sm">
          {' '}
          {objeto[0].RESOURCE_NAME.slice(0, 19)}
        </chakra.span>{' '}
        <Avatar bg="red.500" mt="4" />
        <List spacing={3}>
          <ListItem>
            <ListIcon as={InfoOutlineIcon} color="green.500" />
            Dia:{' '}
            <chakra.span fontWeight="bold">
              {' '}
              {diaAtendimento} de {mesAtendimento} de {anoAtendimento}
            </chakra.span>
          </ListItem>
          <Divider border="2px grey" />
          <ListItem>
            <ListIcon as={InfoOutlineIcon} color="green.500" />
            Horário:{' '}
            <chakra.span fontWeight="bold">
              {objeto[0].START_TIME.slice(0, 5)} as {objeto[0].END_TIME.slice(0, 5)}
            </chakra.span>
          </ListItem>
          <Divider border="2px grey" />
          <ListItem>
            <ListIcon as={InfoOutlineIcon} color="green.500" />
            Especialidade: <chakra.span fontWeight="bold">{objeto[0].ESPECIALIDADE}</chakra.span>
          </ListItem>
          <Divider border="2px grey" />
        </List>
        <Text fontWeight="bold">Deseja Confirmar Agora?</Text>
        <Stack direction="row" spacing={6} align="center">
          <Button colorScheme="teal" height="40px" width="100px" maxWidth={80} variant="solid">
            Sim
          </Button>
          <Button colorScheme="teal" height="40px" width="100px" variant="outline">
            Não
          </Button>
        </Stack>
        <Text mt="5" align="center" fontSize="xs" fontWeight="bold" color="red">
          Caso o pagamento não seja realizado no prazo estipulado a sua aquisição será cancelada.
        </Text>
      </VStack>
    </>
  );
}

export default Checkin;
