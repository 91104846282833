import { VStack, Heading, Button } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { React } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { parse } from 'date-fns';
import TextField from '../../TextField';
import ChangeTitle from '../../ChangeTitle';
import NoUserHeader from '../NoUserHeader';
import requestTimeout from '../../Utils/Functions/requestTimeout';
import ProgressBar from './ProgressBarLivre';

const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');

const DadosBasicosLivrepa = () => {
  ChangeTitle('Cadastro');
  const { state } = useLocation();
  const navigate = useNavigate();
  const timeOutInSeconds = process.env.REACT_APP_REQUEST_TIMEOUT_IN_SECONDS;

  async function submit(values) {
    let split_nascimento = values.nascimento.split('/');
    const url = process.env.REACT_APP_BASE_URL_API_SERVER + '/clients/simple';
    const payload = {
      nome: values.nome,
      cpf: state.cpf,
      celular: values.fone,
      email: values.email,
      nascimento: new Date(split_nascimento[2], split_nascimento[1] - 1, split_nascimento[0]),
    };

    const options = {
      signal: requestTimeout(timeOutInSeconds).signal,
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    };

    try {
      let res = await fetch(url, options);
      res = await res.json();
      if (res.success) {
        navigate('/fimpalivre', {
          state: {
            ...state,
            cliente_id: res.data.paciente_id,
            nome: values.nome,
            email: values.email,
            fone: onlyNumbers(values.fone),
            nascimento: values.nascimento,
          },
        });
      } else {
        console.log('não foi possível processar a solicitação');
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <NoUserHeader />
      <ProgressBar value={4} />
      <Formik
        initialValues={{ nome: '', email: '', fone: '' }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={Yup.object({
          nome: Yup.string()
            .required('Nome é obrigatório!')
            .matches(/^[A-Za-z]+\s[A-Za-z ]+$/m, 'Escreva o nome e sobrenome pelo menos e sem acentos ou caracteres especiais'),
          email: Yup.string().trim().email('O email digitado é inválido'),
          fone: Yup.string()
            .required('Telefone é obrigatório')
            .trim()
            .matches(/^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/, 'Telefone Inválido'),
          nascimento: Yup.date()
            .transform((value, originalValue) => {
              return parse(originalValue, 'dd/MM/yyyy', new Date());
            })
            .required('Data de nascimento é obrigatória!')
            .typeError('Data inválida, digite uma data no formato DD/MM/AAAA'),
        })}
        onSubmit={(values) => {
          submit(values);
        }}
      >
        {(props) => {
          const { setFieldValue } = props;
          return (
            <VStack as={Form} w={{ base: '90%', md: '500px' }} m="auto" justify="top" marginTop="5%" marginBottom="5%" spacing="1rem" align="start">
              <Heading fontSize="1em">Agora vamos continuar seu cadastro com seus dados básicos</Heading>
              <TextField
                name="nome"
                placeholder="Nome completo"
                onChange={(e) => {
                  const value = e.target.value;
                  let arr_names = value.split(' ');
                  for (var i = 0; i < arr_names.length; i++) {
                    arr_names[i] = arr_names[i].charAt(0).toUpperCase() + arr_names[i].slice(1);
                  }
                  let res = arr_names.join(' ');
                  setFieldValue('nome', res);
                }}
              />

              <TextField name="email" placeholder="E-mail" type="email" />

              <TextField name="nascimento" type="tel" placeholder="Data de nascimento" mask="99/99/9999" maskChar={null} />

              <TextField name="fone" type="tel" placeholder="Celular com DDD" mask="(99) 99999-9999" maskChar={null} />

              <Button colorScheme="teal" w="100%" type="submit">
                Avançar
              </Button>
            </VStack>
          );
        }}
      </Formik>
    </>
  );
};

export default DadosBasicosLivrepa;
