import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Button,
  ModalBody,
  useDisclosure,
  ModalCloseButton,
  List,
  Divider,
  ListItem,
  useBreakpointValue,
  Image,
  ListIcon,
  Center,
  HStack,
  Checkbox,
  chakra,
  Drawer,
  DrawerContent,
  DrawerCloseButton,
  Text,
  VStack,
  Spacer,
  Stack,
  Heading,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import Carousel from 'react-elastic-carousel';
import Dollar from '../../../../images/DollarIcon.png';
import { useContext } from 'react';
import { AccountContext } from '../../../AccountContext';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Pdf from 'components/PdfViewer';
import Contrato from '../../../../termos/empreendedor_app.pdf';
import logo from '../../../../images/logo_deovita.svg';
import autovendavel from '../../../../images/assets/icons/auto-vendavel.svg';
import rendimentos from '../../../../images/assets/icons/rendimentos.svg';
import transparencia from '../../../../images/assets/icons/transparencia.svg';
import deovector from '../../../../images/assets/icons/deovector.svg';

function ModalEmpreendedor() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const buttonSize = useBreakpointValue(['xs', 'md']);
  const { user } = useContext(AccountContext);
  const [termos, setTermos] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (user.empreendedor === false) onOpen();
  }, []);

  return (
    <>
      <Drawer isCentered isFullHeight size="md" isOpen={drawerOpen} placement="bottom">
        <DrawerContent isCentered nbgColor="rgba(90, 178, 173, 0.7)" pt="10%">
          <Pdf file={Contrato} />
          <DrawerCloseButton
            color="red"
            borderColor="red"
            pt="15%"
            onClick={() => {
              setDrawerOpen(false);
            }}
          />
        </DrawerContent>
      </Drawer>
      <Modal onClose={onClose} isOpen={isOpen} isCentered size="full">
        <ModalOverlay />
        <ModalContent pt="20%">
          <Center>
            <Image src={logo} mt="5" w="10em"></Image>
          </Center>
          <ModalCloseButton
            pt="20%"
            onClick={() => {
              navigate('/dashboard');
              onClose();
            }}
          />
          <ModalBody>
            <Stack mt="8">
              <ModalHeader textAlign="center" fontSize="sm" textColor="teal.500">
                Conquiste sua liberdade financeira, seja um revendedor DeoVita.
              </ModalHeader>
              <Carousel showArrows={false} itemsToShow={1} focusOnSelect={false}>
                <Stack>
                  <Center>
                    {' '}
                    <Image boxSize="15em" src={autovendavel} />
                  </Center>{' '}
                  <Heading textAlign="center" textColor="teal.500" fontSize="lg" noOfLines={2}>
                    PRODUTO
                  </Heading>
                  <Heading textAlign="center" mb="2" textColor="teal.500" fontSize="">
                    AUTO-VENDÁVEL
                  </Heading>
                  <VStack>
                    <Center>
                      {' '}
                      <chakra.span textAlign="center" fontWeight="bold" textColor="gray.500" fontSize="xs" mt="5" w="80%">
                        Venda em vários canais e leve saúde com preço justo para novas famílias todos os dias.
                      </chakra.span>
                    </Center>
                    <Spacer />

                    <Center flexDirection="column">
                      {' '}
                      <HStack direction={['row']} w="100%" align="center">
                        <Image boxSize="20px" objectFit="cover" src={deovector} alt="Dan Abramov" />
                        <Text fontSize="xs" textColor="gray.500">
                          {' '}
                          Venda pelo aplicativo
                        </Text>
                      </HStack>
                    </Center>
                    <Spacer />
                    <Center>
                      <HStack direction={['row']} w="100%" align="center">
                        <Image boxSize="20px" objectFit="cover" src={deovector} alt="Dan Abramov" />
                        <Text fontSize="xs" textColor="gray.500" isTruncated>
                          {' '}
                          Venda pelo whatsapp, facebook e instagram
                        </Text>
                      </HStack>
                    </Center>
                    <Spacer />
                    <Center flexDirection="column">
                      <HStack direction={['row']} w="100%" align="center">
                        <Image boxSize="20px" objectFit="cover" src={deovector} alt="Dan Abramov" />
                        <Text fontSize="xs" textColor="gray.500">
                          Venda presencialmente
                        </Text>
                      </HStack>
                    </Center>
                  </VStack>
                </Stack>
                <Stack>
                  <Center>
                    {' '}
                    <Image boxSize="15em" src={rendimentos} />
                  </Center>{' '}
                  <Heading textAlign="center" textColor="teal.500" fontSize="md">
                    TENHA OS MELHORES RENDIMENTOS
                  </Heading>
                  <VStack>
                    <Center>
                      {' '}
                      <Text textAlign="center" fontWeight="bold" textColor="gray.500" fontSize="xs" mt="5">
                        Você pode ganhar até 20 reais em uma única venda.
                      </Text>
                    </Center>
                    <Spacer mt="3" />
                    <Spacer mt="3" />

                    <Spacer />
                    <Center flexDirection="row">
                      <HStack mt="5" direction={['row']} paddingInlineStart={4} w="100%" align="center">
                        <Image boxSize="20px" objectFit="cover" src={deovector} alt="Dan Abramov" />
                        <Text fontSize="xs" textColor="gray.500">
                          {' '}
                          Venda no cartão
                        </Text>
                        <Spacer />
                        <Image boxSize="20px" textColor="gray.500" objectFit="cover" src={deovector} alt="Dan Abramov" />
                        <Text fontSize="xs" textColor="gray.500">
                          Venda no pix
                        </Text>
                      </HStack>
                    </Center>
                    <Center flexDirection="column">
                      {' '}
                      <HStack direction={['row']} w="100%" mt="5" align="center">
                        <Image boxSize="20px" objectFit="cover" src={deovector} alt="Dan Abramov" />
                        <Text fontSize="xs" textColor="gray.500">
                          {' '}
                          Venda no boleto
                        </Text>
                      </HStack>
                    </Center>
                    <Spacer />
                  </VStack>
                </Stack>
                <Stack>
                  <Center>
                    {' '}
                    <Image boxSize="15em" src={transparencia} />
                  </Center>{' '}
                  <Heading textAlign="center" mb="3" textColor="teal.500" fontSize="2xl">
                    TRANSPARÊNCIA FACILIDADE EM RECEBER
                  </Heading>
                  <VStack>
                    <Center>
                      {' '}
                      <chakra.span textAlign="center" fontWeight="bold" textColor="gray.500" fontSize="xs" mt="3">
                        Você 100% no controle dos seus rendimentos.
                      </chakra.span>
                    </Center>
                    <Spacer mt="3" />

                    <Center flexDirection="column">
                      {' '}
                      <HStack direction={['row']} w="100%" mt="3" align="center">
                        <Image boxSize="20px" objectFit="cover" src={deovector} alt="Dan Abramov" />
                        <Text fontSize="xs" textColor="gray.500">
                          {' '}
                          Saque a hora que quiser
                        </Text>
                      </HStack>
                    </Center>
                    <Spacer />
                    <Center flexDirection="row">
                      <HStack direction={['row']} paddingInlineStart={4} w="100%" align="center">
                        <Image boxSize="20px" objectFit="cover" src={deovector} alt="Dan Abramov" />
                        <Text fontSize="xs" textColor="gray.500">
                          {' '}
                          Acompanhe seu extrato
                        </Text>
                        <Image boxSize="20px" objectFit="cover" src={deovector} alt="Dan Abramov" />
                        <Text fontSize="xs" textColor="gray.500">
                          {' '}
                          Solicite antecipação
                        </Text>
                      </HStack>
                    </Center>
                    <Spacer />
                  </VStack>
                </Stack>
              </Carousel>
            </Stack>
          </ModalBody>{' '}
          <HStack px="5">
            <Checkbox
              name="termos"
              autofocus="true"
              onChange={(e) => {
                if (!termos) {
                  setTermos(true);
                } else {
                  setTermos(false);
                }
              }}
            />
            <chakra.a
              pl="10px"
              colorScheme="teal"
              onClick={() => {
                setDrawerOpen(true);
              }}
            >
              Li e aceito os <u>termos de uso e de parceria</u>.
            </chakra.a>
          </HStack>
          <Center>
            <Button
              mb="7"
              mt="5"
              size="lg"
              colorScheme="teal"
              w="80%"
              borderRadius="15px"
              onClick={() => {
                onClose();
                navigate('/empreendedor/ativar');
              }}
              isDisabled={!termos}
            >
              Seguinte
            </Button>
          </Center>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ModalEmpreendedor;
