import React, { useEffect, useState } from 'react';
import { Button, HStack, Input, VStack } from '@chakra-ui/react';
import BottomNavBar from '../../../BottomNavBar';
import MainChildHeader from '../MainChildHeader';
import { AccountContext } from '../../../AccountContext';
import { useContext } from 'react';
import Resumo from './Resumo';
import Menu from './Menu';
import ModalEmpreendedor from './ModalEmpreendedor';
import Loading from 'components/Loading';
function Main() {
  const { user, setPage, setUser } = useContext(AccountContext);
  setPage('Revendedor');

  const [contratos, setContratos] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [vendedores, setVendedores] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchContratos = async () => {
    const url = `${process.env.REACT_APP_BASE_URL_API_SERVER}/entrepreneur/extrato/contratos/${user.id_vendedor}`;
    const response = await fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    const fetchData = await response.json();
    setUser((prevUser) => ({ ...prevUser, contratosEmpreendedor: fetchData.data.contratos }));
    setContratos(fetchData.data.contratos);
  };
  const fetchEmpresas = async () => {
    const url = `${process.env.REACT_APP_BASE_URL_API_SERVER}/entrepreneur/extrato/credenciados/${user.id_vendedor}`;
    const response = await fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    const fetchData = await response.json();
    setUser((prevUser) => ({ ...prevUser, empresasEmpreendedor: fetchData.data }));
    setEmpresas(fetchData.data);
  };
  const fetchVendedores = async () => {
    const url = `${process.env.REACT_APP_BASE_URL_API_SERVER}/entrepreneur/extrato/vendedores/${user.id_vendedor}`;
    const response = await fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    const fetchData = await response.json();
    setUser((prevUser) => ({ ...prevUser, vendedoresEmpreendedor: fetchData.data.result }));
    setVendedores(fetchData.data.result);
  };

  async function fetchDatas() {
    if (user.contratosEmpreendedor && Array.isArray(user.contratosEmpreendedor) && user.contratosEmpreendedor.length > 0) {
      setContratos(user.contratosEmpreendedor);
    } else {
      await fetchContratos();
    }
    if (user.empresasEmpreendedor && Array.isArray(user.empresasEmpreendedor) && user.empresasEmpreendedor.length > 0) {
      setEmpresas(user.empresasEmpreendedor);
    } else {
      await fetchEmpresas();
    }
    if (user.vendedoresEmpreendedor && Array.isArray(user.vendedoresEmpreendedor) && user.vendedoresEmpreendedor.length > 0) {
      setVendedores(user.vendedoresEmpreendedor);
    } else {
      await fetchVendedores();
    }
    console.log(user);
    setLoading(false);
  }

  useEffect(() => {
    fetchDatas();
  }, []);
  console.log(contratos);
  return user.empreendedor === false ? (
    <ModalEmpreendedor />
  ) : loading ? (
    <>
      <MainChildHeader />
      <Loading />
      <BottomNavBar name="empreendedor" />
    </>
  ) : (
    <>
      <MainChildHeader />
      <VStack
        align="center"
        justify={{
          base: 'center',
          md: 'space-around',
          xl: 'space-between',
        }}
        direction={{ base: 'column-reverse' }}
        wrap="no-wrap"
        minH="10vh"
        px={3}
        mb="20"
      >
        <Resumo contratos={contratos} empresas={empresas} vendedores={vendedores} />

        <Menu contratos={contratos} empresas={empresas} vendedores={vendedores} />
      </VStack>
      <BottomNavBar name="empreendedor" />
    </>
  );
}

export default Main;
