import ImgCartao from '../../../images/ImgCartao';
import ChildHeader from '../../Dashboard/Main/ChildHeader';
import { AccountContext } from '../../AccountContext';
import { React, useState, useContext } from 'react';
import { Button, Heading, VStack, HStack, chakra, ButtonGroup, Text } from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Form, Formik } from 'formik';
import TextField from '../../TextField';
import BottomNavBar from '../../BottomNavBar';

const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');

const Cartao = () => {
  const [loading, setLoading] = useState(null);
  const [active, setActive] = useState(false);
  const { setPage, user, setUser } = useContext(AccountContext);
  console.log(user.cards.length);
  const navigate = useNavigate();
  const { state } = useLocation();
  const card = state.card;
  const [aviso, setAviso] = useState(null);
  const paciente_id = user.id_cliente_deovita;
  setPage('Dados Bancários');

  var initCardValues = { cvv: '', name: '', cardNumber: '', expirationDate: '' };
  if (state.op !== 'new') {
    initCardValues = {
      name: card.holder_name.toUpperCase(),
      cardNumber: card.first_digits + 'xxxxxx' + card.last_digits,
      cvv: 'xxx',
      expirationDate: card.expiration_date,
    };
  }
  const [data, setData] = useState(initCardValues);
  const HandleChange = (e) => {
    if (!active) setActive(true);
    const { name, value } = e.target;
    setData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  return (
    <>
      <ChildHeader />
      <Formik
        initialValues={{}}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={() => {
          setLoading(true);
          setAviso(null);

          const api_key = process.env.REACT_APP_API_KEY_PAGARME;
          const options = {
            method: 'POST',
            headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify({
              card_number: onlyNumbers(data.cardNumber),
              card_expiration_date: onlyNumbers(data.expirationDate),
              card_holder_name: data.name,
              card_cvv: `${data.cvv}`,
            }),
          };
          fetch(`${process.env.REACT_APP_BASE_URL_API_PAGARME}/cards?api_key=${api_key}`, options)
            .then((response) => {
              return response.json();
            })
            .then((response) => {
              setLoading(false);
              if (response.valid) {
                fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/client/add_credit_card`, {
                  method: 'POST',
                  credentials: 'include',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({
                    paciente_id: paciente_id,
                    first_digits: response.first_digits,
                    last_digits: response.last_digits,
                    brand: response.brand,
                    holder_name: response.holder_name,
                    expiration_date: response.expiration_date,
                    card_id_pagarme: response.id,
                    fingerprint_card_pagarme: response.fingerprint,
                    default: user.cards.length > 0 ? false : true,
                  }),
                })
                  .catch((error) => {
                    setAviso('Não foi possível atualizar os dados no momento.');
                    return;
                  })
                  .then((res) => {
                    if (!res || !res.ok || res.status >= 400) {
                      setAviso('Não foi possível atualizar os dados no momento.');
                      return;
                    }
                    return res.json();
                  })
                  .then((data) => {
                    setLoading(false);
                    if (!data) return;
                    if (data.success) {
                      let dataNewCard = {
                        paciente_cartao_credito_pagarme_id: data.id,
                        holder_name: response.holder_name,
                        brand: response.brand,
                        first_digits: response.first_digits,
                        last_digits: response.last_digits,
                        expiration_date: response.expiration_date,
                        card_id_pagarme: response.id,
                        default: user.cards.length > 0 ? false : true,
                      };
                      setUser((prevState) => ({
                        ...prevState,
                        cards: [...prevState.cards, dataNewCard],
                      }));
                      setAviso('Atualizado com sucesso');
                      navigate(-1);
                      setActive(false);
                    } else {
                      if (data.msg === 'cartão já cadastrado') {
                        setAviso(data.msg);
                      } else {
                        setAviso('Não foi possível atualizar os dados no momento.');
                      }
                    }
                  });
              } else {
                setAviso('Cartão inválido');
              }
            })
            .catch((err) => {
              setLoading(false);
              setAviso('Não foi possível atualizar o cartão no momento. Verifique os dados inseridos ou tente mais tarde');
              console.aviso(err);
            });
        }}
      >
        <VStack as={Form} w={{ base: '85%', md: '500px' }} m="auto" paddingBottom="5%" spacing="1rem" align="start" marginTop="5%">
          <ImgCartao name={data.name.toUpperCase()} cardnumber={data.cardNumber} />
          <Heading>Cartão de Crédito</Heading>
          <chakra.p color={aviso === 'Atualizado com sucesso' ? 'green' : 'red'}>{aviso}</chakra.p>
          <TextField
            name="cardNumber"
            type="tel"
            placeholder="Número do cartão"
            mask="**** **** **** ****"
            maskChar={null}
            value={data.cardNumber}
            onChange={HandleChange}
            isDisabled={state.op !== 'new'}
          />
          <HStack m="auto" justify="center" spacing="1rem" align="start">
            <TextField
              name="expirationDate"
              placeholder="Data de validade"
              type="tel"
              mask="99/99"
              maskChar={null}
              value={data.expirationDate}
              onChange={HandleChange}
              isDisabled={state.op !== 'new'}
            />
            <TextField name="cvv" value={data.cvv} type="tel" placeholder="CVV" onChange={HandleChange} isDisabled={state.op !== 'new'} />
          </HStack>
          <TextField
            name="name"
            value={data.name}
            type="text"
            placeholder="Nome impresso no cartão"
            onChange={HandleChange}
            isDisabled={state.op !== 'new'}
          />
          <ButtonGroup width="100%" paddingBottom="20%">
            {state.op !== 'new' ? (
              <VStack w={{ base: '100%', md: '500px' }}>
                <Button
                  colorScheme="red"
                  w="100%"
                  isDisabled={user.cards.length <= 1 || card.default}
                  isLoading={loading}
                  loadingText="Carregando"
                  onClick={() => {
                    setLoading(true);
                    fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/client/rm_credit_card/${card.paciente_cartao_credito_pagarme_id}`, {
                      method: 'POST',
                      credentials: 'include',
                      headers: { 'Content-Type': 'application/json' },
                    })
                      .catch((error) => {
                        setAviso('Não foi possível atualizar os dados no momento.');
                        return;
                      })
                      .then((res) => {
                        if (!res || !res.ok || res.status >= 400) {
                          setAviso('Não foi possível atualizar os dados no momento.');
                          return;
                        }
                        return res.json();
                      })
                      .then((data) => {
                        setLoading(false);
                        if (!data) return;
                        if (data.success) {
                          let newCards = user.cards.filter(
                            (creditCard) => creditCard.paciente_cartao_credito_pagarme_id !== card.paciente_cartao_credito_pagarme_id
                          );
                          setUser((prevState) => ({ ...prevState, cards: newCards }));
                          setAviso('Atualizado com sucesso');
                          setActive(false);
                          navigate(-1);
                        } else {
                          setAviso('Não foi possível atualizar os dados no momento.');
                        }
                      });
                  }}
                >
                  Excluir
                </Button>
                (
                {(user.cards.length <= 1 || card.default) && (
                  <Text fontSize="xs" color="red">
                    Não é permitido excluir todos os cartões ou o cartão preferencial
                  </Text>
                )}
                )
              </VStack>
            ) : (
              <Button colorScheme="teal" type="submit" w="100%" isLoading={loading} loadingText="Carregando" isDisabled={!active}>
                Salvar alterações
              </Button>
            )}
          </ButtonGroup>
        </VStack>
      </Formik>
      <BottomNavBar />
    </>
  );
};

export default Cartao;
