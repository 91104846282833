import { Button, chakra, VStack, ButtonGroup, Container, Box } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import NoUserHeader from '../NoUserHeader';

function ConferePedidoLivre() {
  const { state } = useLocation();
  const { agenda, medico, paciente } = state;
  const navigate = useNavigate();
  const data = new Date(agenda.AVA_DATE.split('/').reverse().join('-') + 'T08:00:00');
  let diaExtenso = data.toLocaleDateString('pt-br', { weekday: 'long' });
  let mesExtenso = data.toLocaleDateString('pt-br', { month: 'long' });
  const arrName = medico.dados.nome.split(' ');

  return (
    <>
      <NoUserHeader />
      <VStack
        w={{
          base: '90%',
          md: '500px',
        }}
        m="auto"
        alignItems="left"
        spacing={1}
        marginBottom="10%"
      >
        <chakra.h1 fontWeight="bold" margin="5% 0" fontSize="xl">
          Informações do Pedido:
        </chakra.h1>
        <chakra.p fontWeight="bold">
          {medico.dados.tipo}
          {' COM '}
          {arrName[0].toUpperCase()} {arrName[1].toUpperCase()}
        </chakra.p>
        <chakra.p color="gray.500">paciente:</chakra.p>
        <chakra.p fontWeight="bold">{paciente.nome.toUpperCase()}</chakra.p>
        <chakra.p color="gray.500">procedimento:</chakra.p>
        <chakra.p fontWeight="bold">{agenda.ACTIVITY_NAME}</chakra.p>
        <chakra.p color="gray.500">Data:</chakra.p>
        <chakra.p fontWeight="bold">
          {' '}
          {'Dia'} {agenda.AVA_DATE.substring(0, 2)} {'de'} {mesExtenso}, {diaExtenso}
          {' as '}
          {agenda.AVA_START_TIME}
        </chakra.p>
        <chakra.p color="gray.500">Local:</chakra.p>
        <chakra.p fontSize="lg" fontWeight="bold">
          {state.clinica}
        </chakra.p>
        <chakra.p color="black.500" fontWeight="bold" fontSize="sm">
          {' '}
          {state.logradouro}, {state.numero} - {state.bairro} - {state.municipio}
        </chakra.p>
        <chakra.p color="gray.500">valor:</chakra.p>
        <chakra.p fontWeight="bold">R$ {state.valor}</chakra.p>
        <Container maxW="2xl" leftContent>
          <Box padding="1" borderRadius="md" borderWidth="1px" margin="5% 0" bcolor="black" maxW="sm">
            <chakra.p color="black">Orientações:</chakra.p>
            <chakra.p color="red.500" margin="5% 0" fontSize="sm">
              {state.preparo}
            </chakra.p>
          </Box>
        </Container>

        <ButtonGroup pt="1rem" pb="15%" width="100%">
          <Button
            colorScheme="teal"
            width="100%"
            onClick={() => {
              if (!state.valor || state.valor === 0) {
                navigate('/atendimento/loading/', {
                  state: {
                    valor: 0,
                    paciente: paciente,
                    agenda: agenda,
                    medico: medico,
                    orientacoes: agenda.preparo,
                    confim: true,
                    transaction_id: 9999999,
                    agendamentoAvulso: true,
                  },
                });
              } else {
                navigate('/pagamentos', {
                  state: {
                    parcelsActive: true,
                    valor: state.valor,
                    expiration_pix_in_min: 5,
                    navig_to: '/atendimento/loading/',
                    item: state.tipo,
                    paciente: paciente,
                    agenda: agenda,
                    medico: medico,
                    orientacoes: state.preparo,
                    agendamentoAvulso: true,
                    confim: true,
                  },
                });
              }
            }}
          >
            continuar
          </Button>
        </ButtonGroup>
      </VStack>
    </>
  );
}

export default ConferePedidoLivre;
