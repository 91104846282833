import React, { useState, useContext, useEffect } from 'react';
import ChildHeader from '../../Dashboard/Main/ChildHeader';
import {
  useToast,
  HStack,
  chakra,
  VStack,
  Avatar,
  Flex,
  Heading,
  Box,
  Button,
  List,
  ListItem,
  Divider,
  Text,
  WrapItem,
  Image,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogFooter,
  AlertDialogContent,
  ModalCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  LinkBox,
  TableContainer,
  Table,
  Select,
  Badge,
  Center,
  Tr,
  Spacer,
  Td,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon, CheckIcon, CloseIcon, WarningTwoIcon } from '@chakra-ui/icons';
import { MdOutlineWifi, MdOutlineWifiOff } from 'react-icons/md';
import { AccountContext } from '../../AccountContext';
import { useNavigate, useLocation } from 'react-router-dom';
import BottomNavBar from '../../BottomNavBar';
import { Formik } from 'formik';
import Loading from '../../Loading';
import PopupAvaliacao from './PopupAvalicao';
import { array } from 'yup';

function Detalhes() {
  const navigate = useNavigate();
  const { user, setPage } = useContext(AccountContext);
  const [loading, setLoading] = useState(true);
  const { state } = useLocation();
  const { isOpen, onClose } = useDisclosure();
  const { atendimento, statusAtual } = state;
  const [cancelamento, setCancelamento] = useState([]);

  const [agendas, setAgendas] = useState([]);
  const data = new Date(atendimento.APP_DATE.replace(' ', 'T'));
  const img = process.env.REACT_APP_URL_PROFILEPICS_OPERADOR + '/' + atendimento.RESOURCE_LID + '/foto_profissional.png';
  const dataextenso = data.toLocaleDateString('pt-br', { weekday: 'long' });
  const monthNameLong = data.toLocaleString('pt-br', { month: 'long' });
  const options = { month: 'short', day: 'numeric' };
  const subTitle = { casing: 'capitalize', color: 'gray' };
  const body = { casing: 'capitalize', fontWeight: 'bold' };
  const logo = `${process.env.REACT_APP_URL_LOGOMARCA}/` + atendimento.LOCATION_LID + '/logomarca.png';

  let titulo = '';
  if (atendimento.GENDER === 'M') {
    titulo = 'Dr. ';
  } else if (atendimento.GENDER === 'F') {
    titulo = 'Dra. ';
  }

  setPage('Minhas Consultas');

  function myToLowerCase(text) {
    if (typeof text === 'string') {
      return text.toLocaleLowerCase();
    } else {
      return text;
    }
  }

  console.log(user);

  const LoadingIcon = () => {
    return (
      <>
        <ChildHeader />
        <Box w={{ base: '20%', md: '100px' }} m="auto" justify="top" align="start">
          <Loading />
        </Box>
      </>
    );
  };

  function cancelarConsulta(motivoCancelamento) {
    const url = process.env.REACT_APP_BASE_URL_API_SERVER + '/appointments/cancel/';
    const payload = {
      agenda_exames_id: atendimento.APP_LID,
      motivo: motivoCancelamento,
      paciente_id: atendimento.USER_LID,
      medico: atendimento.RESOURCE_LID,
    };

    const fetchPosts = async () => {
      try {
        const response = await fetch(url, {
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify(payload),
        });
        const postsData = await response.json();
        console.log(postsData);
        navigate('/alerts/sucesso/excluir-agendamento');
      } catch (error) {
        console.log(error);
        navigate('/alerts/erro/excluir-agendamento');
      }
    };

    fetchPosts();
  }

  function AlertDialogExample(props) {
    const cancelRef = React.useRef();

    return (
      <>
        <AlertDialog
          isOpen={isOpen}
          motionPreset="slideInBottom"
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          size="xs"
          isCentered
          isLazy={true}
        >
          <AlertDialogOverlay>
            <AlertDialogContent borderRadius={15}>
              <AlertDialogFooter>
                <VStack w="100%" spacing={3} margin={2}>
                  <Center></Center>
                  <Text fontWeight="bold" fontSize="x-large">
                    Deseja remover esta consulta?
                  </Text>
                  <Button
                    ref={cancelRef}
                    isFullWidth
                    onClick={() => {
                      cancelarConsulta();

                      onClose();
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button colorScheme="teal" onClick={onClose} isFullWidth>
                    Continuar
                  </Button>
                </VStack>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    );
  }

  async function fetchAgendas() {
    const url = process.env.REACT_APP_BASE_URL_API_SERVER + '/schedule/getAvailability/' + atendimento.RESOURCE_LID + '/' + atendimento.ACTIVITY_LID;
    const response = await fetch(url, {
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      method: 'GET',
    });
    const postsData = await response.json();
    setAgendas(postsData);
    setLoading(false);
  }

  async function ObterDados() {
    let url = process.env.REACT_APP_BASE_URL_API_SERVER + '/schedule/get_motivos_cancelamento';
    const resposta = await fetch(url, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    const postsDados = await resposta.json();
    setCancelamento(postsDados);
  }

  useEffect(() => {
    ObterDados();
    fetchAgendas();
  }, []);

  function isNotLonger24Hours() {
    let date = atendimento.APP_DATE.split('/').reverse().join('-') + 'T' + atendimento.START_TIME;
    const then = new Date(date);
    const now = new Date();

    const msBetweenDates = then.getTime() - now.getTime();
    const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);

    let response = true;
    if (hoursBetweenDates < 24) {
      response = false;
    }

    return response;
  }

  function Telemedicina() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [disableButton, setDisableButton] = useState(false);
    const [camera, setCamera] = useState('');
    const [mic, setMic] = useState('');

    // data atual
    let dataAtual = new Date();
    let diaAtual = dataAtual.getDate();
    let mesAtual = dataAtual.getMonth() + 1;
    let anoAtual = dataAtual.getFullYear();
    let horasAtual = dataAtual.getHours();
    let horasAtualFormat = horasAtual.toString;
    let horarioAtendimentoTeste = '16:35';

    let minutosAtual = dataAtual.getMinutes();
    let horarioAtual = horasAtual + ':' + minutosAtual;

    let confereDataAtual = diaAtual + '/' + mesAtual;

    // data do atendimento
    let dataAtendimento = new Date(atendimento.APP_DATE);
    let horarioAtendimento = atendimento.START_TIME.substring(0, 5);
    let diaAtendimento = dataAtendimento.getDate();
    let mesAtendimento = dataAtendimento.getMonth() + 1;
    let anoAtendimento = dataAtendimento.getFullYear();
    let horasAtendimento = horarioAtendimento;
    let minutosAtendimento = dataAtendimento.getMinutes();

    let confereDataAtendimento = diaAtendimento + '/' + mesAtendimento;

    let tipoConexao = '';
    let statusVelocidade = '';

    let larguraBanda = '';

    let statusConexao = '';

    let aviso = '';

    if (navigator.connection.effectiveType === '4g') {
      tipoConexao = 'Wi-Fi / 4G';
    }

    if (navigator.connection.downlink >= 1.25) {
      statusVelocidade = 'Conexão Estável';
    } else {
      statusVelocidade = 'Conexão Instável';
      aviso = (
        <Center>
          <Text color="red">É recomendável uma conexão estável para prosseguir com o atendimento</Text>
        </Center>
      );
    }
    if (navigator.connection.downlink === 0) {
      statusVelocidade = 'Sem Conexão';
    }

    let statusIcon = '';
    let statusIconConexao = '';

    if (statusConexao === 'Sem Conexão') {
      statusIcon = ' ' + <WarningTwoIcon />;
    } else {
      statusIcon = <CheckIcon color="green.500" />;
    }

    if (tipoConexao === 'Sem Conexão') {
      statusIcon = <WarningTwoIcon color="yellow.500" />;
      tipoConexao = 'Desconectado';
    } else {
    }

    if (navigator.connection.downlink >= 1.25) {
      statusVelocidade = 'Conexão Estável';
      statusIconConexao = <CheckIcon color="green.500" />;
      aviso = (
        <Text color="green.500" fontWeight="bold">
          Tudo certo para prosseguir!{' '}
        </Text>
      );
    } else {
      statusIconConexao = <WarningTwoIcon color="yellow.500" />;
    }

    if (navigator.onLine === true) {
      statusConexao = 'Usuário Conectado';
      tipoConexao = navigator.connection.effectiveType;
      larguraBanda = navigator.connection.downlink;
    } else {
      statusConexao = 'Usuário Desconectado';
      statusIcon = <WarningTwoIcon color="yellow.500" />;
      tipoConexao = 'Sem Conexão';
      larguraBanda = 'Sem Conexão';
      tipoConexao = 'Sem Conexão';
    }

    let plataforma = navigator.platform;

    useEffect(() => {
      if (navigator.onLine === true) {
        statusConexao = 'Usuário Conectado';
        tipoConexao = navigator.connection.effectiveType;
        larguraBanda = navigator.connection.downlink;
        setDisableButton(false);
      } else {
        setDisableButton(true);
      }
    }, [navigator]);

    function detectDevice(callback) {
      let device = navigator.mediaDevices;

      if (!device || !device.enumerateDevices) return callback(false);
      device.enumerateDevices().then((devices) => {
        callback(devices.some((device) => 'videoinput' === device.kind));
      });

      if (!device || !device.enumerateDevices) return callback(false);
      device.enumerateDevices().then((devices) => {
        callback(devices.some((device) => 'audioinput' === device.kind));
      });
    }

    detectDevice(function (temCamera) {
      let possuiCamera = '';

      possuiCamera = temCamera ? <CheckIcon color="green.500" /> : <CloseIcon color="red.500" />;
      setCamera(possuiCamera);

      if (temCamera === true) {
        setDisableButton(false);
      } else {
        setDisableButton(true);
      }

      if (navigator.onLine === true) {
        setDisableButton(false);
      } else {
        setDisableButton(true);
      }

      // checando se as datas e horarios batem

      if (confereDataAtual === confereDataAtendimento) {
        avisoHorario = 'Tudo certo! Prossiga com o Atendimento';
        setDisableButton(false);
      } else {
        avisoHorario = (
          <Center>
            <Text color="red">Horário atual não é igual ao horário do atendimento</Text>
          </Center>
        );
        setDisableButton(true);
      }

      if (horarioAtual === horarioAtendimento) {
        setDisableButton(false);
      } else {
        setDisableButton(true);
      }
    });

    detectDevice(function (temMic) {
      let possuiMic = '';

      possuiMic = temMic ? <CheckIcon color="green.500" /> : <CloseIcon color="red.500" />;
      setMic(possuiMic);
    });
    let avisoHorario = '';

    if (confereDataAtual === confereDataAtendimento) {
    } else {
      avisoHorario = (
        <Center>
          <Text color="red">O atendimento será habilitado na data e horário marcados!</Text>
        </Center>
      );
    }

    if (horarioAtual === horarioAtendimento) {
      avisoHorario = (
        <Center>
          <Text color="green.500">Atendimento Liberado!</Text>
        </Center>
      );
    } else {
      avisoHorario = (
        <Center>
          <Text color="red">O atendimento será habilitado na data e horário marcados!</Text>
        </Center>
      );
    }

    return (
      <>
        <Button colorScheme="blue" disabled={disableButton} isFullWidth height="12" size="xs" borderRadius={'10'} variant="outline" onClick={onOpen}>
          ir para o atendimento
        </Button>
        {avisoHorario}

        <Modal isOpen={isOpen} onClose={onClose} isCentered size="sm">
          <ModalOverlay />
          <ModalContent borderRadius={25}>
            <ModalCloseButton />
            <ModalBody>
              <VStack
                spacing={8}
                w={{
                  base: '90%',
                  md: '500px',
                }}
                m="20% auto"
                justify="center"
              >
                <Heading size="md">Vamos Checar Sua Conexão </Heading>
                {navigator.onLine === true ? (
                  <MdOutlineWifi size="90" color="rgba(90, 178, 173, 0.7)" />
                ) : (
                  <MdOutlineWifiOff size="90" color="rgba(174, 174, 178, 1)" />
                )}

                <Text fontWeight="bold">
                  <span>{statusConexao}</span> {statusIcon}
                </Text>

                <List spacing={2} size="md">
                  <ListItem>
                    <Text fontWeight="bold">
                      {' '}
                      Tipo de Conexão:<span> {tipoConexao}</span> {statusIcon}
                    </Text>
                  </ListItem>
                  <Divider border="1px" borderColor="gray" />
                  <ListItem>
                    <Text fontWeight="bold">
                      <span>{statusVelocidade}</span> {statusIconConexao}
                    </Text>
                  </ListItem>
                  <Divider border="1px" borderColor="gray" />
                  <Text fontWeight="bold">
                    {' '}
                    Mb/s : {larguraBanda} {statusIconConexao}
                  </Text>

                  <Divider border="1px" borderColor="gray" />
                  <Text fontWeight="bold">Câmera: {camera}</Text>
                  <Divider border="1px" borderColor="gray" />
                  <Text fontWeight="bold">Microfone: {mic}</Text>
                </List>
                <Text fontWeight="bold">
                  Plataforma: <span>{plataforma}</span>
                </Text>
                <Text color="red.500">{aviso}</Text>
                <Center>
                  <Button
                    colorScheme="blue"
                    isFullWidth
                    height="12"
                    disabled={disableButton}
                    size="sm"
                    borderRadius={'10'}
                    variant="outline"
                    onClick={() => navigate('/telemedicina', { state: { atendimento: atendimento, statusAtual } })}
                  >
                    ir para o atendimento
                  </Button>
                </Center>
              </VStack>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    );
  }

  function RemarcarConsulta() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [pag, setPag] = useState(0);
    const [escolha, setEscolha] = useState(null);
    const [reagendamento, setReagendamento] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');

    const toast = useToast();

    let uniqueDates = [];
    agendas.map((agenda) => (!uniqueDates.includes(agenda.AVA_DATE) ? uniqueDates.push(agenda.AVA_DATE) : ''));

    let dataAtendimento = new Date(atendimento.APP_DATE.replace(' ', 'T'));
    let dataextenso = dataAtendimento.toLocaleDateString('pt-br', {
      weekday: 'long',
    });
    let monthNameLong = dataAtendimento.toLocaleString('pt-br', {
      month: 'long',
    });

    let data = '';
    let dataIcon = ['', ''];
    if (typeof uniqueDates[pag] === 'string') {
      data = new Date(uniqueDates[pag].split('/').reverse().join('-') + 'T08:00:00');
      dataIcon = data.toLocaleDateString('pt-br', options).replace(' de ', ' ').replace('.', '').split(' ');
    }

    function remarcarConsulta() {
      setLoading(true);
      const url = process.env.REACT_APP_BASE_URL_API_SERVER + '/schedule/reschedule';
      const payload = {
        agenda_exames_id_atual: parseInt(atendimento.APP_LID),
        agenda_exames_id_novo: escolha,
      };

      const fetchPosts = async () => {
        try {
          const postsData = await fetch(url, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify(payload),
          });

          const response = await postsData.json();
          if (response.success) {
            sendWhatsappReagendamento();
            //setCancelamento(response.data);
            navigate('/alerts/sucesso/default');
          } else {
            navigate('/alerts/erro/default');
          }
        } catch (error) {
          console.log(error);
          navigate('/alerts/erro/default');
        }
      };
      fetchPosts();
    }
    const filteredAgendas = agendas.filter((i) => uniqueDates[pag].includes(i.AVA_DATE));
    const useFilteredAgendas = filteredAgendas.map((agendas, index) => {
      return (
        <>
          <Td>
            <Badge
              id={agendas.AVA_START_TIME + index}
              borderRadius="1em"
              margin="0 0.5em"
              padding="1em 1.4em"
              size="md"
              borderEndRadius="15px"
              borderLeftRadius="15px"
              fontSize="sm"
              backgroundColor="rgba(90, 178, 173, 0.3)"
              color="black"
              onClick={() => {
                document.querySelectorAll(`[class="chakra-badge css-wm29s0"]`).forEach((item) => {
                  item.style.border = 'none';
                });
                document.getElementById(agendas.AVA_START_TIME + index).style.border = '1px solid orange';
                setEscolha(agendas.AVAILABILITY_LID);
                setReagendamento(agendas);
                setDisabled(true);
              }}
            >
              {agendas.AVA_START_TIME}
            </Badge>
          </Td>
        </>
      );
    });

    const sendWhatsappReagendamento = async () => {
      const url = process.env.REACT_APP_BASE_URL_API_SERVER + '/whatsapp/reagendamento';
      const payload = {
        numero: user.phone1,
        nome: atendimento.USER_FIRST_NAME,
        procedimento: reagendamento.ACTIVITY_NAME ? reagendamento.ACTIVITY_NAME : 'CONSULTA COM ' + reagendamento.ESPECIALIDADE,
        medico: atendimento.RESOURCE_NAME,
        data: reagendamento.AVA_DATE,
        hora: reagendamento.AVA_START_TIME,
        clinica: atendimento.NAME,
      };

      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      };
      fetch(url, options);
    };

    return agendas.length > 0 ? (
      <>
        <Button colorScheme="teal" isFullWidth height="12" size="xs" borderRadius={'10'} variant="outline" onClick={onOpen}>
          Remarcar
        </Button>
        <Modal
          isCentered
          onClose={onClose}
          isOpen={isOpen}
          //width='150px'
          //height='471px'
          top="-176px"
          left="1616px"
          size="sm"
        >
          <ModalOverlay />
          <ModalContent borderRadius="23px">
            <ModalHeader textAlign="justify" mt="5">
              Deseja remarcar essa consulta?
            </ModalHeader>
            <ModalCloseButton size="sm" />
            <ModalBody textAlign="left" fontSize="sm">
              {atendimento.ACTIVITY_NAME ? atendimento.ACTIVITY_NAME : 'CONSULTA COM ' + atendimento.ESPECIALIDADE} - {atendimento.NAME} na{' '}
              {dataextenso}, {dataAtendimento.getDate()} de {monthNameLong} de {dataAtendimento.getFullYear()} as{' '}
              {atendimento.START_TIME ? atendimento.START_TIME.split(':')[0] + ':' + atendimento.START_TIME.split(':')[1] : ''} com {titulo}{' '}
              {atendimento.RESOURCE_NAME}
            </ModalBody>
            <chakra.h1 marginTop="4%" textAlign="left" ml="10" color="rgba(0, 0, 0, 0.5)" fontSize="sm">
              Paciente
            </chakra.h1>
            <chakra.p textAlign="left" ml="10">
              {atendimento.USER_FIRST_NAME} {atendimento.USER_SECOND_NAME}
            </chakra.p>
            <ModalHeader fontWeight="bold" fontSize="21" mt="2">
              Selecione dia e horário
            </ModalHeader>
            <LinkBox
              mt="1"
              id=""
              as="card"
              width="328px"
              height="85px"
              ml="7"
              borderRadius="18px"
              fontSize="xs"
              size="10"
              backgroundColor="rgba(90, 178, 173, 0.25)"
              maxInlineSize="80"
              textAlign="center"
            >
              <HStack>
                <ChevronLeftIcon
                  fontSize="5em"
                  w={12}
                  h={8}
                  mt="-3"
                  onClick={() => {
                    if (pag > 0) {
                      console.log(pag);
                      setPag(pag - 1);
                      document.querySelectorAll(`[class="chakra-badge css-raqg5x"]`).forEach((item) => {
                        item.style.border = 'none';
                      });
                      setEscolha(null);
                      setDisabled(false);
                    }
                  }}
                />
                <Flex>
                  <Box
                    backgroundColor="rgba(90, 178, 173, 0.3)"
                    borderRadius="5px"
                    margin="20px"
                    color="rgba(62, 100, 255, 1)"
                    padding="0 10px"
                    ml="-3"
                    mt="2"
                  >
                    <Text fontWeight="bold" fontSize="xl" mt="1">
                      {dataIcon[0]}
                    </Text>
                    <Text fontWeight="bold" fontSize="sm">
                      {dataIcon[1].toUpperCase()}
                    </Text>
                  </Box>
                  <Text margin="15% 0" ml="2" mt="1" fontSize="lg" align="left">
                    <chakra.h1 textAlign="center" color="black" fontSize="md" mt="2" fontWeight="bold">
                      Consulta Médica
                    </chakra.h1>
                    {data.toLocaleString('pt-br', { weekday: 'long' })}
                  </Text>
                </Flex>
                <ChevronRightIcon
                  fontSize="5em"
                  w={12}
                  h={8}
                  mt="-3"
                  onClick={() => {
                    if (pag < uniqueDates.length - 1) {
                      console.log(pag);
                      setPag(pag + 1);
                      document.querySelectorAll(`[class="chakra-badge css-raqg5x"]`).forEach((item) => {
                        item.style.border = 'none';
                      });
                      setEscolha(null);
                      setDisabled(false);
                    }
                  }}
                />
              </HStack>
            </LinkBox>
            <TableContainer minWidth="100%" size="sm" mt="15">
              <Table variant="simple" borderRadius="15px" width="99px" height="44px">
                <Tr>{useFilteredAgendas}</Tr>
              </Table>
            </TableContainer>

            <Button
              colorScheme="teal"
              isFullWidth
              height="12"
              size="xs"
              borderRadius={'10'}
              ml="38"
              mt="5"
              width="300px"
              isLoading={loading}
              onClick={remarcarConsulta}
            >
              Remarcar Consulta
            </Button>
            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal>
      </>
    ) : (
      <>
        <Button
          colorScheme="teal"
          isFullWidth
          height="12"
          size="xs"
          borderRadius={'10'}
          variant="outline"
          onClick={() =>
            toast({
              title: 'Indisponível',
              description: 'Profissional não possuí horários disponíveis para reagendamento ',
              status: 'warning',
              duration: 4000,
              isClosable: true,
              position: 'bottom',
              colorScheme: 'green',
              containerStyle: {
                paddingTop: '50px',
              },
            })
          }
        >
          Remarcar Consulta
        </Button>
      </>
    );
  }

  return loading ? (
    <LoadingIcon />
  ) : (
    <>
      <AlertDialogExample />
      <ChildHeader />
      <VStack
        w={{ base: '90%' }}
        m="auto"
        marginTop="5"
        py="5"
        px="5"
        borderWidth={3}
        borderColor="#00000"
        borderRadius="10px"
        justify="center"
        align="start"
        spacing={5}
      >
        <Text fontWeight="bold">{atendimento.ACTIVITY_NAME ? atendimento.ACTIVITY_NAME : 'CONSULTA COM ' + atendimento.ESPECIALIDADE}</Text>
        <HStack w="100%" justify="center">
          <Image
            minwidth="150px"
            minHeight="50px"
            maxWidth="150px"
            padding="2"
            mt="5"
            name={atendimento.NAME}
            src={logo}
            fallbackSrc={`${process.env.REACT_APP_URL_LOGOMARCA}/logomarca.png`}
          />{' '}
        </HStack>
        <HStack paddingTop="5%" justify="center">
          <div></div>
          <VStack align="start">
            <Text fontSize="lg" textAlign="center" maxW="80">
              {' '}
              {atendimento.NAME}
            </Text>
            <HStack spacing={1} display="flex" alignItems="center" fontSize="sm" pt={0.5}></HStack>
            <HStack spacing={1} fontSize="LG"></HStack>
          </VStack>
        </HStack>
        <chakra.p>
          <Text {...subTitle}>Data:</Text>
          <chakra.p>
            {atendimento.RESOURCE_NAME === 'Sem Solicitante' ||
            atendimento.RESOURCE_NAME === null ||
            atendimento.RESOURCE_NAME === '' ||
            atendimento.RESOURCE_NAME === 'SEM SOLICITANTE' ||
            atendimento.RESOURCE_NAME === undefined ||
            atendimento.RESOURCE_LID === 51298 ||
            atendimento.ordem_chegada === true ? (
              <Text {...body} casing="lowercase">
                {dataextenso}, {data.getDate()} de {monthNameLong} de {data.getFullYear()} no turno da{' '}
                {(atendimento.START_TIME === '06:00:00' ? 'manhã' : 'tarde') + ' - atendimento por ordem de chegada'}.
              </Text>
            ) : (
              <>
                <Text {...body} casing="lowercase">
                  {dataextenso}, {data.getDate()} de {monthNameLong} de {data.getFullYear()} as{' '}
                  {atendimento.START_TIME ? atendimento.START_TIME.split(':')[0] + ':' + atendimento.START_TIME.split(':')[1] : ''}.
                </Text>
              </>
            )}
          </chakra.p>
        </chakra.p>
        <chakra.p>
          <Text {...subTitle}>Local:</Text>
          <Text {...body}>
            {atendimento.NAME.toLowerCase()} {atendimento.CITY.toLowerCase()}
          </Text>
          <Text {...body} fontSize="xs">
            {atendimento.ADRESS.toLowerCase()}, {myToLowerCase(atendimento.NUMBER)}{' '}
          </Text>
        </chakra.p>
        <chakra.p>
          <Text {...subTitle}>Status:</Text>
          <Text {...body}>{statusAtual}</Text>
        </chakra.p>
        <chakra.p>
          <Text {...subTitle}>Telefone:</Text>
          <Text {...body}>{atendimento.PHONE} </Text>
        </chakra.p>
        <VStack align="start" spacing={5}>
          {atendimento.RESOURCE_NAME === 'Sem Solicitante' || atendimento.RESOURCE_NAME === null || atendimento.RESOURCE_NAME === undefined ? (
            <>
              <chakra.h2 color="grey">Profissional</chakra.h2>
              <HStack>
                <VStack align="start">
                  <Avatar id={atendimento.ACTIVITY_LID} size="lg" bg="teal.400" name={atendimento.RESOURCE_NAME} src={img} />
                </VStack>
                <VStack align="start" spacing={-1}>
                  <chakra.h2 fontSize="md" fontWeight="bold" color="">
                    {titulo} {atendimento.RESOURCE_NAME}
                  </chakra.h2>
                  <Text casing="capitalize" fontSize="md" color="">
                    {atendimento.ESPECIALIDADE}
                  </Text>
                  <HStack>
                    <chakra.p fontSize="xs">{atendimento.CONSELHO}</chakra.p>
                    <chakra.p fontSize="xs"> {atendimento.RQE}</chakra.p>
                  </HStack>
                  <HStack spacing={1} fontSize="sm"></HStack>
                </VStack>
              </HStack>
            </>
          ) : (
            <></>
          )}
          <VStack variant="soft-rounded" w="full" alignItems="justify">
            <chakra.p>
              <Text {...subTitle}>Paciente:</Text>
              <Text {...body}>
                {atendimento.USER_FIRST_NAME} {atendimento.USER_SECOND_NAME}
              </Text>
            </chakra.p>
            <chakra.p>
              <Text {...subTitle}>Valor: </Text>
              <Text {...body}>R$ {atendimento.PRICE}</Text>
            </chakra.p>
            <chakra.p>
              <Text {...subTitle}>E-Ticket: </Text>
              <Text {...body}>PMA{atendimento.APP_LID}</Text>
            </chakra.p>
          </VStack>
          <VStack w="full" minWidth="280px" alignItems="justify" spacing={4}>
            {atendimento.PREPARO ? (
              <>
                <Heading size="md">Orientações</Heading>
                <Box padding="5" borderRadius="10" borderWidth="0.063rem" borderColor="yellow.500" color="red.500" fontSize="sm" fontWeight="bold">
                  <chakra.p>{atendimento.PREPARO}</chakra.p>
                </Box>
              </>
            ) : (
              <></>
            )}
            {atendimento.TELEMEDICINA === true && (
              <Button
                colorScheme="blue"
                isFullWidth
                height="12"
                size="xs"
                borderRadius={'10'}
                variant="outline"
                onClick={() => navigate('/telemedicina', { state: { atendimento: atendimento, statusAtual } })}
              >
                ir para o atendimento
              </Button>
            )}
            {statusAtual === 'Concluído' && ( //verificar se já foi feita a avaliação e desativa o botão
              <PopupAvaliacao data={atendimento} />
            )}
            {statusAtual !== 'Concluído' && statusAtual !== 'Pagamento em Analise' && (
              <>
                <HStack isFullWidth align="start">
                  <Button
                    colorScheme="red"
                    size="xs"
                    height="12"
                    isFullWidth
                    variant="outline"
                    borderRadius={'10'}
                    onClick={() => navigate('/consultas/cancelamento', { state: { atendimento: atendimento, statusAtual } })}
                    isDisabled={!isNotLonger24Hours()}
                  >
                    Cancelar
                  </Button>
                  <RemarcarConsulta />
                </HStack>
                {!isNotLonger24Hours() && (
                  <Text fontSize="xs" color="red">
                    Não é permitido cancelar ou remarcar consultas com menos de 24 horas para o atendimento
                  </Text>
                )}
              </>
            )}
            <Button colorScheme="teal" isFullWidth height="12" size="xs" borderRadius={'10'} onClick={() => navigate('/consultas')}>
              ↑ Detalhes
            </Button>
          </VStack>
        </VStack>
      </VStack>
      <WrapItem mt="20">
        <BottomNavBar />
      </WrapItem>
    </>
  );
}

export default Detalhes;
