import React, { useState } from "react";
import format from "date-fns/format";
import { ptBR } from "date-fns/locale";
import {
    Modal,
    Text,
    Textarea,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button,
    FormControl,
    Heading,
    FormLabel,
    useDisclosure,
    ModalCloseButton,
    VStack,
    Center,
} from "@chakra-ui/react";
import StarRating from "./StartRating";

function PopupAvaliacao(props) {
    const atendimento = props.data;
    let dia = atendimento.APP_DATE.substring(0, 2);
    let mes = atendimento.APP_DATE.substring(3, 5);
    let ano = atendimento.APP_DATE.substring(6);

    let dataAtendimento = new Date(atendimento.APP_DATE.replace(' ', 'T'))
    let dataExtenso = dataAtendimento.toLocaleDateString("pt-br", {
        weekday: "long",
    });
    let titulo = "";
    if (atendimento.GENDER === "F") {
        titulo = "Dra";
    } else if (atendimento.GENDER === "M") {
        titulo = "Dr";
    }
    const { isOpen, onOpen, onClose } = useDisclosure();

    const initialRef = React.useRef(null);
    const finalRef = React.useRef(null);
    const [rating, setRating] = useState(0);
    const [opnion, setOpnion] = useState();
    const [loading, setLoading] = useState(false);
    const [erro, setErro] = useState(null);

    async function send() {
        setLoading(true);
        const url = process.env.REACT_APP_BASE_URL_API_SERVER + "/schedule/rating";
        const payload = [
            {
                agenda_exames_id: parseInt(atendimento.APP_LID),
                medico_id: parseInt(atendimento.RESOURCE_LID),
                opiniao: opnion,
                avaliacao: rating,
            },
        ];
        const options = {
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            method: "POST",
            body: JSON.stringify(payload),
        };

        try {
            const res = await fetch(url, options);
            if (!res || !res.ok || res.status >= 400) {
                setErro("Não foi possível enviar sua avaliação");
                setLoading(false);
            } else {
                const data = (await res.json())[0];
                if (data.success) {
                    setErro(null);
                    setLoading(false);
                    onClose();
                } else {
                    setErro(data.data.error);
                    setLoading(false);
                }
            }
        } catch (error) {
            setLoading(false);
            setErro(error);
            console.log(error);
        }
    }
    console.log(atendimento)
    return (
        <>
            {atendimento.STATUS_AVALIACAO === false && rating <= 0 ? (
                <Button variant="outline" colorScheme="teal" isFullWidth height="12" size="xs" borderRadius={"10"} onClick={onOpen}>
                    Avaliar atendimento
                </Button>
            ) : (
                ""
            )}

            <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent width="360px" height="600px" borderRadius={20}>
                    <ModalHeader mt="5" fontSize="lg">
                        Avalie o atendimento realizado!
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <Text size="sm">
                            {atendimento.ACTIVITY_NAME} - {atendimento.NAME} {dataExtenso} ás {atendimento.START_TIME} com o profissional{" "}
                            {titulo} {atendimento.RESOURCE_NAME}{" "}
                        </Text>
                        <Heading size="3" mt="5">
                            Gostaríamos de saber se você está satisfeito com o atendimento médico?
                        </Heading>
                        <VStack mt="5">
                            <StarRating alignItems="center" rating={rating} setRating={setRating} />
                        </VStack>
                        <FormControl mt={8}>
                            <FormLabel>Deseja incluir sua opinião?</FormLabel>
                            <Textarea placeholder="Digite Aqui" value={opnion} onChange={(e) => setOpnion(e.target.value)} />
                        </FormControl>
                        <Text color="red">{erro}</Text>
                        <Center mt="10">
                            <Button
                                colorScheme="teal"
                                isFullWidth
                                height="12"
                                size="sm"
                                borderRadius={"10"}
                                isDisabled={rating <= 0}
                                isLoading={loading}
                                onClick={() => {
                                    send();
                                }}
                            >
                                Concluir Avaliação
                            </Button>
                        </Center>
                    </ModalBody>
                    <ModalFooter></ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
export default PopupAvaliacao;