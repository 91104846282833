import { ArrowForwardIcon } from '@chakra-ui/icons';
import { chakra, Box, Img, LinkBox, Text, VStack, Checkbox, Image, RadioGroup, Stack, Radio, Spinner, HStack } from '@chakra-ui/react';
import { AccountContext } from 'components/AccountContext';
import BottomNavBar from 'components/BottomNavBar';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Med from '../../../../../images/assets/telemedicina/med.png';
import BackButton from '../../../../../images/assets/telemedicina/backbutton.svg';
import { MdOutlineLooksOne, MdOutlineLooksTwo } from 'react-icons/md';

function Main() {
  const { user } = useContext(AccountContext);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <VStack
        align="center"
        justify={{
          base: 'center',
          md: 'space-around',
          xl: 'space-between',
        }}
        direction={{ base: 'column-reverse' }}
        wrap="no-wrap"
        minH="100vh"
        spacing={0}
        background="linear-gradient(180deg, #EB8B2A 0%, #EFA73F 28.65%, #5AB2AD 64.06%, #4E9E8F 100%);"
        color="#585858"
      >
        <Image top="2.5em" left="1.3em" zIndex="9999" position="absolute" src={BackButton} onClick={() => navigate(-1)} />
        <Box
          mt="1"
          id=""
          as="card"
          width="328px"
          minHeight="340"
          borderRadius="18px"
          fontSize="xs"
          size="10"
          backgroundColor="white"
          maxInlineSize="80"
          textAlign="center"
        >
          <Img ml="35%" mt="5%" src={Med} w="30%" />
          <Box m="5%">
            <Text textAlign="left" fontWeight="bold">
              {user.name.split(' ')[0]}...
            </Text>
            <Text textAlign="left" fontWeight="bold">
              Agendar uma consulta com um especialista é muito fácil, veja:
            </Text>
            <HStack mt="2%">
              <MdOutlineLooksOne size="2em" color="#529C94" />
              <Text>Falar com um CLÍNICO GERAL</Text>
            </HStack>
            <HStack mt="2%">
              <MdOutlineLooksTwo size="2em" color="#529C94" />
              <Text>Pedir o encaminhamento</Text>
            </HStack>
          </Box>
          <Text
            textAlign="center"
            id="callBtn"
            color="#529C94"
            fontWeight="bold"
            fontSize="1.5em"
            mt="0.5em"
            onClick={() => {
              navigate('/medico-agora/', { state: { especialista: true } });
            }}
          >
            Falar com clínico
            <chakra.span>
              <ArrowForwardIcon fontSize="0.9em" />
            </chakra.span>
          </Text>
        </Box>
      </VStack>
      <BottomNavBar />
    </>
  );
}

export default Main;
