import { VStack, Button, Text, ButtonGroup } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import React, { useState, useContext } from 'react';
import { AccountContext } from '../../AccountContext';
import { useNavigate } from 'react-router-dom';
import TextField from '../../TextField';
import ChangeTitle from '../../ChangeTitle';
import ChildHeader from '../../Dashboard/Main/ChildHeader';
import BottomNavBar from '../../BottomNavBar';
import * as Yup from 'yup';

const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');

const Endereco = () => {
  ChangeTitle('Perfil');
  const { setPage } = useContext(AccountContext);
  setPage('Endereço');

  const { user, setUser } = useContext(AccountContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [endereco, setEndereco] = useState({
    cep: user.cep,
    code_ibge: user.code_ibge,
    endereco: user.logradouro,
    nro: user.numero,
    complemento: user.complemento,
    cidade: user.cidade,
    estado: user.uf,
    bairro: user.bairro,
  });
  const validationSchema = Yup.object({
    cep: Yup.string().required('Cep é obrigatório').min(8, 'Cep inválido'),
    endereco: Yup.string().required('Endereço é obrigatório'),
    nro: Yup.number('Somente número').required('Número é obrigatório'),
    estado: Yup.string().required('Estado é obrigatório'),
    cidade: Yup.string().required('Cidade é obrigatória'),
    bairro: Yup.string().required('Bairro é obrigatório'),
  });

  const [error, setError] = useState(null);

  const AddressForm = (formProps) => {
    console.log(formProps);
    const { setFieldValue } = formProps;

    const handleChange = (event) => {
      const { name, value } = event.target;
      setFieldValue(name, value);
    };

    function BuscaCep(cep) {
      let cepNumber = onlyNumbers(cep);
      async function buscaCep() {
        try {
          const resposta = await fetch(`${process.env.REACT_APP_BASE_URL_API_VIACEP}/${cepNumber}/json/`);
          resposta.json().then((res) => {
            setError(null);
            setEndereco((prevState) => {
              return {
                ...prevState,
                cep: cep,
                code_ibge: res.ibge,
                endereco: res.logradouro,
                complemento: res.complemento,
                cidade: res.localidade,
                estado: res.uf,
                bairro: res.bairro,
              };
            });
            setFieldValue('cep', cep);
            setFieldValue('endereco', res.logradouro);
            setFieldValue('complemento', res.complemento);
            setFieldValue('cidade', res.localidade);
            setFieldValue('estado', res.uf);
            setFieldValue('bairro', res.bairro);
          });
        } catch (error) {
          setError('Não foi possivel consultar seu cep, verifique o cep ou preencha as informações manualmente');
        }
      }
      buscaCep();
    }

    return (
      <VStack as={Form} w={{ base: '90%', md: '500px' }} m="auto" paddingBottom="5%" justify="top" marginTop="5%" spacing="1rem" align="start">
        <TextField
          name="cep"
          placeholder="CEP"
          type="tel"
          mask="99.999-999"
          maskChar={null}
          onChange={(e) => {
            handleChange(e);
            if (e.currentTarget.value.length >= 10) BuscaCep(e.currentTarget.value);
          }}
          onBlur={(e) => {
            if (e.currentTarget.value.length >= 10) BuscaCep(e.currentTarget.value);
          }}
        />
        <TextField name="endereco" placeholder="Endereço" onChange={handleChange} />
        <TextField name="nro" placeholder="Número" onChange={handleChange} />
        <TextField name="complemento" placeholder="Complemento (opcional)" onChange={handleChange} />
        <TextField name="cidade" placeholder="Cidade" onChange={handleChange} />
        <TextField name="estado" placeholder="Estado" onChange={handleChange} />
        <TextField name="bairro" placeholder="Bairro" onChange={handleChange} />
        <Text as="p" color="red.500">
          {error}
        </Text>
        <ButtonGroup width="100%" paddingBottom="20%">
          <Button colorScheme="teal" w="100%" type="submit" isLoading={loading} loadingText="Atualizando">
            Atualizar
          </Button>
        </ButtonGroup>
      </VStack>
    );
  };

  return (
    <>
      <ChildHeader />
      <Formik
        initialValues={{
          cep: user.cep,
          endereco: user.logradouro,
          nro: user.numero,
          complemento: user.complemento,
          cidade: user.cidade,
          estado: user.uf,
          bairro: user.bairro,
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          setError(null);
          setLoading(true);

          let payload = {
            cep: onlyNumbers(values.cep),
            code_ibge: endereco.code_ibge,
            endereco: values.endereco,
            nro: values.nro,
            complemento: values.complemento,
            cidade: values.cidade,
            estado: values.estado,
            bairro: values.bairro,
            id_cliente: user.id_cliente_deovita,
          };
          console.log(payload);
          const string_error =
            'Não foi possível completar a atualização dos seus dados no momento, tente novamente mais tarde ou entre em contato com o suporte';
          fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/client/update_adress`, {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
          })
            .catch((err) => {
              console.log(err);
              setLoading(false);
              setError(string_error);
              return;
            })
            .then((res) => {
              if (!res || !res.ok || res.status >= 400) {
                console.log(res);
                setLoading(false);
                setError(string_error);
                return;
              }
              return res.json();
            })
            .then((data) => {
              setLoading(false);
              if (!data || !data.success) {
                console.log(data);
                setError(string_error);
                return;
              }
              setUser({
                ...user,
                cep: values.cep,
                code_ibge: endereco.code_ibge,
                logradouro: values.endereco,
                numero: values.nro,
                complemento: values.complemento,
                cidade: values.cidade,
                uf: values.estado,
                bairro: values.bairro,
              });
              navigate('/alerts/sucesso/update_adress');
            });
        }}
      >
        {AddressForm}
      </Formik>
      <BottomNavBar />
    </>
  );
};

export default Endereco;
