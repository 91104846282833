import React from 'react';
import {
  VStack,
  Text,
  useDisclosure,
  Image,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  Heading,
  Divider,
  ModalHeader,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CheckIcon, WarningTwoIcon, CloseIcon } from '@chakra-ui/icons';
import Carousel from 'react-elastic-carousel';
import telemedicina from '../../../images/assets/icons/carroselimgs/telemedicina.png';
import clinicas from '../../../images/assets/icons/carroselimgs/clinicas.png';
import pet from '../../../images/assets/icons/carroselimgs/pet.png';
import domiciliar from '../../../images/assets/icons/carroselimgs/Domiciliar.png';
import { MdOutlineWifi, MdOutlineWifiOff } from 'react-icons/md';
import { useState, useEffect } from 'react';
import { isDisabled } from '@testing-library/user-event/dist/utils';
function BadgesCarrosel() {
  const navigate = useNavigate();

  function Telemedicina() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [disableButton, setDisableButton] = useState(false);
    const [camera, setCamera] = useState('');
    const [mic, setMic] = useState('');
    const [showText, setShowText] = useState('Teste');

    let tipoConexao = '';
    let statusVelocidade = '';

    let larguraBanda = '';

    let statusConexao = '';

    let aviso = '';

  
    let statusIcon = '';
    let statusIconConexao = '';

    if (statusConexao === 'Sem Conexão') {
      statusIcon = ' ' + <WarningTwoIcon />;
    } else {
      statusIcon = <CheckIcon color="green.500" />;
    }

    if (tipoConexao === 'Sem Conexão') {
      statusIcon = <WarningTwoIcon color="yellow.500" />;
      tipoConexao = 'Desconectado';
    } else {
    }


    let plataforma = navigator.platform;



    function detectDevice(callback) {
      let device = navigator.mediaDevices;

      if (!device || !device.enumerateDevices) return callback(false);
      device.enumerateDevices().then((devices) => {
        callback(devices.some((device) => 'videoinput' === device.kind));
      });

      if (!device || !device.enumerateDevices) return callback(false);
      device.enumerateDevices().then((devices) => {
        callback(devices.some((device) => 'audioinput' === device.kind));
      });
    }

    detectDevice(function (temCamera) {
      let possuiCamera = '';
      possuiCamera = temCamera ? <CheckIcon color="green.500" /> : <CloseIcon color="red.500" />;
      setCamera(possuiCamera);

      if (temCamera === true) {
        setDisableButton(false);
      } else {
        setDisableButton(true);
      }

      if (navigator.onLine === true) {
        setDisableButton(false);
      } else {
        setDisableButton(true);
      }
    });

    detectDevice(function (temMic) {
      let possuiMic = '';

      possuiMic = temMic ? <CheckIcon color="green.500" /> : <CloseIcon color="red.500" />;
      setMic(possuiMic);
    });

    return (
      <>
        <Image maxWidth="180px" src={telemedicina} onClick={onOpen}></Image>

        <Modal isOpen={isOpen} onClose={onClose} isCentered size="sm">
          <ModalOverlay />
          <ModalContent borderRadius={25}>
            <ModalCloseButton />
            <ModalBody>
              <VStack
                spacing={8}
                w={{
                  base: '90%',
                  md: '500px',
                }}
                m="20% auto"
                justify="center"
              >
                <Heading size="md">Vamos Checar Sua Conexão </Heading>
                {navigator.onLine === true ? (
                  <MdOutlineWifi size="90" color="rgba(90, 178, 173, 0.7)" />
                ) : (
                  <MdOutlineWifiOff size="90" color="rgba(174, 174, 178, 1)" />
                )}

                <Text fontWeight="bold">
                  <span>{statusConexao}</span> {statusIcon}
                </Text>

                <List spacing={2} size="md">
                  <ListItem>
                    <Text fontWeight="bold">
                      {' '}
                      Tipo de Conexão:<span> {tipoConexao}</span> {statusIcon}
                    </Text>
                  </ListItem>
                  <Divider border="1px" borderColor="gray" />
                  <ListItem>
                    <Text fontWeight="bold">
                      <span>{statusVelocidade}</span> {statusIconConexao}
                    </Text>
                  </ListItem>
                  <Divider border="1px" borderColor="gray" />
                  <Text fontWeight="bold">
                    {' '}
                    Mb/s : {larguraBanda} {statusIconConexao}
                  </Text>

                  <Divider border="1px" borderColor="gray" />
                  <Text fontWeight="bold">Câmera: {camera}</Text>
                  <Divider border="1px" borderColor="gray" />
                  <Text fontWeight="bold">Microfone: {mic}</Text>
                </List>
                <Text fontWeight="bold">
                  Plataforma: <span>{plataforma}</span>
                </Text>
                <Text color="red.500">{aviso}</Text>
                <Center>
                  <Button disabled={disableButton} size="md" colorScheme="teal">
                    Prosseguir Para Atendimento
                  </Button>{' '}
                </Center>
              </VStack>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    );
  }

  return (
    <>
      <Carousel itemsToShow={4} showArrows={false} FocusOnSelect={true} pagination={false}>
        <Image maxWidth="180px" maxHeight="70px" src={clinicas}></Image>
        <Telemedicina />
        <Image maxWidth="180px" maxHeight="70px" src={pet}></Image>
        <Image maxWidth="180px" maxHeight="70px" src={domiciliar}></Image>
      </Carousel>
    </>
  );
}

export default BadgesCarrosel;
