import { CloseIcon, EditIcon, WarningTwoIcon } from '@chakra-ui/icons';
import {
  Table,
  TableContainer,
  Tbody,
  Thead,
  Tr,
  Td,
  chakra,
  ButtonGroup,
  Button,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Image,
  Heading,
  VStack,
  ModalFooter,
  Stack,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../../AccountContext';
import AvisoDependentes from './AvisoDependentes';

function ListaDependentes() {
  const navigate = useNavigate();
  const { user } = useContext(AccountContext);
  const [dependentes, setDependentes] = useState([]);
  const [error, setError] = useState(null);
  const disclosure = useDisclosure();
  const url = `${process.env.REACT_APP_BASE_URL_API_SERVER}/client/dependents`;
  const payload = {
    id_contrato: user.id_contrato,
  };
  useEffect(() => {
    const fetchPosts = async () => {
      const response = await fetch(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(payload),
      });
      const postsData = await response.json();

      setDependentes(postsData.data);
    };
    fetchPosts();
  }, []);

  const useDependentes = dependentes.map((dependente, index) => {
    const arrName = dependente.nome.split(' ');
    const preposicoes = ['das', 'dos', 'des', 'da', 'do', 'de'];
    const nome = Array.isArray(arrName) ? arrName.filter((name, index) => index <= 1 && preposicoes.includes(name) === false).join(' ') : '';
    return (
      <>
        <Tr key={index}>
          <Td>
            {dependente.cpf === null || dependente.cpf === '' || dependente.cpf === undefined ? (
              <WarningTwoIcon color="orange" />
            ) : (
              <WarningTwoIcon color="white" />
            )}
            {' ' + nome.toUpperCase()}
            {/*<chakra.p>Relação/Parentesco:</chakra.p>*/}
          </Td>
          <Td>
            <EditIcon
              onClick={() =>
                navigate('/perfil/dependentes/editar', {
                  state: {
                    id: dependente.paciente_id,
                    dependente_id: dependente.paciente_contrato_dependente_id,
                    nome: dependente.nome,
                    cpf: dependente.cpf,
                    nascimento: dependente.nascimento,
                    cep: dependente.cep,
                    code_ibge: dependente.codigo_ibge,
                    logradouro: dependente.logradouro,
                    numero: dependente.numero,
                    bairro: dependente.bairro,
                    complemento: dependente.complemento,
                    municipio: dependente.municipio,
                    estado: dependente.estado,
                    fone: dependente.celular,
                    sexo: dependente.sexo,
                  },
                })
              }
              color="teal"
              boxSize="2em"
            />
          </Td>
        </Tr>
      </>
    );
  });

  return (
    <>
      <AvisoDependentes disclosure={disclosure} />
      <TableContainer minWidth="100%">
        <Table>
          <Thead>
            <Tr></Tr>
          </Thead>
          <Tbody>{dependentes && useDependentes}</Tbody>
        </Table>
      </TableContainer>
      <Text color="red">{error}</Text>
      <ButtonGroup pb="5%" sx={{ width: '100%' }}>
        <Button
          name="btn"
          colorScheme="teal"
          variant="outline"
          onClick={() => {
            let limit = user.qtde_max_dependentes_total;
            let limitIncluso = user.qtde_max_dependentes;
            if (dependentes.length >= limitIncluso && dependentes.length < limit) {
              disclosure.onOpen();
            }
            if (dependentes.length >= limit) {
              setError(`Você já atingiu o limite de ${limit} dependentes`);
            }
            if (dependentes.length < limitIncluso) {
              navigate('/perfil/dependentes/cadastro', {
                state: { id_cliente_deovita: user.id_cliente_deovita, id_contrato: user.id_contrato, extra: false },
              });
            }
          }}
          w={{
            base: '100%',
          }}
        >
          Adicionar novo dependente
        </Button>
      </ButtonGroup>
    </>
  );
}

export default ListaDependentes;
