import React, { useState, useEffect } from 'react';
import Carousel from 'react-elastic-carousel';
import { chakra, Image, Img } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
function ListaGrupos() {
  const navigate = useNavigate();
  const url = `${process.env.REACT_APP_BASE_URL_API_SERVER}/schedule/getGroups`;
  const [grupos, setGrupos] = useState([]);
  const fetchData = async () => {
    const response = await fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    const fetchData = await response.json();
    setGrupos(fetchData);
  };

  useEffect(() => {
    fetchData();
  }, []);
  const useGrupos = grupos.map((grupo, index) => {
    return (
      <>
        <div>
          <Img
            mt="5"
            id={grupo.ambulatorio_grupo_id}
            src={`${process.env.REACT_APP_URL_GRUPO}/` + grupo.ambulatorio_grupo_id + '.png'}
            alt="imggrupo"
            maxWidth="180px"
            maxHeight="70px"
            onClick={() => {
              navigate('/exames/empresa', { state: { grupo, lista_grupos: grupos } });
            }}
          />
        </div>
      </>
    );
  });
  return (
    <>
      <Carousel itemsToShow={4} showArrows={false} FocusOnSelect={true} pagination={false}>
        {grupos && useGrupos}
      </Carousel>
    </>
  );
}

export default ListaGrupos;
