import { Button, ButtonGroup, Heading, HStack, useToast, VStack } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useEffect } from 'react';
import { useState } from 'react';
import * as Yup from 'yup';
import BottomNavBar from '../../../BottomNavBar';
import Select from '../../../SelectField';
import SelectSearch from '../../../SelectFieldSearch2';
import Input from '../../../TextField';
import validaCPF from '../../../Utils/Functions/validaCPF';
import MainChildHeader from '../MainChildHeader';
import requestTimeout from '../../../Utils/Functions/requestTimeout';
import Loading from '../../../Loading';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AccountContext } from '../../../AccountContext';
import ChildHeader from '../ChildHeader';

const FormDadosBancarios = () => {
  const { user, setPage, setUser } = useContext(AccountContext);
  let pixKeyTypes;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [documentType, setDocumentType] = useState('CPF');
  const documentMask = documentType === 'CPF' ? '999.999.999-99' : '99.999.999/9999-99';
  const [bankData, setBankData] = useState(false);

  setPage('Dados Bancários');

  const [banks, setBanks] = useState([{}]);
  const listbanks = banks.map((bank) => bank.nome);

  const bankAccountTypesPagarme = ['conta_corrente', 'conta_poupanca', 'conta_corrente_conjunta', 'conta_poupanca_conjunta'];
  const bankAccountTypes = ['Conta corrente', 'Conta poupanca', 'Conta corrente conjunta', 'Conta poupanca conjunta'];

  if (documentType === 'CPF') {
    pixKeyTypes = ['CPF', 'E-mail', 'Telefone', 'Aleatória'];
  } else {
    pixKeyTypes = ['CNPJ', 'E-mail', 'Telefone', 'Aleatória'];
  }
  const [pixType, setPixType] = useState(null);

  const defaultArgs = { borderRadius: 8, w: '100%', maskChar: null, disabled: bankData ? true : false };
  const defaultArgsSelect = { textAlign: 'left', fontWeight: 'normal', variant: 'outline', onChange: () => {} };
  const timeOutInSeconds = process.env.REACT_APP_REQUEST_TIMEOUT_IN_SECONDS;
  const navigate = useNavigate();
  const toast = useToast();

  let initialValues = {
    nome: documentType === 'CPF' ? (user.name ? user.name.split(' ')[0] : '') : '',
    documento: documentType === 'CPF' ? user.cpf : '',
    agencia: '',
    digitoAgencia: '',
    conta: '',
    digitoConta: '',
    chavePix: '',
  };

  if (bankData) {
    const banco = banks.find((bank) => bank.banco_id === bankData.banco_id);
    const banco_index = banks.indexOf(banco);
    initialValues = {
      nome: bankData.titular,
      documento: bankData.documento,
      agencia: bankData.agencia,
      conta: bankData.conta,
      digitoConta: bankData.digito_conta,
      banco: banco_index,
      tipoConta: bankAccountTypesPagarme.indexOf(bankData.tipo_conta),
      tipoPix: pixKeyTypes.indexOf(bankData.tipo_chave_pix),
      chavePix: bankData.chave_pix,
    };
  }

  const validationSchema = Yup.object({
    nome: Yup.string().required('Campo obrigatório'),
    documento:
      documentType === 'CPF'
        ? Yup.string()
            .required('Campo obrigatório')
            .trim()
            .test('valida_cpf', 'Cpf inválido', (cpf) => validaCPF(cpf))
        : Yup.string()
            .required('Campo obrigatório')
            .trim()
            .matches(/(^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$)/, 'CNPJ inválido'),
    banco: Yup.number().required('Campo obrigatório'),
    agencia: Yup.string().required('Campo obrigatório'),
    digitoAgencia: Yup.string(),
    conta: Yup.string().required('Campo obrigatório'),
    digitoConta: Yup.string().required('Campo obrigatório'),
    tipoConta: Yup.number().required('Campo obrigatório'),
    tipoPix: Yup.number().required('Campo obrigatório'),
    chavePix: validaChavePix(),
  });

  let pixInputType;
  let pixInputMask;
  switch (pixType) {
    case 'CPF':
      pixInputType = 'tel';
      pixInputMask = '999.999.999-99';
      break;
    case 'CNPJ':
      pixInputType = 'tel';
      pixInputMask = '99.999.999/9999-99';
      break;
    case 'E-mail':
      pixInputType = 'email';
      pixInputMask = '';
      break;
    case 'Telefone':
      pixInputType = 'tel';
      pixInputMask = '(99) 99999-9999';
      break;
    default:
      pixInputType = 'text';
      pixInputMask = '';
      break;
  }

  const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');

  async function postBankData(payload) {
    const url = process.env.REACT_APP_BASE_URL_API_SERVER + '/entrepreneur/bank-data';
    const responseRequest = await fetchDatas(url, 'post', payload);

    if (responseRequest.success) {
      setUser((prevState) => ({ ...prevState, recipient_id: responseRequest.data ? responseRequest.data.recipient_id_pagarme : null }));
      navigate('/alerts/sucesso/dados_bancarios_empreendedor');
    } else {
      navigate('/alerts/erro/default');
    }
  }

  async function onSubmit(values) {
    setLoading(true);
    const payload = {
      empreendedor_id: user.id_empreendedor,
      banco_id: banks[values.banco].banco_id,
      tipo_chave_pix: pixKeyTypes[values.tipoPix],
      chave_pix: values.chavePix,
      bank_account: {
        bank_code: banks[values.banco].codigo,
        agencia: values.agencia,
        //agencia_dv: values.digitoAgencia,
        conta: values.conta,
        conta_dv: values.digitoConta,
        type: bankAccountTypesPagarme[values.tipoConta],
        document_type: documentType.toLowerCase(),
        document_number: onlyNumbers(values.documento),
        legal_name: values.nome,
      },
    };
    postBankData(payload);
  }

  function validaChavePix() {
    switch (pixType) {
      case 'CPF':
        return Yup.string()
          .required('Campo obrigatório')
          .trim()
          .test('valida_cpf', 'Cpf inválido', (cpf) => validaCPF(cpf));
      case 'E-mail':
        return Yup.string().trim().email('O email digitado é inválido').required('Campo obrigatório');
      case 'Telefone':
        return Yup.string()
          .required('Campo obrigatório')
          .trim()
          .matches(/^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/, 'Telefone Inválido');
      default:
        return Yup.string().required('Campo obrigatório');
    }
  }

  async function fetchDatas(url, verb, payload) {
    let options;
    if (verb === 'get') {
      options = {
        signal: requestTimeout(timeOutInSeconds).signal,
        method: 'GET',
      };
    } else if (verb === 'post') {
      options = {
        signal: requestTimeout(timeOutInSeconds).signal,
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      };
    }
    try {
      const respondeData = await fetch(url, options);
      return await respondeData.json();
    } catch (error) {
      setError(true);
      setLoading(false);
      console.log(error);
    }
  }

  async function getDatas() {
    const urlBanks = process.env.REACT_APP_BASE_URL_API_SERVER + '/banks/';
    const urlBankData = process.env.REACT_APP_BASE_URL_API_SERVER + '/entrepreneur/bank-data/' + user.id_empreendedor;
    const requestBanks = await fetchDatas(urlBanks);
    const requestBankData = await fetchDatas(urlBankData);
    if (requestBanks.success && requestBankData.success) {
      requestBankData.data && setDocumentType(requestBankData.data.tipo_documento.toUpperCase());
      setBanks(requestBanks.data);
      setBankData(requestBankData.data);
    } else {
      setError(true);
    }
    setLoading(false);
  }

  useEffect(() => {
    getDatas();
  }, []);

  return loading ? (
    <Loading />
  ) : error ? (
    navigate('/alerts/erro/default')
  ) : (
    <Formik initialValues={initialValues} validateOnChange={false} validateOnBlur={false} validationSchema={validationSchema} onSubmit={onSubmit}>
      <VStack as={Form} w={{ base: '90%', md: '500px' }} align="start" mx="auto" mt="5" mb="20" spacing={5}>
        <Heading size="md">Informe os dados bancários para o recebimento de seus serviços</Heading>
        <Input
          {...defaultArgs}
          name="nome"
          placeholder={documentType === 'CPF' ? 'Primeiro Nome' : 'Razão Social'}
          type="text"
          mask=""
          maxlength="20"
          style={{ textTransform: 'capitalize' }}
        />
        <ButtonGroup w="100%">
          <Button {...defaultArgs} colorScheme={documentType === 'CPF' ? 'teal' : null} onClick={() => setDocumentType('CPF')}>
            CPF
          </Button>
          <Button {...defaultArgs} colorScheme={documentType === 'CNPJ' ? 'teal' : null} onClick={() => setDocumentType('CNPJ')}>
            CNPJ
          </Button>
        </ButtonGroup>
        <Input {...defaultArgs} name="documento" placeholder={documentType} type="tel" mask={documentMask} />
        <SelectSearch {...defaultArgs} {...defaultArgsSelect} name="banco" placeholder="Selecione o banco" options={listbanks} />
        <HStack w="100%" align="start">
          <Input {...defaultArgs} w="50%" name="agencia" placeholder="Agência" type="tel" mask="9999" />
          {/*<Input {...defaultArgs} name="digitoAgencia" placeholder="Dígito" type="tel" mask="9" />*/}
        </HStack>
        <HStack w="100%" align="start">
          <Input {...defaultArgs} name="conta" placeholder="Conta" type="tel" mask="9999999" />
          <Input {...defaultArgs} name="digitoConta" placeholder="Dígito" type="tel" mask="9" />
        </HStack>
        <Select {...defaultArgs} {...defaultArgsSelect} name="tipoConta" placeholder="Selecione o tipo de conta" options={bankAccountTypes} />
        <Select
          {...defaultArgs}
          {...defaultArgsSelect}
          name="tipoPix"
          placeholder="Selecione o tipo de chave pix"
          options={pixKeyTypes}
          onChange={(index) => setPixType(pixKeyTypes[index])}
        />
        <Input {...defaultArgs} name="chavePix" placeholder="Chave pix" type={pixInputType} mask={pixInputMask} />

        {bankData ? (
          <Button
            {...defaultArgs}
            disabled={false}
            colorScheme="teal"
            onClick={() => {
              toast({
                title: 'Aviso',
                description: 'Entre em contato via whatsapp para fazer a edição dos dados',
                status: 'info',
                duration: 4000,
                isClosable: true,
                position: 'bottom',
                colorScheme: 'green',
                containerStyle: {
                  paddingTop: '50px',
                },
              });
            }}
          >
            Editar
          </Button>
        ) : (
          <Button {...defaultArgs} colorScheme="teal" type="submit">
            Salvar
          </Button>
        )}
      </VStack>
    </Formik>
  );
};

const DadosBancarios = () => {
  return (
    <>
      <ChildHeader />
      <FormDadosBancarios />
      <BottomNavBar />
    </>
  );
};

export default DadosBancarios;
