import { VStack, Heading, Text, Button, Wrap } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import TextField from '../../TextField';
import ChangeTitle from '../../ChangeTitle';
import PageBack from '../../PageBack';
import validaCPF from '../../Utils/Functions/validaCPF';
import NoUserHeader from '../NoUserHeader';
import { useContext } from 'react';
import { AccountContext } from '../../AccountContext';
import { useLocation } from 'react-router';

const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');

const CpfLivre = () => {
  ChangeTitle('Dados básicos');
  const { setPage } = useContext(AccountContext);
  setPage('Dados básicos');
  const [userData, setUserData] = useState([]);
  const [error, setError] = useState(null);
  const [error2, setError2] = useState(null);
  const { state } = useLocation();
  const navigate = useNavigate();
  //ajustar link para redirecionar para recuperação de senha
  return (
    <>
      <NoUserHeader />
      <Formik
        initialValues={{ cpf: '' }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={Yup.object({
          cpf: Yup.string()
            .required('Cpf Obrigatório!')
            .trim()
            .test('valida_cpf', 'Cpf inválido', (cpf) => validaCPF(cpf)),
        })}
        onSubmit={(values, actions) => {
          const vals = { ...values };
          let cpf = onlyNumbers(vals.cpf);
          fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/clients/bycpf/${cpf}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
          })
            .catch((err) => {
              return err;
            })
            .then((res) => {
              if (!res || !res.ok || res.status >= 400) {
                setError('Não foi possível completar a consulta do seu cpf nesse momento');
                return;
              }
              return res.json();
            })
            .then((data) => {
              if (!data) return;
              if (!data.success) {
                const paciente = {
                  cpf: onlyNumbers(vals.cpf),
                };
                navigate('/area-livre/exames/dados-basicos', {
                  state: {
                    ...state,
                    paciente: paciente,
                  },
                });
              } else {
                setUserData(data.data[0]);
                setError('Identificamos que você já possui um cadastro em nosso sistema. Caso tenha esquecido a senha');
                setError2('Clique aqui para recuperar sua senha.');
              }
            });
        }}
      >
        <VStack as={Form} w={{ base: '90%', md: '500px' }} m="auto" justify="top" marginTop="5%" marginBottom="5%" spacing="1rem" align="start">
          <Heading fontSize="1em">Primeiro, insira seu número de CPF</Heading>
          <TextField name="cpf" type="tel" placeholder="Digite seu CPF" mask="999.999.999-99" maskChar={null} />
          <Text as="p" fontSize="small">
            Precisamos do seu número de CPF para identificação no sistema.
          </Text>
          <Text as="p" color="red.500">
            {error}
            <Wrap
              pl="5px"
              color="green.500"
              paddingTop="1em"
              paddingBottom="1em"
              onClick={() =>
                navigate('/recuperacao/codigo', {
                  state: {
                    name: userData.nome,
                    phone: userData.celular,
                    cpf: userData.cpf,
                  },
                })
              }
            >
              {error2}
            </Wrap>
          </Text>
          <Button colorScheme="teal" w="100%" type="submit">
            Avançar
          </Button>
        </VStack>
      </Formik>
    </>
  );
};

export default CpfLivre;
