import { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AccountContext } from './AccountContext';
import Opening from './Auth/Abertura';
//import Opening from './Auth/SistemaEmManutencao';
import Login from './Auth/Login';
import SucessoGenerico from './Alerts/Sucesso';
import ErroGenerico from './Alerts/Error';
import PrivateRoutes from './PrivateRoutes';
// cadastro
import Cadastro from './Cadastro/Cpf';
import DadosBasicos from './Cadastro/DadosBasicos';
import DadosAdc from './Cadastro/DadosAdc';
import Senha from './Cadastro/Senha';
import Endereco from './Cadastro/Endereco';
import Conferencia from './Cadastro/Conferencia';
import QrCodeCadastro from './Cadastro/QrCode';
import FimCadastroCliente from './Cadastro/FimCadastroCliente';
import Adicionais from './Cadastro/Adicionais';
import ResumoPagamento from './Cadastro/ResumoPagamento';
import Plano from './Cadastro/Plano';
import PlanoModo from './Cadastro/PlanoModo';
import CadastroFim from './Cadastro/Fim';
import ConfirmaTelefone from './Cadastro/ConfirmacaoTelefone';
import TrocarSenha from './Auth/Recuperacao/TrocarSenha';
import PlanoPendente from './Auth/PagamentoEmAnalise';
// welcome-screen
import ComprarAdicionais from './Dashboard/Main/WelcomeScreen/ComprarAdicionais';
import ResumoCompra from './Dashboard/Main/WelcomeScreen/ResumoPagamento';
import ProcessarCompraAdicionais from './Dashboard/Main/WelcomeScreen/ProcessarCompra';
// dashboard
import Main from './Dashboard/Main/Main';
// pronto atendimento
import InicioPa from './Dashboard/Main/ProntoAtendimento/Inicio';
import TermosPa from './Dashboard/Main/ProntoAtendimento/Termos';
import ComorbidadesPa from './Dashboard/Main/ProntoAtendimento/Comorbidades';
import SintomasPa from './Dashboard/Main/ProntoAtendimento/Sintomas';
import FimPa from './Dashboard/Main/ProntoAtendimento/Fim';
import AtendimentoPa from './Dashboard/Main/ProntoAtendimento/Atendimento';
import ProcessarCompra from './Dashboard/Main/ProntoAtendimento/ProcessarCompra';
// atendimento
import EscolhaProcedimento from './Dashboard/Main/Atendimento/EscolhaProcedimento';
import Agendamento from './Dashboard/Main/Atendimento/Agendamento';
import ConferePedido from './Dashboard/Main/Atendimento/ConferePedido';
import LoadingAtendimento from './Dashboard/Main/Atendimento/Loading';
// Agendamento de Exames
import AgendamentoExames from './Dashboard/Main/Exames/Agendamento';
import EscolheEmpresa from './Dashboard/Main/Exames/EscolheEmpresa';
import PedidoExame from './Dashboard/Main/Exames/ConferePedido';
import FinalizarExame from './Dashboard/Main/Exames/Finalizar';
import ExamesLoading from './Dashboard/Main/Exames/Loading';
// perfil
import Consultas from './Perfil/Historico/ConsultasMarcadas';
import AtualizarEndereco from './Perfil/Usuario/Endereco';
import Perfil from './Perfil/Main';
import DadosPessoais from './Perfil/Usuario/DadosPessoais';
import DadosBancarios from './Perfil/Usuario/DadosBancarios';
import EditarCartao from './Perfil/Usuario/Cartao';
import Detalhes from './Perfil/Historico/Detalhes';
import DetalhesAnalise from './Perfil/Historico/DetalhesEmAnalise';
import CancelamentoConsulta from './Perfil/Historico/CancelamentoConsulta';
// pagamento
import Pagamentos from './Pagamento/Menu';
// dependentes
import Dependentes from './Perfil/Dependentes/Main';
import CadastraDependente from './Perfil/Dependentes/Cadastro/Cadastro';
import CadastraDependentePet from './Perfil/Dependentes/Cadastro/CadastroPet';
import EditarDependente from './Perfil/Dependentes/Cadastro/Editar';
import AvisoExclusaoDependente from './Perfil/Dependentes/Cadastro/Exluir';
import ProcessarExclusaoDependente from './Perfil/Dependentes/Cadastro/ProcessarExclusao';
// recuperacao
import GeraCodigo from './Auth/Recuperacao/GeraCodigo';
import RecuperaSenha from './Auth/Recuperacao/RecuperaSenha';
import CheckCpf from './Auth/Recuperacao/CheckCpf';
import SecurityQuestions from './Auth/Recuperacao/SecurityQuestions';
import UpdatePhone from './Auth/Recuperacao/UpdatePhone';
import Loading from './Loading';
// perfil empresa
import DetalhesEmpresa from './PerfilEmpresa/Empresa/DetalhesEmpresa';
import CurriculoMedico from './PerfilEmpresa/Medico/CurriculoMedico';
import CurriculoDetalhes from './PerfilEmpresa/Medico/CurriculoDetalhes';
// Exames
import PedidoExameLivre from './AreaLivre/Exames/ConferePedidoLivre';
import ExamesCpfLivre from './AreaLivre/Exames/CpfLivre';
import ExamesDadosLivre from './AreaLivre/Exames/DadosBasicosLivre';
// empreendedor
import AreaEmpreendedor from './Dashboard/Main/Empreendedor/Main';
import AtivaEmpreendedor from './Dashboard/Main/Empreendedor/AtivaEmpreendedor';
import DadosBancariosEmpreendedor from './Dashboard/Main/Empreendedor/DadosBancarios';
import DadosResponsavel from './Dashboard/Main/Empreendedor/CadastroEstabelecimento/DadosResponsavel';
import EnderecoCredenciado from './Dashboard/Main/Empreendedor/CadastroEstabelecimento/Endereco';
import SenhaClinica from './Dashboard/Main/Empreendedor/CadastroEstabelecimento/SenhaClinica';
import DadosClinica from './Dashboard/Main/Empreendedor/CadastroEstabelecimento/DadosClinica';
import DadosBancariosClinica from './Dashboard/Main/Empreendedor/CadastroEstabelecimento/DadosBancariosClinica';
import DetalhesClinica from './Dashboard/Main/Empreendedor/CadastroEstabelecimento/DetalhesClinica';
import FimCadastroEstabelecimento from './Dashboard/Main/Empreendedor/CadastroEstabelecimento/Fim';
import QrCode from './Dashboard/Main/Empreendedor/QrCode/GerarQrCode';
import MaterialApoio from './Dashboard/Main/Empreendedor/MaterialApoio/Main';
import ExtratoEmpreendedor from './Dashboard/Main/Empreendedor/Extrato/Main';
import Contratos from './Dashboard/Main/Empreendedor/Contratos/Main';
import Vendedores from './Dashboard/Main/Empreendedor/Vendedores/Main';
import Estabelecimentos from './Dashboard/Main/Empreendedor/Estabelecimentos/Main';

// -------------------------Area Livre--------------------------------
import MainLivre from './AreaLivre/DashboardLivre/MainLivre';
import SucessoLivre from './AreaLivre/DashboardLivre/Orientacoes';
// cadastro area livre
import DadosBasicosLivre from './AreaLivre/Cadastro/DadosBasicosLivre';
import DetalhesLivre from './AreaLivre/Atendimento/DetalhesLivre';
import CpfLivre from './AreaLivre/Cadastro/CpfLivre';
// atendimeto area livre
import AgendamentoLivre from './AreaLivre/Atendimento/AgendamentoLivre';
import ConferePedidoLivre from './AreaLivre/Atendimento/ConferePedidoLivre';
import ConsultasLivre from './AreaLivre/Atendimento/ConsultasLivre';
// pronto atendimento livre
import AtendimentoLivre from './AreaLivre/ProntoAtendimentoLivre/AtendimentoLivre';
import AvisoLivre from './AreaLivre/ProntoAtendimentoLivre/AvisoLivre';
import ComorbidadesLivre from './AreaLivre/ProntoAtendimentoLivre/ComorbidadesLivre';
import FimLivre from './AreaLivre/ProntoAtendimentoLivre/FimLivre';
import TermosLivre from './AreaLivre/ProntoAtendimentoLivre/TermosLivre';
import SintomasLivre from './AreaLivre/ProntoAtendimentoLivre/SintomasLivre';
import ProcessarCompraLivre from './AreaLivre/ProntoAtendimentoLivre/ProcessarCompraLivre';
import CpfLivrepa from './AreaLivre/ProntoAtendimentoLivre/CpfLivrepa';
import DadosBasicosLivrepa from './AreaLivre/ProntoAtendimentoLivre/DadosBasicosLivrepa';
// perfil empresa livre
import DetalhesEmpresaLivre from './AreaLivre/PerfilEmpresa/Empresa/DetalhesEmpresaLivre';
import ListaMedicosLivre from './AreaLivre/PerfilEmpresa/Medico/ListaMedicosLivre';
import CurriculoMedicoLivre from './AreaLivre/PerfilEmpresa/Medico/CurriculoMedicoLivre';
// Telemedicina
import Telemedicina from './Dashboard/Main/Telemedicina/Main';
// Carrinho de Compras
import CarrinhoCompras from './Dashboard/Main/Carrinho/Main';
import Checkin from './Dashboard/Main/Checkin/Checkin';
//Telepet
import TelepetTermos from './Dashboard/Main/Telemedicina/Telepet/Termos';
import TelepetFila from './Dashboard/Main/Telemedicina/Telepet/Fila';
//MedicoAgora
import MedicoAgora from './Dashboard/Main/Telemedicina/MedicoAgora/Main';
import MedicoAgoraEspecialistas from './Dashboard/Main/Telemedicina/Especialista/Main';
import PagamentoEmAnalise from './Auth/PagamentoEmAnalise';

function Views() {
  const { user } = useContext(AccountContext);

  return user.loggedIn === null ? (
    <Loading />
  ) : (
    <Routes>
      <Route path="/" element={<Cadastro />} />
      <Route path="/login" element={<QrCodeCadastro />} />
      <Route path="/cadastro" element={<Cadastro />} />
      <Route path="/cadastro/dadosbasicos" element={<DadosBasicos />} />
      <Route path="/cadastro/dadosadc" element={<DadosAdc />} />
      <Route path="/cadastro/senha" element={<Senha />} />
      <Route path="/cadastro/endereco" element={<Endereco />} />
      <Route path="/cadastro/conferencia" element={<Conferencia />} />
      <Route path="/cadastro/qrcode" element={<QrCodeCadastro />} />
      <Route path="/cadastro/fim-cadastro-cliente" element={<FimCadastroCliente />} />
      <Route path="/cadastro/plano" element={<Plano />} />
      <Route path="/cadastro/plano-modo" element={<PlanoModo />} />
      <Route path="/cadastro/adicionais" element={<Adicionais />} />
      <Route path="/cadastro/resumo-pagamento" element={<ResumoPagamento />} />
      <Route path="/cadastro/fim" element={<CadastroFim />} />
      <Route path="/cadastro/confirmacao" element={<ConfirmaTelefone />} />
      <Route path="/cadastro/analise" element={<PlanoPendente />} />
      <Route path="/agendamento" element={<Agendamento />} />
      <Route path="/fimpa" element={<FimPa />} />
      <Route path="/iniciopa" element={<InicioPa />} />
      <Route path="/termospa" element={<TermosPa />} />
      <Route path="/comorbidadespa" element={<ComorbidadesPa />} />
      <Route path="/sintomaspa" element={<SintomasPa />} />
      <Route path="/pa/atendimento" element={<AtendimentoPa />} />
      <Route path="/pronto-atendimento/compra" element={<ProcessarCompra />} />

      {/* -------------------------- Atendimento ------------------------------------------ */}
      <Route path="/atendimento/escolha-procedimento" element={<EscolhaProcedimento />} />
      <Route path="/atendimento/agendamento" element={<Agendamento />} />
      <Route path="/atendimento/pedido" element={<ConferePedido />} />
      <Route path="/atendimento/loading/" element={<LoadingAtendimento />} />
      {/* -------------------------- Atendimento ------------------------------------------ */}

      <Route path="/recuperacao/codigo" element={<GeraCodigo />} />
      <Route path="/recuperacao/cpf" element={<CheckCpf />} />
      <Route path="recuperacao/trocarsenha" element={<TrocarSenha />} />
      <Route path="/recuperacao/security-questions" element={<SecurityQuestions />} />
      <Route path="/perfil/main" element={<Perfil />} />
      <Route path="/perfil/dependentes" element={<Dependentes />} />
      <Route path="/perfil/dependentes/cadastro" element={<CadastraDependente />} />
      <Route path="/perfil/dependentes/cadastro-pet" element={<CadastraDependentePet />} />
      <Route path="/perfil/dependentes/editar" element={<EditarDependente />} />
      <Route path="/perfil/dependentes/aviso-exclusao" element={<AvisoExclusaoDependente />} />
      <Route path="/perfil/dependentes/processar-exclusao" element={<ProcessarExclusaoDependente />} />
      <Route path="/perfil/dados-pessoais" element={<DadosPessoais />} />
      <Route path="/perfil/dados-bancarios" element={<DadosBancarios />} />
      <Route path="/perfil/editar-cartao" element={<EditarCartao />} />
      <Route path="/recuperacao/codigo" element={<GeraCodigo />} />
      <Route path="/recuperacao/senha" element={<RecuperaSenha />} />
      <Route path="/recuperacao/atualizar-fone" element={<UpdatePhone />} />
      <Route path="/perfil/main" element={<Perfil />} />
      <Route path="/perfil/endereco" element={<AtualizarEndereco />} />
      <Route path="/empresa/detalhes" element={<DetalhesEmpresa />} />
      <Route path="/empresa/medico/curriculo" element={<CurriculoMedico />} />
      <Route path="/empresa/medico/curriculodetalhes" element={<CurriculoDetalhes />} />
      <Route path="/alerts/sucesso/:rota" element={<SucessoGenerico />} />
      <Route path="/alerts/erro/:rota" element={<ErroGenerico />} />
      <Route path="/pagamentos" element={<Pagamentos />} />
      <Route path="/consultas" element={<Consultas />} />
      <Route path="/consultas/detalhes" element={<Detalhes />} />
      <Route path="/consultas/detalhes-analise" element={<DetalhesAnalise />} />
      <Route path="/consultas/cancelamento" element={<CancelamentoConsulta />} />
      <Route path="/welcome-screen/comprar-adicionais" element={<ComprarAdicionais />} />
      <Route path="/welcome-screen/resumo-compra" element={<ResumoCompra />} />
      <Route path="/welcome-screen/processar-compra" element={<ProcessarCompraAdicionais />} />
      <Route path="/carrinho" element={<CarrinhoCompras />} />

      {/* ------------------------------Area Livre------------------------------------------*/}
      <Route path="/mainlivre" element={<MainLivre />} />
      <Route path="/listamedicoslivre" element={<ListaMedicosLivre />} />
      <Route path="/agendamentolivre" element={<AgendamentoLivre />} />
      <Route path="/atendimentolivre" element={<AtendimentoLivre />} />
      <Route path="/avisolivre" element={<AvisoLivre />} />
      <Route path="/cpflivre" element={<CpfLivre />} />
      <Route path="/cadastro/dadosbasicoslivre" element={<DadosBasicosLivre />} />
      <Route path="/comorbidadespalivre" element={<ComorbidadesLivre />} />
      <Route path="/fimpalivre" element={<FimLivre />} />
      <Route path="/sintomaspalivre" element={<SintomasLivre />} />
      <Route path="/termospalivre" element={<TermosLivre />} />
      <Route path="/curriculolivre" element={<CurriculoMedicoLivre />} />
      <Route path="/empresa/detalheslivre" element={<DetalhesEmpresaLivre />} />
      <Route path="/conferepedidolivre" element={<ConferePedidoLivre />} />
      <Route path="/pronto-atendimento/pagamento-avulso" element={<ProcessarCompraLivre />} />
      <Route path="/cpflivrepa" element={<CpfLivrepa />} />
      <Route path="/cadastro/dadosbasicoslivrepa" element={<DadosBasicosLivrepa />} />
      <Route path="/telemedicina" element={<Telemedicina />} />

      <Route path="/area-livre/exames/conferencia" element={<PedidoExameLivre />} />
      <Route path="/area-livre/exames/cpf" element={<ExamesCpfLivre />} />
      <Route path="/area-livre/exames/dados-basicos" element={<ExamesDadosLivre />} />
      <Route path="/consultaslivre" element={<ConsultasLivre />} />
      <Route path="/detalheslivre" element={<DetalhesLivre />} />
      {/* ------------------------------Area Livre------------------------------------------*/}

      {/* ------------------------------Exames------------------------------------------*/}
      <Route path="/exames/agendamento" element={<AgendamentoExames />} />
      <Route path="/exames/empresa" element={<EscolheEmpresa />} />
      <Route path="/exames/conferencia" element={<PedidoExame />} />
      <Route path="/exames/loading" element={<ExamesLoading />} />
      <Route path="/exames/finalizar" element={<FinalizarExame />} />
      {/* ------------------------------Exames------------------------------------------*/}

      {/* ------------------------------Empreendedor------------------------------------------*/}
      <Route path="/empreendedor" element={<AreaEmpreendedor />} />
      <Route path="/empreendedor/ativar" element={<AtivaEmpreendedor />} />
      <Route path="/empreendedor/dados-bancarios" element={<DadosBancariosEmpreendedor />} />
      <Route path="/empreendedor/cadastro-estabelecimento/dados-responsavel" element={<DadosResponsavel />} />
      <Route path="/empreendedor/cadastro-estabelecimento/endereco" element={<EnderecoCredenciado />} />
      <Route path="/empreendedor/cadastro-estabelecimento/senha" element={<SenhaClinica />} />
      <Route path="/empreendedor/cadastro-estabelecimento/dados-clinica" element={<DadosClinica />} />
      <Route path="/empreendedor/cadastro-estabelecimento/dados-bancarios" element={<DadosBancariosClinica />} />
      <Route path="/empreendedor/cadastro-estabelecimento/detalhes-clinica" element={<DetalhesClinica />} />
      <Route path="/empreendedor/cadastro-estabelecimento/fim" element={<FimCadastroEstabelecimento />} />
      <Route path="/empreendedor/qrcode" element={<QrCode />} />
      <Route path="/emprendedor/materialapoio" element={<MaterialApoio />} />
      <Route path="/empreendedor/extrato" element={<ExtratoEmpreendedor />} />
      <Route path="/empreendedor/contratos" element={<Contratos />} />
      <Route path="/empreendedor/vendedores" element={<Vendedores />} />
      <Route path="/empreendedor/estabelecimentos" element={<Estabelecimentos />} />
      <Route path="/arealivre/sucesso" element={<SucessoLivre />} />
      {/* ------------------------------Empreendedor------------------------------------------*/}
      <Route path="/checkin" element={<Checkin />} />
      {/* ------------------------------Telepet------------------------------------------*/}
      <Route path="/telepet/termos" element={<TelepetTermos />} />
      <Route path="/telepet/fila" element={<TelepetFila />} />
      {/* ------------------------------Telepet------------------------------------------*/}
      {/* ------------------------------MedicoAgora------------------------------------------*/}
      <Route path="/medico-agora/" element={<MedicoAgora />} />
      <Route path="/medico-agora/especialista" element={<MedicoAgoraEspecialistas />} />
      {/* ------------------------------MedicoAgora------------------------------------------*/}
      <Route path="/" element={<Cadastro />} />
      <Route element={<PrivateRoutes />}>
        <Route path="/dashboard" element={<Cadastro />} />
      </Route>
      <Route path="*" element={<Cadastro />} />
    </Routes>
  );
}

export default Views;
