import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
  Input,
  chakra,
  Button,
  Stack,
  Text,
  Flex,
  Heading,
  WrapItem,
  Select,
  FormLabel,
  Center,
} from '@chakra-ui/react';
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../../AccountContext';
import BottomNavBarLivre from '../BottomNavBarLivre';
import NoUserHeader from '../NoUserHeader';
import { Formik, Form } from 'formik';
import TextField from 'components/TextField';
import * as Yup from 'yup';

function ConsultasLivre() {
  const { user } = useContext(AccountContext);
  const navigate = useNavigate();
  const { setPage } = useContext(AccountContext);
  const [atendimentos, setAtendimentos] = useState([]);
  const [client, setClient] = useState(user.username);
  const [loading, setLoading] = useState(false);
  const [pendenteAva, setPendenteAva] = useState('');
  const [pendenteModal, showPendente] = useState(false);
  const [cpf, setCpf] = useState('');
  const [inputError, setInputError] = useState('');

  setPage('Consultas');

  function myToLowerCase(text) {
    if (typeof text === 'string') {
      return text.toLocaleLowerCase();
    } else {
      return text;
    }
  }

  const noShow = 'No-show';
  const confirmedByPatient = 'Confirmed by patient';
  const approved = 'Approved';
  const checkout = 'Check-out';

  const fetchAppointments = async (cpf) => {
    const url = `${process.env.REACT_APP_BASE_URL_API_SERVER}/appointments/${cpf.replace(/[^0-9]/g, '')}`;
    const response = await fetch(url, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    const postsData = await response.json();
    setAtendimentos(postsData);
  };

  const Schema = Yup.object({
    cpf: Yup.string()
      .required('cpf is required')
      .test((value) => cpf.isValid(value)),
  });

  const SelectClient = () => {
    const handleChange = (e) => {
      if (e.target.value.length === 15) {
        window.alert('Digite um CPF válido');
      }

      setCpf(e.target.value);
    };

    return (
      <>
        <Stack direction="row">
          {/* <Input width="auto" value={cpf} placeholder="Digite seu CPF"  /> */}
          <Formik validateOnChange={false} validateOnBlur={false} initialValues={{ cpf: '' }} validationSchema={Schema}>
            <TextField
              name="cpf"
              type="tel"
              placeholder="Digite seu CPF"
              mask="999.999.999-99"
              mt={-2}
              maskChar={null}
              maxLength={15}
              variant="outline"
              _placeholder={{ color: 'teal' }}
              value={cpf}
              autoFocus={true}
              onChange={handleChange}
            />
          </Formik>
          <Button
            colorScheme="teal"
            isLoading={loading}
            onClick={() => {
              fetchAppointments(cpf);
            }}
          >
            Buscar
          </Button>
        </Stack>
      </>
    );
  };

  const Atendimento = (props) => {
    let atendimentosFiltrados = [];
    if (props.guia === 'agendamento') {
      atendimentosFiltrados = atendimentos.filter(
        (atendimento) => atendimento.STATUS === noShow || atendimento.STATUS === checkout || atendimento.STATUS === confirmedByPatient
      );
    } else if (props.guia === 'historico') {
      atendimentosFiltrados = atendimentos.filter((atendimento) => atendimento.STATUS === approved);
    }

    return atendimentosFiltrados.map((atendimento) => {
      let statusAtual = '';
      let statusColor = 'black';
      if (atendimento.STATUS === noShow) {
        statusColor = 'yellow.500';
        statusAtual = 'Agendado';
      }
      if (atendimento.STATUS === approved) {
        statusColor = 'green.500';
        statusAtual = 'Concluído';
        setPendenteAva(atendimento);
      }
      if (atendimento.STATUS === checkout) {
        statusColor = 'green.500';
        statusAtual = 'Agendado';
      }
      if (atendimento.STATUS === confirmedByPatient) {
        statusColor = 'green.500';
        statusAtual = 'Confirmado';
      }

      const data = new Date(atendimento.APP_DATE.replace(' ', 'T'));

      const dataextenso = data.toLocaleDateString('pt-br', { weekday: 'long' });

      const monthNameLong = data.toLocaleString('pt-br', { month: 'long' });

      return (
        <>
          <Flex
            minW="210px"
            maxW="340px"
            mx="auto"
            alignItems="center"
            overflow="hidden"
            border="1px solid"
            borderColor={statusColor}
            borderRadius="10px"
            padding="1em"
            marginTop={5}
            px={5}
            pt={5}
          >
            <VStack alignItems="right" spacing={2.5}>
              <Heading fontSize="sm">
                {atendimento.ACTIVITY_NAME ? atendimento.ACTIVITY_NAME : 'CONSULTA COM ' + atendimento.ESPECIALIDADE.toUpperCase()}
              </Heading>
              <Text ml="-5" fontSize="0.7em" casing="capitalize">
                Dr. {myToLowerCase(atendimento.RESOURCE_NAME)}
              </Text>
              <chakra.p fontSize="0.7em">
                <chakra.span fontWeight="bold"> Data do agendamento: </chakra.span>
                <Text casing="capitalize">
                  {' '}
                  {atendimento.DATA_ATUALIZACAO ? atendimento.DATA_ATUALIZACAO.split(':')[0] + ':' + atendimento.DATA_ATUALIZACAO.split(':')[1] : ''}.
                </Text>
              </chakra.p>
              <chakra.p fontSize="0.7em">
                <chakra.span fontWeight="bold"> Data da consulta: </chakra.span>
                <Text casing="capitalize">
                  {dataextenso}, {data.getDate()} de {monthNameLong} de {data.getFullYear()} as{' '}
                  {atendimento.START_TIME ? atendimento.START_TIME.split(':')[0] + ':' + atendimento.START_TIME.split(':')[1] : ''}.
                </Text>
              </chakra.p>

              <chakra.p ml="10" fontSize="0.7em">
                {atendimento.NAME}
              </chakra.p>
              <chakra.p fontSize="0.7em">
                <Text casing="capitalize">
                  <chakra.span fontWeight="bold">PACIENTE: </chakra.span>
                  {myToLowerCase(atendimento.USER_FIRST_NAME)}
                  <chakra.span fontWeight="bold"> STATUS: </chakra.span>
                  {statusAtual}
                </Text>
              </chakra.p>

              <Button
                mt="4"
                borderRadius="10px"
                background="#5AB2AD;"
                color="white"
                size="sm"
                maxwidth="30em"
                height="10"
                onClick={() => navigate('/detalheslivre', { state: { atendimento: atendimento, statusAtual } })}
              >
                ↓ Detalhes
              </Button>
            </VStack>
          </Flex>
        </>
      );
    });
  };

  return (
    <>
      <NoUserHeader />

      <Tabs px={{ base: 2, sm: 4 }} py={5}>
        <SelectClient pb="5" />

        <TabList>
          <Tab>Agendamento</Tab>
          <Tab>Historico</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Atendimento guia="agendamento" />
          </TabPanel>
          <TabPanel>
            <Atendimento guia="historico" />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <WrapItem mt="20">
        <BottomNavBarLivre />
      </WrapItem>
    </>
  );
}

export default ConsultasLivre;
