import React from 'react';
import ContratosInfo from './Contratos/Info';

function ListaContratos(lista) {
  const contratosLista = Object.keys(lista).map((key) => {
    const useContrato = Object.keys(lista[key]).map((contrato) => {
      return (
        <>
          <ContratosInfo data={lista[key][contrato]} />
        </>
      );
    });
    return <>{useContrato}</>;
  });
  return <>{lista && contratosLista}</>;
}

export default ListaContratos;
