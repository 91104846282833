import { VStack } from '@chakra-ui/react'
import React from 'react'
import './dots.css'

export default function DotsLoading (){
    return (
        <VStack
        justify="center"
        >
            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </VStack>
    )
} 