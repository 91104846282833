import React from "react";
import VendedoresInfo from "./Info";

function ListaNiveis(lista) {
  console.log(lista);

  let totalGeral = 0;

  const listaNiveis = Object.keys(lista).map((key) => {
    const useNiveis = Object.keys(lista[key]).map((vendedor) => {
      const vendedorData = lista[key][vendedor];
      console.log(lista[key][vendedor]);
      console.log(vendedorData.qtd);

      totalGeral += parseFloat(lista[key][vendedor].qtd);
      console.log(totalGeral);

      return (
        <>
          <VendedoresInfo data={vendedorData} />
        </>
      );
    });
    return <>{useNiveis}</>;
  });
  return <>{lista && listaNiveis}</>;
}

export default ListaNiveis;
