import { Center, Image } from "@chakra-ui/react";
import React from "react";

function QrCodeImg(props) {
  console.log(props);
  return (
    <>
      <Center>
        <Image mt="2" boxSize="300px" src={props.props}></Image>
      </Center>
    </>
  );
}

export default QrCodeImg;
