import { VStack, Heading, Button } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { React, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '../../TextField';
import ChangeTitle from '../../ChangeTitle';
import NoUserHeader from '../NoUserHeader';

const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');

const DadosBasicosLivre = () => {
  ChangeTitle('Cadastro');
  const [escolha, setEscolha] = useState(null);
  const { state } = useLocation();
  const { agenda, medico, paciente } = state;
  const navigate = useNavigate();

  return (
    <>
      <NoUserHeader />
      <Formik
        initialValues={{ nome: '', email: '', fone: '' }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={Yup.object({
          nome: Yup.string()
            .required('Nome é obrigatório!')
            .matches(/^[A-Za-z]+\s[A-Za-z ]+$/m, 'Escreva o nome e sobrenome pelo menos e sem acentos ou caracteres especiais'),
          email: Yup.string().trim().email('O email digitado é inválido'),
          fone: Yup.string()
            .required('Telefone é obrigatório')
            .trim()
            .matches(/^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/, 'Telefone Inválido'),
        })}
        onSubmit={(values) => {
          const vals = { ...values };
          navigate('/conferepedidolivre', {
            state: {
              ...state,
              paciente: { ...state.paciente, nome: vals.nome, email: vals.email, fone: onlyNumbers(vals.fone) },
            },
          });
        }}
      >
        {(props) => {
          const { setFieldValue } = props;
          return (
            <VStack as={Form} w={{ base: '90%', md: '500px' }} m="auto" justify="top" marginTop="5%" marginBottom="5%" spacing="1rem" align="start">
              <Heading fontSize="1em">Agora vamos continuar seu cadastro com seus dados básicos</Heading>
              <TextField
                name="nome"
                placeholder="Nome completo"
                onChange={(e) => {
                  const value = e.target.value;
                  let arr_names = value.split(' ');
                  for (var i = 0; i < arr_names.length; i++) {
                    arr_names[i] = arr_names[i].charAt(0).toUpperCase() + arr_names[i].slice(1);
                  }
                  let res = arr_names.join(' ');
                  setFieldValue('nome', res);
                }}
              />

              <TextField
                name="email"
                placeholder="E-mail"
                type="email"
                onChange={(e) => {
                  const result = e.target.value.toLowerCase();
                  setFieldValue(e.target.name, result);
                }}
              />

              <TextField name="fone" type="tel" placeholder="Celular com DDD" mask="(99) 99999-9999" maskChar={null} />

              <Button colorScheme="teal" w="100%" type="submit">
                Avançar
              </Button>
            </VStack>
          );
        }}
      </Formik>
    </>
  );
};

export default DadosBasicosLivre;
