import {
  Box,
  Image,
  Spacer,
  Text,
  ButtonModal,
  Stack,
  ModalOverlay,
  Modal,
  Button,
  useDisclosure,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Center,
} from '@chakra-ui/react';
import BgImg from '../../../../../images/Rectangle_286.png';

import { BsInstagram } from 'react-icons/bs';

export default function CardInstagram(props) {
  function BasicUsage() {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
      <>
        <Box
          maxW="160"
          h="170"
          padding="10%"
          borderRadius="24"
          boxShadow="lg"
          textAlign="center"
          opacity="0.8"
          bgColor="#F12900"
          backgroundImage={BgImg}
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          onClick={() => (window.location.href = 'https://www.instagram.com/deovitasaude/')}
        >
          <Box marginTop="0.2em" marginBottom="0.8em" onClick={onOpen}>
            <BsInstagram color="white" fontSize="2em" />
            <Text fontSize="xs" color="white" textAlign="start" fontWeight="bold" mt="3">
              Acompanhe nosso perfil no Instagram
            </Text>
            <Text color="white" fontSize="xs" mt="7">
              @deovitasaude
            </Text>
          </Box>
        </Box>
        <Center></Center>
      </>
    );
  }
  return (
    <div className="App">
      <BasicUsage />
    </div>
  );
}
