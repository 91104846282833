import { VStack, Heading, Text, Button, Wrap } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import TextField from '../TextField';
import ChangeTitle from '../ChangeTitle';
import PageBack from '../PageBack';
import validaCPF from '../Utils/Functions/validaCPF';
import NoUserHeader from '../AreaLivre/NoUserHeader';
import { useContext } from 'react';
import { AccountContext } from '../AccountContext';
import { useEffect } from 'react';

const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');

const Cpf = () => {
  ChangeTitle('Cadastro');
  const { setPage, user, setUser } = useContext(AccountContext);
  setPage('Cadastro');
  const [error, setError] = useState(null);
  const [error2, setError2] = useState(null);
  const [userData, setUserData] = useState([]);
  const navigate = useNavigate();
  const { state } = useLocation();

  const voucherMdv = (cpf) => {
    fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/entrepreneur/voucherMdv`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        cod_indicacao: user.seller,
        cpf: cpf,
      }),
    }).then((res) => {
      if (!res || !res.ok || res.status >= 400) {
        return;
      }
      res.json().then((data) => {
        if (!data || data.success === false) {
          return;
        }
        if (data.success === true && data.data && data.data.length > 0) {
          setUser((prevState) => ({
            ...prevState,
            voucher: data.data[0],
          }));
        }
      });
    });
  };

  return (
    <>
      <NoUserHeader />
      <Formik
        initialValues={{ cpf: '' }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={Yup.object({
          cpf: Yup.string()
            .required('Cpf Obrigatório!')
            .trim()
            /*.matches(
                            "([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})",
                            "Cpf inválido"
                        )*/
            .test('valida_cpf', 'Cpf inválido', (cpf) => validaCPF(cpf)),
        })}
        onSubmit={(values, actions) => {
          const vals = { ...values };
          let cpf = onlyNumbers(vals.cpf);
          fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/clients/bycpf/${cpf}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
          })
            .catch((err) => {
              return err;
            })
            .then((res) => {
              if (!res || !res.ok || res.status >= 400) {
                setError('Não foi possível completar a consulta do seu cpf nesse momento');
                return;
              }
              return res.json();
            })
            .then((data) => {
              if (!data) return;
              if (!data.success) {
                voucherMdv(onlyNumbers(vals.cpf));
                setUser((prevState) => ({
                  ...prevState,
                  voucher: data.data[0],
                }));
                console.log(user);
                navigate('/cadastro/dadosbasicos', {
                  state: { ...state, cpf: onlyNumbers(vals.cpf) },
                });
              } else {
                setUserData(data.data[0]);
                setError('Identificamos que você já possui um cadastro em nosso sistema. Baixe o app e faça o login');
              }
            });
        }}
      >
        <VStack as={Form} w={{ base: '90%', md: '500px' }} m="auto" justify="top" marginTop="5%" marginBottom="5%" spacing="1rem" align="start">
          <Heading fontSize="1em">Primeiro, insira seu número de CPF</Heading>
          <TextField name="cpf" type="tel" placeholder="Digite seu CPF" mask="999.999.999-99" maskChar={null} />
          <Text as="p" fontSize="small">
            Precisamos do seu número de CPF para identificação no sistema.
          </Text>
          <Text as="p" color="red.500">
            {error}
            <Wrap pl="5px" color="green.500" paddingTop="1em" paddingBottom="1em">
              {error2}
            </Wrap>
          </Text>
          <Button colorScheme="teal" w="100%" type="submit">
            Avançar
          </Button>
        </VStack>
      </Formik>
    </>
  );
};

export default Cpf;
