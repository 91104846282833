/* eslint-disable import/no-unresolved */
import * as Yup from 'yup';
import BottomNavBar from 'components/BottomNavBar';
import ChildHeader from 'components/Dashboard/Main/ChildHeader';
import Input from 'components/TextField';
import { Form, Formik } from 'formik';
import { Box, Button, ButtonGroup, Flex, FormLabel, Heading, HStack, Text, Textarea, VStack } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';

function FormDetalhesClinica() {
  const { state } = useLocation();
  const defaultArgs = { borderRadius: 8, w: '100%', mask: '', maskChar: null };
  const initialValues = { detalhes: '', inicioSegunda: '', fimSegunda: '', inicioSabado: '', fimSabado: '', inicioDomingo: '', fimDomingo: '' };
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    detalhes: Yup.string(),
    inicioSegunda: Yup.string().required('obrigatório'),
    fimSegunda: Yup.string()
      .required('obrigatório')
      .test('pass-match', 'horario invalido', function (value) {
        return this.parent.inicioSegunda < value;
      }),
  });

  async function handleSubmit(values) {
    const vals = { ...values };
    navigate('/empreendedor/cadastro-estabelecimento/dados-bancarios', { state: { ...state, ...vals } });
  }

  // eslint-disable-next-line no-nested-ternary
  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      <VStack as={Form} w={{ base: '90%', md: '500px' }} align="start" mx="auto" mt="5" mb="20" spacing={5}>
        <Heading size="sm">Infome mais alguns dados</Heading>
        <Textarea {...defaultArgs} name="detalhes" placeholder="Histórico e informações detalhadas" />
        <Box width="100%">
          <FormLabel>Horários de funcionamento:</FormLabel>
          <Flex w="100%">
            <VStack w="100%" align="baseline">
              <Text w="100%">Seg. à Sex:</Text>
              <Input {...defaultArgs} w="80%" name="inicioSegunda" type="time" />
              <Text>às</Text>
              <Input {...defaultArgs} w="80%" name="fimSegunda" type="time" />
            </VStack>
            <VStack w="100%" align="baseline">
              <Text w="100%">Sábado:</Text>
              <Input {...defaultArgs} w="80%" name="inicioSabado" type="time" />
              <Text>às</Text>
              <Input {...defaultArgs} w="80%" name="fimSabado" type="time" />
            </VStack>
          </Flex>
          <VStack w="100%" align="baseline">
            <Text w="100%" mt="5%">
              Domingo:
            </Text>
            <Input {...defaultArgs} w="40%" name="inicioDomingo" type="time" />
            <Text>às</Text>
            <Input {...defaultArgs} w="40%" name="fimDomingo" type="time" />
          </VStack>
        </Box>
        <ButtonGroup w="100%">
          <Button {...defaultArgs} colorScheme="teal" onClick={() => navigate(-1)}>
            Voltar
          </Button>
          <Button {...defaultArgs} colorScheme="teal" type="submit">
            Continuar
          </Button>
        </ButtonGroup>
      </VStack>
    </Formik>
  );
}

function DetalhesClinica() {
  return (
    <>
      <ChildHeader />
      <FormDetalhesClinica />
      <BottomNavBar />
    </>
  );
}

export default DetalhesClinica;
