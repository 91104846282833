import { ChevronLeftIcon, ChevronRightIcon, StarIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Button,
  chakra,
  ButtonGroup,
  Flex,
  HStack,
  VStack,
  WrapItem,
  LinkBox,
  Heading,
  Text,
  Select,
  Td,
  Badge,
  Table,
  Tr,
  TableContainer,
  useDisclosure,
  Stack,
  Center,
} from '@chakra-ui/react';
import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Loading from '../../../Loading';
import { AccountContext } from '../../../AccountContext';
import BottomNavBar from '../../../BottomNavBar';
import ChildHeader from '../ChildHeader';
import PopupDependenteSemCpf from './PopupDependenteSemCpf';
import SelectField from 'components/SelectField';
import { Formik } from 'formik';
import '../../css/lista-datas.css';
import NoUserHeader from 'components/AreaLivre/NoUserHeader';
import DrawerCompraPlano from '../DrawerCompraPlano';
import DrawerServico from '../DrawerServico';

function Agendamento() {
  const navigate = useNavigate();
  const { user, setPage, socket, setUser } = useContext(AccountContext);
  const [dependentes, setDependentes] = useState([]);
  const arrayNomeDependentes = dependentes ? dependentes.map((dependente) => dependente.nome) : [];

  //dados do medico
  const { state } = useLocation();
  const { medico, startNum, st, defaultDateTime, procedimento, price, procedimentoId, procedimentoParticular, valorParticular } = state;
  const arrName = medico.dados.nome.split(' ');
  const [pag, setPag] = useState(startNum);
  const [pac, setPac] = useState(st);
  const [escolha, setEscolha] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [agendas, setAgendas] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const avaliacao = Math.round(medico.dados.avaliacao_media);
  const disclosure = useDisclosure();
  const scheduleDrawer = useDisclosure();

  let titulo = '';
  if (medico.dados.sexo === 'M') {
    titulo = 'Dr. ';
  } else if (medico.dados.sexo === 'F') {
    titulo = 'Dra. ';
  }

  setPage('Agendamento');

  useEffect(() => {
    const fetchPosts = async () => {
      const url = process.env.REACT_APP_BASE_URL_API_SERVER + '/schedule/getAvailability/' + medico.dados.medico + '/' + procedimentoId;
      const response = await fetch(url, {
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        method: 'GET',
      });
      const postsData = await response.json();
      setAgendas(postsData);
    };
    const fetchDependentes = async () => {
      const url2 = process.env.REACT_APP_BASE_URL_API_SERVER + '/client/dependents2';
      const titularId = {
        id_contrato: user.id_contrato,
      };
      const response = await fetch(url2, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(titularId),
      });
      const resp = await response.json();
      if (resp.data[0] !== null) {
        setDependentes(resp.data);
      } else {
        setDependentes([{ nome: user.name, paciente_id: user.id_cliente_deovita, ...user }]);
        console.log(dependentes);
      }
      console.log(resp.data);
    };
    fetchDependentes();
    fetchPosts();
  }, []);

  let uniqueDates = [];

  const useAgenda = agendas.map((agenda, index) => {
    if (!uniqueDates.includes(agenda.AVA_DATE)) {
      uniqueDates.push(agenda.AVA_DATE);
    }
  });

  const useUniqueDates = uniqueDates.map((uniqueDate, index) => {
    const options = { month: 'short', day: 'numeric' };
    const event = new Date(uniqueDate.split('/').reverse().join('-') + 'T08:00:00');
    const dataIcon = event.toLocaleDateString('pt-br', options).replace(' de ', ' ').replace('.', '').split(' ');
    return (
      <>
        <Flex>
          <Box backgroundColor="rgba(90, 178, 173, 0.3)" borderRadius="5px" margin="5px" color="rgba(62, 100, 255, 1)" padding="0 13px">
            <Text fontWeight="bold" fontSize="xl">
              {dataIcon[0]}
            </Text>
            <Text fontWeight="bold" fontSize="sm" mt="-1">
              {dataIcon[1].toUpperCase()}
            </Text>
          </Box>
          <Text margin="5% 0" fontSize="xs" align="left">
            <chakra.p fontWeight="bold">{medico.dados.tipo ? medico.dados.tipo.toUpperCase() : ''}</chakra.p>
            {event.toLocaleString('pt-br', { weekday: 'long' })}
          </Text>
        </Flex>
      </>
    );
  });
  const filteredAgendas = agendas.filter((i) => uniqueDates[pag].includes(i.AVA_DATE));
  const mapDates = uniqueDates.map((uniqueDate, index) => {
    const options = { month: 'short', day: 'numeric' };
    const event = new Date(uniqueDate.split('/').reverse().join('-') + 'T08:00:00');
    const dataIcon = event.toLocaleDateString('pt-br', options).replace(' de ', ' ').replace('.', '').split(' ');

    return (
      <>
        <Td
          id={`td${index}`}
          borderRadius="15px"
          p="0.5em"
          fontSize="xl"
          textAlign="center"
          class="margin-td"
          onClick={() => {
            document.getElementById('td' + index).scrollIntoView({ behavior: 'smooth' });
            document.querySelectorAll(`[class="chakra-stack css-1px2q30"]`).forEach((item) => {
              item.style.border = 'none';
            });
            document.querySelectorAll(`[class="chakra-badge css-raqg5x"]`).forEach((item) => {
              item.style.border = 'none';
            });
            document.getElementById('date' + index).style.border = '1px solid orange';
            setPag(index);
            setDisabled(false);
          }}
        >
          <HStack
            id={`date${index}`}
            backgroundColor="rgba(90, 178, 173, 0.25)"
            borderRadius="15px"
            style={pag === index ? { border: '1px solid orange' } : {}}
          >
            <Text width="60%">
              <Flex ml="1em">
                <Box backgroundColor="rgba(90, 178, 173, 0.3)" borderRadius="5px" margin="1em" color="rgba(62, 100, 255, 1)" padding="0 1em">
                  <Text fontWeight="bold" fontSize="xl">
                    {dataIcon[0]}
                  </Text>
                  <Text fontWeight="bold" fontSize="sm" mt="-1">
                    {dataIcon[1].toUpperCase()}
                  </Text>
                </Box>
                <Text margin="11% 0" fontSize="xs" align="left">
                  <chakra.p fontWeight="bold">{medico.dados.tipo ? medico.dados.tipo.toUpperCase() : ''}</chakra.p>
                  {event.toLocaleString('pt-br', { weekday: 'long' })}
                </Text>
              </Flex>
            </Text>
          </HStack>
        </Td>
      </>
    );
  });
  const useFilteredAgendas = filteredAgendas.map((filteredAgenda, index) => {
    if (!escolha) {
      var dateTime = `${filteredAgenda.AVA_DATE} ${filteredAgenda.AVA_START_TIME}`;
      if (dateTime === defaultDateTime) {
        setEscolha({
          medicoNome: medico.dados.nome,
          tipo: medico.dados.tipo,
          especialidade: medico.dados.especialidade,
          valor: user.id_contrato > 0 || (user.loggedIn === true && user.titular === false) ? price : valorParticular,
          agenda: filteredAgenda,
          paciente: dependentes[pac],
          clinica: medico.dados.empresa,
          logradouro: medico.dados.logradouro,
          numero: medico.dados.numero,
          bairro: medico.dados.bairro,
          municipio: medico.dados.municipio,
          medico: medico,
          procedimento: procedimento,
          procedimentoId: user.id_contrato > 0 || (user.loggedIn === true && user.titular === false) ? procedimentoId : procedimentoParticular,
        });
        socket.emit('lockSchedule', filteredAgenda.AVAILABILITY_LID);
        setDisabled(true);
      }
    }

    return (
      <>
        <Td>
          <Badge
            id={'agenda' + index}
            borderRadius="0.5em"
            margin="0 0.5em"
            padding="0.5em 1.4em"
            size="lg"
            fontSize="md"
            style={`${filteredAgenda.AVA_DATE} ${filteredAgenda.AVA_START_TIME}` === defaultDateTime ? { border: '1px solid orange' } : {}}
            backgroundColor="rgba(90, 178, 173, 0.3)"
            color="rgba(62, 100, 255, 1)"
            onClick={() => {
              document.getElementById('agenda' + index).scrollIntoView({ behavior: 'smooth' });
              document.querySelectorAll(`[class="chakra-badge css-raqg5x"]`).forEach((item) => {
                item.style.border = 'none';
              });
              document.getElementById('agenda' + index).scrollIntoView({ inline: 'center', behavior: 'smooth' });
              document.getElementById('agenda' + index).style.border = '1px solid orange';

              setEscolha({
                medicoNome: medico.dados.nome,
                tipo: medico.dados.tipo,
                especialidade: medico.dados.especialidade,
                valor: user.id_contrato > 0 || (user.loggedIn === true && user.titular === false) ? price : valorParticular,
                agenda: filteredAgenda,
                paciente: dependentes[pac],
                clinica: medico.dados.empresa,
                logradouro: medico.dados.logradouro,
                numero: medico.dados.numero,
                bairro: medico.dados.bairro,
                municipio: medico.dados.municipio,
                medico: medico,
                procedimento: procedimento,
                procedimentoId: user.id_contrato > 0 || (user.loggedIn === true && user.titular === false) ? procedimentoId : procedimentoParticular,
              });
              if (escolha) {
                setUser((prevState) => ({ ...prevState, pedido_pendente: { ...escolha, agenda: escolha.agenda } }));
              }
              console.log(user);
              socket.emit('unlockSchedule', '');
              socket.emit('lockSchedule', filteredAgenda.AVAILABILITY_LID);
              dependentes[pac].cpf && setDisabled(true);
            }}
          >
            {filteredAgenda.AVA_START_TIME}
          </Badge>
        </Td>
      </>
    );
  });

  return dependentes.length > 0 && agendas.length > 0 ? (
    <>
      {user.loggedIn === true ? <ChildHeader /> : <NoUserHeader />}
      {user.loggedIn === true ? <PopupDependenteSemCpf disclosure={disclosure} datas={dependentes[pac]} /> : null}
      <DrawerCompraPlano disclosure={disclosure} />
      <DrawerServico disclosure={scheduleDrawer} />
      <Formik initialValues={{ empresa: 0, dependente: 0 }} validateOnChange={false} validateOnBlur={false}>
        <VStack
          w={{
            base: '90%',
            md: '500px',
          }}
          m="auto"
          justify="center"
        >
          <Flex minW="320px" maxW="320px" mx="auto" bg="" overflow="hidden">
            <Box w={1 / 3} bgSize="cover" align="center">
              <WrapItem ml="5px" mt="0.8em">
                <Avatar
                  id={medico.dados.medico_id}
                  size="xl"
                  name={medico.dados.nome}
                  src={process.env.REACT_APP_URL_PROFILEPICS_OPERADOR + '/' + medico.dados.medico + '/foto_profissional.png'}
                />{' '}
              </WrapItem>
              <chakra.a
                color="rgba(14, 170, 139, 1)"
                fontWeight="bold"
                fontSize="sm"
                onClick={() => {
                  navigate('/empresa/medico/curriculo', {
                    state: {
                      medico,
                    },
                  });
                }}
              >
                ver curriculo
              </chakra.a>
            </Box>
            <Box w={2 / 3} p={{ base: 4, md: 4 }}>
              <chakra.h2 fontSize="xl" fontWeight="bold" color="">
                {titulo}
                {''}
                {arrName[0]} {arrName[1]}
              </chakra.h2>
              <chakra.p mt={-1} fontSize="md" color="">
                {medico.dados.especialidade}
              </chakra.p>
              <HStack>
                <chakra.p fontSize="sm">{medico.dados.conselho}</chakra.p>
              </HStack>
              <HStack spacing={1} display="flex" alignItems="center" fontSize="sm" pt={0.5}>
                {avaliacao >= 1 ? <StarIcon color="orange" /> : <StarIcon color="gray.500" />}
                {avaliacao >= 2 ? <StarIcon color="orange" /> : <StarIcon color="gray.500" />}
                {avaliacao >= 3 ? <StarIcon color="orange" /> : <StarIcon color="gray.500" />}
                {avaliacao >= 4 ? <StarIcon color="orange" /> : <StarIcon color="gray.500" />}
                {avaliacao >= 5 ? <StarIcon color="orange" /> : <StarIcon color="gray.500" />}
                <chakra.span fontSize="xs"></chakra.span>
              </HStack>
            </Box>
          </Flex>

          <LinkBox as="card" pl="3" pr="3" borderRadius="15px" fontSize="xs" width="100%">
            <SelectField
              name="empresa"
              placeholder="Empresa"
              colorScheme="teal"
              border="green"
              color="black"
              borderRadius="15px"
              borderWidth="2px"
              variant="ghost"
              fontWeight="bold"
              w="100%"
              options={[medico.dados.empresa]}
              onChange={() => {}}
            />
            <Text fontSize="xs" padding="0 1em">
              <chakra.p color="gray.500">
                {' '}
                {medico.dados.logradouro}, {medico.dados.numero} - {medico.dados.bairro} - {medico.dados.municipio}
              </chakra.p>
            </Text>
          </LinkBox>

          {user.id_contrato > 0 || user.titular === false ? (
            <>
              <chakra.h1 fontWeight="bold">Procedimento</chakra.h1>
              <LinkBox as="card" borderRadius="15px" fontSize="xs" width="100%">
                <Center>
                  <Box w="60%">
                    <Text margin="5% 0" fontSize="xs" w="60%">
                      {procedimento ? procedimento + ' ' : ''}
                    </Text>
                    <Badge align="center" bgColor="#FF9A00" borderRadius="15px" w="50%">
                      Assinante
                    </Badge>
                  </Box>
                  <Box>
                    <Text mt="-1em" fontSize="md" fontWeight="bold">
                      {price ? 'R$ ' + price : ''}
                    </Text>
                  </Box>
                </Center>
              </LinkBox>
            </>
          ) : (
            <>
              <chakra.h1 fontWeight="bold">Selecione uma opção:</chakra.h1>
              <LinkBox
                as="card"
                p="3"
                borderWidth="2px"
                borderRadius="15px"
                fontSize="xs"
                width="100%"
                onClick={() => {
                  scheduleDrawer.onOpen();
                }}
              >
                <Center>
                  <Box w="60%">
                    <Text margin="5% 0" fontSize="xs" w="60%">
                      {procedimento ? procedimento + ' ' : ''}
                    </Text>
                    <Badge align="center" bgColor="#FF9A00" borderRadius="15px" w="50%">
                      Assinante
                    </Badge>
                  </Box>
                  <Box>
                    <Text mt="-1em" fontSize="md" fontWeight="bold">
                      {price
                        ? parseFloat(price).toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          })
                        : ''}
                    </Text>
                  </Box>
                </Center>
              </LinkBox>
              {valorParticular && (
                <LinkBox as="card" p="3" borderWidth="2px" borderColor="green" borderRadius="15px" fontSize="xs" width="100%">
                  <Center>
                    <Box w="60%">
                      <Text margin="5% 0" fontSize="xs" w="60%">
                        {procedimento ? procedimento + ' ' : ''}
                      </Text>
                      <Badge align="center" bgColor="d9d9d9" borderRadius="15px" w="60%">
                        Particular
                      </Badge>
                    </Box>
                    <Box>
                      <Text mt="-1em" fontSize="md" fontWeight="bold">
                        {valorParticular ? parseFloat(valorParticular).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : ''}
                      </Text>
                    </Box>
                  </Center>
                </LinkBox>
              )}
            </>
          )}
          {user.titular === true && user.id_contrato > 0 && (
            <>
              <chakra.h1 fontWeight="bold">Paciente</chakra.h1>
              <LinkBox id="" as="card" p="3" borderWidth="2px" borderColor="green" borderRadius="15px" fontSize="xs" width="100%">
                <Heading size="sm" my="2">
                  <SelectField
                    name="dependente"
                    colorScheme="teal"
                    border="green"
                    color="black"
                    borderRadius="15px"
                    borderWidth="2px"
                    variant="ghost"
                    fontWeight="bold"
                    w="100%"
                    onChange={(e) => {
                      if (dependentes[e].cpf) {
                        setPac(e);
                        setDisabled(true);
                      } else {
                        setPac(e);
                        setDisabled(false);
                        disclosure.onOpen();
                      }
                    }}
                    options={arrayNomeDependentes}
                  />
                </Heading>
                <Text fontSize="xs" padding="0 1em">
                  <chakra.p color="gray.500"></chakra.p>
                </Text>
              </LinkBox>
            </>
          )}
          <chakra.h1 fontWeight="bold">Selecione dia e horário</chakra.h1>
          {user.loggedIn === true &&
          (user.id_contrato === 0 || !user.id_contrato) &&
          user.titular === true &&
          (!valorParticular || valorParticular === null) ? (
            <>
              <Text align="center" fontSize="0.8em" pb="20%">
                Sem horarios disponíveis para não assinantes.
              </Text>
            </>
          ) : (
            <>
              <TableContainer minWidth="100%">
                <Table variant="simple" size="lg" class="table-agendamento">
                  <Tr>{mapDates}</Tr>
                </Table>
              </TableContainer>
              <TableContainer minWidth="100%">
                <Table variant="simple">
                  <Tr>{useFilteredAgendas}</Tr>
                </Table>
              </TableContainer>
              <ButtonGroup pt="1rem" pb="25%" width="100%">
                <Button
                  width="100%"
                  colorScheme="teal"
                  onClick={() => {
                    console.log(escolha);
                    if (user.loggedIn === true) {
                      let states = { ...escolha, paciente: dependentes[pac], agenda: escolha.agenda };
                      navigate('/atendimento/pedido', {
                        state: states,
                      });
                    } else {
                      if (escolha) {
                        setUser((prevState) => ({ ...prevState, pedido_pendente: { ...escolha, agenda: escolha.agenda } }));
                      }

                      disclosure.onOpen();
                      console.log(user);
                    }
                  }}
                  disabled={user.loggedIn === true ? !disabled : false}
                >
                  Continuar
                </Button>
              </ButtonGroup>
            </>
          )}
        </VStack>
      </Formik>
      <BottomNavBar />
    </>
  ) : (
    <>
      {user.loggedIn === true ? <ChildHeader /> : <NoUserHeader />}
      <VStack
        w={{
          base: '20%',
          md: '100px',
        }}
        m="50% auto"
        justify="center"
      >
        <Loading />
      </VStack>
    </>
  );
}

export default Agendamento;
