import { ArrowForwardIcon } from '@chakra-ui/icons'
import { chakra, Box, Img, LinkBox, Text, VStack, Checkbox, Image, Progress } from '@chakra-ui/react'
import { AccountContext } from 'components/AccountContext'
import BottomNavBar from 'components/BottomNavBar'
import React, { useContext, useState } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import BackButton from '../../../../../images/assets/telemedicina/backbutton.svg'
import PetsImg from '../../../../../images/assets/telemedicina/pets-white.png'
import ChildHeader from '../../ChildHeader'


function Fila() {
    const { user, setPage, Page } = useContext(AccountContext)
    setPage('Telemedicina');
    const navigate = useNavigate()
    const [isLoading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [medico, setMedico] = useState('');
    const [paciente, setPaciente] = useState('');
    const [agenda, setAgenda] = useState('');
    const today = new Date().toISOString().substring(0, 10)
    const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
    const url = process.env.REACT_APP_BASE_URL_API_SERVER + '/schedule/appointment_pet';
    const payload = {
          cpf: onlyNumbers(user.cpf),
          clientId: user.id_cliente_deovita,
          cart: {
            convenio_id:252,
            dia_previsao: 0,
            grupo: "ENDOSCOPIA",
            materiais: "",
            nome: "ENDOSCOPIA",
            procedimento_convenio_id: 6178,
            procedimento_tuss_id: 173059,
            valor: "0,00"
          },
          turno: "manhã",
          valor: 0,
          empresa: {
            ativo: true,
            bairro: "Centro",
            cep:"63500-065",
            descricao: "Iniciamos nossas atividades em 2006, tendo como base fundamental a garantia de um bom atendimento e qualidade. Com união entre profissionais qualificados e tecnologia avançada, conquistamos a confiança de nossos clientes, trazendo resultados com agilidade e precisão.     Além da sede em Iguatu/CE, hoje estamos presentes em Fortaleza, região metropolitana, e em diversas cidades do estado do Ceará.",
            empresa: "ADOLFO LUTZ",
            empresa_id: 1,
            endereco: "Rua Deputado Adail Barreto",
            estado: "CEARA",
            grupo:"ENDOSCOPIA",
            horario_sab_fim: "13:00",
            horario_sab_inicio: "06:00",
            horario_seg_sex_fim:"18:00",
            horario_seg_sex_inicio: "06:00",
            municipio: "IGUATU",
            numero: "54",
            ordem: "1",
            telefone_01: "0800 280 2133",
            telefone_02: "88 981189898"
          },
          data: today,
        };
        console.log(payload);
        const fetchPosts = async () => {
            try {
              const response = await fetch(url, {
                credentials: 'include',
                headers: {
                  'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify(payload),
              });
              const postsData = await response.json();
              setSuccess(postsData.success)
            } catch (error) {
              console.log(error)
            }
          };
        if(success === false){
            fetchPosts();
        }

        const fetchRdy= async () => {
            try {
              const response = await fetch( process.env.REACT_APP_BASE_URL_API_SERVER + `/pet/schedule/${user.username}`, {
                credentials: 'include',
                headers: {
                  'Content-Type': 'application/json',
                },
                method: 'GET',
              });
              const postsData = await response.json();
              console.log(postsData)
              if(postsData.medico_ready === true){
                setMedico(postsData.medico_id);
                setAgenda(postsData.agenda_exames_id);
                setPaciente(postsData.paciente_id);
                setLoading(false);
                const highestId = window.setTimeout(() => {
                    for (let i = highestId; i >= 0; i--) {
                      window.clearInterval(i);
                    }
                  }, 0);
              }
            } catch (error) {
              console.log(error)
            }
          };

    useEffect(()=>{
        if(success === true){
            setInterval(function() {
                    fetchRdy();
                }, 10000);
        }
        
    },[success])
    return isLoading ? (
        <>
            <VStack
            align="center"
            justify={{
                base: 'center',
                md: 'space-around',
                xl: 'space-between',
            }}
            direction={{ base: 'column-reverse' }}
            wrap="no-wrap"
            minH="100vh"
            spacing={0}
            background='linear-gradient(180deg, #EB8B2A 0%, #EFA73F 28.65%, #5AB2AD 64.06%, #4E9E8F 100%);'
            >
                <Image top="2.5em" left="1.3em" zIndex="9999"  position="absolute"src={BackButton} onClick={() => navigate(-1)}/>
                <LinkBox
                    mt="1"
                    id=""
                    as="card"
                    width="328px"
                    height="300px"
                    borderRadius="18px"
                    fontSize="xs"
                    size="10"
                    maxInlineSize="80"
                    textAlign="center"
                >
                    <VStack m="10%">
                        <Text mt="1em" color="white" fontWeight="bold" fontSize="md" mb="0.5em">Aguarde um instante, você já será atendido</Text>
                        <Box w="100%" h="1em"bgColor="white" borderRadius="15px 15px 15px 15px">
                            <Progress h="1em" borderRadius="15px 15px 15px 15px" colorScheme="blackAlpha" size='xs' isIndeterminate />
                        </Box>
                        <Img src={PetsImg} w="30%" pt="2em" />
                    </VStack>
                    
                </LinkBox>
            </VStack>
            <BottomNavBar />
        </>
    ): (<>
            <ChildHeader />
            <VStack>
            <chakra.iframe
                width="95%"
                paddingTop="0.5em"
                height="600px"
                src={`https://homologacao.seudr.com.br:5000/atendimento/p${paciente}m${medico}a${agenda}`}
                allow="camera *;microphone *"
            ></chakra.iframe>
            </VStack>
            <BottomNavBar />
        </>)
}

export default Fila