import { Stack, Progress, Box, Button, HStack, Flex } from "@chakra-ui/react";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import React from "react";

export default function ProgressBar(props) {
  const { nextStep, prevStep, reset, activeStep } = useSteps({
    initialStep: 0,
  });

  return (
    <>
      <Flex
        w={{ base: "90%", md: "500px" }}
        m="auto"
        marginTop="3%"
        marginBottom="5%"
        justify="top"
        spacing="1rem"
      >
        <Steps
          size="sm"
          colorScheme="teal"
          activeStep={props.value}
          responsive={false}
        >
          <Step key={0} onClick=""></Step>
          <Step key={1} onClick=""></Step>
          <Step key={2} onClick=""></Step>
          <Step key={3} onClick=""></Step>
          <Step key={4} onClick=""></Step>
          <Step key={5} onClick=""></Step>
        </Steps>
      </Flex>
    </>
  );
}
