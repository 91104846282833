import { Formik } from 'formik';
import { Box, Button, Heading, VStack, Stack, Text, Progress } from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import React from 'react';
import ProgressBar from './ProgressBar';

const Atencao = () => {
  const navigate = useNavigate();
  return (
    <>
      <ProgressBar value={''} />
      <Formik validateOnChange={false} validateOnBlur={false}>
        <VStack w={{ base: '90%', md: '500px' }} m="auto" marginTop="3%" marginBottom="5%" justify="top" spacing="1rem">
          <Box w="100%" borderWidth="1px" borderRadius="10px" padding="1rem">
            <VStack align="start">
              <Heading fontSize="1em">Atenção</Heading>
              <Text as="p" fontSize="small">
                Caso tenha dificuldade para respirar, dor severa repentina ou sangramento intenso sem controle, saiba que você precisa procurar um
                Pronto Socorro mais próximo.
              </Text>
            </VStack>
          </Box>

          <Box w="100%" borderWidth="1px" borderRadius="10px" padding="1rem">
            <VStack align="start">
              <Heading fontSize="1em">O que é pronto atendimento</Heading>
              <Text as="p" fontSize="small">
                {
                  'O Pronto Atendimento Virtual consiste em uma chamada de vídeo, realizada entre médico e paciente, sem exame presencial, com a possibilidade de prescrição de tratamento, solicitação de exames, emissão de atestado, declaração de atendimento e outros procedimentos, validados com assinatura digital.'
                }
              </Text>
              <Text as="p" fontSize="small">
                {
                  'Caso o profissional de saude perceba a necessidade de avaliação presencial, o paciente será orientado de como deverá proceder, dentro da necessidade.'
                }
              </Text>
            </VStack>
          </Box>
          <Button colorScheme="teal" w="100%" onClick={() => navigate('/termospa')}>
            Continuar
          </Button>
        </VStack>
      </Formik>
    </>
  );
};
export default Atencao;
