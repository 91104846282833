import { Tabs, TabList, TabPanels, Avatar, Badge, Box, chakra, HStack, VStack } from '@chakra-ui/react';
import React from 'react';
import { useContext } from 'react';
import ChildHeader from '../../ChildHeader';
import Resumo from './Resumo';
import { AccountContext } from '../../../../AccountContext';
import BottomNavBar from '../../../../BottomNavBar';
import SearchBar from './SearchBar';
import { useLocation } from 'react-router-dom';

function Main() {
  const { setPage } = useContext(AccountContext);
  const { state } = useLocation();
  const { contratos } = state;
  let totalArrecadado = 0;
  setPage('Contratos');

  const contratosList = contratos.map((contrato) => {
    console.log(contrato);
    const name = contrato.nome ? contrato.nome.split(' ')[0] + ' ' + (contrato.nome.split(' ')[1] ? contrato.nome.split(' ')[1] : '') : '';
    const date = contrato.data_cadastro ? contrato.data_cadastro.split('T')[0] : 'Sem data';
    totalArrecadado += contrato.valor_adesao ? (parseFloat(contrato.valor_adesao.replace(',', '.')) / 100) * 40 : 0;
    return (
      <>
        <>
          <HStack w="100%" margin="3%">
            <Box>
              <Avatar src={`https://backoffice.deovita.com.br/deovita/upload/webcam/pacientes/${contrato.cpf}.jpeg`} />
            </Box>
            <Box align="center" w="50%">
              {name}
            </Box>
            <Badge borderRadius="15%" backgroundColor="green.200">
              {contrato.plano ? contrato.plano.toLowerCase() : ''}
            </Badge>
            <Box textColor="#0EAA8B" align="center" w="30%" fontSize="10px">
              adimplente
            </Box>
          </HStack>
        </>
      </>
    );
  });
  return (
    <>
      <ChildHeader />
      <VStack margin="5%" align="start">
        <chakra.h1 marginTop="10%" fontWeight="bold">
          Gerenciamento
        </chakra.h1>
        <Resumo data={contratos} total={totalArrecadado} />

        <Tabs width="100%">
          <TabList></TabList>
          <TabPanels>
            <Box marginBottom="5%"></Box>
            <SearchBar />
            <Box marginTop="5%">{contratos && contratosList}</Box>
          </TabPanels>
        </Tabs>
      </VStack>
      <BottomNavBar />
    </>
  );
}

export default Main;
