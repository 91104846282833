import { chakra, useDisclosure } from "@chakra-ui/react";
import { React, useContext } from "react";
import { AccountContext } from "../../../AccountContext";
import Atencao from "./Atencao";
//import Aviso from "./Aviso";
import PopUpComprar from "./PopUpComprar";
import ChildHeader from "../ChildHeader";
import BottomNavBar from "../../../BottomNavBar";
import { useState } from "react";
import Loading from "../../../LoadingWithHeader";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Inicio = () => {
    const { user, setPage } = useContext(AccountContext);
    const [loading, setLoading] = useState(true);
    const [prontoAtendimento, setProntoAtendimento] = useState();
    const [error, setError] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const navigate = useNavigate();
    setPage("Pronto Atendimento");

    async function carregarProntoAtendimento() {
        let url = process.env.REACT_APP_BASE_URL_API_SERVER + "/extras/byPlanId/" + user.id_plano;
        try {
            let response = await fetch(url, { method: "GET" });
            let adicionais = await response.json();
            let prontoAtendimento = Array.isArray(adicionais)
                ? adicionais.filter((adicional) =>
                    typeof adicional.nome === "string" ? adicional.nome.toLowerCase() === "pronto atendimento" : false
                )
                : [];
            prontoAtendimento.length > 0 ? setProntoAtendimento(prontoAtendimento[0]) : setError(true);
            setLoading(false);
        } catch (erro) {
            setError(erro);
            setLoading(false);
        }
    }

    useEffect(() => {
        carregarProntoAtendimento();
    }, []);

    console.log('45')
    const temProntoAtendimento = user.adicionais.some(
        (adicional) => (typeof adicional.nome === "string" ? adicional.nome.toLowerCase() : "") === "pronto atendimento"
    );

    const temPendenciaFinanceira = false;
    console.log('51')
    return loading ? (
        <Loading />
    ) : (
        <>
            <ChildHeader />
            {console.log('57')}
            {temPendenciaFinanceira === false ? (
                <chakra.div paddingBottom="15%">
                    <Atencao />
                </chakra.div>
            ) : error ? (
                navigate("/alerts/erro/comprar_pronto_atendimento")
            ) : (
                <PopUpComprar isOpen={isOpen} onOpen={onOpen} onClose={onClose} data={prontoAtendimento} />
            )}

            {/*<chakra.div paddingBottom="15%">{temProntoAtendimento && temPendenciaFinanceira === false ? <Atencao /> : <Aviso />}</chakra.div>*/}
            {console.log('69')}
            <BottomNavBar />
        </>
    );
};

export default Inicio;
