/* eslint-disable import/no-unresolved */
import * as Yup from 'yup';
import { useContext, useRef } from 'react';
import BottomNavBar from 'components/BottomNavBar';
import ChildHeader from '../../ChildHeader';
import Input from 'components/TextField';
import { Form, Formik } from 'formik';
import {
  Button,
  Heading,
  VStack,
  chakra,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  HStack,
  Checkbox,
  ButtonGroup,
  useToast,
  Drawer,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';
import validaCPF from 'components/Utils/Functions/validaCPF';
import { AccountContext } from 'components/AccountContext';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Pdf from 'components/PdfViewer';
import Contrato from '../../../../../termos/parceiro.pdf';

function Termos(props) {
  const { isOpen, onClose, finalRef } = props;
  return (
    <Drawer isCentered isFullHeight size="md" isOpen={isOpen} onClose={onClose} placement="bottom">
      <DrawerContent isCentered nbgColor="rgba(90, 178, 173, 0.7)" pt="10%">
        <Pdf file={Contrato} />
        <DrawerCloseButton color="red" borderColor="red" pt="15%" />
      </DrawerContent>
    </Drawer>
  );
}

function FormDadosClinica() {
  const { setPage } = useContext(AccountContext);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [documentType, setDocumentType] = useState('CNPJ');
  const [loading, setLoading] = useState(false);
  const documentMask = documentType === 'CPF' ? '999.999.999-99' : '99.999.999/9999-99';
  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = useRef(null);
  setPage('Cadastro de Credenciado');
  const defaultArgs = { borderRadius: 8, w: '100%', mask: '', maskChar: null };
  const initialValues = { nome: '', razaoSocial: '', qtdeMedicos: '', documento: '', email: '', fone: '', celular: '', whatsapp: '' };
  const navigate = useNavigate();
  const toast = useToast();
  const objectToast = {
    title: 'Error',
    description: 'Não foi possível ',
    status: 'error',
    duration: 4000,
    isClosable: true,
    position: 'bottom',
    colorScheme: 'green',
    containerStyle: {
      paddingTop: '50px',
    },
  };
  let saveButtonIsDisabled = true;
  if (acceptTerms) saveButtonIsDisabled = false;

  const validationSchema =
    documentType === 'CPF'
      ? Yup.object({
          nome: Yup.string()
            .required('Campo obrigatório')
            .matches(/^[A-Za-z]+\s[A-Za-z ]+$/m, 'Escreva o nome e sobrenome pelo menos e sem acentos ou caracteres especiais'),
          documento: Yup.string()
            .required('Campo obrigatório')
            .trim()
            .test('valida_cpf', 'Cpf inválido', (cpf) => validaCPF(cpf)),
          email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
          fone: Yup.string()
            .trim()
            .matches(/^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}-?[0-9]{4}$/, 'Telefone Inválido'),
          celular: Yup.string()
            .trim()
            .matches(/^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}-?[0-9]{4}$/, 'Telefone Inválido')
            .required('Campo obrigatório'),
          whatsapp: Yup.string()
            .trim()
            .matches(/^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}-?[0-9]{4}$/, 'Telefone Inválido')
            .required('campo obrigatório'),
        })
      : Yup.object({
          nome: Yup.string().required('Campo obrigatório'),
          razaoSocial: Yup.string().required('Campo obrigatório'),
          documento: Yup.string()
            .required('Campo obrigatório')
            .trim()
            .matches(/(^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$)/, 'CNPJ inválido'),
          qtdeMedicos: Yup.number().integer().positive().required('campo obrigatório'),
          email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
          fone: Yup.string()
            .trim()
            .matches(/^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}-?[0-9]{4}$/, 'Telefone Inválido'),
          celular: Yup.string()
            .trim()
            .matches(/^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}-?[0-9]{4}$/, 'Telefone Inválido')
            .required('Campo obrigatório'),
          whatsapp: Yup.string()
            .trim()
            .matches(/^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}-?[0-9]{4}$/, 'Telefone Inválido')
            .required('campo obrigatório'),
        });

  const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');

  async function handleSubmit(values) {
    setLoading(true);
    const url = `${process.env.REACT_APP_BASE_URL_API_SERVER}/licensedEstablishment/checkExists`;
    const payload = { document: onlyNumbers(values.documento), documentType: documentType };
    const options = { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(payload) };
    try {
      const responseData = await fetch(url, options);
      const response = await responseData.json();
      if (response.success) {
        let establishmentExists = response.data.exists;
        if (establishmentExists) {
          setLoading(false);
          toast({ ...objectToast, description: 'Estabelecimento já credenciado' });
        } else {
          const tipoempresa = documentType === 'CNPJ' ? 0 : 1;
          const vals = {
            ...values,
            tipoempresa,
            qtdeMedicos: values.qtdeMedicos === '' ? 0 : values.qtdeMedicos,
            documento: onlyNumbers(values.documento),
            nextPage: '/empreendedor/cadastro-estabelecimento/detalhes-clinica',
          };
          navigate('/empreendedor/cadastro-estabelecimento/endereco', { state: vals });
        }
      } else {
        setLoading(false);
        toast({ ...objectToast, description: 'Erro ao consultar cpf' });
      }
    } catch (err) {
      setLoading(false);
      toast({ ...objectToast, description: 'Erro ao consultar cpf' });
    }
  }

  // eslint-disable-next-line no-nested-ternary
  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={(values, options) => handleSubmit(values, options)}
    >
      {(formikProps) => {
        return (
          <VStack as={Form} w={{ base: '90%', md: '500px' }} align="start" mx="auto" mt="5" mb="20" spacing={5}>
            <Heading size="sm">Infome os dados do {documentType === 'CPF' ? 'medico' : 'estabelecimento'}</Heading>
            <Input {...defaultArgs} name="nome" placeholder={documentType === 'CPF' ? 'Nome' : 'Nome Fantasia'} type="text" />
            <ButtonGroup w="100%">
              {/*<Button {...defaultArgs} colorScheme={documentType === 'CPF' ? 'teal' : null} onClick={() => setDocumentType('CPF')}>
                Medico
        </Button 
        <Button {...defaultArgs} colorScheme={documentType === 'CNPJ' ? 'teal' : null} onClick={() => setDocumentType('CNPJ')}>
                Clinica
              </Button>*/}
            </ButtonGroup>
            {documentType === 'CPF' ? (
              ''
            ) : (
              <>
                <Input {...defaultArgs} name="razaoSocial" placeholder="Razão Social" type="text" />
                <Input {...defaultArgs} name="qtdeMedicos" placeholder="Quantidade de medicos" type="tel" mask="9999" />
              </>
            )}
            <Input {...defaultArgs} name="documento" placeholder={documentType} type="tel" mask={documentMask} />
            <Input {...defaultArgs} name="email" placeholder="E-mail" type="email" />
            <Input {...defaultArgs} name="fone" placeholder="Telefone" type="tel" mask="(99) 99999-9999" />
            <Input {...defaultArgs} name="celular" placeholder="Celular" type="tel" mask="(99) 99999-9999" />
            <Input {...defaultArgs} name="whatsapp" placeholder="Whatsapp" type="tel" mask="(99) 99999-9999" />
            <Termos onClose={onClose} isOpen={isOpen} finalRef={finalRef} />
            <HStack>
              <Checkbox
                name="termos"
                onChange={() => {
                  if (!acceptTerms) {
                    setAcceptTerms(true);
                  } else {
                    setAcceptTerms(false);
                  }
                }}
              />
              <chakra.a pl="10px" colorScheme="teal" onClick={onOpen}>
                Li e aceito os <u>termos de uso e de parceria</u>.
              </chakra.a>
            </HStack>
            <ButtonGroup w="100%">
              <Button {...defaultArgs} colorScheme="teal" onClick={() => navigate(-1)}>
                Voltar
              </Button>
              <Button
                {...defaultArgs}
                colorScheme="teal"
                type="submit"
                isDisabled={saveButtonIsDisabled}
                loadingText="Carregando..."
                isLoading={loading}
              >
                Continuar
              </Button>
            </ButtonGroup>
          </VStack>
        );
      }}
    </Formik>
  );
}

function DadosClinica() {
  return (
    <>
      <ChildHeader />
      <FormDadosClinica />
      <BottomNavBar />
    </>
  );
}

export default DadosClinica;
