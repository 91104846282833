import ListItems from "./ListarInfoLivre";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function SintomasLivre() {
  const { state } = useLocation();
  const navigate = useNavigate();
  return ListItems("sintoma", state.vals);
}

export default SintomasLivre;
