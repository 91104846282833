import {
  HStack,
  VStack,
  Heading,
  Text,
  Td,
  Badge,
  Table,
  Tr,
  TableContainer,
  List,
  Center,
  ListItem,
  Image,
  Box,
  chakra,
  Flex,
  Spacer,
  useDisclosure,
  Img,
  Stack,
} from '@chakra-ui/react';
import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccountContext } from '../../../AccountContext';
import ChildHeader from '../ChildHeader';
import NoUserHeader from '../../../AreaLivre/NoUserHeader';
import BottomNavBar from '../../../BottomNavBar';
import Loading from 'components/Loading';
import haversine from 'haversine-distance';
import { MdOutlineLocationOn } from 'react-icons/md';
import _ from 'lodash';
import DrawerProcedimento from './DrawerProcedimentos';
import SadFace from '../../../../images/assets/icons/sad_face.svg';
import DrawerCompraPlano from '../DrawerCompraPlano';

function EscolheEmpresa() {
  const navigate = useNavigate();
  const { user, setPage } = useContext(AccountContext);
  const drawerProcedure = useDisclosure();
  const { state } = useLocation();
  const [unidades, setUnidades] = useState([]);
  const [error, setError] = useState(null);
  const numberOfProcedures = user.cart ? user.cart.itens.length : 0;
  const [todos, setTodos] = useState(false);
  const [parciais, setParciais] = useState(false);
  const [pick, setPick] = useState();
  const drawerCompraPlano = useDisclosure();
  const [orcamento, setOrcamento] = useState(false);
  console.log(numberOfProcedures);
  console.log(state);
  console.log(user);
  useEffect(() => {
    if (!user.cart) {
      navigate('/dashboard');
    } else {
      const fetchPosts = async () => {
        const url = process.env.REACT_APP_BASE_URL_API_SERVER + '/schedule/getCompaniesByCart/';
        const payload = {
          cart: user.cart,
          lat: user.coords && user.coords.latitudeAtual ? user.coords.latitudeAtual : null,
          lng: user.coords && user.coords.longitudeAtual ? user.coords.longitudeAtual : null,
        };
        const response = await fetch(url, {
          headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify(payload),
        });
        const postsData = await response.json();
        setUnidades(postsData);
        if (postsData.length === 0) setError('Não há empresas com horarios disponíveis que realizem os procedimentos selecionados.');
      };
      fetchPosts();
    }
  }, [user.cart]);

  const unidadesAgg = _.groupBy(unidades, 'empresa');

  const useUnidades = Object.keys(unidadesAgg).map((unidade, index) => {
    let total_deovita = 0;
    let total_particular = 0;
    console.log(unidadesAgg[unidade]);
    unidadesAgg[unidade].forEach((procedure) => {
      total_deovita += parseFloat(procedure.valor_deovita);
      total_particular += parseFloat(procedure.valor_particular);
    });

    const empresa = unidadesAgg[unidade][0];

    let distancia =
      user.coords && empresa.latitude && empresa.longitude ? (' ' + empresa && empresa.distancia ? empresa.distancia.toFixed(2) + ' km' : '') : '';

    if (unidadesAgg[unidade].length === numberOfProcedures && todos === false) {
      setTodos(true);
    }

    return unidadesAgg[unidade].length === numberOfProcedures ? (
      <ListItem
        fontWeight="bold"
        bgColor="rgba(90, 178, 173, 0.3)"
        borderRadius="20px"
        shadow="md"
        mt="0.8em"
        width="100%"
        backgroundColor="gray.100"
        minH="3em"
        pl="5em"
        m="2%"
        onClick={() => {
          if (user.loggedIn === true) {
            if (state.orcamento_id) {
              setOrcamento(state.orcamento_id);
            }
            setPick(unidadesAgg[unidade]);
            drawerProcedure.onOpen();
          } else {
            drawerCompraPlano.onOpen();
          }
        }}
      >
        <Flex w="100%" minH="40%" maxH="50%">
          {/*"https://static.wixstatic.com/media/78e2d4_a9c84dec0a004ce69058567dca42d82c~mv2.jpg/v1/fill/w_412,h_239,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/IMG_20220722_134843.jpg"*/}
          <Image
            src={`${process.env.REACT_APP_URL_LOGOMARCA}/` + empresa.empresa_id + '/logomarca.png'}
            fallbackSrc={`${process.env.REACT_APP_URL_LOGOMARCA}/logomarca.png`}
            backgroundColor="white"
            border="1px"
            h="5em"
            w="5em"
            ml="-6em"
            mr="5%"
            mt="0.5em"
            mb="0.5em"
            borderRadius="1.5em"
            name={empresa.NAME}
          />

          <VStack>
            <VStack align="right" h="100%" spacing={0} minW="50%" maxH="100%">
              <Text fontSize="xs" mt="2" textColor="gray" textAlign="start" maxW="80" lineheight="24.38px" noOfLines={2}>
                {empresa.empresa}
              </Text>

              <Flex>
                <Box>
                  {' '}
                  <Text fontSize="9px" fontWeight={5} textAlign="left" textColor="gray.400" noOfLines={1}>
                    {empresa.endereco}, {empresa.numero},
                  </Text>
                  <Text fontSize="9px" fontWeight={5} textAlign="left" textColor="gray.400" isTruncated>
                    - {empresa.municipio} / {empresa.estado}
                  </Text>
                </Box>
              </Flex>
            </VStack>
            <Flex alignSelf="start">
              <Badge backgroundColor="orange" color="white" pr="10%" borderLeftRadius="5px">
                <Stack spacing={-1}>
                  <chakra.p fontSize="0.5em">Particular</chakra.p>
                  <chakra.p fontSize="0.8em">{total_particular.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</chakra.p>
                </Stack>
              </Badge>
              <Badge backgroundColor="green" color="white" ml="-1" pr="10%" borderRightRadius="5px">
                <Stack spacing={-1}>
                  <chakra.p fontSize="0.5em">Assinante</chakra.p>
                  <chakra.p fontSize="0.8em">{total_deovita.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</chakra.p>
                </Stack>
              </Badge>
            </Flex>
            <HStack w="100%">
              <Text fontSize="9px" color="#808080">
                Realiza:{' '}
                <chakra.span color="#50A193" textDecoration="underline">
                  {' '}
                  {unidadesAgg[unidade].length} de {numberOfProcedures} | VER
                </chakra.span>
              </Text>
              <Flex>
                <MdOutlineLocationOn class="googlemap" color={user.coords && user.coords.longitudeAtual ? 'green' : '#E2E8F0'} />
                <chakra.span color="orange" fontWeight={5} fontSize="9px">
                  {distancia}
                </chakra.span>
              </Flex>{' '}
            </HStack>
          </VStack>
        </Flex>
      </ListItem>
    ) : (
      <></>
    );
  });

  const useUnidadesParciais = Object.keys(unidadesAgg).map((unidade, index) => {
    const empresa = unidadesAgg[unidade][0];
    let distancia =
      user.coords && empresa.latitude && empresa.longitude ? (' ' + empresa && empresa.distancia ? empresa.distancia.toFixed(2) + ' km' : '') : '';
    if (unidadesAgg[unidade].length < numberOfProcedures && parciais === false) {
      setParciais(true);
    }
    let total_deovita = 0;
    let total_particular = 0;
    console.log(unidadesAgg[unidade]);
    unidadesAgg[unidade].forEach((procedure) => {
      total_deovita += parseFloat(procedure.valor_deovita);
      total_particular += parseFloat(procedure.valor_particular);
    });
    return unidadesAgg[unidade].length < numberOfProcedures ? (
      <ListItem
        fontWeight="bold"
        bgColor="rgba(90, 178, 173, 0.3)"
        borderRadius="20px"
        shadow="md"
        mt="0.8em"
        width="100%"
        backgroundColor="gray.100"
        minH="3em"
        pl="5em"
        m="2%"
        onClick={() => {
          if (user.loggedIn === true) {
            setPick(unidadesAgg[unidade]);
            drawerProcedure.onOpen();
          } else {
            drawerCompraPlano.onOpen();
          }
        }}
      >
        <Flex w="100%" minH="40%" maxH="50%">
          <Image
            src={`${process.env.REACT_APP_URL_LOGOMARCA}/` + empresa.empresa_id + '/logomarca.png'}
            fallbackSrc={`${process.env.REACT_APP_URL_LOGOMARCA}/logomarca.png`}
            backgroundColor="white"
            border="1px"
            h="5em"
            w="5em"
            ml="-6em"
            mr="5%"
            mt="0.5em"
            mb="0.5em"
            borderRadius="1.5em"
            name={empresa.NAME}
          />

          <VStack>
            <VStack align="right" h="100%" spacing={0} minW="50%" maxH="100%">
              <Text fontSize="xs" mt="2" textColor="gray" textAlign="start" maxW="80" noOfLines={2}>
                {empresa.empresa}
              </Text>

              <Flex>
                <Box>
                  {' '}
                  <Text fontSize="9px" fontWeight={5} textAlign="left" textColor="gray.400" noOfLines={1}>
                    {empresa.endereco}, {empresa.numero},
                  </Text>
                  <Text fontSize="9px" fontWeight={5} textAlign="left" textColor="gray.400" isTruncated>
                    - {empresa.municipio} / {empresa.estado}
                  </Text>
                </Box>
              </Flex>
            </VStack>
            <Flex alignSelf="start">
              <Badge backgroundColor="orange" color="white" pr="10%" borderLeftRadius="5px">
                <Stack spacing={-1}>
                  <chakra.p fontSize="0.5em">Particular</chakra.p>
                  <chakra.p fontSize="0.8em">{total_particular.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</chakra.p>
                </Stack>
              </Badge>
              <Badge backgroundColor="green" color="white" ml="-1" pr="10%" borderRightRadius="5px">
                <Stack spacing={-1}>
                  <chakra.p fontSize="0.5em">Assinante</chakra.p>
                  <chakra.p fontSize="0.8em">{total_deovita.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</chakra.p>
                </Stack>
              </Badge>
            </Flex>
            <HStack w="100%">
              <Text fontSize="9px" color="#808080">
                Realiza:{' '}
                <chakra.span color="#50A193" textDecoration="underline">
                  {' '}
                  {unidadesAgg[unidade].length} de {numberOfProcedures} | VER
                </chakra.span>
              </Text>
              <Flex>
                {distancia && (
                  <>
                    <MdOutlineLocationOn class="googlemap" color="green" />

                    <chakra.span color="orange" fontWeight={5} fontSize="9px">
                      {distancia}
                    </chakra.span>
                  </>
                )}
              </Flex>{' '}
            </HStack>
          </VStack>
        </Flex>
      </ListItem>
    ) : (
      <></>
    );
  });
  return unidades.length > 0 ? (
    <>
      {user.loggedIn ? <ChildHeader /> : <NoUserHeader />}
      <VStack m="5%" pb="25%" spacing={3} align="center">
        {pick && <DrawerProcedimento disclosure={drawerProcedure} data={pick} />}
        <DrawerCompraPlano disclosure={drawerCompraPlano} />
        {todos === true ? (
          <Heading fontSize="xs" fontWeight="bold" color="#585858">
            Locais que realizam todos os procedimentos
          </Heading>
        ) : (
          <>
            <VStack w="70%">
              <Img src={SadFace} />
              <Text textAlign="center">Não encontramos uma unidade que realize todos os procedimentos.</Text>
            </VStack>
            <VStack w="100%">
              <Text textAlign="center">Mas você pode selecionar abaixo unidades que fazem separadamente.</Text>
            </VStack>
          </>
        )}
        <List pl="1em" mt="3" w="100%" spacing={4}>
          {unidades && useUnidades}
        </List>
        {parciais === true ? (
          <>
            <Heading fontSize="xs" fontWeight="bold" color="#585858">
              Locais que realizam parte dos procedimentos
            </Heading>
            <List pl="1em" mt="3" w="100%" spacing={4}>
              {unidades && useUnidadesParciais}
            </List>
          </>
        ) : (
          <></>
        )}
      </VStack>
    </>
  ) : (
    <>
      {user.loggedIn ? <ChildHeader /> : <NoUserHeader />}
      <VStack m="5%" pb="25%" spacing={3} align="start">
        <Heading fontSize="lg">Escolha uma empresa</Heading>
        <Box height="10%" w="100%">
          {error === null ? <Loading /> : <Text>{error}</Text>}
        </Box>
      </VStack>
    </>
  );
}

export default EscolheEmpresa;
