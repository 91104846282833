import React from 'react';
import { Img, Image, Grid, GridItem, VStack, HStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import dadosbancariosImg from '../../../../images/assets/icons/iconesEmprendedor/dadosbancarios.png';
import extratofinanceiroImg from '../../../../images/assets/icons/iconesEmprendedor/extratofinanceiro.png';
import materialdeapoioImg from '../../../../images/assets/icons/iconesEmprendedor/materialdeapoio.png';
import qrcodeImg from '../../../../images/assets/icons/iconesEmprendedor/qrcode.png';
import estabelecimentosImg from '../../../../images/assets/icons/iconesEmprendedor/estabelecimentoscredenciados.png';
import cadastrarestabelecimentosImg from '../../../../images/assets/icons/iconesEmprendedor/cadastrarestabelecimento.png';
import contratosImg from '../../../../images/assets/icons/iconesEmprendedor/contratos.png';
import vendedoresImg from '../../../../images/assets/icons/iconesEmprendedor/vendedores.png';

function Menu(props) {
  const { contratos, empresas, vendedores } = props;
  const navigate = useNavigate();

  return (
    <>
      <VStack w="90%" pb="15%">
        <HStack w="90%">
          <Grid w="100%" templateColumns="repeat(2, 1fr)">
            <GridItem w="100%">
              <Img src={qrcodeImg} onClick={() => navigate('/empreendedor/qrcode')} />
            </GridItem>
            <GridItem w="100%">
              <Img
                src={extratofinanceiroImg}
                onClick={() => navigate('/empreendedor/extrato', { state: { vendedores: vendedores, contratos: contratos } })}
              />
            </GridItem>
            <GridItem w="100%">
              <Img src={materialdeapoioImg} onClick={() => navigate('/emprendedor/materialapoio')} />
            </GridItem>
            <GridItem w="100%">
              <Image src={dadosbancariosImg} onClick={() => navigate('/empreendedor/dados-bancarios')} />
            </GridItem>
            {/*
            <GridItem w="100%" h="55">
              <Img src={cadastrarestabelecimentosImg} onClick={() => navigate('/empreendedor/cadastro-estabelecimento/dados-clinica')} />
            </GridItem>
            <GridItem w="100%" h="55">
              <Img src={estabelecimentosImg} onClick={() => navigate('/empreendedor/estabelecimentos', { state: { empresas: empresas } })} />
            </GridItem>
            */}
            {/* <GridItem w="100%" h="55">
              <Img src={contratosImg} onClick={() => navigate('/empreendedor/contratos', { state: { contratos: contratos } })} />
          </GridItem>*/}
            {/*<GridItem w="100%" h="55">
              <Img src={vendedoresImg} onClick={() => navigate('/empreendedor/vendedores', { state: { vendedores: vendedores } })} />
          </GridItem>*/}
          </Grid>
        </HStack>
      </VStack>
    </>
  );
}

export default Menu;
