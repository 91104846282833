import { DeleteIcon } from "@chakra-ui/icons";
import {
    Text,
    Button,
    Table,
    TableContainer,
    Tbody,
    Td,
    Tfoot,
    Th,
    Thead,
    Tr,
    VStack,
    Box,
    useDisclosure,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogFooter,
    AlertDialogContent,
    Center,
} from "@chakra-ui/react";
import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AccountContext } from "../../../AccountContext";
import BottomNavBar from "../../../BottomNavBar";
import ChildHeader from "../ChildHeader";

export default function ResumoPagamento() {
    const { setPage } = useContext(AccountContext);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [carrinho, setCarrinho] = useState(state.carrinho);
    const [currentIndex, setCurrentIndex] = useState();
    let valorParcial = 0;

    setPage("Resumo da compra");

    function AlertDialogExample(props) {
        const cancelRef = React.useRef();

        return (
            <>
                <AlertDialog
                    isOpen={isOpen}
                    motionPreset="slideInBottom"
                    leastDestructiveRef={cancelRef}
                    onClose={onClose}
                    size="xs"
                    isCentered
                    isLazy={true}
                >
                    <AlertDialogOverlay>
                        <AlertDialogContent borderRadius={15}>
                            <AlertDialogFooter>
                                <VStack w="100%" spacing={3} margin={2}>
                                    <Center>
                                        <DeleteIcon h={50} w={50} color="teal" />
                                    </Center>
                                    <Text fontWeight="bold" align="center" fontSize="x-large">
                                        Deseja remover {props.name} da lista
                                    </Text>
                                    <Button
                                        ref={cancelRef}
                                        isFullWidth
                                        fontSize="xl"
                                        onClick={() => {
                                            setCarrinho((state) => {
                                                state.splice(currentIndex, 1);
                                                return [...state];
                                            });
                                            onClose();
                                        }}
                                    >
                                        Remover
                                    </Button>
                                    <Button colorScheme="teal" onClick={onClose} isFullWidth fontSize="xl">
                                        Cancelar
                                    </Button>
                                </VStack>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>
            </>
        );
    }

    const TableItens = () => {
        return (
            <>
                <TableContainer>
                    <Table variant="unstyled">
                        <Thead>
                            <Tr>
                                <Th fontSize="md">Itens</Th>
                                <Th></Th>
                                <Th></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {carrinho.map((item, index) => {
                                valorParcial += parseFloat(item.valor_numerico);
                                return (
                                    <>
                                        <AlertDialogExample name={item.nome} index={index} />
                                        <Tr fontSize="sm">
                                            <Td>{item.nome}</Td>
                                            <Td>{item.valor}</Td>
                                            <Td>
                                                <DeleteIcon
                                                    color="teal"
                                                    onClick={() => {
                                                        setCurrentIndex(index);
                                                        onOpen();
                                                    }}
                                                />
                                            </Td>
                                        </Tr>
                                    </>
                                );
                            })}
                        </Tbody>
                        <Tfoot>
                            <Tr borderTop="1px" borderColor="gray.500">
                                <Th fontSize="sm">Valor parcial</Th>
                                <Th fontSize="sm">R$ {valorParcial ? valorParcial.toString().replace(".", ",") : ""}</Th>
                            </Tr>
                        </Tfoot>
                    </Table>
                </TableContainer>
            </>
        );
    };
    return (
        <>
            <ChildHeader />
            <VStack w={{ base: "90%", md: "300px" }} m="auto" justify="top" mt="5%" mb="5%" spacing="5rem" align="center">
                <TableItens />
                <Box>
                    <Text align="center">Numero de dependentes do plano: {state.dependentes_plano}</Text>
                </Box>
                <Button
                    isFullWidth
                    colorScheme="teal"
                    isDisabled={carrinho <= 0}
                    onClick={() => {
                        let adicionais = carrinho;
                        var listaAdicionaisIds = adicionais.map((item) => item.id);
                        navigate("/pagamentos", {
                            state: {
                                ...state,
                                adicionaisIds: listaAdicionaisIds,
                                navig_to: "/welcome-screen/processar-compra",
                                expiration_pix_in_min: 5,
                                item: "Contratação Adicionais",
                                valor: valorParcial,
                                parcelsActive: true,
                            },
                        });
                    }}
                >
                    Ir para o pagamento
                </Button>
                <BottomNavBar />
            </VStack>
        </>
    );
}
