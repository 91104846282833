import MainChildHeader from '../MainChildHeader';
import Loading from '../../../Loading';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { Center, Heading, Text } from '@chakra-ui/react';
import { useContext } from 'react';
import { AccountContext } from '../../../AccountContext';

const AtivaEmpreendedor = () => {
  const [loading, setLoading] = useState(true);
  const [erro, setErro] = useState(false);
  const navigate = useNavigate();
  const { user, setUser } = useContext(AccountContext);

  async function ativarEmpreendedor() {
    const url = process.env.REACT_APP_BASE_URL_API_SERVER + '/entrepreneur/';
    const options = {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ paciente_id: user.id_cliente_deovita }),
    };
    try {
      const responseData = await fetch(url, options);
      const response = await responseData.json();
      console.log(response);
      setErro(!response.success);
      if (response.success) {
        let empreendedor = response.data;
        setUser((currentUser) => ({
          ...currentUser,
          empreendedor: true,
          id_empreendedor: empreendedor.empreendedor_id,
          id_vendedor: empreendedor.vendedor_id,
        }));
      }
      setLoading(false);
    } catch (erro) {
      setErro(true);
      setLoading(false);
    }
  }

  useEffect(() => {
    ativarEmpreendedor();
  }, []);

  const LoadingScreen = () => (
    <>
      <MainChildHeader />
      <Center>
        <Text>Estamos preparando tudo!</Text>
      </Center>
      <Loading />
    </>
  );
  return loading ? <LoadingScreen /> : erro ? navigate('/alerts/erro/default') : navigate('/alerts/sucesso/ativa_empreendedor');
};

export default AtivaEmpreendedor;
