import { Box, Button, VStack, HStack, Image, Text, useDisclosure } from "@chakra-ui/react";
import { useContext } from "react";
import { useState } from "react";
import img4 from "../../../../images/ad4.png";
import { useLocation, useNavigate } from "react-router-dom";
import { AccountContext } from "../../../AccountContext";
import PopupAdicional from "./PopupAdicional";
import ChildHeader from "../ChildHeader";
import BottomNavBar from "../../../BottomNavBar";

export default function ComprarAdicionais(props) {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [disableButton, setDissableButton] = useState(true);
    const [carrinho, setCarrinho] = useState([]); //{ id: 0, nome: state.nome_plano, valor: state.valor_plano_formatado, valor_numerico: state.valor_plano },
    const { setPage, user } = useContext(AccountContext);
    const adicionais = user.adicionais_pendentes;
    console.log(carrinho);

    setPage("Adicionais");

    const Adicional = (props) => {
        const { isOpen, onOpen, onClose } = useDisclosure();
        let data = props.data;
        if (carrinho.length > 0) {
            setDissableButton(false);
        }

        let selected = carrinho.some((item) => item.nome === data.nome && item.valor === data.valor);

        return (
            <>
                <PopupAdicional isOpen={isOpen} onClose={onClose} data={data} carrinho={carrinho} addCarrinho={setCarrinho} />
                <Box
                    width="100%"
                    padding="2"
                    borderRadius="20"
                    border="0.063rem solid"
                    borderColor={selected ? "green" : "#DCDDE0"}
                    boxShadow="lg"
                    backgroundColor="#fff"
                    textAlign="start"
                    marginTop="2em"
                    onClick={selected ? () => {} : onOpen}
                >
                    <HStack>
                        <Image src={img4} />
                        <Box>
                            <Text fontSize="1.25rem" fontWeight="600" casing="capitalize">
                                {typeof data.nome === "string" ? data.nome : "-"}
                            </Text>
                            <Text fontSize="0.8em" color="#737373">
                                clique para ver mais detalhes
                            </Text>
                            <Text fontSize="sm" mt="2" casing="lowercase">
                                {typeof data.descricao === "string" ? data.descricao : ""}
                            </Text>
                        </Box>
                    </HStack>
                </Box>
            </>
        );
    };

    return (
        <>
            <ChildHeader />
            <VStack w={{ base: "90%", md: "300px" }} m="auto" justify="top" marginBottom="5%" spacing="1rem" align="center">
                {adicionais.map((adicional) => (
                    <Adicional data={adicional} />
                ))}
                <Button
                    colorScheme="teal"
                    mt="12"
                    variant="solid"
                    isFullWidth
                    height="2.75rem"
                    isDisabled={disableButton}
                    onClick={() => navigate("/welcome-screen/resumo-compra", { state: { ...state, carrinho: carrinho } })}
                >
                    Continuar
                </Button>
                <BottomNavBar />
            </VStack>
        </>
    );
}
