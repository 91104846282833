import React, { useState, useContext, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Text } from '@chakra-ui/react';
import { AccountContext } from 'components/AccountContext';

function CircleCart(props) {
  const { user, page, setPage } = useContext(AccountContext);
  const [isOpen, setIsOpen] = useState(false);
  const quantity = user.cart && user.cart.items ? user.cart.items.length : 0;

  console.log(user);

  useEffect(() => {
    setIsOpen(true);
    const timer = setTimeout(() => {
      setIsOpen(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [user.cart?.itens?.length]);

  return (
    <>
      <motion.div
        animate={{ scale: isOpen ? 4 : 1, backgroundColor: isOpen ? '#FFA500' : '#5AB2AD' }}
        transition={{ duration: 0.5 }}
        style={{
          borderRadius: '50%',
          width: '1em',
          height: '1em',
          position: 'absolute',
          marginTop: '1.4em',
          marginLeft: '87.5%',
          backgroundColor: isOpen ? '#FFA500' : '#5AB2AD',
          color: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: '101',
        }}
      >
        <Text fontSize="40%">{props.quantidade}</Text>
      </motion.div>
    </>
  );
}
export default CircleCart;
