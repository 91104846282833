import ImgCartao from '../../images/ImgCartao';
import { React, useState, useContext } from 'react';
import { Button, Heading, VStack, Text, HStack, Select, ButtonGroup } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useNavigate, useLocation } from 'react-router';
import { AccountContext } from '../AccountContext';
import TextField from '../TextField';
import SelectField from '../SelectField';

const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');

const CartaoGenerico = (selecionado) => {
  const valor_selecionado = selecionado.selecionado;
  const mdvPercent = selecionado.mdvPercent;
  const mdvDiretor = selecionado.mdvDiretor;
  const mdvGerente = selecionado.mdvGerente;
  const navigate = useNavigate();
  const { state } = useLocation();
  const { navig_to, valor, parcelsActive, split, voucher } = state;
  const fromCadastro = navig_to === '/cadastro/analise' ? true : false;
  const parcelas = Parcelas(fromCadastro === true ? valor_selecionado : valor);
  const arrayTextoParcelas = parcelas.map((parcela) => parcela.string);

  const { user } = useContext(AccountContext);
  const { cards } = user;
  let defaultCard;
  let defaulData = { cvv: '', name: '', cardNumber: '' };
  function Parcelas(valor_total) {
    let valor_min_parcela = fromCadastro === true ? 80 : 50;
    let qtde_max_parcelas = 12;
    let qtde_parcelas = parseInt(valor_total / valor_min_parcela);
    if (qtde_parcelas <= 0) qtde_parcelas = 1;
    if (qtde_parcelas > qtde_max_parcelas) qtde_parcelas = qtde_max_parcelas;

    let parcelas = [];
    for (var i = 1; i <= qtde_parcelas; i++) {
      let valor_parcela_string = (valor_total / i).toFixed(2);
      let valor_parcela_float = parseFloat(valor_parcela_string);
      let string_parcela = `${i}x R$${valor_parcela_string} (sem juros)`;
      let parcela = { id: i, val: valor_parcela_float, string: string_parcela.replace('.', ',') };
      parcelas.push(parcela);
    }

    return parcelas;
  }
  const arrayCards = cards
    ? cards.map((card, index) => {
        if (card.default) {
          defaultCard = index;
          defaulData = {
            cardId: card.card_id_pagarme,
            name: card.holder_name,
            cardNumber: `${card.brand} final ${card.last_digits}`,
            expirationDate: card.expiration_date,
          };
        }

        return `${card.brand} final ${card.last_digits}`;
      })
    : [];
  arrayCards.push('Adicionar novo cartão');
  const [data, setData] = useState(defaulData);
  const [loading, setLoading] = useState(false);
  const [enterCard, setEnterCard] = useState(false);
  const [aviso, setAviso] = useState(null);
  const [parcel, setParcel] = useState('1');

  const HandleChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  function pagar(vals) {
    const erroMsg = '"Não foi possível completar seu pagamento nesse momento"';

    const requestOptions = {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(vals),
    };

    fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/payment/${state.service}/card`, requestOptions)
      .catch((err) => {
        setLoading(false);
        setAviso(erroMsg);
        return;
      })
      .then((res) => {
        if (!res || !res.ok || res.status >= 400) {
          setLoading(false);
          setAviso(erroMsg);
          return;
        }
        return res.json();
      })
      .then((data) => {
        if (!data) return;
        if (data.sucess === false && data.message === 'Já existe um contrato para esse paciente') {
          navigate('/alerts/sucesso/cadastro_contrato/');
        }
        if (data.sucess) {
          setAviso(null);
          setLoading(false);
          vals['transaction_id'] = data.id;
          vals['paid'] = true;
          navigate(navig_to, { state: { ...vals, ...state } });
        } else {
          setLoading(false);
          setAviso(erroMsg);
        }
      });
  }

  function handleClickCard(card) {
    setData({
      cardId: card.card_id_pagarme,
      name: card.holder_name,
      cardNumber: `${card.brand} final ${card.last_digits}`,
      expirationDate: card.expiration_date,
    });
  }

  function SelectCard() {
    return arrayCards.length === 1 ? (
      navigate('/perfil/editar-cartao', { state: { op: 'new', card: {} } })
    ) : (
      <SelectField
        name="cartoes"
        colorScheme="teal"
        variant="outline"
        onChange={(e) => {
          let lastIndex = arrayCards.length === 1 ? 1 : arrayCards.length - 1;
          if (lastIndex === parseInt(e)) {
            navigate('/perfil/editar-cartao', { state: { op: 'new', card: {} } });
          } else {
            handleClickCard(cards[e]);
          }
        }}
        //label="Cartões"
        placeholder="Selecione o cartão"
        options={arrayCards}
      />
    );
  }

  function SavedCards() {
    return (
      <>
        <Button
          colorScheme="teal"
          variant="ghost"
          isFullWidth
          justifyContent="center"
          onClick={() => {
            setData(defaulData);
            setEnterCard(true);
          }}
        >
          {arrayCards.length === 1 ? 'Cadastrar cartão' : 'Usar outro cartão'}
        </Button>
      </>
    );
  }

  const formCreditCard = () => {
    return (
      <>
        <TextField name="cardNumber" type="tel" placeholder="Número do cartão" mask="9999 9999 9999 9999" maskChar={null} onBlur={HandleChange} />
        <HStack m="auto" justify="center" spacing="1rem" align="start">
          <TextField name="expirationDate" placeholder="Data de validade" type="tel" mask="99/99" maskChar={null} onBlur={HandleChange} />
          <TextField name="cvv" type="tel" placeholder="CVV" onBlur={HandleChange} />
        </HStack>
        <TextField name="name" type="text" placeholder="Nome impresso no cartão" onBlur={HandleChange} />
      </>
    );
  };

  return (
    <>
      <Formik
        initialValues={{ cartoes: defaultCard, parcel: 0 }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values) => {
          var val = {};
          if (user.loggedIn && cards.length > 0) {
            val = {
              cardId: data.cardId,
              amount: fromCadastro === true ? valor_selecionado * 100 : valor * 100,
              installments: parcel,
              forma_pgto: 'card',
              paid: false,
              split_rules: voucher
                ? voucher.recipient_id_gerente
                  ? [
                      { percentage: mdvGerente, recipient_id: voucher.recipient_id_gerente },
                      { percentage: mdvDiretor, recipient_id: voucher.recipient_id_diretor },
                      { percentage: mdvPercent, recipient_id: voucher.recipient_id_pagarme },
                      {
                        percentage: 100 - mdvPercent - mdvDiretor - mdvGerente,
                        recipient_id: `re_clgwth495asjr019tm6ezd3yy`,
                      },
                    ]
                  : voucher.recipient_id_diretor
                  ? [
                      { percentage: mdvDiretor, recipient_id: voucher.recipient_id_diretor },
                      { percentage: mdvPercent, recipient_id: voucher.recipient_id_pagarme },
                      {
                        percentage: 100 - mdvPercent - mdvDiretor,
                        recipient_id: `re_clgwth495asjr019tm6ezd3yy`,
                      },
                    ]
                  : [
                      {
                        percentage: mdvPercent,
                        recipient_id: voucher.recipient_id_pagarme,
                      },
                      {
                        percentage: 100 - mdvPercent,
                        recipient_id: `re_clgwth495asjr019tm6ezd3yy`,
                      },
                    ]
                : split,
              item: fromCadastro
                ? {
                    client_id: state.client_id,
                    nome: state.name,
                    fone: user.phone1 ? user.phone1 : state.phone,
                    adicionais: state.adicionais,
                    plano: state.id_plano,
                  }
                : state.item,
              voucher: voucher,
            };
            console.log(val);
          } else {
            val = {
              cardNumber: typeof values.cardNumber === 'string' ? onlyNumbers(values.cardNumber) : '',
              cvv: values.cvv,
              expirationDate: typeof values.expirationDate === 'string' ? onlyNumbers(values.expirationDate) : '',
              amount: fromCadastro === true ? valor_selecionado * 100 : valor * 100,
              holder_name: values.name,
              split_rules: voucher
                ? voucher.recipient_id_gerente
                  ? [
                      { percentage: mdvGerente, recipient_id: voucher.recipient_id_gerente },
                      { percentage: mdvDiretor, recipient_id: voucher.recipient_id_diretor },
                      {
                        percentage: mdvPercent,
                        recipient_id: voucher.recipient_id_pagarme,
                      },
                      {
                        percentage: 100 - mdvPercent - mdvDiretor - mdvGerente,
                        recipient_id: `re_clgwth495asjr019tm6ezd3yy`,
                      },
                    ]
                  : voucher.recipient_id_diretor
                  ? [
                      { percentage: mdvDiretor, recipient_id: voucher.recipient_id_diretor },
                      {
                        percentage: mdvPercent,
                        recipient_id: voucher.recipient_id_pagarme,
                      },
                      {
                        percentage: 100 - mdvPercent - mdvDiretor,
                        recipient_id: `re_clgwth495asjr019tm6ezd3yy`,
                      },
                    ]
                  : [
                      {
                        percentage: mdvPercent,
                        recipient_id: voucher.recipient_id_pagarme,
                      },
                      {
                        percentage: 100 - mdvPercent,
                        recipient_id: `re_clgwth495asjr019tm6ezd3yy`,
                      },
                    ]
                : split,

              installments: parcel,
              forma_pgto: 'card',
              paid: false,
              item: fromCadastro
                ? {
                    client_id: state.client_id,
                    nome: state.name,
                    fone: user.phone1 ? user.phone1 : state.phone,
                    adicionais: state.adicionais,
                    plano: state.id_plano,
                  }
                : state.item,
              voucher: voucher,
            };
          }
          console.log(val);
          const vals = { ...val };
          setLoading(true);
          setAviso(null);
          pagar(vals);
        }}
      >
        <VStack as={Form} w={{ base: '90%', md: '500px' }} m="auto" justify="top" spacing="0.7rem" align="start">
          <Heading as="h4" size="md">
            Cartão de Crédito
          </Heading>

          <ImgCartao name={data.name ? data.name.toLocaleUpperCase() : ''} cardnumber={data.cardNumber} />
          {user.loggedIn
            ? fromCadastro || state.service === 'telemedicine'
              ? formCreditCard()
              : enterCard
              ? SelectCard()
              : SavedCards()
            : formCreditCard()}
          <Text as="p" color="red.500">
            {aviso}
          </Text>
          <SelectField
            name="parcel"
            colorScheme="teal"
            variant="outline"
            onChange={(e) => {
              setParcel(parseInt(e) + 1);
            }}
            w="100%"
            isDisabled={!parcelsActive}
            options={arrayTextoParcelas}
          />
          <ButtonGroup width="100%">
            <Button colorScheme="teal" type="submit" w="100%" isLoading={loading} loadingText="Carregando">
              Pagar
            </Button>
          </ButtonGroup>
        </VStack>
      </Formik>
    </>
  );
};

export default CartaoGenerico;
